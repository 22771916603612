import React, { useState, useContext, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function PrizesList() {
  const [listContent, setListContent] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/awards/getawards",
        { ReferenceID: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.output.AwardsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }

  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Awards")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"> {localizer.getJsonKey("Awards")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {" "}
                      {localizer.getJsonKey("Awards")}{" "}
                    </h5>

                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            {listContent == undefined && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            {listContent != undefined &&
                              listContent.map((x, ind) => (
                                <div
                                  key={ind}
                                  class="col-12 col-md-12 col-lg-12"
                                >
                                  <div
                                    class="feature-panel-holder"
                                    data-hover=""
                                  >
                                    <div class="feature-panel feature-panel-2">
                                      <div class="feature-content">
                                        <div class="avatar-icon">
                                          <img src={x.iconUrl} alt={localizer.getKey(x.titleAr,x.titleEn)} title={localizer.getKey(x.titleAr,x.titleEn)}  longdesc="#service-single"/>
                                        </div>
                                        <h4 class="mar-bot0">
                                          {localizer.getKey(
                                            x.titleAr,
                                            x.titleEn
                                          )}
                                        </h4>
                                      </div>

                                      <p class="external-desc">
                                        {localizer.getKey(
                                          x.contentAr,
                                          x.contentEn
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu></SubMenu>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PrizesList;
