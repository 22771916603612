import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import _ from "lodash";
import {
    DetailsLoader,
    RepeatableTableRows,
    ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2";  

function CustomerRegistration() {
    const context = useContext(SessionContext);

    const localizer = useLocalizer();
    const [isSubmited, setIsSubmited] = useState(false);
    const [age, setAge] = useState({});
    const [city, setCity] = useState({});
    const [district, setDistrict] = useState({});
    const [qualification, setQualification] = useState({});
    const [gender, setGender] = useState({});
    const [isDone, setIsDone] = useState(false);
    const [ID, setID] = useState(10);
    var valuesData;
    const FormSchema = Yup.object().shape({
        fullname: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
        mobileNo: Yup.string().matches(/^([0-9]{10})$/, localizer.getJsonKey("MaxMobileNo")).required(localizer.getJsonKey("PleaseInsertMobNum")),
        qualification: Yup.string().required(
            localizer.getJsonKey("ChooseQualification")
        ),
        city: Yup.string().required(localizer.getJsonKey("ChooseCity")),
        district: Yup.string().required(localizer.getJsonKey("ChooseDistrict")),
        age: Yup.number().required(localizer.getJsonKey("ChooseAgeRange")),
        gender: Yup.number().required(localizer.getJsonKey("ChooseGender")),
    });
    const FormSchema2 = Yup.object().shape({

        codeNumber: Yup.string().matches(/^([0-9]{4})$/, localizer.getJsonKey("MaxNumber")).required(localizer.getJsonKey("CodeNumber")),
    });
    useEffect(() => {
        if (context.tokenVal != undefined) {
            getContent();
        }
    }, [context.tokenVal]);

    const getContent = async function (selected) {
        //debugger;
        const result = await httpService.get(
            apiUrl + "/CustomerDataRegistration-opts/get-lookups",
            { tokenVal: context.tokenVal }
        );
        debugger
        if (result.data.Data == null) {
            debugger
            setIsDone(false)
            Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
        } else {

            setIsDone(true)
            setAge(result.data.Data.AgeInfo);
            setCity(result.data.Data.cityInfo);
            setDistrict(result.data.Data.DistrictInfo);
            setQualification(result.data.Data.EducationLevelInfo);
            setGender(result.data.Data.GenderInfo);
        }  

    };

    const confirmSubmit = function (values) {
        valuesData = values
        Helpers.confirmALert('هل أنت متأكد من إرسال البيانات ؟', submitForm)

    }
    const submitForm = async function () {
        debugger;

        setIsDone(false)
        await httpService
            .post(
                apiUrl +   "/CustomerDataRegistration-opts/add-CustomerDataRegistration-request",
                {
                    FullName: valuesData.fullname,
                    phoneNumber: valuesData.mobileNo,
                    AgeID: valuesData.age,
                    CityID: valuesData.city,
                    EducationID: valuesData.qualification,
                    GenderID: valuesData.gender,
                 
                    ReferenceID: PortalID,

                },
                { tokenVal: context.tokenVal }
            
            )
            .then((res) => {
                setIsDone(true)
                setID(res.data.Data.ID)
                setIsSubmited(true)
                debugger
                Helpers.notify(localizer.getJsonKey("SendSuccessData"));
            }).catch((error) => {
                setIsDone(true)
                Helpers.notify("الطلب مسجل مسبقًا بنفس رقم الجوال", true);
            })

    };

    const submitCodeNO = async function (values) {
        debugger;
        setIsDone(false)
        await httpService
            .post(
                apiUrl + `/CustomerDataRegistration-opts/update-CustomerDataRegistration-requestVerification?OTP=${values.codeNumber}`,
                {
                         ID: ID,

                }
            )
            .then((res) => {
                debugger
                setIsDone(true)
                Helpers.notify(localizer.getJsonKey("RegistrationSuccess"));
                setTimeout(function () {
                    window.open(window.location.origin + "/ar/CustomerDataRegistration", '_self')

                }, 5000);
            }).catch((error) => {
                setIsDone(true)
                Helpers.notify("حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني", true);
            });
    };
    return (
        <React.Fragment>
            <section className="page-title page-title-6 center" id="page-title">
                <div
                    className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                    style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="title">
                                    <h1 className="title-heading">
                                        {localizer.getJsonKey("CustomerRegistration")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap rtl">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item">
                                <a href={localizer.getLang() + "/"}> {localizer.getJsonKey("Home")}</a>
                            </li>

                            <li className="breadcrumb-item active" aria-current="page">
                                {localizer.getJsonKey("CustomerRegistration")}
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section class="contact contact-4 rtl" id="contact-4">
                <div class="container">
                    <div class="contact-panel contact-panel-3">
                        <div class="heading heading-6">
                            <h2 class="heading-title">
                                {" "}
                                {localizer.getJsonKey("CustomerRegistration")}{" "}
                            </h2>
                        </div>
                        <div class="contact-card">
                            {!isDone ?
                                <ThreeDots></ThreeDots>
                                :
                                <div class="contact-body">
                                    <h5 class="card-heading">
                                      
                                        {!isSubmited ? localizer.getJsonKey("CustomerData") : localizer.getJsonKey("CodeNumber")}
                                    </h5>
                                    {!isSubmited ?

                                        <Formik
                                            initialValues={{
                                                fullname: "",
                                                city: "",
                                                mobileNo: "",
                                                age: "",
                                                district: "",
                                                qualification: "",
                                                gender: "",
                                            }}
                                            validationSchema={FormSchema}
                                            //validateOnChange={false}
                                            //validateOnBlur={false}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {

                                                setSubmitting(true);
                                                await confirmSubmit(values, setSubmitting);
                                                setSubmitting(false);
                                          /*      resetForm();*/
                                            }}
                                        >
                                            {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                                <Form>
                                                    <div className="contactForm">
                                                        {isSubmitting ? (
                                                            <ThreeDots></ThreeDots>
                                                        ) : (
                                                            <div className="row">
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="fullname"
                                                                        placeholder={localizer.getJsonKey("CustomerName")}
                                                                        title={localizer.getJsonKey("CustomerName")}
                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.fullname}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        placeholder={localizer.getJsonKey("MobileNum")}
                                                                        title={localizer.getJsonKey("MobileNum")}
                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.mobileNo}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="gender"
                                                                        placeholder={localizer.getJsonKey("ChooseGender")}
                                                                        title={localizer.getJsonKey("ChooseGender")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("ChooseGender")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(gender) &&
                                                                            gender.map((e, i) => (
                                                                                <option key={i} value={e.ID}>
                                                                                    {e.Gender1}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.gender}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="age"
                                                                        placeholder={localizer.getJsonKey("Age")}
                                                                        title={localizer.getJsonKey("Age")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("ChooseAgeRange")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(age) &&
                                                                            age.map((e, i) => (
                                                                                <option key={i} value={e.ID}>
                                                                                    {e.AGE1}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.age}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="qualification"
                                                                        placeholder={localizer.getJsonKey("Qualification")}
                                                                        title={localizer.getJsonKey("Qualification")}
                                                                    >
                                                                        <option value="0">
                                                                                {localizer.getJsonKey("ChooseQualification")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(qualification) &&
                                                                            qualification.map((e, i) => (
                                                                                <option key={i} value={e.ID}>
                                                                                    {e.EducationLevel1}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.qualification}
                                                                    </label>
                                                                </div>

                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="city"
                                                                            placeholder={localizer.getJsonKey("City")}
                                                                            title={localizer.getJsonKey("City")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("ChooseCity")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(city) &&
                                                                            city.map((e, i) => (
                                                                                <option key={i} value={e.ID}>
                                                                                    {e.CityName}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.city}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="district"
                                                                            placeholder={localizer.getJsonKey("District")}
                                                                            title={localizer.getJsonKey("District")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("ChooseDistrict")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(district) &&
                                                                            district.map((e, i) => (
                                                                                <option key={i} value={e.ID}>
                                                                                    {e.DisrtictName}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.district}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <button
                                                                        style={{ backgroundColor: '#253745', color: 'white' }}
                                                                        className="btn btn--secondary"
                                                                        type="submit"
                                                                        disabled={!isValid}
                                                                    >
                                                                        {localizer.getJsonKey("Send")}{" "}
                                                                        <i className="fas fa-arrow-left"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contact-result"></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        :
                                        <Formik
                                            initialValues={{
                                                codeNumber: ''
                                            }}
                                            validationSchema={FormSchema2}
                                            validateOnChange={false}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {

                                                setSubmitting(true);
                                                await submitCodeNO(values, setSubmitting);
                                                setSubmitting(false);
                                                resetForm();
                                            }}
                                        >
                                            {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                                <Form>
                                                    <div className="contactForm">
                                                        {isSubmitting ? (
                                                            <ThreeDots></ThreeDots>
                                                        ) : (
                                                            <div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="codeNumber"
                                                                        placeholder={localizer.getJsonKey("CodeNumber")}
                                                                        title={localizer.getJsonKey("CodeNumber")}
                                                                        style={{ display: "inline" }}
                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.codeNumber}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <button
                                                                        style={{ backgroundColor: '#253745', color: 'white' }}
                                                                        className="btn btn--secondary"
                                                                        type="submit"
                                                                        disabled={!isValid}
                                                                    >
                                                                        {localizer.getJsonKey("Send")}{" "}
                                                                        <i className="fas fa-arrow-left"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contact-result"></div>
                                                                </div>
                                                            </div>


                                                        )}
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default CustomerRegistration;
