import React, { useState, useContext, useEffect, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { RotatingGallery, ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";
import { Formik, Form, Field, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import _ from "lodash";

const FormSchema = Yup.object().shape({
  answers: Yup.array().min(4, "too short").required("it is required"),
});
// const FormSchema = Yup.object().shape({
//   answers: Yup.array()
//     .of(
//       Yup.object().shape({
//         Ind: Yup.string().required("Name is required"),
//         Id: Yup.string().required("Name is required"),
//       })
//     )
//     .min(1, "Required"),
// });

function Survey() {
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState([]);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      //TODO
      getContent();
    }
  }, [context.tokenVal]);

  const getContent = async function () {
    //debugger;
    const result = await httpService.post(
      apiUrl + "/Surveys/GetPublishedSurveys",
      {
        ReferenceID: PortalID,
      },
      { tokenVal: context.tokenVal }
    );
    setPageContent(result.data.output.SurveysEntity);
  };

  const submitForm = async function (values) {
    //debugger;

    const result = await httpService
      .post(apiUrl + "/surveys/UpdateQuestionsCount", values.answers, {
        tokenVal: context.tokenVal,
      })
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("SendSuccess"));
      });
  };
  return (
    <div className="col-12 col-lg-7">
      <div className="contact-card">
        <div className="contact-body">
          <h5 className="card-heading">{localizer.getJsonKey("HomeSurvey")}</h5>
          <p className="card-desc">{localizer.getJsonKey("HomeSurveyDesc")}</p>
          <Formik
            FormSchema={FormSchema}
            initialValues={{
              answers: [],
            }}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors, setFieldError }
            ) => {
             // console.log(values);
              let answersLength = values.answers.filter(
                (i) => i.Id != ""
              ).length;
              if (answersLength < pageContent.length)
                setErrors(localizer.getJsonKey("PleaseChooseValue"));
              else {
                await submitForm(values);
                resetForm();
              }
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              isValid,
              setFieldValue,
              values,
              setFieldError,
            }) =>
              isSubmitting ? (
                <ThreeDots></ThreeDots>
              ) : (
                <Form>
                  <label   title={localizer.getJsonKey("HomeSurvey")}  className="form-label"
                                  htmlFor="select-1" style={{ color: "red" }}>
                    <div className="d-none"> Errors</div>
                    {!isValid && errors}
                  </label>
                  <FieldArray name="answers">
                    {({ insert, remove, push, replace }) => (
                      <div className="contactForm">
                        <div className="row">
                          {pageContent.length > 0 &&
                            pageContent.map((e, i) => (
                              <div key={i} className="col-12 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="select-1"
                                  title={localizer.getKey(e.nameAr, e.nameEn)}
                                >
                                  {localizer.getKey(e.nameAr, e.nameEn)}
                                </label>
                                <Field
                                  component="select"
                                  name={`answers.${i}.Id`}
                                  className="form-control"
                                  title="Choose"
                                  // onChange={(e) => {
                                  //   let index = _.findIndex(
                                  //     values.answers,
                                  //     (e) => {
                                  //       return e.Ind == i;
                                  //     },
                                  //     0
                                  //   );
                                  //   console.log("founed at index  " + index);

                                  //   index == -1
                                  //     ? push({
                                  //         Ind: i,
                                  //         Id: e.currentTarget.value,
                                  //       })
                                  //     : replace(index, {
                                  //         Ind: i,
                                  //         Id: e.currentTarget.value,
                                  //       });
                                  //   if (
                                  //     index != -1 &&
                                  //     e.currentTarget.value == ""
                                  //   )
                                  //     remove(index);

                                  //   console.log(
                                  //     "targert:" + e.currentTarget.value
                                  //   );
                                  //   console.log(values);
                                  // }}
                                >
                                  <option value="">
                                    {localizer.getJsonKey("PleaseChoose")}
                                  </option>
                                  {e.surveysAnswers.map((se, si) => (
                                    <option value={se.id} key={si}>
                                      {localizer.getKey(se.nameAr, se.nameEn)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            ))}

                          <div className="col-12">
                            <button
                              type="submit"
                              className={
                                "btn btn--secondary w-100 " +
                                (!isValid ? "disabled" : "")
                              }
                            >
                              {localizer.getJsonKey("SendSurvey")}
                              <i className="fas fa-arrow-left"></i>
                            </button>
                          </div>
                          <div className="col-12">
                            <div className="contact-result"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Survey;
