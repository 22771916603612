import React, { useState, useContext, useEffect } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";

import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
function EservicesLinks() {
  const eservicesQuickLinksCode = "1041743780";
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const params = useParams();
  const localizer = useLocalizer();

  useEffect(() => {
    if (context.tokenVal != undefined && params.lid != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Menus/GetMenus",
        {
          ReferenceID: PortalID,
          Code: eservicesQuickLinksCode,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.MenusEntity.subMenus);
        window.loadslider(".eservicesQuickLinks");
      });
  }

  return (
    <section
      className="features features-1 bg-overlay bg-overlay-theme2 rtl bg-section"
      id="features-1"
      style={{ backgroundImage: "url('/assets/images/background/2.jpg')" }}
    >
      <div className="bg-section d-none">
        <img src="/assets/images/background/2.jpg" 
            alt="Background" 
            title="Background" 
            />
      </div>
      <div className="container">
        <div className="heading heading-2 heading-light heading-light2">
          <div className="row">
            <div className="col-12 col-lg-5">
              <p className="heading-subtitle">
                {" "}
                {localizer.getJsonKey("NoNeedPaper")}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-5">
              <h2 className="heading-title">
                {localizer.getJsonKey("Eservices")}
              </h2>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1">
              <p className="heading-desc">
                {localizer.getJsonKey("EservicesDesc")}
              </p>

              <div className="actions-holder">
                {/* <a
                  className="btn btn--primary btn--inversed full-w"
                  href="https://eservices.alriyadh.gov.sa/"
                  target="_blank"
                >
                  {" "}
                  {localizer.getJsonKey("EservicesPortal")}
                  <i className="fas fa-arrow-left"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {pageContent.length == 0 ? (
          <ThreeDots></ThreeDots>
        ) : (
          <div
            className="owl-carousel carousel-dots eservicesQuickLinks"
            data-slide="4"
            data-slide-rs="2"
            data-autoplay="true"
            data-nav="false"
            data-dots="true"
            data-space="25"
            data-loop="true"
            data-speed="800"
            rtl={localizer.getLang().indexOf("ar") == -1 ? "false" : "true"}
          >
            {pageContent.map((e, i) => (
              <div key={i}>
                <div className="feature-panel-holder" data-hover="">
                  <div className="feature-panel">
                    <div className="feature-icon">
                      <i className={e.fontIcon} title={localizer.getKey(e.nameAr, e.nameEn)}></i>
                    </div>
                    <div className="feature-content">
                      <h4>{localizer.getKey(e.nameAr, e.nameEn)}</h4>
                      <p>
                        {_.truncate(
                          localizer.getKey(
                            e.briefeContentAr,
                            e.briefeContentEn
                          ),
                          { length: 80 }
                        )}
                      </p>
                    </div>
                    {e.url.indexOf("http") == -1 ? (
                      <Link
                        to={
                          localizer.getLang() +
                          e.url +
                          (e.articleId == null ? "" : "/" + e.articleId)
                        }
                        target={e.openBlankPage ? "_blank" : ""}
                      >
                        <i className="fas fa-arrow-left"></i>{" "}
                        <span> {localizer.getJsonKey("GoToService")} </span>{" "}
                      </Link>
                    ) : (
                      <a href={e.url} target={e.openBlankPage ? "_blank" : ""}>
                        <i className="fas fa-arrow-left"></i>{" "}
                        <span> {localizer.getJsonKey("GoToService")}</span>{" "}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default EservicesLinks;
