import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import GridList from "../../Components/GridList";
import Pagination from "./Controls/Pagination";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";

import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
import Moment from "react-moment";

export default function EventsList() {
  const [eventsData, seteventsData] = useState();
  const [eventsArr, setEventsArr] = useState([]);

  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  const getEventsData = async (page) => {
    const result = await httpService
      .post(
         apiUrl + "/Events/GetEvents",
        //"https://localhost:44389/gateway/Events/GetEvents",
        {
          ReferenceId: PortalID,
          Pagination: {
            currentPageIndex: page,
            totalPagesCount: null,
            recordPerPage:12
          },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
       // console.log(res.data.output.EventsEntity);
        seteventsData(res.data.output);
        setEventsArr(eventsArr.concat(res.data.output.EventsEntity));
        context.setEntityID(res.data.output.EventId);
      });
  };
  // useEffect(() => {
  //   if (context.tokenVal != undefined) {
  //     getEventsData(1);
  //   }
  // }, [context.tokenVal]);

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("Events")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("MediaCenter")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Events")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="blog blog-grid blog-grid-5 rtl" id="blogevents">
        <div className="container">
          <div className="row">
            <GridList
              itemsLength={eventsArr.length}
              totalCount={
                eventsData != undefined ? eventsData.Pagination.totalItemsCount : 0
              }
              nextFun={getEventsData}
            >
              {eventsArr.map((item) => (
                <div key={item.id} className="col-12 col-md-6 col-lg-4">
                  <div className="blog-entry" data-hover="">
                    <div className="entry-content">
                      <div className="entry-meta">
                        <div className="entry-date">
                          
                         
                          
                        </div>

                        <div className="entry-author"></div>
                      </div>
                      <div className="entry-title">
                        <h4>
                          <Link to={item.id}>
                            {_.truncate(
                              localizer.getKey(item.nameAr, item.nameEn),
                              { length: 60 }
                            )}
                          </Link>
                        </h4>
                      </div>
                      <div className="entry-img-wrap">
                        <div className="entry-img">
                          <Link to={item.id}>
                            <img src={item.image} 
                                alt="event background" 
                                title="event background" 
                                />
                          </Link>
                        </div>
                        <div className="entry-category" style={{color:'white'}}>
                        <span>
                          <Moment format="DD/MM/YYYY"> 
                          { _.trim( item.date)
                          } 
                        </Moment> 
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </GridList>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
