import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl,PortalID, attachmentToken } from "../../Config/Config.json";
import _ from "lodash";
import {
    DetailsLoader,
    RepeatableTableRows,
    ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext, useFormik } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import axios from "axios";
import Swal from "sweetalert2";  

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
//import "./FilePond.css"
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Register the plugins
registerPlugin( FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

function AddIdea() {
    const context = useContext(SessionContext);

    const localizer = useLocalizer();
    const [invalidFile, setinvalidFile] = useState(false);
    const [isloading, setIsloading] = useState(false);

    const [files, setFiles] = useState([]);
    const [number, setNumber] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [lookups, setLookups] = useState({});
    const [ideaFields, setIdeaFields] = useState({});
    const [ideaID, setIdeaID] = useState(null);
    const [fileID, setFileID] = useState(false);
    const [filesLength, setFilesLength] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [fileDuplicated, setFileDuplicated] = useState([]);
    var attachmentId;
    var valuesData;

    const FormSchema = Yup.object().shape({
        fullname: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
        mobileNo: Yup.string().matches(/^([0-9]{10})$/, localizer.getJsonKey("MaxMobileNo")).required(localizer.getJsonKey("PleaseInsertMobNum")),
        email: Yup.string().email(localizer.getJsonKey("EmailNotValid")).required(localizer.getJsonKey("PleaseInsertEmail")),
        ideaDescription: Yup.string().required(
            localizer.getJsonKey("PleaseInsertDesc")
        ).test('len', 'وصف الفكرة يجب ان يحتوي على 500 حروف على الأكثر', val => val?.length <= 500),

        serveMunicipalSector: Yup.string().required(
            localizer.getJsonKey("EnterServeMunicipalSector")
        ),
        isExist: Yup.string().required(
            localizer.getJsonKey("InsertIsExist")
        ),
        ideaFields: Yup.number().required(localizer.getJsonKey("EnterIdeaFields")),
        EnterideaFields: Yup.string().when("ideaFields", {
            is: 12,
            then: Yup.string().required(localizer.getJsonKey("EnterideaFields")).test('len', 'مجال الفكرة يجب ان يحتوي على 50 حروف على الأكثر', val => val?.length <= 50),
        }),
        city: Yup.string().when("country", {
            is: (country) => country != null,
            then: Yup.string().required(localizer.getJsonKey("EnterCity")),
        }),

        country: Yup.string().when("isExist", {
            is: "true",
            then: Yup.string().required(localizer.getJsonKey("EnterCountry")),
        }),

        toggle: Yup.boolean().oneOf([true], localizer.getJsonKey("AcceptIdeaConditions")).required(localizer.getJsonKey("AcceptIdeaConditions")),

    });
    const FormSchema2 = Yup.object().shape({

        codeNumber: Yup.string().matches(/^([0-9]{4})$/, localizer.getJsonKey("MaxNumber")).required(localizer.getJsonKey("CodeNumber")),
    });

    useEffect(() => {
        if (context.tokenVal != undefined) {
            getContent();
            getIdeaFields()
        }

    }, [context.tokenVal]);

    useEffect(() => {
        debugger
     setinvalidFile(false)
        allowedExtensions(files)

    }, [files]);


    useEffect(() => {
        if (files.length == 0)
            setinvalidFile(false)

    }, [files]);

    const getContent = async function (selected) {
        //debugger;
        const result = await httpService.get(
            apiUrl + "/Innovation/GetIdeaCountries",
            {
                ReferenceID: PortalID,
            },
            { tokenVal: context.tokenVal }
        ).then((res) => {
            debugger
            if (res.data.Data != null) {
                setLookups(res.data.Data);
            } else {
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }
           

            debugger
        });

    }
    const getIdeaFields = async function (selected) {
        //debugger;
        const result = await httpService.get(
            apiUrl + "/Innovation/GetIdeaFields",
            {
                ReferenceID: PortalID,
            },
            { tokenVal: context.tokenVal }
        ).then((res) => {
            debugger
            if (res.data.Data != null) {
                setIsDone(true)
                setIdeaFields(res.data.Data);
            } else {

                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }
          

            debugger
        });;

    }

    const submitIdea = function (values) {
        setNumber(values.mobileNo)
        setIsSending(true)
        setFiles([])
        debugger
        httpService
            .post(
                apiUrl + "/Innovation/AddIdea",
                {
                    //CountryID ,ServeMunicipalSector ,IdeaFielDescription,IdeaFieldID                     IdeaFielDescription: values.fieldDescription,
                    FullName: values.fullname,
                    Phone: values.mobileNo,
                    email: values.email,
                    IdeaDescription: values.ideaDescription,
                    IdeaExist: values.isExist == "true" ? true : false,
                    CountryID: values.isExist == "true" ? values.country : null,
                    CityName: values.country != null ? values.city : null,
                    IdeaFielDescription: values.ideaFields == 12 ? values.EnterideaFields : null,
                    IdeaFieldID: values.ideaFields,
                    AttachmentID: attachmentId == undefined ? null : attachmentId,
                    ServeMunicipalSector: values.serveMunicipalSector == "true" ? true : false
                },

            )
            .then((res) => {
                debugger
                setFiles([])

                if (res.data.ResultID == 1) {
                    Helpers.notify(res.data.Message, true);
                    setIsSending(false)

                } else if (res.data.Data != null) {
                    setIdeaID(res.data.Data.Id)
                    sendOTP(values.mobileNo)
                    debugger

                }
            }).catch((error) => {
                Helpers.notify(error.response.data.ResultMessage, true);


            })
    }


    const submitForm = async function (values) {
        valuesData = values
        Helpers.confirmALert('هل أنت متأكد من إرسال الفكرة ؟', confirmSubmit)
        debugger
    }

    const confirmSubmit = function () {
        setIsSending(true)
        setinvalidFile(false)
        debugger
        if (files.length != 0) {
            uploadFile(files, valuesData)
        } else {
            submitIdea(valuesData);
        }
    }


    const uploadFile = async function (files, valuesData) {
        debugger
        for (var i = 0; i < files.length; i++) {
            if (files[i].fileSize <= 4000000) {
                const headers = {
                    'attachment-token': attachmentToken
                }
                var bodyFormData = new FormData();
                bodyFormData.append('FolderName', 'Innovations');
                bodyFormData.append('Token', attachmentToken);
                bodyFormData.append('Attachment.FileName', files[i].file.name);
                bodyFormData.append('Attachment.Extension', files[i].fileExtension);
                bodyFormData.append('Attachment.File', files[i].file);
                if (attachmentId != null && attachmentId != undefined)
                    bodyFormData.append('Attachment.HeaderID', attachmentId);

                const result = await axios.post(apiUrl + "/attachments/add-attachment", bodyFormData, { headers })
                    .then((response) => {
                        debugger
                        if (i == 0) {
                            attachmentId = response.data.data
                        }

                    })
                if (i + 1 == files.length) {
                    debugger
                    submitIdea(valuesData);
                }
            } else {

            }
        }

    }

    const sendOTP = async function (number) {
    /*    setIsloading(true)*/
        debugger
        await httpService
            .post(
                apiUrl + "/IntraVolunteer/otp",
                {
                    Mobile: number,
                    ServiceName: "Innovations"

                    /*     ReferenceID: PortalID,*/
                },
                /*               { tokenVal: context.tokenVal }*/

            )
            .then((res) => {
                debugger
               /* setIsloading(false)*/
                if (res.data.data == true) {
                    setIsSubmited(true)
                    setIsSending(false)
                    debugger
                    Helpers.notify(res.data.resultMessage);
                } else {
                    Helpers.notify(res.data.resultMessage, true);
                }

            }).catch((error) => {
                debugger
                //setIsloading(false)
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            })
    }

    const submitCodeNO = async function (values) {
        debugger;
        setIsloading(true)
        await httpService
            .post(
                apiUrl + "/IntraVolunteer/check-otp",
                {
                    Mobile: number,
                    OtpCode: values.codeNumber,
                    ServiceName: "Innovations"

                }
            )
            .then((res) => {
                debugger
              /*  setIsloading(false)*/
                if (res.data.data != false) {
                   
                    activateIdea(ideaID)
                    debugger

                } else {
                    setIsloading(false)
                    Helpers.notify(res.data.resultMessage, true);
                    /*    setIsSubmited(false)*/
                }
            }).catch((error) => {
                debugger
                setIsloading(false)
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            });

    };
    const submitNewOTP = async function (number) {

        /*        setNumber(values.mobileNo)*/
        debugger;
        await httpService
            .post(
                apiUrl + "/IntraVolunteer/otp",
                {
                    Mobile: number,
                    ServiceName: "Innovations",
                    ReferenceID: PortalID,
                },
                { tokenVal: context.tokenVal }

            )
            .then((res) => {
                debugger
                if (res.data.data == true) {
                    setIsSubmited(true)
                    debugger
                    Helpers.notify(res.data.resultMessage);
                } else {
                    Helpers.notify(res.data.resultMessage, true);
                }

            }).catch((error) => {
                debugger
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            })

    };

    const activateIdea = async function (id) {
        debugger
        const result = await httpService.get(
            apiUrl + `/Innovation/ActivateIdea/${id}`,
            {
                /* ReferenceID: PortalID,*/
            },

        ).then((res) => {
            if (res.data.Data == true) {
                
                Helpers.notify(localizer.getJsonKey("SendIdeaSuccess"));
                //setIsloading(false)
                setTimeout(function () {
                    window.open(window.location.origin + "/ar/AddIdea", '_self')

                }, 5000);
            }
            else {
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }


        });
    }

    const uploadFiles = function (e, file) {
        debugger

        if (e != null) {
            setinvalidFile(true)
        }
        else {
            setinvalidFile(false)

        }
    }

    function allowedExtensions(files) {

        files.find(file => {
            if ( file.fileExtension == "png" || file.fileExtension == "jpg" || file.fileExtension == "jpeg" || file.fileExtension == "docx" || file.fileExtension == "pdf") {
                debugger
          
              //  setinvalidFile(false)
            }
            else {
                debugger
                setinvalidFile(true)
            }
            if (file.fileSize > 4000000) {
                setinvalidFile(true)

            }
            })
    

    };
    return (
        <React.Fragment>
            <section className="page-title page-title-6 center" id="page-title">
                <div
                    className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                    style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="title">
                                    <h1 className="title-heading">
                                        {localizer.getJsonKey("IdeaData")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap rtl">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item">
                                <a href={localizer.getLang() + "/"}> {localizer.getJsonKey("Home")}</a>
                            </li>

                            <li className="breadcrumb-item active" aria-current="page">
                                {localizer.getJsonKey("AddNewIdea")}
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section class="contact contact-4 rtl" id="contact-4">
          
                <div class="container">
                    <div class="contact-panel contact-panel-3 form-card" style={{ flexDirection: "column" }} >
                        <div class="heading heading-6" style={{ textAlign: "center" }}>
                            <h2 class="heading-title">
                                {" "}
                                {localizer.getJsonKey("AddNewIdea")}{" "}
                            </h2>
                        </div>
                        <div class="contact-card">
                            {!isDone ?
                                <ThreeDots></ThreeDots>
                                :
                                <div class="contact-body">
                                    <h5 class="card-heading" style={{marginBottom: "0px;"}}>
                                        {!isSubmited ? "" : localizer.getJsonKey("CodeNumber")}
                                    </h5>

                                    {!isSubmited ?
                                        <Formik
                                            initialValues={{
                                                fullname: "",
                                                email: "",
                                                city: "",
                                                mobileNo: "",
                                                country: "",
                                                isExist: "",
                                                ideaFields: "",
                                                EnterideaFields: "",
                                                ideaDescription: "",

                                            }}
                                            validationSchema={FormSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {

                                                setSubmitting(true);
                                                await submitForm(values, setSubmitting);
                                                setSubmitting(false);
                                                /*  resetForm();*/
                                            }}
                                        >
                                            {({ errors, isSubmitting, isValid, setFieldValue, values }) => (
                                                <Form>

                                                    <div className="contactForm">
                                                        {isSubmitting || isSending ? (
                                                            <ThreeDots></ThreeDots>
                                                        ) : (
                                                                <div className="row label-input">
                                                                    <div className="col-12 mb-4">
                                                                        <p className="heading-desc mt-4 mx-2" style={{ listStyle: "circle;" }}>
                                                                            {localizer.getJsonKey("submitterIdeaCommitted")}
                                                                            <ul style={{ listStyle: "circle;" }}>
                                                                                <li>{localizer.getJsonKey("IdeaConditions")}</li>
                                                                            </ul>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12 ">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("Name")} : <span className="required"><strong className="required"> *</strong></span>

                                                                        </label>
                                                                    <Field
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="fullname"
                                                                        title={localizer.getJsonKey("Name")}
                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.fullname}
                                                                    </label>
                                                                </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("Email")} : <span className="required"><strong className="required"> *</strong></span>
                                                                        </label>
                                                                    <Field
                                                                        className="form-control"
                                                                        name="email"
                                                                        title={localizer.getJsonKey("Email")}

                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.email}
                                                                    </label>
                                                                </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("MobileNum")} : <span className="required"><strong className="required"> *</strong></span>

                                                                        </label>
                                                                    <Field
                                                                        className="form-control"
                                                                        name="mobileNo"
                                                                        title={localizer.getJsonKey("MobileNum")}

                                                                    />
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.mobileNo}
                                                                    </label>
                                                                </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("ServeMunicipalSector")} <span className="required"><strong className="required"> *</strong></span>

                                                                        </label>
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="serveMunicipalSector"
                                                                        title={localizer.getJsonKey("ServeMunicipalSector")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("ServeMunicipalSector")}{" "}
                                                                        </option>
                                                                        <option value="true">
                                                                            {localizer.getJsonKey("Yes")}
                                                                        </option>
                                                                        <option value="false">
                                                                            {localizer.getJsonKey("No")}
                                                                        </option>
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.serveMunicipalSector}
                                                                    </label>
                                                                </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("IsExist")}  <span className="required"><strong className="required"> *</strong></span>

                                                                        </label>
                                                                    <Field
                                                                        className="form-control"
                                                                        component="select"
                                                                        name="isExist"
                          
                                                                        title={localizer.getJsonKey("IsExist")}
                                                                    >
                                                                        <option value="">
                                                                            {localizer.getJsonKey("IsExist")}{" "}
                                                                        </option>
                                                                        <option value="false">
                                                                            {localizer.getJsonKey("No")}
                                                                            </option>
                                                                            <option value="true">
                                                                                {localizer.getJsonKey("Yes")}
                                                                            </option>
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.isExist}
                                                                        </label>

                                                                </div>

                                                                {values.isExist == "true" &&
                                                                    <div className="" style={{ display: "contents" }}>
                                                                            <div className="col-12 " style={{ marginRight: "7px", width: "48%" }}>
                                                                                <label className="">
                                                                                    {localizer.getJsonKey("Country")} : <span className="required"><strong className="required"> *</strong></span>

                                                                                </label>
                                                                            <Field
                                                                                component="select"
                                                                                className="form-control"
                                                                                name="country"
                                                                                title={localizer.getJsonKey("Country")}
                                                                            >
                                                                                <option value="">
                                                                                    {localizer.getJsonKey("ChooseCountry")}{" "}
                                                                                </option>
                                                                                {!_.isEmpty(lookups) &&
                                                                                    lookups.map((e, i) => (
                                                                                        <option key={i} value={e.Id}>
                                                                                            {e.CountryNameAr}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                            <label id="" class=" errorr">
                                                                                {errors && errors.country}
                                                                            </label>
                                                                        </div>
                                                                            <div className="col-12 " style={{ marginRight: "2%", width: "48%" }}>
                                                                                <label className="">
                                                                                    {localizer.getJsonKey("City")} : <span className="required"><strong className="required"> *</strong></span>

                                                                                </label>
                                                                            <Field
                                                                                className="form-control"
                                                                                name="city"
                                                                                placeholder={localizer.getJsonKey("City")}
                                                                                title={localizer.getJsonKey("City")}

                                                                            />
                                                                            <label id="" class=" errorr">
                                                                                {errors && errors.city}
                                                                            </label>
                                                                        </div> </div>
                                                                }


                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("IdeaFields")} : <span className="required"><strong className="required"> *</strong></span>
                                                                        </label>
                                                                    <Field
                                                                        component="select"
                                                                        className="form-control"
                                                                        name="ideaFields"
                                                                        title={localizer.getJsonKey("IdeaFields")}
                                                                    >
                                                                        <option value="">
                                                                                {localizer.getJsonKey("ChooseIdeaFields")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(ideaFields) &&
                                                                            ideaFields.map((e, i) => (
                                                                                <option key={i} value={e.Id}>
                                                                                    {e.FieldName}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.ideaFields}
                                                                    </label>
                                                                </div>
                                                                {values.ideaFields == 12 &&
                                                                        <div className="col-12">
                                                                            <label className="">
                                                                                {localizer.getJsonKey("EnterideaFields")} : <span className="required"><strong className="required"> *</strong></span>
                                                                            </label>
                                                                        <Field className="form-control"
                                                                            component="textarea"
                                                                            rows="2"
                                                                            title={localizer.getJsonKey("EnterideaFields")}
                                                                            name="EnterideaFields"></Field>
                                                                        <label id="" class=" errorr">
                                                                            {errors && errors.EnterideaFields}
                                                                        </label>
                                                                    </div>
                                                                }


                                                                    <div className="col-12">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("IdeaDescription")} : <span className="required"><strong className="required"> *</strong></span>
                                                                        </label>
                                                                    <Field className="form-control"
                                                                        component="textarea"
                                                                        rows="4"
                                                                        title={localizer.getJsonKey("IdeaDescription")}
                                                                        name="ideaDescription"></Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.ideaDescription}
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="mx-2" style={{ fontFamily: 'hanimation', color: "#9b9b9b" }}>
                                                                        {localizer.getJsonKey("Attachments")}
                                                                    </label>
                                                                    <br></br>

                                                                    <FilePond
                                                                        files={files}
                                                                        allowReorder={true}
                                                                        allowMultiple={true}
                                                                        onupdatefiles={setFiles}
                                                                        maxFiles={4}
                                                                        maxFileSize="4MB"
                                                                        name="files"
                                                                        allowFileSizeValidation={true}
                                                                        allowFileTypeValidation={true}
                                                                        onaddfile={(error, file) => uploadFiles(error, file)}
                                                                        acceptedFileTypes={[
                                                                            'image/jpeg', 'image/png',
                                                                            'application/pdf',
                                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                        ]}

                                                                        fileValidateTypeLabelExpectedTypesMap={{
                                                                            "image/jpeg": ".jpg",
                                                                            "image/png": ".png",
                                                                            "application/pdf": ".pdf",
                                                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx"
                                                                            }}
                                                                        labelMaxFileSizeExceeded={'File is too large'}
                                                                        labelInvalidField='Field contains invalid files'
                                                                            labelIdle=
                                                                            {localizer.getLang() == '/ar' ? `<p class="filepondtext heading-desc filepond--label-action">اضغط لاختيار المرفقات</p><p class="filepondtext heading-desc">الحد الأقصى لعدد الملفات هو 4</p>` : `<span class="filepondtext heading-desc filepond--label-action">Drag & Drop your files or Browse</span> <p class=" heading-desc">The maximum number of files is 4</p>`}
                                                                                    
                                                                    />
                                  
                                                                </div>
                                                                <div className="col-12">
                                                                    <label>
                                                                        <Field type="checkbox" name="toggle" className="mb-4" />

                                                                            <span className="mx-2" style={{fontFamily: 'hanimation'} } >{localizer.getJsonKey("termsOfConditionsIdea")}</span>
                                                                        <label id="" className="" style={{ marginTop: "40px", bottom: "0", right: "0", color: "red", marginLeft: "6px", marginRight: "6px", }} >
                                                                            {errors && errors.toggle}
                                                                        </label>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <button
                                                                        style={{ backgroundColor: '#253745', color: 'white' }}
                                                                        className="btn btn--secondary"
                                                                        type="submit"
                                                                        disabled={ invalidFile}
                                                                    >
                                                                        {localizer.getJsonKey("Send")}{" "}
                                                                        <i className="fas fa-arrow-left"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contact-result"></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        :

                                        <Formik
                                            initialValues={{
                                                codeNumber: ''
                                            }}
                                            validationSchema={FormSchema2}
                                            validateOnChange={false}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {

                                                setSubmitting(true);
                                                await submitCodeNO(values, setSubmitting);
                                                setSubmitting(false);
                                                resetForm();
                                            }}
                                        >
                                            {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                                <Form>
                                                    <div className="contactForm">
                                                        {isSubmitting || isloading ? (
                                                            <ThreeDots></ThreeDots>
                                                        ) : (
                                                            <div>
                                                                <div className="col-12 col-md-6">
                                                                    <Field
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="codeNumber"
                                                                        placeholder={localizer.getJsonKey("CodeNumber")}
                                                                        title={localizer.getJsonKey("CodeNumber")}
                                                                        style={{ display: "inline" }}
                                                                    />
                                                                        <label id="" class="label-input errorr">
                                                                        {errors && errors.codeNumber}
                                                                    </label>

                                                                </div>
                                                                <div className=" col-md-6" style={{ cursor: "pointer" }}>
                                                                    <p style={{ cursor: "pointer" }} class="external-desc my-2"
                                                                        onClick={() => submitNewOTP(number)}>
                                                                        {localizer.getJsonKey("SendAnotherCode")}
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    {/*            <p class="external-desc my-2" style={{ cursor: "pointer !important;" } }*/}
                                                                    {/*    onClick={() => submitNewOTP(number)}>*/}
                                                                    {/*    {localizer.getJsonKey("SendAnotherCode")}*/}
                                                                    {/*</p>*/}
                                                                    <button
                                                                        style={{ backgroundColor: '#253745', color: 'white' }}
                                                                        className="btn btn--secondary"
                                                                        type="submit"
                                                                        disabled={!isValid || isloading}
                                                                    >
                                                                        {localizer.getJsonKey("Send")}{" "}
                                                                        <i className="fas fa-arrow-left"></i>
                                                                    </button>

                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contact-result"></div>
                                                                </div>
                                                            </div>


                                                        )}
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
  
        </React.Fragment>
    );
}

export default AddIdea;
