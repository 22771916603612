import React, { useState, useContext, useEffect } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, GMapsKey } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
function DepartmentsDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Reference/GetReferenceDetails",
          { ReferenceID: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.ReferencesEntity);
         // console.log(res.data.output.ReferencesEntity);
          context.setEntityID(
            res.data.output.ReferencesEntity.content.entityId
          );
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent != undefined && pageContent.titleAr}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Departments")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section class="service-single rtl" id="service-single">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 order-0 genericContent">
                <div class="service-entry">
                  <div class="entry-content">
                    <div class="entry-introduction entry-infos">
                      <div class="single-product">
                        <h5 class="entry-heading">
                          {localizer.getKey(
                            pageContent.nameAr,
                            pageContent.nameEn
                          )}
                        </h5>

                        {!_.isNil(pageContent.content.url) && (
                          <div class="col-12">
                            <div class="checkout-banner">
                              <p>
                                {localizer.getJsonKey("GoToWebSite") + " "}
                                {localizer.getKey(
                                  pageContent.nameAr,
                                  pageContent.nameEn
                                )}
                              </p>

                              <a
                                class="btn btn--primary justify-content-center"
                                href={pageContent.content.url}
                                target="_blank"
                              >
                                {localizer.getJsonKey("Goto")}
                              </a>
                            </div>
                          </div>
                        )}
                        <div class="external-sub-logo">
                          <div class="features features-3 row-hover">
                            <p class="entry-desc">
                              {ReactHtmlParser(
                                localizer.getKey(
                                  pageContent.content.contentAr,
                                  pageContent.content.contentEn
                                )
                              )}
                            </p>

                            {!_.isNil(pageContent.content.chiefName) && (
                              <div class="blog-single">
                                <div class="entry-widget-bio">
                                  <div class="entry-widget-content">
                                    {!_.isNil(
                                      pageContent.content.chiefImage
                                    ) && (
                                      <img
                                        src={pageContent.content.chiefImage}
                                        alt={localizer.getKey(pageContent.content.chiefName,pageContent.content.chiefNameEn)}
                                        title={localizer.getKey(pageContent.content.chiefName,pageContent.content.chiefNameEn)}
                                      />
                                    )}
                                    <div class="entry-bio-desc">
                                      <h4>
                                        {pageContent.isAgency
                                          ? localizer.getJsonKey(
                                              "ChiefWordAgency"
                                            )
                                          : localizer.getJsonKey("ChiefWord")}
                                      </h4>
                                      <span>
                                        {localizer.getKey(
                                          pageContent.content.chiefName,
                                          pageContent.content.chiefNameEn
                                        )}
                                      </span>

                                      <p class="entry-desc">
                                        {ReactHtmlParser(
                                          localizer.getKey(
                                            pageContent.content.chiefWord,
                                            pageContent.content.chiefWordEn
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <p class="entry-desc">
                              <strong>
                                {localizer.getJsonKey("ContactWith")}{" "}
                                {localizer.getKey(
                                  pageContent.nameAr,
                                  pageContent.nameEn
                                )}
                              </strong>

                              <br />
                            </p>

                            <div class="row">
                              <div class="col-12 col-lg-6">
                                <p class="entry-desc">
                                  {localizer.getJsonKey("ForContact")}
                                </p>

                                <div class="full-table">
                                  <table class="table">
                                    <tbody>
                                      <tr>
                                        <td class="name">
                                          {" "}
                                          <i class="fas fa-map-marker-alt"></i>{" "}
                                          {localizer.getJsonKey("Telephone")}
                                        </td>
                                        <td class="value">
                                          {" "}
                                          {pageContent.content.phone}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="name">
                                          {" "}
                                          <i class="fas fa-map-marker-alt"></i>{" "}
                                          {localizer.getJsonKey("Address")}
                                        </td>
                                        <td class="value">
                                          {localizer.getKey(
                                            pageContent.content.address,
                                            pageContent.content.addressEn
                                          )}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td class="name">
                                          {" "}
                                          <i class="fas fa-map-marker-alt"></i>{" "}
                                          {localizer.getJsonKey("Email")}
                                        </td>
                                        <td class="value">
                                          {pageContent.content.email}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {!_.isNil(pageContent.content.latitude) && (
                                <div class="col-12 col-lg-6">
                                  <iframe
                                    src={
                                      "https://www.google.com/maps/embed/v1/place?key=" +
                                      GMapsKey +
                                      "&q=" +
                                      _.trim(pageContent.content.latitude) +
                                      "," +
                                      _.trim(pageContent.content.longtitude) +
                                      "&zoom=18"
                                    }
                                    width="100%"
                                    height="280"
                                    style={{ border: "0" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                  ></iframe>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SubMenu></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default DepartmentsDetails;
