import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
import Static from "./Static";

function UnifiedProceduresguide() {
  const [pageContent, setPageContent] = useState(Static);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  let params = useParams();

  useEffect(() => {
    setPageContent(Static);
  }, []);

  const handleClick = (ID) => {
   // console.log(pageContent);
    let temp = pageContent;

    const list = temp.listOfQuestions.map((item) => {
      if (item.id === ID) {
        item.show = !item.show;
      } else {
        item.show = false;
      }
      return item;
    });

    let item = {};
    item.listOfQuestions = list;
    setPageContent(item);
  };
  return (
    <React.Fragment>
      <section
        className="page-title page-title-blank-2 bg-white rtl"
        id="page-title"
      >
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{
            backgroundImage: "url('/assets/images/page-titles/6.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("RMUnifiedProceduresguide")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("RM")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("RMUnifiedProceduresguide")}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading heading-18 text-center">
                <h2 className="heading-title">
                  {localizer.getJsonKey("RMUnifiedProceduresguide")}
                </h2>
              </div>
            </div>
          </div>

          <div class="accordion accordion-2" id="accordion03">
            <div class="row">
              {pageContent &&
                pageContent.listOfQuestions.map((e, i) => (
                  <div key={i} class="col-12">
                    <div class="card">
                      <div class="card-heading">
                        <a
                          className="card-link collapsed "
                          data-hover=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls={"child" + i}
                          href={"#child" + i}
                          onClick={() => handleClick(e.id)}
                        >
                          {e.question}
                        </a>
                      </div>
                      {e.show ? (
                        <div class="card-body">{ReactHtmlParser(e.answer)}</div>
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default UnifiedProceduresguide;
