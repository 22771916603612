import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function PortalStat() {
  const [listContent, setListContent] = useState([]);
  const [CommentsContent, setCommentsContent] = useState([]);
  const [helpfulContent, sethelpfulContent] = useState([]);
  const [NothelpfulContent, setNothelpfulContent] = useState([]);
  const [NothelpfulAnswers, setNothelpfulAnswers] = useState();

  const [NothelpfulAnswersPer, setNothelpfulAnswersPer] = useState();
  const [helpfulAnswers, sethelpfulAnswers] = useState();
  const [helpfulAnswersPer, sethelpfulAnswersPer] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent(0);
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Statistics/GetPortalStatistics",
        //"https://localhost:44389/gateway/Statistics/GetPortalStatistics",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.PortalStatistics.visits);
        sethelpfulContent(res.data.output.PortalStatistics.helpFul);
        setNothelpfulContent(res.data.output.PortalStatistics.notHelpFul);
        setNothelpfulAnswers(res.data.output.PortalStatistics.notHelpfulResult);
        sethelpfulAnswers(res.data.output.PortalStatistics.helpfulResult);
        setNothelpfulAnswersPer(res.data.output.PortalStatistics.nothelpfulPercentage);
        sethelpfulAnswersPer(res.data.output.PortalStatistics.helpfulPercentage);
        setCommentsContent(res.data.output.PortalStatistics.commentsStatistics);
        context.setEntityID(-1);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("PortalStat")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("PortalStat")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("PortalStat")}
                    </h5>
                    <div className="row">
                        <div className="col-12">
                            {/* Most visits stats */}
                            <center> <h6> {localizer.getJsonKey("MostVisit")}  </h6> </center>
                            {listContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            <div class="table-responsive">
                              <table
                                class="table table-hover table-bordered"
                                cellspacing="0"
                                rules="all"
                                border="1"
                              >
                                <tbody>
                                  <tr class="GridViewTr">
                                    <th scope="col">
                                      {localizer.getJsonKey("page")}
                                    </th>
                                    <th scope="col">
                                      {localizer.getJsonKey("ViewsCount")}
                                    </th>

                                    <th scope="col">
                                      {localizer.getJsonKey("Link")}
                                    </th>
                                  </tr>

                                  {listContent.length > 0 &&
                                    listContent.map((x, ind) => (
                                      <tr key={ind}>
                                        <td>{localizer.getKey(x.nameAr, x.nameEn)}</td>

                                        <td>
                                            {x.viewsCount}
                                        </td>
                                        <td>
                                          <a href={localizer.getLang()+ x.link}> {localizer.getJsonKey("pageLink")} </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                    
                    <br />
                    <br />
                    <center><h6>{localizer.getJsonKey("visitorsSatisfaction")}</h6> </center>
                    <div className="row">
                      <div className="bg-success p-2" style={{width:'10px ', height:'10px '}}>
                        <span className=""> &nbsp;</span>
                      </div>
                      <div className="col-10">
                        <span> {localizer.getJsonKey("visitorsSatisfiedAnswers")}  : {helpfulAnswersPer+"%"}   </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="bg-secondary p-2" style={{width:'10px', height:'10px'}}>
                        <span className=""> &nbsp;</span>
                      </div>
                      <div className="col-10">
                        <span> {localizer.getJsonKey("visitorsNotSatisfiedAnswers")}  :  {NothelpfulAnswersPer+"%"}  </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <span style={{width:helpfulAnswersPer+"%", color:'white', textAlign:'center'}} className="bg-success float-lg-start p-2" > {helpfulAnswers} </span>
                      <span style={{width:NothelpfulAnswersPer+"%", color:'white', textAlign:'center'}} className="bg-secondary float-end p-2" > {NothelpfulAnswers} </span>
                    </div>
                  
                    <div className="row">
                       
                        <div className="col-6">
                            {/* helpful Count */}
                                      
                            <center> <h6> {localizer.getJsonKey("MostHelpfulContent")}  </h6> </center>
                            {helpfulContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            <div class="table-responsive">
                              <table
                                class="table table-hover table-bordered"
                                cellspacing="0"
                                rules="all"
                                border="1"
                              >
                                <tbody>
                                  <tr class="GridViewTr">
                                    <th scope="col">
                                      {localizer.getJsonKey("page")}
                                    </th>
                                    <th scope="col">
                                      {localizer.getJsonKey("helpFulCount")}
                                    </th>

                                    <th scope="col">
                                      {localizer.getJsonKey("Link")}
                                    </th>
                                  </tr>

                                  {helpfulContent.length > 0 &&
                                    helpfulContent.map((x, ind) => (
                                      <tr key={ind}>
                                        <td>{localizer.getKey(x.nameAr, x.nameEn)}</td>

                                        <td>
                                            {x.helpFulConunt}
                                        </td>
                                        <td>
                                          <a href={localizer.getLang()+ x.link}> {localizer.getJsonKey("pageLink")} </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                        </div>
                        <div className="col-6">
                            {/* Not helpful Count */}
                                      
                            <center> <h6> {localizer.getJsonKey("MostHelpfulContent")}  </h6> </center>
                            {NothelpfulContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            <div class="table-responsive">
                              <table
                                class="table table-hover table-bordered"
                                cellspacing="0"
                                rules="all"
                                border="1"
                              >
                                <tbody>
                                  <tr class="GridViewTr">
                                    <th scope="col">
                                      {localizer.getJsonKey("page")}
                                    </th>
                                    <th scope="col">
                                      {localizer.getJsonKey("NotHelpfulCount")}
                                    </th>

                                    <th scope="col">
                                      {localizer.getJsonKey("Link")}
                                    </th>
                                  </tr>

                                  {NothelpfulContent.length > 0 &&
                                    NothelpfulContent.map((x, ind) => (
                                      <tr key={ind}>
                                        <td>{localizer.getKey(x.nameAr, x.nameEn)}</td>

                                        <td>
                                            {x.notHelpFulConunt}
                                        </td>
                                        <td>
                                          <a href={localizer.getLang()+ x.link}> {localizer.getJsonKey("pageLink")} </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-12">
                            {/* Most visits stats */}
                            <center> <h6> {localizer.getJsonKey("Comments")}  </h6> </center>
                            {CommentsContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            <div class="table-responsive">
                              <table
                                class="table table-hover table-bordered"
                                cellspacing="0"
                                rules="all"
                                border="1"
                              >
                                <tbody>
                                  <tr class="GridViewTr">
                                    <th scope="col">
                                      {localizer.getJsonKey("page")}
                                    </th>
                                    <th scope="col">
                                      {localizer.getJsonKey("CommentsCount")}
                                    </th>

                                    <th scope="col">
                                      {localizer.getJsonKey("Link")}
                                    </th>
                                  </tr>

                                  {CommentsContent.length > 0 &&
                                    CommentsContent.map((x, ind) => (
                                      <tr key={ind}>
                                        <td>{localizer.getKey(x.nameAr, x.nameEn)}</td>

                                        <td>
                                            {x.commentsCount}
                                        </td>
                                        <td>
                                          <a href={localizer.getLang()+ x.link}> {localizer.getJsonKey("pageLink")} </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PortalStat;
