import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './UpperQuickLinks.scss';
import Slider from "react-slick";
import { FiTriangle } from "react-icons/fi";
import classNames from "classnames";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FiTriangle className={"arrow-next"} onClick={onClick}></FiTriangle>

  );
}
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FiTriangle className={"arrow-prev"} onClick={onClick}></FiTriangle>

  );
}


function UpperQuickLinks() {
  const upperQuickLinksCode = "4966632690";
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  let lang = localizer.getLang();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    pauseOnHover: true,
    slidesToScroll: 1,
    centerPadding: "30px",
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: lang === '/en',
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (context.tokenVal != undefined) {
      //TODO
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Menus/GetMenus",
        {
          ReferenceID: PortalID,
          Code: upperQuickLinksCode,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.MenusEntity.subMenus);
      });
  }
  return (
    
    <div className={classNames("row", "g-0", "features-holder", "radius", { en: lang === '/en' })}>
        <Slider {...settings}>
          {pageContent.map((e, i) => (
            <div key={e.id} className="col-12 col-sm-6 col-md-4 col-lg-2">
              <div className="feature-panel feature-card feature-quick-link" style={{
                boxShadow: "none",
              }}>
                <div className="feature-content">
                  {true ? (
                    <Link className="upper-quick-svg"
                    to={
                      localizer.getLang() +
                      e.url +
                      (e.articleId == null ? "" : "/" + e.articleId)
                    }
                      tabIndex='-1'
                    >
                      <i>
                      <div className="svg-cont">
                      <img src={e.imageIcon} 
                          alt={'alt' + localizer.getKey(e.nameAr, e.nameEn)}
                          title={localizer.getKey(e.nameAr, e.nameEn)}
                          />
                      </div>
                      </i>
                      <h5>{localizer.getKey(e.nameAr, e.nameEn)}</h5>
                    </Link>
                  ) : (
                    <a className="upper-quick-svg" href={e.url} target={e.openBlankPage ? "_blank" : ""} aria-label={localizer.getKey(e.nameAr, e.nameEn)} >
                      <i>
                      <div className="svg-cont" >
                      <img src={e.imageIcon} 
                          alt={'alt' + localizer.getKey(e.nameAr, e.nameEn)} 
                          title={localizer.getKey(e.nameAr, e.nameEn)} 
                          />
                      </div>
                      </i>
                      <h5>{localizer.getKey(e.nameAr, e.nameEn)}</h5>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>


    
  );
}

export default UpperQuickLinks;
