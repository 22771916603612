import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";

import Cookies from "universal-cookie";
import Helpers from "../Helpers/Helpers";
import useLocalizer from "../Localization/useLocalizer";
import Moment from "react-moment";
import { ThreeDots } from "../Components/SkeletonLoader";
import _ from "lodash";

export default function BottomRating() {
  const location = useLocation();
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState(Helpers.GetRatingToLocalStorage());
  const [helpful, setHelpful] = useState(Helpers.GetHelpfulToLocalStorage());
  let [searchParams, setSearchParams] = useSearchParams();
  let queryID = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    setRatings(Helpers.GetRatingToLocalStorage());
    setHelpful(Helpers.GetHelpfulToLocalStorage());
  }, [context.entityID, location.pathname, searchParams, pageContent]);

  useEffect(() => {
    if (
      context.tokenVal != undefined &&
      context.entityID != undefined &&
      context.entityID != -1
    ) {
      getContent();
    }
  }, [context.entityID, location.pathname, searchParams]);

  const submitRating = async function (rvalue) {
    setLoading(true);
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Rates/InsertRates",
        {
          ReferenceID: PortalID,
          EntityID: context.entityID == undefined ? null : context.entityID,
          ItemId: queryID.did != undefined ? queryID.did : "",
          Value: rvalue,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setLoading(false);
        Helpers.SaveRatingToLocalStorage(location.pathname);
        //Helpers.notify(localizer.getJsonKey("SendSuccess"));
        getContent();
      });
  };
  const submitHelpful = async function (ishelpful) {
    // setLoading(true);
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Statistics/SetHelpfulOrNot",
        {
          ReferenceID: PortalID,
          EntityID: context.entityID == undefined ? null : context.entityID,
          ID: queryID.did != undefined ? queryID.did : "",
          IsHelpful: ishelpful,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        // setLoading(false);
        Helpers.SaveHelpfulToLocalStorage(location.pathname);
        getContent();
        //Helpers.notify(localizer.getJsonKey("SendSuccess"));
      });
  };
  const getContent = async function () {
    if (context.entityID != -1 && context.entityID != undefined) {
      await httpService
        .post(
          apiUrl + "/Statistics/GetEntitiesStatistics",
          {
            ReferenceID: PortalID,
            EntityID: context.entityID,
            ID: queryID.did != undefined ? queryID.did : null,
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.Statistics);
        });
    }
  };

  return (
    <section
      className={
        "content-settings " +
        ((location.pathname == "/ar" ||
          location.pathname == "/en" ||
          context.entityID == -1 ||
          context.entityID == undefined) &&
          "d-none")
      }
    >
      <div className="container">
        <div className="r_conts">
          <div className="useful">
            <span className="rc_title">
              {localizer.getJsonKey("IsContentHelpful")}
            </span>
            {_.indexOf(helpful, location.pathname) == -1 ? (
              <>
                <button
                  className="btn btn--primary justify-content-center"
                  style={{ margin: "5px" }}
                  onClick={() => submitHelpful(true)}
                >
                  {localizer.getJsonKey("Yes")}
                </button>
                <button
                  className="btn btn--primary justify-content-center"
                  style={{ margin: "5px" }}
                  onClick={() => submitHelpful(false)}
                >
                  {localizer.getJsonKey("No")}
                </button>
              </>
            ) : (
              "تمت الإجابة"
            )}
          </div>
          <div className="rate">
            <span className="rc_title">
              {localizer.getJsonKey("RateContent")}
              <span className="selected-rating">{pageContent.ratingValue}</span>
              <small> / 5</small>
            </span>
            {_.indexOf(ratings, location.pathname) == -1 ? (
              <div className="form-group " id="rating-ability-wrapper">
                <label className="control-label" htmlFor="rating">
                  <span className="field-label-info"></span>
                  <input
                    type="hidden"
                    id="selected_rating"
                    name="selected_rating"
                    value="5"
                    required="required"
                  />
                </label>

                <button
                  type="button"
                  className={
                    "btnrating btn btn-lg " +
                    (pageContent.ratingValue >= 1 && " btn-warning")
                  }
                  data-attr="1"
                  id="rating-star-1"
                  onClick={(e) => submitRating(1)}
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={(e) => submitRating(2)}
                  className={
                    "btnrating btn btn-lg " +
                    (pageContent.ratingValue >= 2 && " btn-warning")
                  }
                  data-attr="2"
                  id="rating-star-2"
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={(e) => submitRating(3)}
                  className={
                    "btnrating btn btn-lg " +
                    (pageContent.ratingValue >= 3 && " btn-warning")
                  }
                  data-attr="3"
                  id="rating-star-3"
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={(e) => submitRating(4)}
                  className={
                    "btnrating btn btn-lg " +
                    (pageContent.ratingValue >= 4 && " btn-warning")
                  }
                  data-attr="4"
                  id="rating-star-4"
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={(e) => submitRating(5)}
                  className={
                    "btnrating btn btn-lg " +
                    (pageContent.ratingValue >= 5 && " btn-warning")
                  }
                  data-attr="5"
                  id="rating-star-5"
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                </button>
              </div>
            ) : (
              "تم التقييم "
            )}
          </div>
          <div className="read">
            <span className="rc_title">
              {localizer.getJsonKey("RatesCount")}:{" "}
              <span>{pageContent.ratingCount} </span>
            </span>
            <span className="rc_title">
              {localizer.getJsonKey("ViewsCount")}:{" "}
              <span>{pageContent.viewsCount} </span>
            </span>
          </div>
          <div className="update">
            <span className="rc_title">
              <span className="fc_title">
                {" "}
                {localizer.getJsonKey("LastUpdate")}
              </span>
            </span>
            <span id="">
              <Moment format="DD/MM/YYYY">{pageContent.latestUpdate}</Moment>
            </span>
          </div>

          <span className="clr"></span>
        </div>
      </div>
    </section>
  );
}
