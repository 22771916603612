import React, { useState, useEffect, useContext } from "react";
import {
  Routes,
  Route,
  Link,
  useSearchParams,
  useParams,
} from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import GridList from "../../Components/GridList";
import useLocalizer from "../../Localization/useLocalizer";

function EServicesList() {
  const [contentArr, setContentArr] = useState([]);
  const [responseContent, setResponseContent] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const context = useContext(SessionContext);
  let params = useParams();
  const localizer = useLocalizer();

  // useEffect(() => {
  //   //debugger;
  //   if (context.tokenVal != undefined) {
  //     getListContent();
  //   }
  // }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...contentArr].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setContentArr(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...contentArr].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setContentArr(strAscending);
  }
  const getListContent = async (page) => {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Govservices/GetEserviceList",
        {
          ReferenceID: PortalID,
          ParentId: params.cid,
          Pagination: {
            currentPageIndex: page,
            totalPagesCount: 15,
          },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setResponseContent(res.data.output);

        setContentArr(contentArr.concat(res.data.output.EservicesEntity));
        context.setEntityID(res.data.output.EntityID);
      });
  };
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Eservices")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>الرئيسية</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("Eservices")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <div class="row">
                    <div class="col-6  col-lg-6">
                    <h5 class="entry-heading">
                    {localizer.getJsonKey("Eservices")}
                    </h5>
                    </div>
                    
             
                      
                      <div class="col-3  col-lg-3 ">
                            <button style={{height:30,width:'85% !important'}} 
                              class="btn btn--secondary w-100 "
                              onClick={SortAscending}
                            >
                              {localizer.getJsonKey("SortAscending")}
                              <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                            </button>
                           
                          </div>
                       <div class="col-3  col-lg-3 ">
                         
                            <button style={{height:30,width:'85% !important'}} 
                              class="btn btn--secondary w-100 "
                              onClick={SortDescending}
                            >
                              {localizer.getJsonKey("SortDescending")}
                              <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                            </button>
                            
                   
                      </div>
                    </div>
                
                    
                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            <GridList
                              itemsLength={contentArr.length}
                              totalCount={
                                responseContent != undefined
                                  ? responseContent.Pagination.totalItemsCount
                                  : 0
                              }
                              nextFun={getListContent}
                            >
                              {contentArr.length > 0 &&
                                contentArr
                                  .filter((x, i) =>
                                    searchTerm == ""
                                      ? x
                                      : x.nameAr.includes(searchTerm)
                                  )
                                  .map((x, ind) => (
                                    <div
                                      key={ind}
                                      class="col-12 col-md-12 col-lg-12"
                                    >
                                      <div
                                        class="feature-panel-holder"
                                        data-hover=""
                                      >
                                        <div class="feature-panel feature-panel-2">
                                          <div class="feature-content">
                                            {/*<div class="avatar-icon">
                                              <img src={x.imageUrl} alt="" />
                                  </div>*/}
                                            <h4 class="mar-bot0">
                                              
                                              {localizer.getKey(
                                              x.nameAr,
                                              x.nameEn
                                            )}

                                              </h4>
                                          </div>
                                          <a href={x.path} target="_blank">
                                            {" "}
                                            <span>{localizer.getJsonKey("Details")}</span>{" "}
                                            <i class="fas fa-arrow-left"></i>
                                          </a>

                                          {/*<p class="col-12 external-desc">
                                            {x.siteNameAr}
                                </p>*/}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                            </GridList>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12 text--center">
                  <ul class="pagination">
                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a class="current" href="">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="">2</a>
                    </li>
                    <li>
                      <a href="">3</a>
                    </li>
                    <li>
                      <a href="">4</a>
                    </li>

                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-left"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <SubMenu />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default EServicesList;
