import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import BottomComments from "./BottomComments";
import BottomRating from "./BottomRating";
import MasterFooter from "./MasterFooter";
import MasterHeader from "./MasterHeader";

function MasterLayout(props) {
  const { pathname } = useLocation();
  const params = useParams();
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/vendor.min.css"
      />
      <link rel="stylesheet" type="text/css" href="/assets/css/LineIcons.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/home.css" />

      {params.lid == "ar" ? (
        <React.Fragment>
          <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/costum.css"
          />
          <link rel="stylesheet" type="text/css" href="/assets/css/Fixes.css" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/styleEn.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/costumEn.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/Fixes_en.css"
          />
        </React.Fragment>
      )}
      <MasterHeader></MasterHeader>
      {props.renderControl}
      <BottomComments></BottomComments>
      <BottomRating></BottomRating>
      <MasterFooter nomargin></MasterFooter>
    </React.Fragment>
  );
}

export default MasterLayout;
