import React from "react";
import { Outlet } from "react-router";
import useLocalizer from "../Localization/useLocalizer";

function ParentComplaint() {
  const localizer = useLocalizer();
  return (
    <React.Fragment>
      <section className="map map-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="d-none">موقعنا على الخريطة</h3>
            </div>
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10258.595289464889!2d46.71509368357826!3d24.62502395596511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05a0bab9b661%3A0x4227e17f708691b4!2z2KPZhdin2YbYqSDZhdiv2YrZhtipINin2YTYsdmK2KfYtg!5e0!3m2!1sar!2ssa!4v1630839668367!5m2!1sar!2ssa"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>

      <section
        className="testimonial testimonial-5 bg-overlay bg-overlay-white2 rtl bg-section"
        style={{
          backgroundImage: "url('/assets/images/background/wavy-pattern.png')",
        }}
      >
        <div className="container">
          <div className="contact-panel contact-panel-2 product-tabs">
            <Outlet/>
            
          </div>
        </div>
      </section>

     
    </React.Fragment>
  );
}

export default ParentComplaint;
