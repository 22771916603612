import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

export const RepeatableTableRows = (props) => {
  const { rows = 5 } = props;
  const rowHeight = 60;

  return (
    <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`} {...props}>
      {new Array(rows).fill(" ").map((el, index) => {
        const contentVerticalPosition = (contentHeight) =>
          rows > 1 ? contentHeight + rowHeight * index : contentHeight;
        return (
          <Fragment key={index}>
            <rect
              x="20"
              y={`${contentVerticalPosition(20)}`}
              rx="4"
              ry="4"
              width="40"
              height="20"
            />
            <rect
              x="100"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
            <rect
              x="750"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
            <rect
              x="1450"
              y={`${contentVerticalPosition(20)}`}
              rx="4"
              ry="4"
              width="20"
              height="20"
            />
            <rect
              y={`${contentVerticalPosition(59)}`}
              x="10"
              ry="10"
              width="1500"
              height="1"
            />
          </Fragment>
        );
      })}
    </ContentLoader>
  );
};
RepeatableTableRows.metadata = {
  name: "Lukas Werner",
  github: "sherpaPSX",
  description:
    "Repeatable table rows. You can easily define number of rows in props and generate then in one svg",
  filename: "RepeatableTableRows",
};

export const DetailsLoader = (props) => (
  <ContentLoader
    width={"100%"}
    height={500}
    style={{ direction: "rtl" }}
    viewBox="0 0 450 400"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
    {...props}
  >
    <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
    <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
    <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
  </ContentLoader>
);

DetailsLoader.metadata = {
  name: "Nic Bovee", // My name
  github: "ghettifish", // Github username
  description: "A simple favorite from the DoorDash local favorites.", // Little tagline
  filename: "DetailsLoader", // filename of your loader
};

export const ThreeDots = (props) => (
  <ContentLoader
    viewBox="0 0 400 160"
    height={160}
    width={400}
    backgroundColor="var(--global--color-primary)"
    {...props}
  >
    <circle cx="150" cy="86" r="8" />
    <circle cx="194" cy="86" r="8" />
    <circle cx="238" cy="86" r="8" />
  </ContentLoader>
);

ThreeDots.metadata = {
  name: "RioF",
  github: "clariokids",
  description: "Three Dots",
  filename: "ThreeDots",
};
// export default {
//   DetailsLoader,
//   RepeatableTableRows,
// };

export const RotatingGallery = (props) => {
  return (
    <ContentLoader
      backgroundColor="var(--global--color-primary)"
      viewBox="0 0 1644 360"
      height={360}
      width={1644}
      {...props}
    >
      <rect x="448" y="30" rx="0" ry="0" width="750" height="300" />
      <rect x="239" y="53" rx="0" ry="0" width="643" height="254" />
      <rect x="30" y="76" rx="0" ry="0" width="527" height="208" />
      <rect x="762" y="53" rx="0" ry="0" width="643" height="254" />
      <rect x="1087" y="76" rx="0" ry="0" width="527" height="208" />
    </ContentLoader>
  );
};

RotatingGallery.metadata = {
  name: "Marius Jørgensen",
  github: "marjorg",
  description: "A rotaing gallery",
  filename: "RotatingGallery",
};
