import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID} from "../../Config/Config.json";
import _ from "lodash";
import Select from 'react-select';

import {
    DetailsLoader,
    RepeatableTableRows, 
    ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";  

function Volunteers(props) {
    const context = useContext(SessionContext);
    /* const animatedComponents = makeAnimated();*/
    const localizer = useLocalizer();
    const [selectedSkill, setselectedSkill] = useState([]);
    const [arr, setarr] = useState(null);
    const [reformattedData, setReformattedData] = useState(null);
    const [selected, setSelected] = useState([]);
    const [errorMessage, seterrorMessage] = useState(false);
    const [volunteerSkills, setVolunteerSkills] = useState([]);
    const [lookups, setLookups] = useState({});
    var thisIsMyCopy = '<p>copy copy copy <strong>strong copy</strong></p>';
    var valuesData;
    const FormSchema = Yup.object().shape({
        fullname: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
        mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
        userId: Yup.string().matches(/^([0-9]{10})$/, localizer.getJsonKey("MaxIDNo")).required(localizer.getJsonKey("PleaseInsertID")),
        email: Yup.string()
            .email(localizer.getJsonKey("EmailNotValid"))
            .required(localizer.getJsonKey("PleaseInsertEmail")),
        qualification: Yup.string().required(
            localizer.getJsonKey("ChooseQualification")
        ),
        district: Yup.string().required(localizer.getJsonKey("ChooseDistrict")),
        age: Yup.string().required(localizer.getJsonKey("ChooseAgeRange")),
        toggle: Yup.string().required(localizer.getJsonKey("AcceptConditions")),
        toggle: Yup.boolean().oneOf([true], localizer.getJsonKey("AcceptConditions")),
        selected: Yup.array().required('Required'),
        gender: Yup.string().required(localizer.getJsonKey("ChooseGender")),
        recaptcha: Yup.string().required(
            localizer.getJsonKey("PleaseInsertCapcha")
          ),
    });
    useEffect(() => {
        if (context.tokenVal != undefined) {
            getContent();
        }

    }, [context.tokenVal]);

    const getContent = async function (selected) {
        //debugger;
        const result = await httpService.get(
            apiUrl + "/Volunteers/GetLookups",
            {
                ReferenceID: PortalID,
            },
            { tokenVal: context.tokenVal }
        ).then((res) => {
            if (res.data.output != null) {
                setLookups(res.data.output);
                if (res.data.output.Skills) {

                    res.data.output.Skills.map((obj) => {
                        if (localizer.getLang() == '/ar') {
                            selectedSkill.push({
                                label: obj.nameAr,
                                value: obj.id,
                            });
                        } else {
                            selectedSkill.push({
                                label: obj.nameEn,
                                value: obj.id,
                            });
                        }


                    });
                }
            } else {
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }

            debugger
        }).catch((error) => {
            Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
        })

    }

    const confirmSubmit = function (values) {
        console.log(selected)
        seterrorMessage(true)

        valuesData = values
        if (selected.length <= 3 && selected.length !=  0) {
            seterrorMessage(false)
            Helpers.confirmALert(localizer.getJsonKey("ConfirmSubmit"), submitForm, localizer.getLang())
        }
       
    }

    const submitForm = async function () {

            selected.map((obj) => {
             
                volunteerSkills.push({
                    SkillId: obj.value,
                });
            })
        
            debugger
            await httpService
                .post(
                    apiUrl + "/Volunteers/SaveVolunteers ",
                    {
                        ReferenceID: PortalID,
                        mobileNo: valuesData.mobileNo,
                        userId: valuesData.userId,
                        name: valuesData.fullname,
                        email: valuesData.email,
                        qualificationId: valuesData.qualification,
                        districtId: valuesData.district,
                        ageId: valuesData.age,
                        genderId: valuesData.gender,
                        Capcha: valuesData.recaptcha,
                        VolunteerSkills: volunteerSkills,

                    },
                    { tokenVal: context.tokenVal }
                )
                .then((res) => {
                    debugger          
                    if (res == null) {
                        debugger
                    } else {
                        if (res.data.header.success == true) {
                            Helpers.notify(localizer.getJsonKey("SendVolunteeringSuccess"));
                            setTimeout(function () {
                                window.open(window.location.origin + "/ar/Volunteers", '_self')

                            }, 5000);

                        }
                    }
               

                }).catch((error) => {
                    debugger
                    Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);


                })
            setVolunteerSkills([])
        //};
    }
    // Function triggered on selection
    function handleSelect(event) {
        debugger

        setSelected(current => [...current, { value: event.value }]);
    }
    return (
        <React.Fragment>
            <section className="page-title page-title-6 center" id="page-title">
                <div
                    className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                    style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="title">
                                    <h1 className="title-heading">
                                        {localizer.getJsonKey("VolunteerRequest")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap rtl">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item">
                                <a href={localizer.getLang() + "/"}> {localizer.getJsonKey("Home")}</a>
                            </li>

                            <li className="breadcrumb-item active" aria-current="page">
                                {localizer.getJsonKey("VolunteerRequest")}
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section class="contact contact-4 rtl" id="contact-4">
                <div class="container">
                    <div class="contact-panel contact-panel-3 form-card" style={{ flexDirection: "column" }} >
                        <div class="heading heading-6" style={{ textAlign: "center" }}>
                            <h2 class="heading-title">
                                {" "}
                                {localizer.getJsonKey("VolunteerRequest")}{" "}
                            </h2>

                            <p class="heading-desc" style={{marginRight:"30px;",marginLeft:"30px;"}}>
                                {localizer.getJsonKey("VolunteerDesc")}
                            </p>
                        </div>
                        <div class="contact-card">
                            <div class="contact-body">
                                {/*<h5 class="card-heading" style={{textAlign: "center"}}>*/}
                                {/*    {localizer.getJsonKey("VolunteerRequestForm")}*/}
                                {/*</h5>*/}
                                <p class="card-desc">
                                    {localizer.getJsonKey("VolunteerFormDesc")}
                                </p>
                                <Formik
                                    initialValues={{
                                        fullname: "",
                                        userId: "",
                                        email: "",
                                        mobileNo: "",
                                        age: "",
                                        district: "",
                                        qualification: "",
                                        gender: "",
                                        recaptcha: "",
                                        toggle: false,
                                        selected: []
                                    }}
                                    validationSchema={FormSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        await confirmSubmit(values, setSubmitting);
                                        setSubmitting(false);
                                        /* resetForm();*/
                                    }}
                                >
                                    {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                        <Form>
                                            <div className="contactForm">
                                                {isSubmitting ? (
                                                    <ThreeDots></ThreeDots>
                                                ) : (
                                                        <div className="row label-input" >
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("Name")} : <span className="required"><strong className="required"> *</strong></span>
                                                                   
                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="fullname"
                                                              
                                                                    title={localizer.getJsonKey("Name")}
                                                            />
                                                            <label id="" class=" errorr">
                                                                {errors && errors.fullname}
                                                            </label>
                                                        </div>

                                                            <div className="col-12 col-md-6">
                                                                <label id="" >
                                                                    {localizer.getJsonKey("UserID")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="userId"
                                                                title={localizer.getJsonKey("UserID")}
                                                            />
                                                            <label id="" class=" errorr">
                                                                {errors && errors.userId}
                                                            </label>
                                                        </div>
                                                            <div className="col-12 col-md-6">
                                                                <label id="" className="heading-desc">
                                                                    {localizer.getJsonKey("Email")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                title={localizer.getJsonKey("Email")}
                                                                required=""
                                                            />
                                                            <label id="" class=" errorr">
                                                                {errors && errors.email}
                                                            </label>
                                                        </div>
                                                            <div className="col-12 col-md-6">
                                                                <label id="" className="heading-desc">
                                                                    {localizer.getJsonKey("MobileNum")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="mobileNo"
                                                                title={localizer.getJsonKey("MobileNum")}
                                                            />
                                                            <label id="" class=" errorr">
                                                                {errors && errors.mobileNo}
                                                            </label>
                                                        </div>
                                                            <div className="col-12 col-md-6">
                                                                <label id="" className="heading-desc">
                                                                    {localizer.getJsonKey("Age")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                component="select"
                                                                name="age"
                                                                placeholder="age"
                                                                title="age"
                                                            >
                                                                <option value="0">
                                                                    {localizer.getJsonKey("ChooseAgeRange")}{" "}
                                                                </option>
                                                                    {!_.isEmpty(lookups) &&  localizer.getLang() == '/ar' &&
                                                                    lookups.AgeRanges.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameAr}
                                                                        </option>
                                                                    ))}
                                                                    {!_.isEmpty(lookups) && localizer.getLang() != '/ar' &&
                                                                    lookups.AgeRanges.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameEn}
                                                                        </option>
                                                                    ))}
                                                            </Field>
                                                            <label id="" class=" errorr">
                                                                {errors && errors.age}
                                                            </label>
                                                        </div>
                                                            <div className="col-12 col-md-6">
                                                                <label id="" className="heading-desc">
                                                                    {localizer.getJsonKey("District")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                component="select"
                                                                name="district"
                                                                placeholder="district"
                                                                title="district"
                                                            >
                                                                <option value="0">
                                                                    {localizer.getJsonKey("ChooseDistrict")}{" "}
                                                                </option>
                                                                    {!_.isEmpty(lookups) && localizer.getLang() == '/ar' &&
                                                                    lookups.Districts.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameAr}
                                                                        </option>
                                                                    ))}
                                                                    {!_.isEmpty(lookups) && localizer.getLang() != '/ar' &&
                                                                    lookups.Districts.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameEn}
                                                                        </option>
                                                                    ))}
                                                            </Field>
                                                            <label id="" class="errorr">
                                                                {errors && errors.district}
                                                            </label>
                                                        </div>
                                                            <div className="col-12 col-md-6">
                                                                <label id="">
                                                                    {localizer.getJsonKey("Qualification")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                component="select"
                                                                name="qualification"
                                                                placeholder="qualification"
                                                                title="qualification"
                                                            >
                                                                <option value="0">
                                                                    {localizer.getJsonKey("ChooseQualification")}{" "}
                                                                </option>
                                                                    {!_.isEmpty(lookups) &&  localizer.getLang() == '/ar' &&
                                                                    lookups.Qualifications.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameAr}
                                                                        </option>
                                                                    ))}
                                                                    {!_.isEmpty(lookups) && localizer.getLang() != '/ar' &&
                                                                    lookups.Qualifications.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameEn}
                                                                        </option>
                                                                    ))}
                                                            </Field>
                                                            <label id="" class=" errorr">
                                                                {errors && errors.qualification}
                                                            </label>
                                                        </div>

                                                            <div className="col-12 col-md-6">
                                                                <label id="">
                                                                    {localizer.getJsonKey("Gender")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                            <Field
                                                                className="form-control"
                                                                component="select"
                                                                name="gender"
                                                                placeholder="gender"
                                                                title="gender"
                                                            >
                                                                <option>
                                                                    {localizer.getJsonKey("ChooseGender")}{" "}
                                                                </option>
                                                                    {!_.isEmpty(lookups) && localizer.getLang() == '/ar' &&
                                                                    lookups.Genders.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameAr}
                                                                        </option>
                                                                    ))}
                                                                    {!_.isEmpty(lookups) && localizer.getLang() != '/ar' &&
                                                                    lookups.Genders.map((e, i) => (
                                                                        <option key={i} value={e.id}>
                                                                            {e.nameEn}
                                                                        </option>
                                                                    ))}
                                                            </Field>
                                                            <label id="" class=" errorr">
                                                                {errors && errors.gender}
                                                            </label>
                                                        </div>
                                                        <div className="col-12 " style={{
                                                            fontFamily: "hanimation",
                                                            color: "#9b9b9b;",
                                                            fontSize: "1rem;",
                                                            fontWeight: "400;"
                                                            }} >
                                                                <label id="">
                                                                    {localizer.getJsonKey("Skills")}<span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                                <Select style={{ padding: "5px", borderColor: "red"}}
                                                                className=" pt-6"
                                                                options={selectedSkill}
                                                                placeholder={localizer.getJsonKey("ChooseSkills")}
                                                                defaultValue={selected}
                                                                onChange={setSelected}
                                                                isSearchable={true}
                                                                isMulti

                                                            />

                                                                <label id="" class=" " style={{ color: "red", fontFamily: "Bahij_TheSansArabic-Plain;" }} >
                                                                    {selected.length > 3 || errorMessage ? localizer.getJsonKey("ChooseSkills") : ''}
                                                            </label>
                                                        </div>

                                                        <div
                                                            className="col-12"
                                                            style={{ marginTop: "10px" }}
                                                        >
                                                            <ReCAPTCHA
                                                                sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                                                                onChange={(response) => {
                                                                    console.log(response);
                                                                    setFieldValue("recaptcha", response);
                                                                }}
                                                            ></ReCAPTCHA>
                                                            <div>

                                                                <label id="" class="col-12 errorr" style={{ position: "relative;" }}>
                                                                    {errors && errors.recaptcha}
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 ">
                                                            <p className="heading-desc mt-4 mx-2" style={{ listStyle: "circle;" }}>
                                                                    {localizer.getJsonKey("VolunteerCommitted")}
                                                                <ul style={{ listStyle: "circle;" }}>
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_1")}</li>
                                 
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_2")}</li>
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_3")}</li>
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_4")}</li>
                                                                        <li style={{ listStyle: "inside;" }}> {localizer.getJsonKey("Rule_5")}</li>
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_6")}</li>
                                                                        <li style={{ listStyle: "inside;" }}>{localizer.getJsonKey("Rule_7")}</li>
                                                                </ul>
                                                            </p>

                                                            <label>
                                                                <Field type="checkbox" name="toggle" className="my-4" />

                                                                    <span className="mx-2" style={{ fontFamily: "hanimation" }} >{localizer.getJsonKey("termsOfConditions")}</span>
                                                                <label id="" className="" style={{ marginTop: "40px", bottom: "0", right: "0", color: "red", marginLeft: "6px", marginRight: "6px", }} >
                                                                    {errors && errors.toggle}
                                                                </label>
                                                            </label>
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                style={{ backgroundColor: '#253745', color: 'white' }}
                                                                className="btn btn--secondary"
                                                                type="submit"
                                                              
                                                            >
                                                                {localizer.getJsonKey("Send")}{" "}
                                                                <i className="fas fa-arrow-left"></i>
                                                            </button>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="contact-result"></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Volunteers;