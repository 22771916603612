import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import { Chart } from "react-google-charts";
import useLocalizer from "../../Localization/useLocalizer";

function TotalRequests() {
  const [yearlyContent, setYearlyContent] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getYearlyContent();
    }
  }, [context.tokenVal]);

  async function getYearlyContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/EserviceStats/GetTotalStatsYearly",
        { ReferenceId: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setYearlyContent(res.data.output);
        context.setEntityID(-1);
      });
  }
  return (
    <div class="features features-3 mar-top50">
      <div class="contact-card">
        <div class="heading heading-1">
          <h2 class="heading-title">
            {localizer.getJsonKey("EservicesRequests")}
          </h2>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 d-none">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <i class="flaticon-026-world"></i>
                </div>
                <div class="feature-content">
                  <h4> {localizer.getJsonKey("EservicesRequestsCount")} </h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      <span class="counting" data-counterup-nums="1,108,202">
                        {yearlyContent && (
                          <span
                            class="counting"
                            data-counterup-nums={
                              yearlyContent.PrevEserviceStatsEntity
                            }
                          >
                            {yearlyContent.PrevEserviceStatsEntity}
                          </span>
                        )}
                      </span>
                    </div>
                    <div class="counter-name">
                      <h6> {new Date().getFullYear() - 1} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <i class="flaticon-026-world"></i>
                </div>
                <div class="feature-content">
                  <h4> {localizer.getJsonKey("EservicesRequestsCount")} </h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      {yearlyContent && (
                        <span
                          class="counting"
                          data-counterup-nums={
                            yearlyContent.CurrEserviceStatsEntity
                          }
                        >
                          {parseFloat(
                            yearlyContent.CurrEserviceStatsEntity
                          ).toLocaleString()}
                        </span>
                      )}
                    </div>
                    <div class="counter-name">
                      <h6>{new Date().getFullYear()}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="heading heading-1 d-none">
          <p class="heading-subtitle heading-subtitle-bg">
            رسم توضيحي بالاشهر لعام 2021 وحتى تاريخه
          </p>
        </div>
      </div>
    </div>
  );
}

export default TotalRequests;
