import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams ,useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID ,newOpenDataGateWay} from "../../Config/Config.json";

import _ from "lodash";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";

function CommissionerActivation() {
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  const navigate = useNavigate();

   useEffect(() => {
    async function checkActivation() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/CommissionerAdditionRequests/ActivateEmail?email="+searchParams.get('email')+"&code=" + searchParams.get('code') ,
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          if(res.data.succeeded && (res.data.message!="" || res.data.message!=null)){
            Helpers.notify(localizer.getJsonKey(res.data.message));
          }
          navigate(localizer.getLang() + "/");
        });
    }
    
    if (context.tokenVal != undefined) {
      checkActivation();
    }
  }, [context.tokenVal]);

  return (
    <div></div>
  );
}
export default CommissionerActivation;
