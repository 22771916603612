import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function ExternalSites() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const localizer = useLocalizer();

  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/ExternalSites/GetExternalSites",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.ExternalSitesEntity);
          context.setEntityID(res.data.output.EntityID);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("ExternalSites")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("ExternalSites")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("ExternalSites")}
                    </h5>
                    {pageContent == undefined ? <RepeatableTableRows /> : ""}
                    <div class="single-product">
                      <div class="product-tabs ">
                        <ul class="nav nav-tabs" role="tablist">
                          {pageContent != undefined &&
                            pageContent.map((x, ind) => (
                              <li key={ind} role="presentation">
                                <a
                                  class={ind == 0 ? "active" : ""}
                                  href={"#tab" + ind}
                                  data-bs-target={"#tab" + ind}
                                  aria-controls={"tab" + ind}
                                  role="tab"
                                  data-bs-toggle="tab"
                                  aria-selected="true"
                                >
                                  {localizer.getKey(x.nameAr, x.nameEn)}
                                </a>
                              </li>
                            ))}
                        </ul>
                        <div class="tab-content external-sub-logo">
                          {pageContent != undefined &&
                            pageContent.map((x, ind) => (
                              <div
                                key={"sub" + ind}
                                class={
                                  "tab-pane" + (ind == "0" ? " active" : "")
                                }
                                id={"tab" + ind}
                                role="tabpanel"
                              >
                                <div class="features features-3 row-hover">
                                  <div class="row">
                                    {x.siteslists.map((sub, sind) => (
                                      <div
                                        key={sind}
                                        class="col-12 col-md-12 col-lg-12"
                                      >
                                        <div
                                          class="feature-panel-holder"
                                          data-hover=""
                                        >
                                          <div class="feature-panel feature-panel-2">
                                            <div class="feature-content">
                                              <div class="avatar">
                                                <img
                                                  src={sub.image}
                                                  alt={localizer.getKey(sub.nameAr,sub.nameEn)}
                                                  title={localizer.getKey(sub.nameAr,sub.nameEn)}
                                                />
                                              </div>
                                              <h4>
                                                {localizer.getKey(
                                                  sub.nameAr,
                                                  sub.nameEn
                                                )}
                                              </h4>
                                            </div>
                                            <a href={sub.url} target="_blank">
                                              <span>
                                                {localizer.getJsonKey(
                                                  "VisitSite"
                                                )}
                                              </span>
                                              <i class="fas fa-arrow-left"></i>
                                            </a>

                                            <p class="external-desc">
                                              {localizer.getKey(
                                                sub.briefeContentAr,
                                                sub.briefeContentEn
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu></SubMenu>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ExternalSites;
