import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import { apiUrl, ContentMenuCode } from "../../Config/Config.json";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import { TbExternalLink } from "react-icons/tb";

import _ from "lodash";
import * as Yup from "yup";

function MasterNewsLetters() {
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      //console.log("calling initiatives");

    }
  }, [context.tokenVal]);

  const FormSchema = Yup.object().shape({
    
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail"))
  });
  
  const submitForm = async function (values) {
    const result = await httpService
      .get(
        apiUrl + "/News/SaveNewsLettersEmail?email=" + values.email,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
       
        if(res.data.header.success == true)
          Helpers.notify(localizer.getJsonKey("SendSuccess"));
        else
        {
          Helpers.notify(localizer.getJsonKey("Error"));
        }
      });
  };
  const handleClickWeather = () => {
    window.open('https://www.my.gov.sa/wps/portal/snp/content/subscribe/!ut/p/z1/jY9BDoIwFETPwgn6f2kLLqsoiJZSEcRuTFekiaIxxvNruheZ3STvJTPEkp7Y0b394F7-Prrrt5-tuFBUeY5Adar2CzBxwcwhEVu5E-QUgArXUCBDDYxuwGSZrPgRS-wEsXP8sk4ZSkCtY74Es1KJlk2HAHyeDz8i4b9vAzL1IABTEwMwsaFxT_K4tW0Pvh5kFH', '_blank');
  };
  const handleClickNaturalDisasters = () => {
    window.open('https://www.my.gov.sa/wps/portal/snp/content/subscribe/!ut/p/z1/jY9BDoIwFETPwgn6f2kLLqsoiJZSEcRuTFekiaIxxvNruheZ3STvJTPEkp7Y0b394F7-Prrrt5-tuFBUeY5Adar2CzBxwcwhEVu5E-QUgArXUCBDDYxuwGSZrPgRS-wEsXP8sk4ZSkCtY74Es1KJlk2HAHyeDz8i4b9vAzL1IABTEwMwsaFxT_K4tW0Pvh5kFH0AxRcc0w!!/#header2_4','_blank');
  };

  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("NewsLetter")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("NewsLetter")}</a>
              </li>
            </ol>
          </div>
        </div>

      </section>
      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-12 order-0">
              <div class="service-entry">
                  <div class="entry-content">
                    <div class="entry-introduction entry-infos">
                      <h3 class="entry-heading">
                        {localizer.getJsonKey("NewsLetter")}
                      </h3>
                      <h6>{localizer.getJsonKey("NewsLetterHeader")} </h6>

                    </div>
                  </div>
                </div>
            </div>
            

            <div className="blog-single comm-inter">
            <div className="entry-widget entry-add-comment mb-0  clearfix">
              <div className="entry-widget-title">
                <h4> {localizer.getJsonKey("NewsLetterUse")}</h4>
              </div>

              <div className="entry-widget-content">
              <Formik
              initialValues={{
                email: ""
              }}
              validationSchema={FormSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values);
                resetForm();
              }}
            >
            {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
            <Form>
            <div className="mb-0">
              <div className="row">
                <div className="col-12 col-lg-3">
              
                  <Field
                    className="form-control"
                    type="email"
                    id="contact-usage"
                    name="email"
                    placeholder= {localizer.getJsonKey("YourEmail")}
                    title= {localizer.getJsonKey("YourEmail")}
                    required=""
                  />
                  </div>
                 
                  <div className="col-12 col-lg-5">

                  </div>
                </div>
                <div className='row'>
                <div className="col-12 col-lg-3">
                    <button
                    className="btn btn--primary btn-line btn-line-before"
                    type="submit"
                    disabled={!isValid}
                  >
                    <span>
                      {" "}
                      {localizer.getJsonKey("SendNewsLetter")}
                    </span>
                    <i className="fas fa-arrow-left"></i>
                  </button>
          
                    
                       </div>
                <div className="col-12 col-lg-3">
                   <button onClick={handleClickWeather}
                                            type="submit"
                                            class="btn btn--secondary w-100"
                                          >
                                            {localizer.getJsonKey("NewsLetterWeather")}
                                            <i class="fas fa-arrow-left"></i>
                   </button>
                   </div>
                   <div className="col-12 col-lg-3">
                   <button onClick={handleClickNaturalDisasters}
                                            type="submit"
                                            class="btn btn--secondary w-100"
                                          >
                                            {localizer.getJsonKey("NewsLetterNaturalDisasters")}
                                            <i class="fas fa-arrow-left"></i>
                   </button>
                   </div>
                   </div>
              </div>

            </Form>
            )}
          </Formik>
               
              </div>
            </div>
            </div>
        </div>
      </div>
           
      </section>


    </React.Fragment>
  );
}

export default MasterNewsLetters;
