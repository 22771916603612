import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GridList from "../../Components/GridList";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import { apiUrl, PortalID } from "../../Config/Config.json";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
import Moment from "react-moment";
import Moments from "moment";
import {  useSearchParams } from "react-router-dom";

import {  Field,Form,Formik } from "formik";

export default function CParticipations() {
  const [newsData, setnewsData] = useState();
  const [newsArr, setNewsArr] = useState([]);
  const [artists, setArtists] = useState([]);
  const [searchParams] = useSearchParams();
  const [lookups, setLookups] = useState({});
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    
  if (
    context.tokenVal != undefined 
  ) {
    getContent();
    getNewsData();
  }
}, []);


const getContent = async () => {
  const resultLookups = await httpService
    .post(
      apiUrl + "/Consultations/GetLookups",
      {
        ReferenceId: PortalID,
        // Pagination: {
        //   currentPageIndex: page,
        //   totalPagesCount: 15,
        // },
      },
      { tokenVal: context.tokenVal }
    )
    .then((resultLookups) => {
  

      setLookups(resultLookups.data.output);
     // console.log("consultationssgggg",resultLookups.data.output);
    });
};

  const getNewsData = async () => {
    const result = await httpService
      .post(
        apiUrl + "/Consultations/GetConsultationsList",
        {
          ReferenceId: PortalID,
          // Pagination: {
          //   currentPageIndex: page,
          //   totalPagesCount: 15,
          // },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger
        setnewsData(res.data.output);
        // setNewsArr(newsArr.concat(res.data.output.ConsultationsEntity));
        setNewsArr(res.data.output.ConsultationsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
      getContent();
  };

  const submitSearch = async function (values) {
    const objectArray = newsData.ConsultationsEntity;
    const valueArray = values.chkEntityType;
    var arr = [...artists]; //copy array by value  
    if(localizer.getLang() == "/ar")
    {
     _.filter(objectArray, (array, index) => {
          
        const strArray = newsData.ConsultationsEntity.filter(x => x.consultationsStatusAr.includes(valueArray[index])
         || x.consultationsTopicTextAr.includes(valueArray[index]) 
         || x.consultationsClassAr.includes(valueArray[index]) ||x.consultationsTypeAr.includes(valueArray[index]) 
         );
         {strArray.map((item) => {
        
          if(!arr.find(x => x.id == item.id ))
          {
            arr.push(item)
  
          }
         })}
  
       
      });
    }
    else
    {
      _.filter(objectArray, (array, index) => {
          
        const strArray = newsData.ConsultationsEntity.filter(x => x.consultationsStatusTextEn.includes(valueArray[index])
         || x.consultationsTopicTextEn.includes(valueArray[index]) 
         || x.consultationsClassEn.includes(valueArray[index]) ||x.consultationsTypeEn.includes(valueArray[index]) 
         );
         {strArray.map((item) => {
        
          if(!arr.find(x => x.id == item.id ))
          {
            arr.push(item)
  
          }
         })}
  
       
      });
    }
  
    if(valueArray.length > 0)
    {
      setNewsArr(arr); 
    }
   // console.log(arr);
    
  };
  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("Participations")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Participations")}
              </li>
            </ol>
          </div>
        </div>
      </section>
 <div className="row">
      <section className="col-12 col-lg-8 rtl">
        <div className="container">
          <div className="row">
            <GridList
              itemsLength={newsArr.length}
              totalCount={
                newsData != undefined ? newsData.length : 0
              }
              nextFun={getNewsData}
            >
              {newsArr.map((item) => (
                
               
                <div key={item.id} className="col-12 col-md-6 col-lg-4">
                  <div className="blog-entry" data-hover="">
                  
                    <div className="entry-content">
                    <div class="entry-introduction">  
                        
                        <h6 class="entry-heading">
                           {localizer.getJsonKey("ConsultationsTitle")}
                        </h6>
                        <div  style={{height:30}} className="entry-desc">
                          <p>{localizer.getKey(item.nameAr, item.nameEn)}</p>
                        </div>

                        <h6 class="entry-heading">
                          {localizer.getJsonKey("ConsultationsTopic")}
                        </h6>
                        <div className="entry-desc">
                            <p>{localizer.getKey(item.consultationsTopicTextAr, item.consultationsTopicTextEn)}</p>
                        </div>

                        <h6 class="entry-heading">
                          {localizer.getJsonKey("ConsultationsDate")}
                        </h6>
                        <div className="entry-desc">
                          <p><Moment format="MM-DD-YYYY">{item.startDate}</Moment> -  <Moment format="MM-DD-YYYY">{item.endDate}</Moment></p>
                        </div>

                        <h6 class="entry-heading">
                          {localizer.getJsonKey("ConsultationsStatus")}
                        </h6>
                        <div className="entry-desc">
                          <p>{localizer.getKey(item.consultationsStatusAr, item.consultationsStatusEn)}</p>
                        </div>

                        <div className="entry-more">
                        
                        <a
                                        href={item.url}
                                        target="_blank"
                                        className="btn btn--white btn-bordered"
                                      >
                                        {" "}
                                        {localizer.getJsonKey("Details")}  {" "}
                                        <i className="fas fa-arrow-left"></i>
                                      </a>


                          
                        </div>
                    </div>
                  </div>
                
                </div>
                </div>
              ))}
              
            </GridList>
          </div>
        </div>
      </section>

      <section className="col-12 col-lg-4 service-single rtl"   id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0 srt-srt-right">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <div class="contact-panel no-marr">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                             
                              <div class="">
                                <div class="row">
                                  <Formik
                                    initialValues={{
                                      searchTerm:
                                        searchParams.get("query") != undefined
                                          ? searchParams
                                              .get("query")
                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                          : "",
                                      fromDate: "",
                                      toDate: "",
                                      chkEntityType: [],
                                    }}
                                    onSubmit={(
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                     // console.log(values);
                                      submitSearch(values);
                                      //resetForm();
                                    }}
                                  >
                                    {({
                                      errors,
                                      touched,
                                      isSubmitting,
                                      isValid,
                                    }) => (
                                      <Form>
                                       
                                        <div className="col-12 col-md-12">
                                          <label className="form-label">
                                            {localizer.getJsonKey(
                                              "ConsultationsStatus"
                                            )}
                                          </label>
                                          <div
                                            className="custom-radio-group"
                                            id="custom-radio-group"
                                          >
                                              <>
                                              {lookups.ConsultationsStatusEntity !=null &&  lookups.ConsultationsStatusEntity.map((item) => (
                                              
                                              <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={localizer.getKey(item.nameAr, item.nameEn)}
                                                value={localizer.getKey(item.nameAr, item.nameEn)}
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={localizer.getKey(item.nameAr, item.nameEn)}>
                                              {localizer.getKey(item.nameAr, item.nameEn)}
                                              </label>
                                            </div>
                                             ))}                                          
                                            </>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <label className="form-label">
                                            {localizer.getJsonKey(
                                              "ConsultationsClass"
                                            )}
                                          </label>
                                          <div
                                            className="custom-radio-group"
                                            id="custom-radio-group"
                                          >
                                              <>
                                              {lookups.ConsultationsClassEntity != null && lookups.ConsultationsClassEntity.map((item) => (
                                              
                                              <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={localizer.getKey(item.nameAr, item.nameEn)}
                                                value={localizer.getKey(item.nameAr, item.nameEn)}
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={localizer.getKey(item.nameAr, item.nameEn)}>
                                              {localizer.getKey(item.nameAr, item.nameEn)}
                                              </label>
                                            </div>
                                             ))}                                          
                                            </>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <label className="form-label">
                                            {localizer.getJsonKey(
                                              "ConsultationsTopic"
                                            )}
                                          </label>
                                          <div
                                            className="custom-radio-group"
                                            id="custom-radio-group"
                                          >
                                              <>
                                              
                                              {  lookups.ConsultationsTopicEntity != null && lookups.ConsultationsTopicEntity.map((item) => (
                                              
                                              <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={localizer.getKey(item.nameAr, item.nameEn)}
                                                value={localizer.getKey(item.nameAr, item.nameEn)}
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={localizer.getKey(item.nameAr, item.nameEn)}>
                                              {localizer.getKey(item.nameAr, item.nameEn)}
                                              </label>
                                            </div>
                                             ))}                                          
                                            </>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <label className="form-label">
                                            {localizer.getJsonKey(
                                              "ConsultationsType"
                                            )}
                                          </label>
                                          <div
                                            className="custom-radio-group"
                                            id="custom-radio-group"
                                          >
                                              <>
                                              
                                              {  lookups.ItemConsultationsTypeEntity != null && lookups.ItemConsultationsTypeEntity.map((item) => (
                                              
                                              <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={localizer.getKey(item.nameAr, item.nameEn)}
                                                value={localizer.getKey(item.nameAr, item.nameEn)}
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={localizer.getKey(item.nameAr, item.nameEn)}>
                                              {localizer.getKey(item.nameAr, item.nameEn)}
                                              </label>
                                            </div>
                                             ))}                                          
                                            </>
                                          </div>
                                        </div>
                                        <div class="col-12">
                                          <button
                                            type="submit"
                                            class="btn btn--secondary w-100"
                                          >
                                            {localizer.getJsonKey("Search")}
                                            <i class="fas fa-arrow-left"></i>
                                          </button>
                                        </div>
     
                                      
                                      </Form>
                                    )}
                                  </Formik>
                             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

       
          </div>
        </div>
      </section>
      </div>
    </React.Fragment>
  );
}
