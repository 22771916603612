import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import { TbExternalLink } from "react-icons/tb";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Helpers from "../Helpers/Helpers";
import ReCAPTCHA from "react-google-recaptcha";
import useLocalizer from "../Localization/useLocalizer";
import "./Corruption.scss"
function Corruption() {
    let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };
  const localizer = useLocalizer();
  const context = useContext(SessionContext);
  const FormSchema = Yup.object().shape({
    name: Yup.string(localizer.getJsonKey("PleaseInsertName")),
   
    description: Yup.string().required(
      localizer.getJsonKey("PleaseInsertDetails")
    ),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      ,
    recaptcha: Yup.string().required(
      localizer.getJsonKey("PleaseInsertCapcha")
    ),
  });

  const submitForm = async function (values) {
    const result = await httpService
      .post(
        apiUrl + "/ContactUs/SaveContactCorruption",
        {
         
          Name: values.name,
         
         
          Description: values.description,
          
          Email: values.email,
          Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("SendSuccess"));
        //console.log('res',res)
      alert('done')
      });
  };
  return (
    <div
      className="contact-body tab-pane active"
      id="president"
      role="tabpanel"
    >
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("Corruption")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("Corruption")}</h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                    </div>
                  </div>
                  <p>
                  {localizer.getJsonKey("HappyToServeYou")}
                  </p>
                  <div className="rate-des">
                  <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                      
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
     <div style={{marginRight:'20px'}} className=' corruption'>
     <ul className="list-unstyled contact-infos">
                         <li className="contact-info" >
                          <p className="fs-3">
                            <strong>
                            {localizer.getJsonKey("Alternative")}
                            </strong>
                          </p>
                         </li>
                          <li className="contact-info" style={{marginTop:'20px'}}>
                          <div className= "d-flex flex-row align-items-baseline   ">
                            <i className="Riyadh-phone-Icon" style={{padding:"10px"}}></i>
                            <p className="">
                              <a href="tel:920003218">
                              {localizer.getJsonKey("980")}
                              </a>
                            </p></div>
                          </li>
                          <li className="contact-info" style={{marginTop:'20px'}}>
                          <div className= "d-flex flex-row align-items-baseline   ">
                            <i className="Riyadh-phone-Icon" style={{padding:"10px"}}></i>
                            <p className="">
                              <a href="" >
                              {localizer.getJsonKey("fax")}
                              </a>
                            </p>
                          </div>
                          </li>
                          <li className="contact-info" style={{marginTop:'20px'}}>
                            <div className= "d-flex flex-row align-items-baseline   ">
                          <i className="fas fa-envelope" style={{padding:"10px"}}></i>
                            <p className="">
                              <a href="mailto:980@nazaha.gov.sa">
                              {localizer.getJsonKey("980-email")}
                              </a>
                            </p></div>
                          </li>
                          </ul>
                          <ul className="list-unstyled contact-infos Links " style={{marginTop:'50px'}} >
                          <li className="contact-info">
                          <div className= "d-flex flex-row align-items-baseline   ">
                           
                            <p className="">
                            <strong>
                            {localizer.getJsonKey("tawasul")}
                            </strong>
                          </p>
                            <p className="">
                              <a href="https://www.my.gov.sa/wps/portal/snp/content/reportcorruption"> 
                              {localizer.getJsonKey("fromHere")}<TbExternalLink style={{color: "#08a76c", fontSize: "1em" }}/></a>
                            </p></div>
                          </li>
                          <li className="contact-info" style={{marginTop:'20px'}}>
                          <div className= "d-flex flex-row align-items-baseline   ">
                         
                            <p className="">   
                            <strong>
                            {localizer.getJsonKey("nazaha")}
                          
                            </strong>
                          </p>
                            <p className="">
                              <a href="https://nazaha.gov.sa/">{localizer.getJsonKey("fromHere")} <TbExternalLink style={{color: "#08a76c", fontSize: "1em" }}/></a>
                            </p></div>
                          </li>
                            
                            
                        </ul>
     </div>
    </div>
  );
}

export default Corruption;
