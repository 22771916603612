import React, { useState, useRef, useCallback } from 'react';
import $ from "jquery";
import { ThreeDots } from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";


const NewsPaper = ({pdfDownload}) => {
    const embedCode = '<iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" src="https://heyzine.com/flip-book/d8416b6537.html" style="border: 0px; width: 100%; height:700px; margin: 35px auto 0px auto;"></iframe>';
    const localizer = useLocalizer();
    return (
        <div className="pdf-container">
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
        <div class="py-2 pb-4"  style={{ display: "flex",justifyContent: "center",backgroundColor: "#e2e2e2"}}>
                                      <a href={pdfDownload} target={"_blank"} className="" >
                                      {localizer.getJsonKey("pdfDownload")}
                                      <i class="fas fa-download downloadIcon " style={{margin: '4px'}}></i>
                                      </a>
        </div>
      </div>
    );
};

export default NewsPaper;