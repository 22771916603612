import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
function InitiativeList() {
  const [mainSelectedOption, setMainSelectedOption] = useState(0);
  const [subSelectedOption, setSubSelectedOption] = useState(0);

  const [pageLookups, setPageLookups] = useState([]);
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    async function getLookups() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/InitiativesProjects/GetLookups",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageLookups(res.data.output);
          //console.log(res);
        });
    }
    if (context.tokenVal != undefined) {
      //console.log("calling initiatives");
      getLookups();
      getListContent();
    }
  }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setListContent(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setListContent(strAscending);
  }
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/InitiativesProjects/GetInitiativesProjectList",
        {
          ReferenceID: PortalID,
          TypeId: mainSelectedOption == 0 ? null : mainSelectedOption,
          BeneficiaryId: subSelectedOption == 0 ? null : subSelectedOption,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.InitiativesProjectsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Initiatives")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Initiatives")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="blog blog-grid blog-grid-5 rtl" id="blog">
        <div class="container">
          <h5 class="entry-heading">{localizer.getJsonKey("Initiatives")}</h5>
          <div class="contact-panel no-padd">
            <div class="row">
              <div class="col-12">
                <div class="contact-card">
                  <div class="contact-body">
                    <div
                      class="contactForm"
                      method="post"
                      action=""
                      novalidate="novalidate"
                    >
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <label class="form-label" for="select-1">
                            {localizer.getJsonKey("Initiativetype")}
                          </label>
                          <select
                            class="form-control"
                            id="select-1"
                            value={mainSelectedOption}
                            onChange={(c) =>
                              setMainSelectedOption(c.target.value)
                            }
                          >
                            <option value="0">
                              {localizer.getJsonKey("All")}
                            </option>
                            {pageLookups.length != 0 &&
                              pageLookups.InitiativesProjectsTypeEntity.map(
                                (x, ind) => (
                                  <option value={x.id} key={ind} class="option">
                                    {localizer.getKey(x.nameAr, x.nameEn)}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                        <div class="col-12 col-md-3">
                          <label class="form-label" for="select-2">
                            {localizer.getJsonKey("TargetCustomers")}
                          </label>
                          <select
                            class="form-control"
                            id="select-2"
                            value={subSelectedOption}
                            onChange={(c) =>
                              setSubSelectedOption(c.target.value)
                            }
                          >
                            <option value="0">
                              {localizer.getJsonKey("All")}
                            </option>
                            {pageLookups.length != 0 &&
                              pageLookups.BeneficiariesEntity.map((x, ind) => (
                                <option value={x.id} key={ind} class="option">
                                  {localizer.getKey(x.nameAr, x.nameEn)}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={getListContent}
                          >
                            {localizer.getJsonKey("Search")}
                            <i class="fas fa-arrow-left"></i>
                          </button>
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                         
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                          
                        </div>
                        
                        <div class="col-12">
                          <div class="contact-result"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {listContent.length == 0 && (
            <RepeatableTableRows></RepeatableTableRows>
          )}

          <div class="row">
            {listContent.map((x, i) => {
              return (
                <div key={i} class="col-12 col-md-6 col-lg-4">
                  <div class="blog-entry" data-hover="">
                    <div class="entry-content">
                      <div class="entry-meta">
                        <div class="entry-author"></div>
                      </div>

                      <div class="entry-img-wrap">
                        <div class="entry-img">
                          <a href="projects-details.html">
                            <img
                              src="/assets/images/projects/projects/1.jpg"
                              alt={localizer.getKey(x.typeTextAr, x.typeTextEn)}
                              title={localizer.getKey(x.typeTextAr, x.typeTextEn)}
                            />
                          </a>
                        </div>
                        <div class="entry-category">
                          <Link to={x.id}>
                            {localizer.getKey(x.typeTextAr, x.typeTextEn)}
                          </Link>
                        </div>
                      </div>

                      <div class="entry-bio">
                        <div class="events-root jss260 jss239">
                          <div class="events-root jss261 jss240">
                            <div class="events-root jss262 jss242">
                              <div class="events-root jss263 jss245">
                                <div class="MuiTypography-root MuiTypography-body1Bold jss243 eventDate">
                                  <Moment format="DD">
                                    {x.initiativeDate}
                                  </Moment>
                                </div>
                                <div class="MuiTypography-root MuiTypography-labelRegular jss244">
                                  <Moment format="MM">
                                    {x.initiativeDate}
                                  </Moment>
                                </div>
                                <div class="MuiTypography-root MuiTypography-labelRegular">
                                  <Moment format="YYYY">
                                    {x.initiativeDate}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="events-root jss265 jss233 jss241">
                            <div class="events-root jss266">
                              <h3 class="MuiTypography-root MuiTypography-cardTitle1">
                                {localizer.getKey(x.titleAr, x.titleEn)}
                              </h3>
                              <div class="MuiTypography-root jss234 jss253 MuiTypography-body3">
                                <span class="MuiBox-root jss267">
                                  {localizer.getKey(
                                    x.BriefeContentAr,
                                    x.BriefeContentEn
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="entry-more">
                        <Link to={x.id} class="btn btn--white btn-bordered">
                          {localizer.getJsonKey("Details")}
                          <i class="fas fa-arrow-left"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div class="row d-none">
            <div class="col-12 text--center">
              <ul class="pagination">
                <li>
                  <a href="#" aria-label={localizer.getJsonKey("Next")}>
                    <i class="fas fa-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a class="current" href="">
                    1
                  </a>
                </li>
                <li>
                  <a href="">2</a>
                </li>
                <li>
                  <a href="">3</a>
                </li>
                <li>
                  <a href="">4</a>
                </li>

                <li>
                  <a href="#" aria-label={localizer.getJsonKey("Next")}>
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default InitiativeList;
