import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function AboutRiyadh(props) {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let articleID = searchParams.get("did");
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Articles/GetArticleDetails",
          { ReferencesMajorId: PortalID, id: articleID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.ArticlesEntity);
          context.setEntityID(res.data.output.ArticlesEntity.entityId);
          //console.log(res);
        });
    }
    if (context.tokenVal != undefined) {
      //console.log("token effect " + context.tokenVal);
      getContent();
    }
  }, [context.tokenVal]);

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {pageContent == undefined ? "" : pageContent.nameAr}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>الرئيسية</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">أمانة الرياض</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                عن أمانة الرياض
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-8 order-0">
              {pageContent == undefined && <DetailsLoader></DetailsLoader>}
              {ReactHtmlParser(
                pageContent != undefined ? pageContent.contentAr : ""
              )}
            </div>

            <SubMenu />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AboutRiyadh;
