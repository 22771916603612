import React, { useState, useContext, useEffect, useRef } from "react";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID,newOpenDataGateWay } from "../../Config/Config.json";
import { useLocation,Link, useParams, useSearchParams ,useNavigate} from "react-router-dom";
import $ from 'jquery'; 
import _ from "lodash";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";

function DataSetRequestSurvey() {
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const navigate = useNavigate();
  const cancelPopUp = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  let [questions, setQuestions] = useState([]);
  let [answerChoices, setAnswerChoices] = useState([]);
  let [answers, setAnswers] = useState([]);
  let [firstQues, setFirstQues] = useState({hasBeenAnswered:false,id:1,answer:''});
  let [secondQues, setSecondQues] = useState({hasBeenAnswered:false,id:2,answer:''});
  
  let params = useParams();
   useEffect(() => {
    async function CheckRequestAnswers() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/DataSetRequests/GetById?id="+searchParams.get('requestID'),
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          if(res.data.data.surveyAnswers.length==0){
            getContent();
            GetSurveyAnswerChoices();
          }
          else{
            Helpers.notify(localizer.getJsonKey("SurveyHasBeenAnsweredBefore"),true);
            navigate(localizer.getLang() + "/");
          }
          
        });
    }
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay+"/SurveyQuestions/GetSurveyByIdAsync?id="+searchParams.get('surveyID'),
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setQuestions(res.data.data.questions);
          //console.log(questions,"questions");
          var answerList=[];
          for (let index = 0; index < questions.length; index++) {
            const ques = questions[index];
            answerList.push({
              questionID:ques.id,
              text:ques.questionType==2?'1':''
            })
            
          }
          setAnswers(answerList);
        });
    }
    async function GetSurveyAnswerChoices() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay+"/SurveyQuestions/GetSurveyAnswerChoices",
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setAnswerChoices(res.data);
          //console.log("answerChoices",answerChoices);
        });
    }
    if (context.tokenVal != undefined) {
      CheckRequestAnswers();
    }
  }, [context.tokenVal]);

  async function onQuestionChoiceChange(choice,question){
      if(answers.length !=0){
        var list=answers;
        var ans=list.find(x=>x.questionID==question.id);
        var index=list[list.indexOf(ans)].text=choice.value;
        setAnswers(list);
        if(question.id==1){
          setFirstQues({hasBeenAnswered:true,id:1,answer:choice.value+''});
          //console.log("FirstQues",firstQues);
        }
        if(question.id==2){
          setSecondQues({hasBeenAnswered:true,id:2,answer:choice.value+''});
          //console.log("secondQues",secondQues);
        }
      }
      else{
        var answerList=[];
        for (let index = 0; index < questions.length; index++) {
          const ques = questions[index];
          if(ques.id== question.id){
            answerList.push({
              questionID:ques.id,
              text:choice.value+''
              // ,hasBeenAnswered:true
            });
            if(ques.id==1){
              setFirstQues({hasBeenAnswered:true,id:1,answer:choice.value+''});
              //console.log("FirstQues",firstQues);
            }
            if(ques.id==2){
              setSecondQues({hasBeenAnswered:true,id:2,answer:choice.value+''});
              //console.log("secondQues",secondQues);
            }
          }
          else{
            answerList.push({
              questionID:ques.id,
              text:ques.questionType==2?'1':''
              // ,hasBeenAnswered:false
            })
          }
          
          
        }
        setAnswers(answerList);
      }
  }
  
  async function onQuestionTextChange(event,question){
    if(answers.length !=0){
      var list=answers;
      var ans=list.find(x=>x.questionID==question.id);
      var index=list[list.indexOf(ans)].text=event.target.value;
      setAnswers(list);
    }
    else{
      var answerList=[];
      for (let index = 0; index < questions.length; index++) {
        const ques = questions[index];
        if(ques.id== question.id){
          answerList.push({
            questionID:ques.id,
            text:ques.questionType==1?event.target.value:'1'
          })
        }
        else{
          answerList.push({
            questionID:ques.id,
            text:ques.questionType==2?'1':''
          })
        }
        
        
      }
      setAnswers(answerList);
    }
}
  async function submitForm() {
    var answerList=[];
    if(answers.length==0){
        for (let index = 0; index < questions.length; index++) {
          const ques = questions[index];
            answerList.push({
              questionID:ques.id,
              text:ques.questionType==2?'1':''
            })
        }
        setAnswers(answerList);
    }
    var data={
      requestID:searchParams.get('requestID'),
      surveyID:searchParams.get('surveyID'),
      answers:answers.length==0?answerList:answers
    }
    await httpService
      .post(
        apiUrl +newOpenDataGateWay+ "/SurveyQuestions/EditSurveyAnswer",data,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        if(res.data.succeeded){
          if(res.data.data==true){
            Helpers.notify(localizer.getJsonKey("SurveySendSuccessfully"));
          }
          else{
            Helpers.notify(localizer.getJsonKey("SurveyHasBeenAnsweredBefore"),true);
          }
        }
      });
  };

  return (
    <div className="contact-body tab-pane active" id="opendata" role="tabpanel">
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("DataSetRequestSurvey")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("DataSetRequestSurvey")} </h4>
                    </div>
                    {/* <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div> */}
                  </div>
                  {/* <p>{localizer.getJsonKey("odContactUsDesc")}</p>                   */}
                  {/* <div className="rate-des">
                    <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <form>
        <div className="contactForm">
            <div className="row">
              {questions.map((x, ind) => (
                <div className="col-12 col-md-12 mb-5"  key={ind}>
                  <h5>{localizer.getKey(x.textAr, x.textEn)}</h5>  
                  {x.questionType==2?
                    (answerChoices.map((choice, ind2) => (
                      <label class="radio-inline datasetIcon">
                        {/* <input  type="radio" name={x.id+'_checkBox'}  value={choice.value} onClick={(event) => {onQuestionChoiceChange(choice, x);}}/> */}
                        <span className="datasetIcon">
                          {/* {localizer.getKey(choice.nameAr, choice.nameEn.replaceAll('_', ' '))} */}
                          {choice.value==1 &&
                            (
                              ((x.id==1) ? 
                                        ((firstQues.id == x.id) && (firstQues.hasBeenAnswered==true) && (firstQues.answer==1))
                                        :((secondQues.id == x.id) && (secondQues.hasBeenAnswered==true) && (secondQues.answer==1)) 
                              )?
                              <span  class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="50"
                                    height="50"
                                    viewBox="0 0 512 512">
                                  <path id="time-1-icon-selected" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM96.8 314.1c-3.8-13.7 7.4-26.1 21.6-26.1H393.6c14.2 0 25.5 12.4 21.6 26.1C396.2 382 332.1 432 256 432s-140.2-50-159.2-117.9zM217.6 212.8l0 0 0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0zm160 0l0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0 0 0z"/>
                                </svg>
                                <image class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                              :
                              <span  class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="50"
                                    height="50"
                                    viewBox="0 0 512 512">
                                  <path id="time-1-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM96.8 314.1c-3.8-13.7 7.4-26.1 21.6-26.1H393.6c14.2 0 25.5 12.4 21.6 26.1C396.2 382 332.1 432 256 432s-140.2-50-159.2-117.9zM217.6 212.8l0 0 0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0zm160 0l0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0 0 0z"/>
                                </svg>
                                <image class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                            )
                          }
                          {choice.value==2 &&
                            (
                              ((x.id==1) ? 
                                        ((firstQues.id == x.id) && (firstQues.hasBeenAnswered==true) && (firstQues.answer==2))
                                        :((secondQues.id == x.id) && (secondQues.hasBeenAnswered==true) && (secondQues.answer==2)) 
                              )?
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                  width="50"
                                  height="50"
                                  viewBox="0 0 512 512">
                                  <path id="time-2-icon-selected" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                </svg>
                                <image  onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                              :
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                  width="50"
                                  height="50"
                                  viewBox="0 0 512 512">
                                  <path id="time-2-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                </svg>
                                <image  onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                            )
                          }
                          {choice.value==3 &&
                            (
                              ((x.id==1) ? 
                                        ((firstQues.id == x.id) && (firstQues.hasBeenAnswered==true) && (firstQues.answer==3))
                                        :((secondQues.id == x.id) && (secondQues.hasBeenAnswered==true) && (secondQues.answer==3)) 
                              )?
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                width="50"
                                height="50"
                                viewBox="0 0 512 512">
                                  <path id="time-3-icon-selected" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM176.4 176a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM160 336H352c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                              :
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                width="50"
                                height="50"
                                viewBox="0 0 512 512">
                                  <path id="time-3-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM176.4 176a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM160 336H352c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                            )
                          }
                          {choice.value==4 &&
                            (
                              ((x.id==1) ? 
                                        ((firstQues.id == x.id) && (firstQues.hasBeenAnswered==true) && (firstQues.answer==4))
                                        :((secondQues.id == x.id) && (secondQues.hasBeenAnswered==true) && (secondQues.answer==4)) 
                              )?
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  viewBox="0 0 512 512">
                                  <path id="time-4-icon-selected" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM159.3 388.7c-2.6 8.4-11.6 13.2-20 10.5s-13.2-11.6-10.5-20C145.2 326.1 196.3 288 256 288s110.8 38.1 127.3 91.3c2.6 8.4-2.1 17.4-10.5 20s-17.4-2.1-20-10.5C340.5 349.4 302.1 320 256 320s-84.5 29.4-96.7 68.7zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                              :
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  viewBox="0 0 512 512">
                                  <path id="time-4-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM159.3 388.7c-2.6 8.4-11.6 13.2-20 10.5s-13.2-11.6-10.5-20C145.2 326.1 196.3 288 256 288s110.8 38.1 127.3 91.3c2.6 8.4-2.1 17.4-10.5 20s-17.4-2.1-20-10.5C340.5 349.4 302.1 320 256 320s-84.5 29.4-96.7 68.7zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                            )
                          }
                          {choice.value==5 &&
                            (
                              ((x.id==1) ? 
                                        ((firstQues.id == x.id) && (firstQues.hasBeenAnswered==true) && (firstQues.answer==5))
                                        :((secondQues.id == x.id) && (secondQues.hasBeenAnswered==true) && (secondQues.answer==5)) 
                              )?
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="50"
                                    height="50"
                                    viewBox="0 0 512 512">
                                  <path id="time-5-icon-selected" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-224a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM100.7 132.7c6.2-6.2 16.4-6.2 22.6 0L160 169.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L182.6 192l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L160 214.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L137.4 192l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6zm192 0c6.2-6.2 16.4-6.2 22.6 0L352 169.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L374.6 192l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L352 214.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L329.4 192l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                              :
                              <span class="datasetIcon" onClick={(event) => {onQuestionChoiceChange(choice, x);}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="50"
                                    height="50"
                                    viewBox="0 0 512 512">
                                  <path id="time-5-icon" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-224a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM100.7 132.7c6.2-6.2 16.4-6.2 22.6 0L160 169.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L182.6 192l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L160 214.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L137.4 192l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6zm192 0c6.2-6.2 16.4-6.2 22.6 0L352 169.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L374.6 192l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L352 214.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L329.4 192l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6z"/>
                                </svg>
                                <image onClick={(event) => {onQuestionChoiceChange(choice, x);}} class="my-svg-alternate" width="96" height="96" src="ppngfallback.png" />
                              </span>
                            )
                          }
                          </span>
                      </label>
                    )))
                    :
                    (
                      <textarea 
                        className="form-control"
                        cols="10"
                        rows="5"
                        onChange={(event) => {onQuestionTextChange(event, x);}}
                        name={'textQuestion'+x.id}>
                      </textarea>
                    )
                  }

                    
                </div>
              ))}
              <div className="col-12 col-md-12">
                {/* <button
                  className="btn btn--secondary"
                  type="submit"
                  onClick={() => {submitForm()}}>
                  {localizer.getJsonKey("Send")}{" "}
                  <i className="fas fa-arrow-left"></i>
                </button> */}


                <button type="submit" className="btn btn--secondary"
                    onClick={(e) => {
                      submitForm();
                      navigate(
                        localizer.getLang() +"/"
                      );
                    }}>
                     {localizer.getJsonKey("Send")}{" "}
                    <i className="fas fa-arrow-left"></i>
                  </button>
              </div>
              <div className="col-12">
                <div className="contact-result"></div>
              </div>
              </div>
        </div>
      </form>
    </div>
  );
}
export default DataSetRequestSurvey;
