import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import allLocales from '@fullcalendar/core/locales-all'

function AdsList() {
  const [listContent, setListContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [visible, setVisible] = useState(false)
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const eventsArr = []

  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);

  
  async function getListContent() {
    //debugger;
    setLoading(true);
    const result = await httpService
      .post(
        apiUrl + "/Advertisements/GetPublishedAddsList",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setLoading(false);
        setListContent(res.data.output.AdvertisementsEntity);

        res.data.output.AdvertisementsEntity.forEach(element => {
          eventsArr.push({ id: element.id, title: localizer.getLang() == '/en' ? element.titleEn : element.titleAr, start: element.fromDate != null ?element.fromDate : element.createdDate != null ? element.createdDate : new Date()  , end: element.toDate != null ? element.toDate : element.createdDate != null ? element.createdDate : new Date()  })
        });

        setEvents(eventsArr);
        context.setEntityID(res.data.output.EntityID);
      });
  }



  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">{localizer.getJsonKey("RMAds")}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")} </a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("RMAds")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="blog blog-grid blog-grid-5 rtl" id="blog">
        <div class="container">
          <div className="row">
            <div className="col-md-11">
              <h6 class="entry-heading">{localizer.getJsonKey("RMAds")}</h6>
            </div>
            <div className="col-md-1">
              <button  className="btn btn-success" type="button" style={{width:'75px' , height:"50px"}} onClick={(event) => {
                event.preventDefault()
                setVisible(!visible)
              }} ><i class="fa fa-bars"></i></button>
            </div>
          </div>
          <br />
          {visible && 
          <>
          <div id="CalenderDiv" className="row">
              <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    events={events}
                    weekends={false}
                    direction= {localizer.getLang() == '/ar' ? 'rtl' : 'ltr'}
                    locales={allLocales} 
                    locale={localizer.getLang() == '/ar' ? 'ar' : 'en'}
                    eventClick={
                      function(arg){
                        window.location.href=  (localizer.getLang() == '/ar' ? '/ar' : '/en') + '/amanaAds/' + arg.event.id
                      }
                    }
                  />
          </div>
          <br/>
          <br/>
          </>
          
          
          }
     
          {loading && <RepeatableTableRows></RepeatableTableRows>}
          <div class="row">
            {listContent.map((x, i) => {
              return (
                <div key={i} class="col-12 col-md-6 col-lg-4">
                  <div class="blog-entry" data-hover="">
                    <div class="entry-content">
                      <div class="entry-meta">
                        <div class="entry-author"></div>
                      </div>


                      {/**<div class="entry-img-wrap">
                        <div class="entry-img">
                          <a href="javascript:void(0)" aria-label={localizer.getKey(x.titleAr, x.titleEn)} >
                            <img
                              src="/assets/images/projects/projects/1.jpg"
                              alt={localizer.getKey(x.titleAr, x.titleEn)}
                              title={localizer.getKey(x.titleAr, x.titleEn)}
                            />
                          </a>
                        </div>
                        <div class="entry-category d-none"></div>
                      </div>**/}

                      <div class="entry-bio">
                        <div class="events-root jss260" style={{display: "flex", borderColor: "rgba(86, 90, 92, 1)"}}>
                          <div class="events-root jss261">
                            <div class="events-root jss262 jss242">
                              <div class="events-root jss263 jss245">
                                <div class="MuiTypography-root MuiTypography-body1Bold jss243 eventDate">
                                  <Moment format="DD">{x.createdDate}</Moment>
                                </div>
                                <div class="MuiTypography-root MuiTypography-labelRegular jss244">
                                  <Moment format="MM">{x.createdDate}</Moment>
                                </div>
                                <div class="MuiTypography-root MuiTypography-labelRegular">
                                  <Moment format="YYYY">{x.createdDate}</Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="events-root jss265 jss233 jss241">
                            <div class="events-root jss266">
                              <h3 class="MuiTypography-root MuiTypography-cardTitle1">
                                {localizer.getKey(x.titleAr, x.titleEn)}
                              </h3>
                              {/*<div class="MuiTypography-root jss234 jss253 MuiTypography-body3">
                                <span class="MuiBox-root jss267">
                                  {ReactHtmlParser(
                                    localizer.getKey(x.contentAr, x.contentEnn)
                                  )}
                                </span>
                              </div>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="entry-more">
                        <Link to={x.id} class="btn btn--white btn-bordered">
                          {localizer.getJsonKey("Details")}
                          <i class="fas fa-arrow-left"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div class="row d-none">
            <div class="col-12 text--center">
              <ul class="pagination">
                <li>
                  <a href="#" aria-label={localizer.getJsonKey("Next")}>
                    <i class="fas fa-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a class="current" href="">
                    1
                  </a>
                </li>
                <li>
                  <a href="">2</a>
                </li>
                <li>
                  <a href="">3</a>
                </li>
                <li>
                  <a href="">4</a>
                </li>

                <li>
                  <a href="#" aria-label={localizer.getJsonKey("Next")}>
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );


 
}

export default AdsList;
