import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
//import $ from "jquery";
import useLocalizer from "../../Localization/useLocalizer";
function AlbumImages() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      let queryID = searchParams.get("did");
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Multimedia/GetImageByAlbum",
          { ReferenceID: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.AttachmentEntity);
          context.setEntityID(res.data.output.AttachmentEntity[0].entityId);
          //setTimeout(window.InitializeGallery(), 200);
          window.InitializeGallery();
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, params]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("AlbumImages")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"> {localizer.getJsonKey("ImageAlbums")}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Images")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="projects projects-gallery rtl">
        <div class="container">
          <div class="row">
            {pageContent.length > 0 &&
              pageContent.map((e, i) => (
                <div key={i} class="col-12 col-md-6 col-lg-4 team-item ">
                  <div class="project-panel">
                    <div class="project-panel-holder">
                      <div class="project-img">
                        <img src={e.url} 
                            alt=" item" 
                            title=" item" 
                            />
                        <div class="project-hover">
                          <div class="project-action">
                            <div class="project-zoom">
                              <i class="far fa-eye"></i>
                              <a
                                className="img-gallery-item"
                                href={e.url}
                                title="gallary item"
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AlbumImages;
