import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, newOpenDataGateWay} from "../../Config/Config.json";
import $ from 'jquery'; 
import _ from "lodash";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";

function DataSharingForm() {
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const [entityTypes, setEntityTypes] = useState([]);
   useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay +  "/DataSharings/GetEntityTypes",
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          debugger
          setEntityTypes(res.data);
          //console.log("entityTypes",entityTypes);
        });
    }
    
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  const FormSchema = Yup.object().shape({
    Name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
    Email: Yup.string().email(localizer.getJsonKey("EmailNotValid")).required(localizer.getJsonKey("PleaseInsertEmail")),
    PhoneNumber: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    RequestTopic: Yup.string().required(localizer.getJsonKey("PleaseInsertRequestTopic")),
    RequestDescription: Yup.string().required(localizer.getJsonKey("PleaseInsertRequestDescription")),
    EntityTypeID: Yup.number().min(0,localizer.getJsonKey("PleaseInsertEntityType"))
    .required(localizer.getJsonKey("PleaseInsertEntityType")),
    EntityName: Yup.string().required(localizer.getJsonKey("PleaseInsertEntityName")),
    file:Yup.mixed().required(localizer.getJsonKey("PleaseInsertDataSharingFile"))
  });

  const submitForm = async function (values) {
    debugger;
        var formData = new FormData();
        formData.append('id',0);
        formData.append('name',values.Name);
        formData.append('email',values.Email);
        formData.append('phoneNumber',values.PhoneNumber);
        formData.append('requestTopic',values.RequestTopic);
        formData.append('requestDescription',values.RequestDescription);
        formData.append('entityTypeID', values.EntityTypeID);
        formData.append('entityName',values.EntityName);
        formData.append('attachments[0].id',''  );
        formData.append('attachments[0].file', values.file);
        formData.append('attachments[0].attachmentTypeId',6);
        formData.append('attachments[0].name', values.file.name);
        formData.append('attachments[0].isDeleted', false);
    await httpService
      .post(
        apiUrl + newOpenDataGateWay + "/DataSharings",formData,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        debugger
       // console.log(res,"ressssss request");
        if(res.data.succeeded){
          Helpers.notify(localizer.getJsonKey(res.data.message));
        }
      });
  };
  return (
    <div className="contact-body tab-pane active" id="opendata" role="tabpanel">
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("DataSharing")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("DataSharing")} </h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  
                  <p>{localizer.getJsonKey("ODDataSharingPageMsg")}</p>                  
                  
                  {/* <div className="rate-des">
                    <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      
      <Formik
        initialValues={{
          EntityTypeID:'0',
          EntityName:"",
          Name: "",
          Email: "",
          PhoneNumber: "",
          RequestTopic: "",
          RequestDescription: ""
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await submitForm(values, setSubmitting);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ errors, isSubmitting, isValid, setFieldValue ,touched}) => (
          <Form>
            <div className="contactForm">
              {isSubmitting ? (
                <ThreeDots></ThreeDots>
              ) : 
              (
                <div className="row">
                  <div className="col-12 col-md-12">
                    {/* <Field
                      className="form-control"
                      as="select"
                      name="EntityTypeID"
                      placeholder={localizer.getJsonKey("EntityType")}
                      title={localizer.getJsonKey("EntityType")}>
                        <option value='-1' class="option">
                              {localizer.getJsonKey("EntityType")}
                        </option>
                        {entityTypes?.length > 0 && entityTypes.map((x, ind) => (
                            <option value={x.value} key={ind} class="option">
                                {localizer.getKey(x.nameAr,x.nameEn)}
                            </option>
                        ))}
                    </Field> */}
                    <div className="entity-type">
                      {localizer.getJsonKey("EntityType")}               
                    </div>
                    <div className="custom-radio-group entity-type-options">
                        {entityTypes?.length > 0 && entityTypes.map((x, ind) => (
                          <label key={ind} for={x.value+'_checkBox'}>
                              <Field 
                              id={x.value+'_checkBox'}
                              className="radio-inline datasetIcon"
                              type="radio" 
                              name="EntityTypeID" 
                              value={x.value+""}/>
                              <span class="datasetIcon">
                                  {localizer.getKey(x.nameAr,x.nameEn)}
                              </span>
                          </label>
                        ))}
                    </div>

                    <label id="" class=" errorr">
                      {errors && errors.EntityTypeID}
                    </label>
                  </div>

                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="EntityName"
                      placeholder={localizer.getJsonKey("EntityName")}
                      title={localizer.getJsonKey("EntityName")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.EntityName}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="Name"
                      placeholder={localizer.getJsonKey("Name")}
                      title={localizer.getJsonKey("Name")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.Name}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="email"
                      name="Email"
                      placeholder={localizer.getJsonKey("Email")}
                      title={localizer.getJsonKey("Email")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.Email}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="PhoneNumber"
                      placeholder={localizer.getJsonKey("MobileNum")}
                      title={localizer.getJsonKey("MobileNum")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.PhoneNumber}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                  <Field
                     className="form-control"
                     cols="30"
                     rows="10"
                     as="textarea"
                     name="RequestTopic"
                     placeholder={localizer.getJsonKey("RequestTopic")}
                     title={localizer.getJsonKey("RequestTopic")}
                    ></Field>
                    <label id="" class=" errorr">
                      {errors && errors.RequestTopic}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                     className="form-control"
                     cols="30"
                     rows="10"
                     as="textarea"
                     type="text"
                     name="RequestDescription"
                     placeholder={localizer.getJsonKey("RequestDescription")}
                     title={localizer.getJsonKey("RequestDescription")}
                    ></Field>
                    <label id="" class=" errorr">
                      {errors && errors.RequestDescription}
                    </label>
                  </div>


                  <div className="col-12">
                      <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("UploadFile")}
                            title={localizer.getJsonKey("UploadFile")}
                            name="file"
                            type="file"
                            accept=".pdf,.docx,.doc"
                            value={null}
                            onChange={(event) => {
                                setFieldValue("file",event.currentTarget.files[0])// reader.result);
                              }}
                            />
                            <label id="" class=" errorr">
                              {errors && errors.file}
                            </label>
                  </div>
                 <div className="col-12">
                    <button
                      className="btn btn--secondary"
                      type="submit"
                      disabled={!isValid}
                    >
                      {localizer.getJsonKey("Send")}{" "}
                      <i className="fas fa-arrow-left"></i>
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="contact-result"></div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default DataSharingForm;
