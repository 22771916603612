import React, { useState, useContext, useEffect } from "react";

import { useLocation, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import { Chart } from "react-google-charts";
import useLocalizer from "../../Localization/useLocalizer";

function Building() {
  const [yearlyContent, setYearlyContent] = useState();
  const [monthlyContent, setMonthlyContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  const options = {
    series: {
      0: { color: "#4f9ac3" },
      1: { targetAxisIndex: 1, color: "#b3e9e9" },
    },
    hAxis: {
      title: localizer.getJsonKey("Months"),
    },
    vAxis: {
      title: localizer.getJsonKey("Count"),
    },
  };
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getMonthlyContent();
      getYearlyContent();
    }
  }, [context.tokenVal]);

  async function getMonthlyContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/EserviceStats/GetBuildingStatsMonthly",
        { ReferenceId: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setMonthlyContent((oldArray) => [
          ...oldArray,
          [
            localizer.getJsonKey("Month"),
            localizer.getJsonKey("NewLicence"),
            localizer.getJsonKey("RenewedLicence"),
          ],
        ]);
        res.data.output.CurrEserviceStatsEntity.map((e, i) =>
          setMonthlyContent((oldArray) => [
            ...oldArray,
            [e.month, parseFloat(e.newCount), parseFloat(e.reNewCount)],
          ])
        );
        context.setEntityID(-1);
      });
  }
  async function getYearlyContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/EserviceStats/GetBuildingStatsYearly",
        { ReferenceId: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setYearlyContent(res.data.output.EserviceStatsEntity[0]);
        context.setEntityID(-1);
      });
  }
  return (
    <div class="features features-3 mar-top50">
      <div class="contact-card">
        <div class="heading heading-1">
          <h2 class="heading-title">
            {localizer.getJsonKey("BuildingLicence")}
          </h2>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <span class="uk-icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      width="68"
                      height="68"
                      x="0px"
                      y="0px"
                      viewBox="0 0 480 480"
                      //   style="enable-background: new 0 0 480 480;"
                    >
                      <g>
                        <g>
                          <path
                            d="M472,76h-88V12c0-4.418-3.582-8-8-8H104c-4.418,0-8,3.582-8,8v112H8c-4.418,0-8,3.582-8,8v328c0,4.418,3.582,8,8,8
			s8-3.582,8-8V140h80v328c0,4.418,3.582,8,8,8h272c4.418,0,8-3.582,8-8V92h80v368c0,4.418,3.582,8,8,8s8-3.582,8-8V84
			C480,79.582,476.418,76,472,76z M232,460h-32v-72h32V460z M280,460h-32v-72h32V460z M368,460h-72v-80c0-4.418-3.582-8-8-8h-96
			c-4.418,0-8,3.582-8,8v80h-72V20h256V460z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C192,39.582,188.418,36,184,36z
			 M176,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C272,39.582,268.418,36,264,36z
			 M256,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C352,39.582,348.418,36,344,36z
			 M336,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,119.582,188.418,116,184,116z
			 M176,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,119.582,268.418,116,264,116z
			 M256,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,119.582,348.418,116,344,116z
			 M336,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,199.582,188.418,196,184,196z
			 M176,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,199.582,268.418,196,264,196z
			 M256,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,199.582,348.418,196,344,196z
			 M336,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,279.582,188.418,276,184,276z
			 M176,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,279.582,268.418,276,264,276z
			 M256,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,279.582,348.418,276,344,276z
			 M336,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,156h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,159.582,52.418,156,48,156z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,156h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,159.582,84.418,156,80,156z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,188h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,191.582,52.418,188,48,188z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,188h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,191.582,84.418,188,80,188z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,220h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,223.582,52.418,220,48,220z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,220h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,223.582,84.418,220,80,220z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,252h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,255.582,52.418,252,48,252z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,252h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,255.582,84.418,252,80,252z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,284h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,287.582,52.418,284,48,284z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,284h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,287.582,84.418,284,80,284z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,316h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,319.582,52.418,316,48,316z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,316h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,319.582,84.418,316,80,316z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,348h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,351.582,52.418,348,48,348z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,348h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,351.582,84.418,348,80,348z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,380h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,383.582,52.418,380,48,380z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,380h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,383.582,84.418,380,80,380z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,412h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,415.582,52.418,412,48,412z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,412h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,415.582,84.418,412,80,412z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,108h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,111.582,444.418,108,440,108z
			 M432,140h-16v-16h16V140z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,172h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,175.582,444.418,172,440,172z
			 M432,204h-16v-16h16V204z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,236h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,239.582,444.418,236,440,236z
			 M432,268h-16v-16h16V268z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,300h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,303.582,444.418,300,440,300z
			 M432,332h-16v-16h16V332z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,364h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,367.582,444.418,364,440,364z
			 M432,396h-16v-16h16V396z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>
                <div class="feature-content">
                  <h4> {localizer.getJsonKey("RenewedLicenceCount")}</h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      {yearlyContent && (
                        <span
                          class="counting"
                          data-counterup-nums={yearlyContent.reNewCount}
                        >
                          {parseFloat(
                            yearlyContent.reNewCount
                          ).toLocaleString()}
                        </span>
                      )}
                    </div>
                    <div class="counter-name">
                      <h6> {new Date().getFullYear()}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <span class="uk-icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      width="68"
                      height="68"
                      x="0px"
                      y="0px"
                      viewBox="0 0 480 480"
                      //   style="enable-background: new 0 0 480 480;"
                    >
                      <g>
                        <g>
                          <path
                            d="M472,76h-88V12c0-4.418-3.582-8-8-8H104c-4.418,0-8,3.582-8,8v112H8c-4.418,0-8,3.582-8,8v328c0,4.418,3.582,8,8,8
			s8-3.582,8-8V140h80v328c0,4.418,3.582,8,8,8h272c4.418,0,8-3.582,8-8V92h80v368c0,4.418,3.582,8,8,8s8-3.582,8-8V84
			C480,79.582,476.418,76,472,76z M232,460h-32v-72h32V460z M280,460h-32v-72h32V460z M368,460h-72v-80c0-4.418-3.582-8-8-8h-96
			c-4.418,0-8,3.582-8,8v80h-72V20h256V460z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C192,39.582,188.418,36,184,36z
			 M176,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C272,39.582,268.418,36,264,36z
			 M256,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,36h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V44C352,39.582,348.418,36,344,36z
			 M336,84h-32V52h32V84z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,119.582,188.418,116,184,116z
			 M176,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,119.582,268.418,116,264,116z
			 M256,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,116h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,119.582,348.418,116,344,116z
			 M336,164h-32v-32h32V164z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,199.582,188.418,196,184,196z
			 M176,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,199.582,268.418,196,264,196z
			 M256,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,196h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,199.582,348.418,196,344,196z
			 M336,244h-32v-32h32V244z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M184,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C192,279.582,188.418,276,184,276z
			 M176,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M264,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C272,279.582,268.418,276,264,276z
			 M256,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M344,276h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C352,279.582,348.418,276,344,276z
			 M336,324h-32v-32h32V324z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,156h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,159.582,52.418,156,48,156z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,156h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,159.582,84.418,156,80,156z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,188h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,191.582,52.418,188,48,188z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,188h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,191.582,84.418,188,80,188z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,220h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,223.582,52.418,220,48,220z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,220h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,223.582,84.418,220,80,220z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,252h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,255.582,52.418,252,48,252z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,252h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,255.582,84.418,252,80,252z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,284h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,287.582,52.418,284,48,284z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,284h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,287.582,84.418,284,80,284z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,316h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,319.582,52.418,316,48,316z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,316h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,319.582,84.418,316,80,316z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,348h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,351.582,52.418,348,48,348z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,348h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,351.582,84.418,348,80,348z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,380h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,383.582,52.418,380,48,380z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,380h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,383.582,84.418,380,80,380z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M48,412h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C56,415.582,52.418,412,48,412z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M80,412h-8c-4.418,0-8,3.582-8,8v8c0,4.418,3.582,8,8,8h8c4.418,0,8-3.582,8-8v-8C88,415.582,84.418,412,80,412z"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,108h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,111.582,444.418,108,440,108z
			 M432,140h-16v-16h16V140z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,172h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,175.582,444.418,172,440,172z
			 M432,204h-16v-16h16V204z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,236h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,239.582,444.418,236,440,236z
			 M432,268h-16v-16h16V268z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,300h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,303.582,444.418,300,440,300z
			 M432,332h-16v-16h16V332z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M440,364h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C448,367.582,444.418,364,440,364z
			 M432,396h-16v-16h16V396z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>
                <div class="feature-content">
                  <h4>{localizer.getJsonKey("NewLicenceCount")}</h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      {" "}
                      {yearlyContent && (
                        <span
                          class="counting"
                          data-counterup-nums={yearlyContent.newCount}
                        >
                          {parseFloat(yearlyContent.newCount).toLocaleString()}
                        </span>
                      )}
                    </div>
                    <div class="counter-name">
                      <h6> {new Date().getFullYear()} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="heading heading-1" style={{ direction: "ltr" }}>
          <p class="heading-subtitle heading-subtitle-bg">
            {localizer.getJsonKey("MonthsChart")} {new Date().getFullYear()}{" "}
            {localizer.getJsonKey("TillNow")}
          </p>
          {monthlyContent.length == 0 ? (
            <DetailsLoader></DetailsLoader>
          ) : (
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={monthlyContent}
              options={options}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Building;
