import React, { useState, useContext, useEffect, useRef } from "react";
import { useSearchParams, Link,useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import _ from "lodash";

import httpService from "../../services/httpService";
import { apiUrl, PortalID ,newOpenDataGateWay} from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function DataSetList() {
  const [pageContent, setPageContent] = useState([]);
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const [mainSelectedOption, setMainSelectedOption] = useState(searchParams.get('type')?searchParams.get('type'):0);
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay+"/DataSetTypes/GetAll?isAdmin=false",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          //console.log(res, "rrsssssss");
          setPageContent(res.data.data.items);
          setMainSelectedOption(searchParams.get('type')?searchParams.get('type'):res.data.data.items[0].id);
          //console.log("selected option",res.data.data.items[0].id)
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
      getListContent();
    }
  }, [context.tokenVal]);

  async function UpdateViewTimes(event,item) {
    const result = await httpService
      .post(
        apiUrl + newOpenDataGateWay+"/DataSets/UpdateViewTimes",
        {
          entityID:item.id
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
      });
  }

  const SortDescending = () => {
    const strDescending = [...listContent].sort((a, b) =>
      localizer.getKey(a.nameAr, a.nameEn) >
      localizer.getKey(b.nameAr, b.nameEn)
        ? -1
        : 1
    );
    setListContent(strDescending);
  };
  const SortAscending = () => {
    const strAscending = [...listContent].sort((a, b) =>
      localizer.getKey(a.nameAr, a.nameEn) >
      localizer.getKey(b.nameAr, b.nameEn)
        ? 1
        : -1
    );
    setListContent(strAscending);
  };
  async function getListContent() {
    const result = await httpService
      .post(
        apiUrl +newOpenDataGateWay+ "/DataSets/GetAll",
        {
          // ReferenceID: PortalID,
          // ID: mainSelectedOption == 0 ? null : mainSelectedOption,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.data.items);
      });
  }

  return (
    <React.Fragment>
      {localizer.getLang()=="ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("DataSets")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {" "}
                  {localizer.getJsonKey("Home")}
                </a>
              </li>

              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/DataSetLanding"}>
                  {" "}
                  {localizer.getJsonKey("OpenData")}
                </a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("DataSets")}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("DataSets")}
                    </h5>
                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div>
                                <div class="row">
                                  <div class="col-12 col-md-5">
                                    <label class="form-label">
                                      {localizer.getJsonKey("DataSetType")}
                                    </label>
                                    <select
                                      class="form-control"
                                      value={mainSelectedOption}
                                      onChange={(e) => {
                                        setMainSelectedOption(e.target.value);
                                      }}
                                    >
                                      {pageContent.length > 0 &&
                                        pageContent.map((x, ind) => (
                                          <option
                                            value={x.id}
                                            key={ind}
                                            class="option"
                                          >
                                            {localizer.getKey(
                                              x.nameAr,
                                              x.nameEn
                                            )}
                                          </option>
                                        ))}
                                    </select>
                                  </div>

                                  <div class="col-12 col-md-3">
                                    {/* <button
                                      onClick={getListContent}
                                      class="btn btn--secondary w-100 serch-left-flot">
                                      {localizer.getJsonKey("Search")}
                                      <i class="fas fa-arrow-left"></i>
                                    </button> */}
                                  </div>
                                  <div class="col-12 col-md-2">
                                    <button
                                      class="btn btn--secondary w-100 serch-left-flot"
                                      onClick={SortAscending}
                                    >
                                      {localizer.getJsonKey("SortAscending")}
                                      <i
                                        style={{
                                          backgroundColor: "initial",
                                          color: "white",
                                        }}
                                        class="fas fa-arrow-up"
                                      ></i>
                                    </button>
                                  </div>
                                  <div class="col-12 col-md-2">
                                    <button
                                      class="btn btn--secondary w-100 serch-left-flot"
                                      onClick={SortDescending}
                                    >
                                      {localizer.getJsonKey("SortDescending")}
                                      <i
                                        style={{
                                          backgroundColor: "initial",
                                          color: "white",
                                        }}
                                        class="fas fa-arrow-down"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            {/* {(listContent.filter((x) => x.dataSetTypeID == mainSelectedOption).length ==0) &&  
                              <div class="align-items-center" align="center">
                                  <h6>
                                    {localizer.getJsonKey("NoData")}
                                  </h6>
                              </div>
                            } */}

                            {(listContent.filter((x) => x.dataSetTypeID == mainSelectedOption).length == 0 || listContent.length ==0)&& (
                              // <RepeatableTableRows style={{ width: "100%" }} />
                              <div class="align-items-center" align="center">
                                  <h6>
                                    {localizer.getJsonKey("NoData")}
                                  </h6>
                              </div>
                            )}
                            {listContent.filter(
                              (x) => x.dataSetTypeID == mainSelectedOption).length > 0 &&
                              listContent
                                .filter((x) => x.dataSetTypeID == mainSelectedOption)
                                .map((x, ind) => (
                                  <div key={ind} class="col-12 col-md-12 col-lg-12">
                                    <div class="feature-panel-holder" data-hover="">
                                      <div class="feature-panel feature-panel-2">
                                        <div class="feature-content">
                                            <div class="avatar-icon">
                                              <img src="/assets/images/icons/min-logo.png" alt={localizer.getKey(x.nameAr,x.nameEn)} title={localizer.getKey(x.nameAr,x.nameEn)}/>
                                            </div>
                                            <h4 class="mar-bot0">
                                              {localizer.getKey(x.nameAr,x.nameEn)}
                                            </h4>
                                          </div>
                                          {/* <div class="datasetIcon">
                                              <span><i class="fas fa-calendar"></i></span><span class="datasetIcon">{x.createdDateHijri}</span>
                                              <span class="datasetIcon"><i class="fas fa-briefcase"></i></span><span class="datasetIcon">{x.dataSetType?.name}</span>
                                              <span class="datasetIcon"><i class="fas fa-briefcase"></i></span><span class="datasetIcon">{x.publisher?.name}</span>
                                          </div> */}
                                          <Link to={localizer.getLang()+'/DataSets/'+x.id} onClick={event => {UpdateViewTimes(event,x)}}>
                                            <span >
                                              {localizer.getJsonKey("Details")}
                                            </span>{" "}
                                            <i  class="fas fa-arrow-left"></i>
                                          </Link>
                                        <p class="external-desc d-none"></p>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                           
                         
                         
                         
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12 text--center">
                  <ul class="pagination">
                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a class="current" href="">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="">2</a>
                    </li>
                    <li>
                      <a href="">3</a>
                    </li>
                    <li>
                      <a href="">4</a>
                    </li>

                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-left"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <SubMenu></SubMenu>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default DataSetList;
