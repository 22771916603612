import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";

export default function ParticipationDetails() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  //let [searchParams, setSearchParams] = useSearchParams();
  // let newsID = searchParams.get("did");
  let params = useParams();
  const localizer = useLocalizer();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Consultations/GetConsultationsDetails",
          { ReferencesMajorId: PortalID, ID: params.id },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
         // console.log("resssssssss",res);
          setPageContent(res.data.output.ConsultationsEntity);
          context.setEntityID(res.data.output.ConsultationsEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, params]);

  return (
    <React.Fragment>
      <section
        className="page-title page-title-blank-2 bg-white rtl"
        id="page-title"
      >
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{
            backgroundImage: "url('/assets/images/page-titles/6.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("ProjectDetails")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrap">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <a href={localizer.getLang()+"/c-participations"}>{localizer.getJsonKey("Participations")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {localizer.getJsonKey("ProjectDetails")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pageContent == undefined && <DetailsLoader />}
      {pageContent != undefined && (
        <section ref={componentRef} className="blog blog-single rtl" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">

               
                <div className="blog-entry">
                  <div className="entry-content">
                    <div className="entry-title">
                     <h4>{localizer.getKey(pageContent.nameAr,pageContent.nameEn)}</h4>
                    </div>
                    <div className="entry-bio">

                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div
                                class="contactForm"
                                method="post"
                                action=""
                                novalidate="novalidate"
                              >
                                <div class="row">
                                  <div class="col-12 col-md-3">
                                  <span > {localizer.getJsonKey("ConsultationsTopic")} :</span>
                                  </div>
                                  <div class="col-12 col-md-7">
                                  <h6>{ReactHtmlParser(localizer.getKey(pageContent.consultationsTopicTextAr,pageContent.consultationsTopicTextEn))}</h6>
                        </div>
                        <div class="col-12 col-md-3">
                                  <span > {localizer.getJsonKey("ConsultationsStatus")} :</span>
                                  </div>
                                  <div class="col-12 col-md-7">
                                  <h6>{ReactHtmlParser(localizer.getKey(pageContent.consultationsStatusAr,pageContent.consultationsStatusEn))}</h6>
                        </div>
                        <div class="col-12 col-md-3">
                                  <span > {localizer.getJsonKey("ConsultationsType")} :</span>
                                  </div>
                                  <div class="col-12 col-md-7">
                                      <h6>{ReactHtmlParser(localizer.getKey(pageContent.consultationsTypeAr,pageContent.consultationsTypeEn))}</h6>
                                  </div>
                                  <div class="col-12 col-md-3">
                                     <span > {localizer.getJsonKey("ConsultationsClass")} :</span>
                                  </div>
                                  <div class="col-12 col-md-7">
                                      <h6>{ReactHtmlParser(localizer.getKey(pageContent.consultationsClassAr,pageContent.consultationsClassEn))}</h6>
                                  </div>
                                  <div class="col-6 col-md-3">
                                     
                                      <div className="entry-more">
                                      <a
                                        href={pageContent.url}
                                        target="_blank"
                                        className="btn btn--white btn-bordered"
                                      >
                                        {" "}
                                        {localizer.getJsonKey("MoreDetails")}  {" "}
                                        <i className="fas fa-arrow-left"></i>
                                      </a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

             
                  
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
