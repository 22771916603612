import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function DepartmentsList() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  useEffect(() => {
    if (context.tokenVal != undefined) {
      //console.log("calling initiatives");

      getListContent();
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Reference/GetDepartmentsList",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.ReferencesEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Departments")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("Departments")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("Departments")}
                    </h5>

                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            {listContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            {listContent.length > 0 &&
                              listContent.map((x, ind) => (
                                <div
                                  key={ind}
                                  class="col-12 col-md-12 col-lg-12"
                                >
                                  <div
                                    class="feature-panel-holder"
                                    data-hover=""
                                  >
                                    <div class="feature-panel feature-panel-2">
                                      <div class="feature-content">
                                        <div class="avatar-icon">
                                          <img
                                            src="/assets/images/icons/min-logo.png"
                                            alt={localizer.getKey(x.nameAr, x.nameEn)}
                                            title={localizer.getKey(x.nameAr, x.nameEn)}
                                          />
                                        </div>
                                        <h4 class="mar-bot0">
                                          {localizer.getKey(x.nameAr, x.nameEn)}
                                        </h4>
                                      </div>
                                      <Link to={x.id}>
                                        <span>
                                          {localizer.getJsonKey("Details")}
                                        </span>
                                        <i class="fas fa-arrow-left"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12 text--center">
                  <ul class="pagination">
                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a class="current" href="">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="">2</a>
                    </li>
                    <li>
                      <a href="">3</a>
                    </li>
                    <li>
                      <a href="">4</a>
                    </li>

                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-left"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <SubMenu />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default DepartmentsList;
