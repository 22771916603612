import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function GenericContent(props) {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const location = useLocation();
  const localizer = useLocalizer();

  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Articles/GetArticleDetails",
          { ReferenceId: PortalID, id: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.ArticlesEntity);
          context.setEntityID(res.data.output.ArticlesEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, location.pathname]);

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {pageContent == 0
                      ? ""
                      : localizer.getKey(
                          pageContent.nameAr,
                          pageContent.nameEn
                        )}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {pageContent == 0
                  ? ""
                  : localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl " id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-8 order-0 genericContent">
              <div className="service-entry">
                <div className="entry-content">
                  <div className="entry-introduction">
                    {pageContent == 0 && <DetailsLoader></DetailsLoader>}
                    {ReactHtmlParser(
                      pageContent != 0
                        ? localizer.getKey(
                            pageContent.contentAr,
                            pageContent.contentEn
                          )
                        : ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SubMenu />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default GenericContent;
