import React from 'react';
import NewsPaper from './NewsPaper';
import useLocalizer from "../../Localization/useLocalizer";
import pdfDownload from './RiyadhMagazine.pdf'

const PDFViewer = () => {
    const localizer = useLocalizer();

    return (
        <div className="App" style={{ backgroundColor: "#e2e2e2"}}>
            <NewsPaper  pdfDownload={pdfDownload}/>
        </div>
    );
};

export default PDFViewer;
