import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Helpers from "../Helpers/Helpers";
import useLocalizer from "../Localization/useLocalizer";

function BottomComments() {
  const location = useLocation();
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState();
  const [commentsCollapsed, setCommentsCollapsed] = useState(true);
  const [pageOutPut, setPageOutPut] = useState();
  const [resF, setResF] = useState(true);
  const [resMessage, setResMessage] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  // let queryID = searchParams.get("did");
  let queryID = useParams();
  const localizer = useLocalizer();
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(localizer.getJsonKey("RequiredField")),
    mobile: Yup.string().required(localizer.getJsonKey("RequiredField")),
    comment: Yup.string().required(localizer.getJsonKey("RequiredField")),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("RequiredField")),
    acceptTerms: Yup.boolean()
      .required(localizer.getJsonKey("RequiredField"))
      .oneOf([true], localizer.getJsonKey("RequiredField")),
  });
  useEffect(() => {
    if (
      context.tokenVal != undefined &&
      context.entityID != undefined &&
      context.entityID != -1
    ) {
      //console.log("token effect " + context.tokenVal);
      getContent();
    }
  }, [context.entityID, location, searchParams]);
  const submitComment = async function (values) {
    //console.log(location.pathname);
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Comments/InsertComment",
        {
          ReferenceID: PortalID,
          EntityID: context.entityID == undefined ? null : context.entityID,
          ItemId: queryID.did != undefined ? queryID.did : "",
          Text: values.comment,
          ItemUrl: location.pathname,
          CommenterName: values.name,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //Helpers.notify(localizer.getJsonKey("SendSuccess"));
       // console.log("res", res);
        setResF(false);
        setResMessage(
          localizer.getKey(res.data.header.message, res.data.header.messageEn)
        );
      });
  };
  const getContent = async function () {
    if (context.entityID != -1) {
      await httpService
        .post(
          apiUrl + "/Comments/GetAllComments",
          {
            ReferenceID: PortalID,
            EntityID: context.entityID,
            ItemId: queryID != undefined ? queryID.did : "",
            Text: "",
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.CommentsEntity);
        });
    }
  };
  return (
    <section
      className={
        "content-settings " +
        ((location.pathname == "/ar" ||
          location.pathname == "/en" ||
          context.entityID == -1 ||
          context.entityID == undefined) &&
          "d-none")
      }
    >
      <div className="container">
        <div className="blog-single comm-inter">
          <div class="accordion accordion-2" id="accordion03">
            <div
              class="comments-body"
              onClick={(e) => setCommentsCollapsed(!commentsCollapsed)}
            >
              <div class={"card " + (commentsCollapsed ? "" : "active-acc")}>
                <div class="card-heading">
                  <a
                    class="card-link"
                    data-hover=""
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded={!commentsCollapsed}
                    aria-controls="collapse01-1"
                    href="#collapse01-1"
                  >
                    <span>
                      {localizer.getJsonKey("Comments")}{" "}
                      {pageContent != undefined && pageContent.length}
                    </span>
                    {localizer.getJsonKey("ShowComments")}
                  </a>
                </div>
                <div
                  class={"collapse " + (commentsCollapsed ? "" : "show")}
                  id="collapse01-1"
                  data-bs-parent="#accordion03"
                >
                  <div class="card-body">
                    <div class="entry-widget mar0 entry-comments" id="comments">
                      <div class="entry-widget-title"></div>
                      <div class="entry-widget-content">
                      { pageContent != undefined &&
                        <ul className="comments-list">
                         {
                            pageContent.map((x, ind) => (
                              <li key={ind} className="comment-body">
                                <div className="avatar">
                                  <img
                                    src="/assets/images/blog/author/1.png"
                                    alt="user avatar"
                                    title="user avatar"
                                    longdesc="#accordion03"
                                  />
                                </div>
                                <div
                                  className="comment"
                                  style={{ width: "100%" }}
                                >
                                  <h6> {x.commenterName} </h6>
                                  <div className="date">
                                    {" "}
                                    <Moment format="YYYY/MM/DD">
                                      {x.createdDate
                                        ? x.createdDate
                                        : Date().toLocaleString()}
                                    </Moment>{" "}
                                  </div>
                                  <p>{x.text}</p>

                                  <ul
                                    className={
                                      "replies-list" +
                                      (!x.replyText ? " d-none" : "")
                                    }
                                  >
                                    <li className="comment-body">
                                      <div className="avatar">
                                        <img
                                          src="/assets/images/blog/author/2.png"
                                          alt="user avatar"
                                          title="user avatar"
                                          longdesc="#accordion03"
                                        />
                                      </div>
                                      <div
                                        className="comment"
                                        style={{ width: "100%" }}
                                      >
                                        <h6>{localizer.getJsonKey("RM")}</h6>
                                        <div className="date">
                                          <Moment format="YYYY/MM/DD">
                                            {x.replyDate
                                              ? x.replyDate
                                              : Date().toLocaleString()}
                                          </Moment>{" "}
                                        </div>
                                        <p>{x.replyText}</p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            ))
                          }
                            </ul>
                            
                            }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {resF ? (
            <div className="entry-widget entry-add-comment mb-0  clearfix">
              <div className="entry-widget-title">
                <h4>{localizer.getJsonKey("LeaveComment")}</h4>
              </div>

              <div className="entry-widget-content">
                <Formik
                  initialValues={{
                    name: "",
                    mobile: "",
                    email: "",
                    comment: "",
                    acceptTerms: false,
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    submitComment(values);
                    resetForm();
                  }}
                >
                  {({ errors, touched, isSubmitting, isValid }) => (
                    <Form>
                       {console.log(isSubmitting)}
                      <div className="mb-0">
                        <div className="row">
                          <div className="col-12 col-lg-4">
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={localizer.getJsonKey("Name")}
                              title={localizer.getJsonKey("Name")}
                              name="name"
                              spellcheck="true"

                            />
                            <label id="" class="errorr" for="contact-name">
                              {errors.name && touched.name && errors.name}
                            </label>
                          </div>
                          <div className="col-12 col-lg-4">
                            <Field
                              className="form-control"
                              type="email"
                              placeholder={localizer.getJsonKey("Email")}
                              title={localizer.getJsonKey("Email")}
                              name="email"
                            />
                            <label id="" class="errorr" for="contact-name">
                              {errors.email && touched.email && errors.email}
                            </label>
                          </div>
                          <div className="col-12 col-lg-4">
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={localizer.getJsonKey("MobileNum")}
                              title={localizer.getJsonKey("MobileNum")}
                              name="mobile"
                            />
                            <label id="" class="errorr" for="contact-name">
                              {errors.mobile && touched.mobile && errors.mobile}
                            </label>
                          </div>
                          <div className="col-12">
                            <Field
                              className="form-control"
                              rows="2"
                              placeholder={localizer.getJsonKey("BottomComment")}
                              title={localizer.getJsonKey("BottomComment")}
                              name="comment"
                              spellcheck="true"
                            ></Field>
                            <label id="" class="errorr" for="contact-name">
                              {errors.comment &&
                                touched.comment &&
                                errors.comment}
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="custom-radio-group">
                              <div className="custom-control custom-radio custom-control-inline">
                                <Field
                                  className="custom-control-input"
                                  type="checkbox"
                                  id="customRadioInline1"
                                  name="acceptTerms"
                                  title="terms and condition"
                                />

                                <label htmlFor="customRadioInline1">
                                  {localizer.getJsonKey("AcceptTerms")}
                                </label>
                              </div>
                            </div>
                            <label id="" class="errorr" for="contact-name">
                              {errors.acceptTerms &&
                                touched.acceptTerms &&
                                errors.acceptTerms}
                            </label>
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn--primary btn-line btn-line-before"
                              type="submit"
                              disabled={!isValid}
                            >
                              <span>
                                {" "}
                                {localizer.getJsonKey("SendComment")}
                              </span>
                              <i className="fas fa-arrow-left"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div class="card mb-0">
              <div class="card-body">
                <div class="entry-widget mar0 entry-comments ">
                  <div class="entry-widget-content greenText">{resMessage}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default BottomComments;
