import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
function News() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/news/getnews",
        {
          ReferenceID: PortalID,
          ShowInHome: true,
          Pagination: {
            currentPageIndex: 1,
            totalPagesCount: 5,
          },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.NewsEntity);
        window.loadslider(".HomeNews");
      });
  }
  return (
    <section
      className="projects projects-modern projects-modern-1"
      id="projects-modern-1"
    >
      <div className="container">
        <div className="heading heading-2 rtl">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="heading-title">{localizer.getJsonKey("News")}</h2>
            </div>
            <div className="col-lg-6">
              <div className="actions-holder">
                <Link className="btn btn--primary" to={"news"}>
                  {localizer.getJsonKey("AllNews")}
                  <i className="fa fa-arrow-left"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="newsDiv">
          <div className="col-12">
            <div
              className={
                pageContent.length > 0
                  ? "owl-carousel carousel-dots HomeNews"
                  : ""
              }
              data-slide="3"
              data-slide-rs="1"
              data-autoplay="true"
              data-nav="false"
              data-dots="true"
              data-space="30"
              data-loop="true"
              data-speed="3000"
              rtl={localizer.getLang().indexOf("ar") == -1 ? "false" : "true"}
            >
              {pageContent.length > 0 &&
                pageContent.map((e, i) => (
                  <div key={i}>
                    <div className="project-panel" data-hover="">
                      <div className="project-panel-holder">
                        <div className="project-img">
                          <Link className="link" to={"news/" + e.id}>
                            <div className="d-none">
                              {localizer.getKey(e.titleAr, e.titleEn)}
                            </div>
                            </Link>
                          <img
                            src={e.originalPic}
                            alt={localizer.getKey(e.titleAr, e.titleEn)}
                            title={localizer.getKey(e.titleAr, e.titleEn)}
                            style={{ height: "256px" }}
                            longdesc="#newsDiv"
                          />
                        </div>

                        <div className="project-content">
                          <div className="project-cat">
                            <a href="javascript:void(0)">
                              {localizer.getKey(e.newsSourceAr, e.newsSourceEn)}
                            </a>
                            <a href="javascript:void(0)">
                              <Moment format="DD MM YYYY">
                                {e.PublishDateG}
                              </Moment>
                            </a>
                          </div>
                          <div className="project-title">
                            <h4>
                              <Link
                                title={localizer.getKey(e.titleAr, e.titleEn)}
                                to={"news/" + e.id}
                              >
                                {_.truncate(
                                  localizer.getKey(e.titleAr, e.titleEn),
                                  { length: 60 }
                                )}
                              </Link>
                            </h4>
                          </div>
                          <div className="project-desc">
                            <p>
                              {_.truncate(
                                localizer.getKey(
                                  e.briefeContentAr,
                                  e.briefeContentEn
                                ),
                                { length: 100 }
                              )}
                            </p>
                          </div>
                          <div className="project-more">
                            <Link
                              className="btn btn--bordered btn--white"
                              to={"news/" + e.id}
                            >
                              <i className="fas fa-arrow-left"></i>{" "}
                              {localizer.getJsonKey("ReadMore")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
