import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import axios from "axios";
import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import Skeleton from "react-loading-skeleton";
// import $ from "jquery";
import useLocalizer from "../../Localization/useLocalizer";

function MobileAppsDetails() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const localizer = useLocalizer();

  //let queryID = searchParams.get("did");
  let params = useParams();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/MobileApplications/GetMobileApplicationDetails",
          {
            ReferenceID: PortalID,
            ID: params.did,
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          //debugger;
          setPageContent(res.data.output.MobileApplicationsEntity);
          context.setEntityID(
            res.data.output.MobileApplicationsEntity.entityId
          );
          window.loadslider(".mobappsslider");
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent &&
                      localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("MobApps")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      {!pageContent ? (
        <DetailsLoader />
      ) : (
        // <DetailsLoader></DetailsLoader>
        <section class="service-single rtl" id="service-single">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 order-0">
                <div class="service-entry">
                  <div class="entry-content">
                    <div class="entry-introduction entry-infos">
                      <h5 class="entry-heading">
                        {pageContent &&
                          localizer.getKey(
                            pageContent.nameAr,
                            pageContent.nameEn
                          )}
                      </h5>

                      <div class="contact-panel no-padd">
                        <div class="row">
                          <div class="col-12">
                            <div class="contact-card">
                              <div class="contact-body">
                                <div class="row">
                                  <div class="col-12 col-md-3">
                                    <img
                                      src={pageContent.imageUrl}
                                      alt={localizer.getKey(pageContent.nameAr,pageContent.nameEn)}
                                      title={localizer.getKey(pageContent.nameAr,pageContent.nameEn)}
                                    />
                                  </div>

                                  <div class="col-12 col-md-9">
                                    <p>
                                      <strong>
                                        {localizer.getJsonKey("AboutApp")}
                                      </strong>
                                    </p>
                                    <p class="entry-desc">
                                      {localizer.getKey(
                                        pageContent.briefeContentAr,
                                        pageContent.briefeContentEn
                                      )}
                                    </p>

                                    <ul class="list-unstyled advantages-list advantages-list-3">
                                      <li>
                                        {localizer.getJsonKey("AppSize")}:{" "}
                                        {pageContent.applicationSize}{" "}
                                        {localizer.getJsonKey("MegaByte")}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="single-product">
                        <div class="blog-single">
                          <div class="entry-widget-bio">
                            <div class="entry-widget-content centerrr">
                              <img
                                src="/assets/images/icons/noteicon-2.png"
                                alt={localizer.getJsonKey("UserGuide")}
                                title={localizer.getJsonKey("UserGuide")}
                              />
                              <div class="entry-bio-desc">
                                <h4> {localizer.getJsonKey("UserGuide")}</h4>

                                <p>
                                  {localizer.getJsonKey(
                                    "DownloadUserGuideDesc"
                                  )}
                                </p>

                                <a href={pageContent.userManualUrlAr}>
                                  <span>
                                    {localizer.getJsonKey("UserGuide")}
                                  </span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 18"
                                    width="18"
                                    height="18"
                                  >
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            class="shp0"
                                            d="M2.12 2L2.93 1L14.93 1L15.87 2L2.12 2ZM9 14.5L3.5 9L7 9L7 7L11 7L11 9L14.5 9L9 14.5ZM17.54 2.23L16.15 0.55C15.88 0.21 15.47 0 15 0L3 0C2.53 0 2.12 0.21 1.84 0.55L0.46 2.23C0.17 2.57 0 3.02 0 3.5L0 16C0 17.1 0.9 18 2 18L16 18C17.1 18 18 17.1 18 16L18 3.5C18 3.02 17.83 2.57 17.54 2.23Z"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="entry-benefits entry-infos">
                          <h5 class="entry-heading">
                            {localizer.getJsonKey("MobAppFaq")}
                          </h5>
                          <div class="accordion accordion-2" id="accordion03">
                            <div class="row">
                              {pageContent.listOfQuestions.map((e, i) => (
                                <div key={i} class="col-12">
                                  <div class="card">
                                    <div class="card-heading">
                                      <a
                                        className="card-link collapsed "
                                        data-hover=""
                                        data-bs-toggle="collapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls={"child" + i}
                                        href={"#child" + i}
                                      >
                                        {localizer.getKey(
                                          e.questionAr,
                                          e.questionEn
                                        )}
                                      </a>
                                    </div>
                                    <div
                                      class="collapse show"
                                      id={"child" + i}
                                      data-bs-parent="#accordion03"
                                    >
                                      <div class="card-body">
                                        {localizer.getKey(
                                          e.answerAr,
                                          e.asnwerEn
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div class="entry-infos projects projects-grid projects-grid-2 mar-bot60 mar-top60">
                          <h5 class="entry-heading">
                            {localizer.getJsonKey("MobAppImages")}
                          </h5>
                          <div
                            class="carousel owl-carousel mobappsslider"
                            data-slide="2"
                            data-slide-rs="2"
                            data-autoplay="true"
                            data-nav="true"
                            data-dots="false"
                            data-space="30"
                            data-loop="true"
                            data-speed="800"
                            rtl={
                              localizer.getLang().indexOf("ar") == -1
                                ? "false"
                                : "true"
                            }
                          >
                            {pageContent.listOfImage.map((e, i) => (
                              <div key={i}>
                                <div class="project-panel" data-hover="">
                                  <div class="project-panel-holder">
                                    <div class="project-img auto-img">
                                      <img src={e.url} 
                                          alt="Mobile Application Icon" 
                                          title="Mobile Application Icon" 
                                          />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div class="blog-single">
                          <div class="entry-widget-bio sub-icons">
                            <div class="entry-widget-content centerrr">
                              <img
                                src="/assets/images/icons/noteicon-2.png"
                                alt="author"
                                title="author"
                              />
                              <div class="entry-bio-desc">
                                <h4> {localizer.getJsonKey("DownloadApp")}</h4>

                                <p>{localizer.getJsonKey("DownloadAppUrls")}</p>

                                <div class="row">
                                  <div class="col-md-6 col-xs-6 cod-image">
                                    <a
                                      href={pageContent.iosUrl}
                                      target="_blank"
                                    >
                                      <div class="title-daw">
                                        <i class="fab fa-apple"></i>
                                        <span class="hidden-xs hidden-sm">
                                          {localizer.getJsonKey("DownloadApp")}
                                        </span>
                                      </div>
                                    </a>
                                    <img
                                      src={
                                        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=" +
                                        encodeURIComponent(pageContent.iosUrl) +
                                        "&choe=UTF-8"
                                      }
                                      alt="Chart cover"
                                      title="Chart cover"
                                    />
                                  </div>
                                  <div class="col-md-6 col-xs-6 cod-image">
                                    <a
                                      href={pageContent.androidUrl}
                                      target="_blank"
                                    >
                                      <div class="title-daw">
                                        <i class="fab fa-android"></i>
                                        <span class="hidden-xs hidden-sm">
                                          {localizer.getJsonKey("DownloadApp")}
                                        </span>
                                      </div>
                                    </a>
                                    <img
                                      src={
                                        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=" +
                                        encodeURIComponent(
                                          pageContent.androidUrl
                                        ) +
                                        "&choe=UTF-8"
                                      }
                                      alt="chart cover"
                                      title="chart cover"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SubMenu></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
export default MobileAppsDetails;
