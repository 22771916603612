import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function CompetionsList() {
  const [mainSelectedOption, setMainSelectedOption] = useState(0);
  const [ddlContent, setDDLContent] = useState([]);
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const [searchTerm, setSearchTerm] = useState("");
  const localizer = useLocalizer();
  const [active,setActive]=useState();
  //const [mainType,setMainType]=useState();
  const lang=localizer.getLang() 
  let en;
  if(lang=='/en'){en=true;}

  useEffect(() => {
    if (context.tokenVal != undefined) {
      getDDLContent();
      getListContent(0);
    }
  }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setListContent(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setListContent(strAscending);
  }
  async function getDDLContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Investments/GetInvestmentsTypes",
        { ReferenceID: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setDDLContent(res.data.output.InvestmentsEntity);
      });
  }
  async function getListContent(oppType = 0) {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Investments/GetInvestmentsCompetitionsList",
        {
          ReferenceID: PortalID,
          //opportunityType: oppType != 0 ? oppType : null,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.InvestmentsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("InvestComp")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("InvestComp")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("InvestComp")}
                    </h5>

                    <p class="card-desc">
                      {localizer.getJsonKey("InvestCompDesc")}
                    </p>
                    
                    <div class={en? "single-product engli-product" : "single-product"}>
                        <div class="product-tabs " style={{ marginTop: "0px" }}>
                          <ul class="nav nav-tabs" role="tablist" style={{backgroundColor:'#f4f4f4',padding:'14px 40px 5px 50px',borderRadius:'50px', display:'flex',justifyContent:'space-evenly'}}>
                       
                              <li role="presentation">
                                  <a
                                    class={`${active==1 ? "active" : ""}`}
                                    href={"#tab0"}
                                    data-bs-target={"#tab0"}
                                    aria-controls={"tab0"}
                                    role="tab"
                                    data-bs-toggle="tab"
                                    aria-selected="true"
                                    onClick={()=>{ setActive(1); getListContent(0,3); }}
                                  >
                                    {localizer.getJsonKey("PreviousCompetitions")}
                                  </a>
                              </li>
                              <li role="presentation">
                                  <a
                                    class={`${active==2 ? "active": "" }`}
                                    href={"#tab1"}
                                    data-bs-target={"#tab1"}
                                    aria-controls={"tab1"}
                                    role="tab"
                                    data-bs-toggle="tab"
                                    aria-selected="false"
                                    onClick={()=>{ setActive(2); getListContent(0,2); }}
                                  >
                                    {localizer.getJsonKey("CurrentCompetitions")}
                                  </a>
                              </li>
                              <li role="presentation">
                                  <a
                                    class={`${active==3 ? "active" : ""}`}
                                    href={"#tab2"}
                                    data-bs-target={"#tab2"}
                                    aria-controls={"tab2"}
                                    role="tab"
                                    data-bs-toggle="tab"
                                    aria-selected="false"
                                    onClick={()=>{setActive(3);getListContent(0,3);}}
                                  >
                                    {localizer.getJsonKey("NextCompetitions")}
                                  </a>
                              </li>
                          </ul>
                        </div>
                    </div>
                    <ol class="numberss">
                      <li>
                        {localizer.getJsonKey("GoToWebSite")}
                        <a
                          href="https://tenders.etimad.sa/Tender/AllTendersForVisitor"
                          target="_blank"
                        >
                          <span>{localizer.getJsonKey("EtimadSite")}</span>
                        </a>
                        .<br />
                      </li>
                      <li>
                        {localizer.getJsonKey("ChooseSearch")}
                        <br />
                      </li>
                      <li>
                        {localizer.getJsonKey("ChooseRM")}
                        <br />
                      </li>
                    </ol>

                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div
                                class="contactForm"
                                method="post"
                                action=""
                                novalidate="novalidate"
                              >
                                <div class="row">
                                  <div class="col-12 col-md-4">
                                    <label class="form-label">
                                      {localizer.getJsonKey("SearchAll")}
                                    </label>

                                    <input
                                      class="form-control"
                                      id="name"
                                      type="text"
                                      placeholder={localizer.getJsonKey(
                                        "WriteSearchWord"
                                      )}
                                      title={localizer.getJsonKey(
                                        "WriteSearchWord"
                                      )}
                                      onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                      }
                                      value={searchTerm}
                                    />
                                  </div>
                                  <div class="col-12 col-md-4">
                                    <label class="form-label">
                                      {localizer.getJsonKey("Category")}
                                    </label>
                                    <select
                                      class="form-control"
                                      value={mainSelectedOption}
                                      onChange={(e) => {
                                        setMainSelectedOption(e.target.value);
                                        getListContent(e.target.value);
                                      }}
                                    >
                                      <option value="0">
                                        {localizer.getJsonKey("All")}
                                      </option>
                                      {ddlContent.length > 0 &&
                                        ddlContent.map((x, ind) => (
                                          <option
                                            value={x.id}
                                            key={ind}
                                            class="option"
                                          >
                                            {x.nameAr}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                         
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                          
                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-content external-sub-logo">
                          <div class={"tab-pane"} id={"tab0"} role="tabpanel">
                              <div class="features features-3 row-hover">
                                  <div class="row">
                                      {listContent.length == 0 && (
                                        <RepeatableTableRows style={{ width: "100%" }} />
                                      )}
                                      <div class="table-responsive">
                                        <table
                                          class="table table-hover table-bordered"
                                          cellspacing="0"
                                          rules="all"
                                          border="1"
                                        >
                                          <tbody>
                                            {listContent.length > 0 &&
                                              listContent.filter((x, i) =>
                                                searchTerm == ""
                                                  ? x
                                                  : x.nameAr.includes(searchTerm)
                                              )
                                              .filter(x=> x.opportunityMainType == 0 )
                                              .length > 0 && (
                                                <tr class="GridViewTr" >
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestNumber")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestType")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("Title")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("RefNum")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("OpportunityDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("LastInquiryDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("LastSubmitDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("OpenBidDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("CompDocFees")}
                                                  </th>
                                                </tr>
                                              )}

                                            {listContent.length > 0 &&
                                              listContent
                                                .filter((x, i) =>
                                                  searchTerm == ""
                                                    ? x
                                                    : x.nameAr.includes(searchTerm)
                                                )
                                                .filter(x=> x.opportunityMainType == 0 )
                                                .map((x, ind) => (
                                                  <tr key={ind} id={x.id}>
                                                    <td>{x.opportunityNo}</td>
                                                    <td>{x.opportunityTypeName}</td>
                                                    <td>
                                                      <a
                                                        class="GridViewLink"
                                                        href={x.opportunityUrl}
                                                        target="_blank"
                                                      >
                                                        {x.nameAr}
                                                      </a>
                                                    </td>
                                                    <td>{x.opportunityReference}</td>
                                                    <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {x.opportunityDate}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastInquiryDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastAdmissionDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.openBidDate}
                                                      </Moment>
                                                    </td>
                                                    <td> {x.price}</td>
                                                  </tr>
                                                ))}
                                          </tbody>
                                        </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class={`tab-pane show active`} id={"tab1"} role="tabpanel">
                            <div class="features features-3 row-hover">
                                <div class="row">
                                      {listContent.length == 0 && (
                                        <RepeatableTableRows style={{ width: "100%" }} />
                                      )}
                                      <div class="table-responsive">
                                        <table
                                          class="table table-hover table-bordered"
                                          cellspacing="0"
                                          rules="all"
                                          border="1"
                                        >
                                          <tbody>
                                            {listContent.length > 0 &&
                                              listContent.filter((x, i) =>
                                                searchTerm == ""
                                                  ? x
                                                  : x.nameAr.includes(searchTerm)
                                              )
                                              .filter(x=> x.opportunityMainType == 1 )
                                              .length > 0 && (
                                                <tr class="GridViewTr">
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestNumber")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestType")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("Title")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("RefNum")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("OpportunityDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("LastInquiryDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("LastSubmitDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("OpenBidDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("CompDocFees")}
                                                  </th>
                                                </tr>
                                              )}

                                            {listContent.length > 0 &&
                                              listContent
                                                .filter((x, i) =>
                                                  searchTerm == ""
                                                    ? x
                                                    : x.nameAr.includes(searchTerm)
                                                )
                                                .filter(x=> x.opportunityMainType == 1 )
                                                .map((x, ind) => (
                                                  <tr key={ind} id={x.id}>
                                                    <td>{x.opportunityNo}</td>
                                                    <td>{x.opportunityTypeName}</td>
                                                    <td>
                                                      <a
                                                        class="GridViewLink"
                                                        href={x.opportunityUrl}
                                                        target="_blank"
                                                      >
                                                        {x.nameAr}
                                                      </a>
                                                    </td>
                                                    <td>{x.opportunityReference}</td>
                                                    <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {x.opportunityDate}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastInquiryDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastAdmissionDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.openBidDate}
                                                      </Moment>
                                                    </td>
                                                    <td> {x.price}</td>
                                                  </tr>
                                                ))}
                                          </tbody>
                                        </table>
                                      </div>
                                </div>
                            </div>
                          </div>
                          <div class={"tab-pane"} id={"tab2"} role="tabpanel">
                            <div class="features features-3 row-hover">
                              <div class="row">
                                      {listContent.length == 0 && (
                                        <RepeatableTableRows style={{ width: "100%" }} />
                                      )}
                                      <div class="table-responsive">
                                        <table
                                          class="table table-hover table-bordered"
                                          cellspacing="0"
                                          rules="all"
                                          border="1"
                                        >
                                          <tbody>
                                            {listContent.length > 0 &&
                                              listContent.filter((x, i) => searchTerm == "" ? x : x.nameAr.includes(searchTerm))
                                              .filter(x=> x.opportunityMainType == 2 )
                                              .length > 0 && (
                                                <tr class="GridViewTr">
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestNumber")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("InvestType")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("Title")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("RefNum")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("OpportunityDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("LastInquiryDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("LastSubmitDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    {localizer.getJsonKey("OpenBidDate")}
                                                  </th>
                                                  <th scope="col">
                                                    {localizer.getJsonKey("CompDocFees")}
                                                  </th>
                                                </tr>
                                              )}

                                            {listContent.length > 0 &&
                                              (listContent.filter((x, i) =>searchTerm == ""? x: x.nameAr.includes(searchTerm)))
                                                .filter(x=> x.opportunityMainType == 2 )
                                                .map((x, ind) => (
                                                  <tr key={ind} id={x.id}>
                                                    <td>{x.opportunityNo}</td>
                                                    <td>{x.opportunityTypeName}</td>
                                                    <td>
                                                      <a
                                                        class="GridViewLink"
                                                        href={x.opportunityUrl}
                                                        target="_blank"
                                                      >
                                                        {x.nameAr}
                                                      </a>
                                                    </td>
                                                    <td>{x.opportunityReference}</td>
                                                    <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {x.opportunityDate}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastInquiryDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.lastAdmissionDate}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      <Moment format="DD/MM/YYYY">
                                                        {x.openBidDate}
                                                      </Moment>
                                                    </td>
                                                    <td> {x.price}</td>
                                                  </tr>
                                                ))}


                                          



                                          </tbody>
                                        </table>

                                        
                                      </div>
                                      {
                                              listContent.filter((x, i) =>searchTerm == ""? x: x.nameAr.includes(searchTerm))
                                                .filter(x=> x.opportunityMainType == 2 ).length == 0 &&(
                                      <h6 style={{textAlign: "center"}}>{localizer.getJsonKey("NextCompetitionsMessage")}</h6>
                                      )}
                              </div>
                            </div>
                          </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default CompetionsList;
