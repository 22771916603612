import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, newOpenDataGateWay} from "../../Config/Config.json";
import $ from 'jquery'; 
import _ from "lodash";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";

function AddCommissionerRequest() {
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const [entities, setEntities] = useState([]);
  const [domain, setDomain] = useState("");
  const [emailStr, setEmailStr] = useState("");

  //const pattern = /^[a-zA-Z0-9!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
  const pattern=/^[\w-\.]*$/;
  // const { values } = useFormikContext();
   useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay +  "/Entities/GetAll",
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setEntities(res.data.data.items);
         // console.log("entities",entities);
        });
    }
    
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  const FormSchema = Yup.object().shape({
    EmployeeName: Yup.string().required(localizer.getJsonKey("PleaseInsertEmployeeName")),
    // Password:Yup.string().required(localizer.getJsonKey("PleaseInsertPassword")),
    Email: Yup.string().matches(pattern,localizer.getJsonKey("EmailNotValid")).required(localizer.getJsonKey("PleaseInsertEmail")),
    PhoneNumber: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    EntityID: Yup.number().required(localizer.getJsonKey("PleaseInsertEntity"))
                          .min(1,localizer.getJsonKey("PleaseInsertEntity")),
    Domain: Yup.string(),
    file:Yup.mixed().required(localizer.getJsonKey("PleaseInsertCommissioningLetter"))
    // recaptcha: Yup.string().required(localizer.getJsonKey("PleaseInsertCapcha")),
  });

  const submitForm = async function (values) {
        var formData = new FormData();
        formData.append('id',0);
        formData.append('employeeName',values.EmployeeName);
        formData.append('email',values.Email);
        formData.append('phoneNumber',values.PhoneNumber);
        formData.append('entityID', values.EntityID);
        formData.append('domain',entities.find(x=>x.id==values.EntityID).domain);
        // formData.append('Capcha',values.recaptcha);
        formData.append('attachments[0].id',''  );
        formData.append('attachments[0].file', values.file);
        formData.append('attachments[0].attachmentTypeId',5);
        formData.append('attachments[0].name', values.file.name);
        formData.append('attachments[0].isDeleted', false);
    await httpService
      .post(
        apiUrl + newOpenDataGateWay + "/CommissionerAdditionRequests",formData,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setDomain("");
        if(res.data.succeeded && res.data.data==null && (res.data.message!="" || res.data.message!=null)){
          Helpers.notify(localizer.getJsonKey(res.data.message));
        }
        // else if (res.data.succeeded) Helpers.notify(localizer.getJsonKey("RequestSendSuccessfully"));
      });
  };
  function handleChange (entityID){
    if(entityID!=0){
      var domain= entities.find(x=>x.id==entityID).domain;
      setDomain(domain);
    }
    else{
      setDomain("");
    }
  } 

  return (
    <div className="contact-body tab-pane active" id="opendata" role="tabpanel">
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("AddCommissionerRequest")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("AddCommissionerRequest")} </h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  
                  <p>{localizer.getJsonKey("odContactUsDesc")}</p>                  
                  
                  <div className="rate-des">
                  <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                      
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      
      <Formik
        initialValues={{
          EmployeeName: "",
          Email: "",
          PhoneNumber: "",
          EntityID:"",
          Domain:"",
          // recaptcha: "",
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await submitForm(values, setSubmitting);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ errors, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <div className="contactForm">
              {isSubmitting ? (
                <ThreeDots></ThreeDots>
              ) : 
              (
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="EmployeeName"
                      placeholder={localizer.getJsonKey("EmployeeName")}
                      title={localizer.getJsonKey("EmployeeName")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.EmployeeName}
                    </label>
                  </div>
                  {/* {domain==""?
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      // type="email"
                      name="Email"
                      placeholder={localizer.getJsonKey("Email")}
                      title={localizer.getJsonKey("Email")}
                    />
                    <label id="" class="errorr">
                      {errors && errors.Email}
                    </label>
                  </div>:
                  
                  <div className="col-12 col-md-6">
                    {localizer.getLang()!="ar"?
                      <div className="input-group">
                          <Field
                              className="form-control"
                              name="Email"
                              placeholder={localizer.getJsonKey("Email")}
                              title={localizer.getJsonKey("Email")}
                            />
                            <label id="" class=" errorr">
                              {errors && errors.Email}
                            </label>
                          <span className="input-group-text domainSpan" dir="ltr" id="basic-addon2">{'@'+domain}</span>
                      </div>:
                      <div className="input-group">
                          <span className="input-group-text domainSpan" dir="ltr" id="basic-addon2">{'@'+domain}</span>
                          <Field
                              className="form-control"
                              name="Email"
                              placeholder={localizer.getJsonKey("Email")}
                              title={localizer.getJsonKey("Email")}
                            />
                            <label id="" class=" errorr">
                              {errors && errors.Email}
                            </label>
                      </div>
                    }
                  </div>
                  } */}
                  <div className="col-12 col-md-6">
                  {/* {localizer.getLang()=='en'?
                    <div className="input-group">
                      <Field
                            className="form-control"
                            name="Email"
                            placeholder={localizer.getJsonKey("Email")}
                            title={localizer.getJsonKey("Email")}
                          />
                          <label id="" class=" errorr">
                            {errors && errors.Email}
                          </label>
                      <div class="input-group-append">
                        <span className="input-group-text domainSpan" dir="ltr" id="basic-addon2">{'@'+domain}</span>
                      </div>
                    </div>: */}
                    <div className="input-group">
                        <div class="input-group-prepend">
                          <span className="input-group-text domainSpan" dir="ltr" id="basic-addon2"
                           data-toggle="tooltip" data-placement="top" title={domain==''?localizer.getJsonKey("entityDomainToolTip"):''}>{domain==''? '@entity_domain' : '@'+domain}</span>
                        </div>
                        <Field
                        className="form-control"
                        name="Email"
                        placeholder={localizer.getJsonKey("Email")}
                        title={localizer.getJsonKey("Email")}
                        />
                        <label id="" class=" errorr">
                          {errors && errors.Email}
                        </label>
                    </div>
                  {/* } */}
                  </div>


                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="PhoneNumber"
                      placeholder={localizer.getJsonKey("MobileNum")}
                      title={localizer.getJsonKey("MobileNum")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.PhoneNumber}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <select 
                        onChange={(event) => {
                          setFieldValue("EntityID", event.target.value);
                          handleChange(event.target.value);
                          // setFieldValue("Email",entities.find(x=>x.id==event.target.value).domain);
                        }}
                        className="form-control"
                        name="EntityID"
                        placeholder={localizer.getJsonKey("Entity")}
                        title={localizer.getJsonKey("Entity")}>
                          <option value='0' class="option">{localizer.getJsonKey("PleaseSelectYourEntity")}</option>
                          {entities?.length > 0 && entities.map((x, ind) => (
                          <option value={x.id} key={ind} class="option">
                            {localizer.getKey(x.nameAr,x.nameEn)}
                          </option>
                          ))}
                        </select>
                        <label id="" class=" errorr">
                          {errors && errors.EntityID}
                        </label>
                  </div>
                  <div className="col-12">
                      <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("UploadFile")}
                            title={localizer.getJsonKey("UploadFile")}
                            name="file"
                            type="file"
                            accept=".pdf,.docx,.doc"
                            value={null}
                            onChange={(event) => {
                                setFieldValue("file",event.currentTarget.files[0])// reader.result);
                              }}
                            />
                            <label id="" class=" errorr">
                              {errors && errors.file}
                            </label>
                  </div>
                 <div className="col-12">
                    <button
                      className="btn btn--secondary"
                      type="submit"
                      disabled={!isValid}
                    >
                      {localizer.getJsonKey("Send")}{" "}
                      <i className="fas fa-arrow-left"></i>
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="contact-result"></div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
  <script>
    $(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
  </script>
}
export default AddCommissionerRequest;
