import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { ValidationError } from "yup";
import _ from "lodash";

function ArrayFileExtension(values, extensions) {
  let valid = true;
  console.log("v",values);
  //console.log("v t",values.type);
  //console.log("e",extensions);
  debugger;
  if (_.isNil(values)) return true;

  _.forEach(values, function (v) {
    if (
      v.FileBase64 &&
      !extensions.includes(v.FileBase64.split(";")[0].split("/")[1])
    ) {
      valid = false;
      return valid;
    }
  });
  return valid;
}
function FileExtension(s) {
    //console.log("v",s);
  return s.split(";")[0];
}
function MbyteCount(s) {
  return (encodeURI(s).split(/%..|./).length - 1) / 1024 / 1024;
}
export default { FileExtension, MbyteCount, ArrayFileExtension };
