import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import axios from "axios";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, GMapsKey,newOpenDataGateWay } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import _ from "lodash";
import useLocalizer from "../../Localization/useLocalizer";
function SuccessStoryDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/SuccessStories/GetById?id=" + params.did,
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.data);
          context.setEntityID(res.data.data.id);
        });
    }

    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);

  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section class="page-title page-title-6 center" id="page-title">
        <div class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent.length > 0 &&
                      localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="breadcrumb-wrap rtl">
        <div class="container">
          <ol class="breadcrumb d-flex">
            <li class="breadcrumb-item">
              <a href={localizer.getLang() + "/"}>
                {localizer.getJsonKey("Home")}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {localizer.getJsonKey("DataSets")}
            </li>
            <li class="breadcrumb-item active">
              {localizer.getJsonKey("SuccessStoryDetails")}
            </li>
          </ol>
        </div>
      </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section
          class="single-product rtl mar-top50 mar-bot50"
          id="single-product"
        >
          <div class="container">
            <div class="row">
            <img class="img-fluid mb-5" src={pageContent.imageBase64} alt="image"/>
              <div class="col-12 col-lg-12">
                <div class="product-content features features-3 row-hover">
                  <div class="product-title">
                    <h3>
                      {localizer.getKey(pageContent.projectNameAr, pageContent.projectNameEn)}
                    </h3>
                  </div>
                  <p class="entry-desc">
                    <strong>{localizer.getJsonKey("ProjectSummary")}</strong>
                  </p>
                  <div class="product-desc">
                    <p align="justify">
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.projectSummaryAr,
                          pageContent.projectSummaryEn
                        )
                      )}
                    </p>
                  </div>
                  <p class="entry-desc">
                    <strong>{localizer.getJsonKey("ProjectDescription")}</strong>
                  </p>
                  <div class="product-desc">
                    <p align="justify">
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.projectDescriptionAr,
                          pageContent.projectDescriptionEn
                        )
                      )}
                    </p>
                  </div>
                  <p class="entry-desc">
                    <strong>{localizer.getJsonKey("ProjectResults")}</strong>
                  </p>
                  <div class="product-desc">
                    <p align="justify">
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.projectResultsAr,
                          pageContent.projectResultsEn
                        )
                      )}
                    </p>
                  </div>
                  {/* <div class="serv-details">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="name">
                            {" "}
                            <i class="fas fa-users"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("Publisher")}:
                            </span>
                          </td>
                          <td class="value">
                            {localizer.getKey(
                              pageContent.publisher?.nameAr,
                              pageContent.publisher?.nameEn
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td class="name">
                            {" "}
                            <i class="fas fa-desktop"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("DataSetType")}:
                            </span>
                          </td>
                          <td class="value">
                            {localizer.getKey(
                              pageContent.dataSetType?.nameAr,
                              pageContent.dataSetType?.nameEn
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td class="name">
                            <i class="fas fa-calendar"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("Date")}:
                            </span>
                          </td>
                          <td class="value">{pageContent.createdDateHijri}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default SuccessStoryDetails;
