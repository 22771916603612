import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import Skeleton from "react-loading-skeleton";
import useLocalizer from "../../Localization/useLocalizer";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
function PrincesList() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      //console.log("calling initiatives");

      getListContent();
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Officials/GetPrincesList",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.OfficialsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("RiyadhPrinces")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("RiyadhPrinces")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      {listContent.length == 0 ? (
        <RepeatableTableRows count={5} />
      ) : (
        <section className="team team-1 rtl" id="team">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 order-0">
                <div className="row" style={{ justifyContent: "center" }}>
                  {listContent.length > 0 &&
                    listContent
                      .filter((m) => m.officialOrder == "1")
                      .map((x, ind) => (
                        <div key={ind} className="col-12 col-md-12 col-lg-6">
                          <div className="member member-2">
                            <div className="member-img">
                              <img src={x.originalPic} alt={localizer.getKey(x.nameAr, x.nameEn)} title={localizer.getKey(x.nameAr, x.nameEn)} longdesc="#team"/>
                            </div>

                            <div className="member-content">
                              <div className="member-info">
                                <a href="javascript:void(0)">
                                  {localizer.getKey(x.nameAr, x.nameEn)}
                                </a>
                                <h6 class="dateeeeee">
                                  {localizer.getKey(
                                    x.contentAr.replace(/<\/?[^>]+(>|$)/g, ""),
                                    x.contentEn.replace(/<\/?[^>]+(>|$)/g, "")
                                  )}
                                </h6>
                                <h6 class="dateeeeee"></h6>
                              </div>

                              <div className="member-social"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                <div className="row">
                  {listContent.length > 0 &&
                    listContent
                      .filter((m) => m.officialOrder != "1")
                      .map((x, ind) => (
                        <div key={ind} className="col-12 col-md-6 col-lg-6">
                          <div className="member member-2">
                            <div className="member-img">
                              <img src={x.originalPic} alt={localizer.getKey(x.nameAr, x.nameEn)} title={localizer.getKey(x.nameAr, x.nameEn)} longdesc="#team"/>
                            </div>

                            <div className="member-content">
                              <div className="member-info">
                                <a href="javascript:void(0)">
                                  {" "}
                                  {localizer.getKey(x.nameAr, x.nameEn)}
                                </a>
                                <h6 class="dateeeeee">
                                  {localizer.getKey(
                                    x.contentAr.replace(/<\/?[^>]+(>|$)/g, ""),
                                    x.contentEn.replace(/<\/?[^>]+(>|$)/g, "")
                                  )}
                                </h6>
                              </div>

                              <div className="member-social"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              <SubMenu customMenu={ContentMenuCode}></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default PrincesList;
