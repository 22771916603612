import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import GridList from "../../Components/GridList";
import Pagination from "./Controls/Pagination";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";

import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";

export default function NewsList() {
  const [newsData, setnewsData] = useState();
  const [newsArr, setNewsArr] = useState([]);

  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  const getNewsData = async (page) => {
    const result = await httpService
      .post(
        apiUrl + "/news/getnews",
        {
          ReferenceId: PortalID,
          Pagination: {
            currentPageIndex: page,
            totalPagesCount: 15,
          },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setnewsData(res.data.output);
        setNewsArr(newsArr.concat(res.data.output.NewsEntity));
        context.setEntityID(res.data.output.EntityID);
      });
  };
  // useEffect(() => {
  //   if (context.tokenVal != undefined) {
  //     getNewsData(1);
  //   }
  // }, [context.tokenVal]);

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("News")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("MediaCenter")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("News")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="blog blog-grid blog-grid-5 rtl" id="blognews">
        <div className="container">
          <div className="row">
            <GridList
              itemsLength={newsArr.length}
              totalCount={
                newsData != undefined ? newsData.Pagination.totalItemsCount : 0
              }
              nextFun={getNewsData}
            >
              {newsArr.map((item) => (
                <div key={item.id} className="col-12 col-md-6 col-lg-4">
                  <div className="blog-entry" data-hover="">
                    <div className="entry-content">
                      <div className="entry-meta">
                        <div className="entry-date">
                          <span className="day">{item.NewsDate}</span>
                          <span className="year">{item.NewsDate}</span>
                        </div>

                        <div className="entry-author"></div>
                      </div>
                      <div className="entry-title">
                        <h4>
                          <Link to={item.id}>
                            {_.truncate(
                              localizer.getKey(item.titleAr, item.titleEn),
                              { length: 60 }
                            )}
                          </Link>
                        </h4>
                      </div>
                      <div className="entry-img-wrap">
                        <div style={{marginBottom:"20px"}} className="entry-img">
                          <Link to={item.id}>
                            <img src={item.originalPic} alt={localizer.getKey(item.titleAr, item.titleEn)} title={localizer.getKey(item.titleAr, item.titleEn)} longdesc="#blognews" />
                          </Link>
                        </div>
                        <div style={{position:"initial"}} className="entry-category">
                          <a href={item.newsSourceAr}>
                            {localizer.getKey(
                              item.newsSourceAr,
                              item.newsSourceEn
                            )}
                          </a>
                        </div>
                      </div>

                      <div className="entry-bio">
                        <p>
                          {_.truncate(
                            localizer.getKey(
                              item.briefeContentAr,
                              item.briefeContentEn
                            ),
                            { length: 100 }
                          )}
                        </p>
                      </div>
                      <div className="entry-more">
                        <Link
                          className="btn btn--white btn-bordered"
                          to={item.id}
                        >
                          {localizer.getJsonKey("ReadMore")}
                          <i className="fas fa-arrow-left"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </GridList>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
