import React, { useState, useContext, useEffect } from "react";

import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../Context/SessionContext";

import httpService from "../services/httpService";
import {
  apiUrl,
  PortalID,
  MenuCode,
  ContentMenuCode,
} from "../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../Components/SkeletonLoader";
import { ThreeDots } from "../Components/SkeletonLoader";
import useLocalizer from "../Localization/useLocalizer";

function SubMenu(props) {
  const [pageContent, setPageContent] = useState();
  const [menusEntity, setMenusEntity] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const params = useParams();
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + "/Menus/GetMenus",
          {
            ReferenceId: PortalID,
            EntityID: null,
            /*EntityID:
              props.customMenu && context.entityID != -1
                ? context.entityID
                : null,*/
            Code: props.customMenu
              ? props.customMenu
              : window.location.href.indexOf("content") !== -1
              ? ContentMenuCode
              : MenuCode,
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent([]);
          if (res.data.output.MenusEntity != null) {
            setMenusEntity(res.data.output.MenusEntity);
            setPageContent(res.data.output.MenusEntity.subMenus);
          }
        });
    }
    //if (context.tokenVal != undefined && context.entityID != undefined) {
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.entityID]);
  return (
    <div className="col-12 col-lg-4 order-1" style={props.style}>
      <div className="sidebar sidebar-service">
        <div className="widget widget-services">
          <div className="widget-title">
            <h5>
              {menusEntity
                ? localizer.getKey(menusEntity.nameAr, menusEntity.nameEn)
                : ""}
            </h5>
          </div>
          <div className="widget-content">
            <ul className="list-unstyled">
              {/* <li className="active">
                <a href="#">
                  {" "}
                  <span>{props.title}</span>
                  <i className="fas fa-arrow-left"></i>
                </a>
              </li> */}

              {pageContent != undefined &&
                pageContent.map((e, i) => (
                  <li key={i} className="">
                    <Link
                      to={
                        `/${params.lid}` +
                        e.url +
                        (e.articleId == null ? "" : "/" + e.articleId)
                      }
                      target={e.openBlankPage ? "_blank" : ""}
                    >
                      {" "}
                      <span>{localizer.getKey(e.nameAr, e.nameEn)}</span>
                      <i className="fas fa-arrow-left"></i>
                    </Link>
                  </li>
                ))}
            </ul>
            {pageContent == undefined && <ThreeDots></ThreeDots>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubMenu;
