import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import useLocalizer from "../../Localization/useLocalizer";
function MayorsDetails() {
  const [pageContent, setPageContent] = useState({});
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const params = useParams();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      //console.log("calling initiatives");

      getListContent();
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;

    const result = await httpService
      .post(
        apiUrl + "/Officials/GetOffcialDetails",
        {
          ReferenceID: PortalID,
          ID: params.did,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setPageContent(res.data.output.OfficialsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("RiyadhMayors")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("RiyadhMayors")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="team team-1 rtl" id="team">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="row">
                <div class=" col-12 col-md-6 col-lg-12">
                  <div class="member member-2">
                    <div class="member-img">
                      <img
                        src={pageContent.originalPic}
                        alt={localizer.getKey(pageContent.nameAr,pageContent.nameEn)}
                        title={localizer.getKey(pageContent.nameAr,pageContent.nameEn)}
                      />
                    </div>

                    <div class="member-content">
                      <div class="member-info">
                        <a href="javascript:void(0)">
                          {localizer.getKey(
                            pageContent.nameAr,
                            pageContent.nameEn
                          )}{" "}
                        </a>
                      </div>

                      <div class="member-social"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mar-bot50">
                  <h5 class="entry-heading">
                    {localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                  </h5>

                  <p class="entry-desc">
                    {ReactHtmlParser(
                      localizer.getKey(
                        pageContent.contentAr,
                        pageContent.contentEn
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
            <SubMenu customMenu={ContentMenuCode}></SubMenu>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default MayorsDetails;
