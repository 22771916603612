import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function GovServicesDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  //let queryID = searchParams.get("did");
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Govservices/GetGovServiceDetails",
          { ReferenceID: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.GovServicesEntity);
          context.setEntityID(res.data.output.GovServicesEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent != undefined &&
                      localizer.getKey(
                        pageContent.siteNameAr,
                        pageContent.siteNameEn
                      )}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("GovServices")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section class="service-single rtl" id="service-single">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 order-0 genericContent">
                <div class="service-entry">
                  <div class="entry-content">
                    <div class="entry-introduction entry-infos">
                      <div class="single-product">
                        <div class="col-12">
                          <div class="checkout-banner">
                            <p>
                              {localizer.getKey(
                                pageContent.siteNameAr,
                                pageContent.siteNameEn
                              )}

                              <span>
                                {" "}
                                {localizer.getKey(
                                  pageContent.nameAr,
                                  pageContent.nameEn
                                )}
                              </span>
                            </p>

                            <a
                              class="btn btn--primary justify-content-center"
                              href={pageContent.serviceUrl}
                              target="_blank"
                            >
                              {localizer.getJsonKey("StartService")}
                            </a>
                          </div>
                        </div>

                        <div class="external-sub-logo">
                          <div class="features features-3 row-hover">
                            <p class="entry-desc">
                              {ReactHtmlParser(
                                localizer.getKey(
                                  pageContent.descriptionAr,
                                  pageContent.descriptionEn
                                )
                              )}
                            </p>

                            <p class="entry-desc">
                              <strong>
                                {" "}
                                {localizer.getJsonKey("ServiceInfo")}
                              </strong>
                              <br />
                            </p>
                            <div class="serv-details">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-users"></i>
                                      {localizer.getJsonKey("TargetAudience")}
                                    </td>
                                    <td class="value">
                                      {localizer.getKey(
                                        pageContent.audienceAr,
                                        pageContent.audienceEn
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-desktop"></i>
                                      {localizer.getJsonKey(
                                        "GovServiceChannels"
                                      )}
                                    </td>
                                    <td class="value">
                                      {localizer.getKey(
                                        pageContent.serviceChannelAr,
                                        pageContent.serviceChannelEn
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <p class="entry-desc">
                              <strong>
                                {localizer.getJsonKey("Requirements")}
                              </strong>
                              <br />
                            </p>

                            {ReactHtmlParser(
                              localizer.getKey(
                                pageContent.requirementsAr,
                                pageContent.requirementsEn
                              )
                            )}
                            <ol class="numberss d-none">
                              <li>تقديم طلب إلكتروني.</li>
                              <li>سجل تجاري</li>
                              <li>تفويض أو وكالة شرعية</li>
                              <li>سجل النفايات الخطرة</li>
                              <li>
                                هناك ثلاث فئات: المشاريع ذات التأثيرات البيئية
                                المحدودة، والمشاريع ذات التأثيرات البيئية
                                الهامة، والمشاريع ذات التأثيرات البيئية الخطيرة.
                              </li>
                              <li>على حسب نوع الفئة هناك متطلبات إضافية.</li>
                            </ol>

                            <div class="blog-single">
                              <div class="entry-widget-bio">
                                <div class="entry-widget-content">
                                  <div class="entry-bio-desc">
                                    <h4> {localizer.getJsonKey("Steps")}</h4>
                                    {ReactHtmlParser(
                                      localizer.getKey(
                                        pageContent.stepsAr,
                                        pageContent.stepsEn
                                      )
                                    )}

                                    <ol class="numberss d-none">
                                      <li>
                                        قدّم طلب تصريح بيئي على نظام التراخيص
                                        البيئية للمركز الوطني للرقابة على
                                        الالتزام البيئي واملأ النموذج.
                                      </li>
                                      <li>يتم مراجعة ودراسة الطلب.</li>
                                      <li>يتم إصدار التصريح البيئي.</li>
                                    </ol>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SubMenu></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default GovServicesDetails;
