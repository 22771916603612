import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";


function notify(msg, isError = false) {
    if (isError) {
        toast.error(msg, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            RTL: "TRUE",
        });
    } else {
        toast.success(msg, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            RTL: "TRUE",
        });
    }
}
function SaveRatingToLocalStorage(data) {
    var a = [];

    a = JSON.parse(localStorage.getItem("ratings")) || [];

    a.push(data);

    localStorage.setItem("ratings", JSON.stringify(a));
}
function GetRatingToLocalStorage() {
    return JSON.parse(localStorage.getItem("ratings")) || [];
}
function SaveHelpfulToLocalStorage(data) {
    var a = [];

    a = JSON.parse(localStorage.getItem("Helpful")) || [];

    a.push(data);

    localStorage.setItem("Helpful", JSON.stringify(a));
}
function GetHelpfulToLocalStorage() {
    return JSON.parse(localStorage.getItem("Helpful")) || [];
}
function encryptDecrypt(inpString) {
    debugger
    inpString = inpString.split("");

    // Define XOR key
    // Any character value will work
    let xorKey = 'K';

    // calculate length of input string
    let len = inpString.length;

    // perform XOR operation of key
    // with every character in string
    for (let i = 0; i < len; i++) {
        inpString[i] = (String.fromCharCode((inpString[i].charCodeAt(0)) ^ xorKey.charCodeAt(0)));
    }
    return inpString.join("");
}

function confirmALert(title, confirmBtnAction, lang = '/ar') {

    Swal.fire({
        title: title != undefined && title != null ? title : 'هل أنت متأكد؟',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: lang == '/ar' ? 'تأكيد' : 'Yes',
        cancelButtonText: lang == '/ar' ? 'إلغاء' : 'No',
        focusConfirm: false,
        confirmButtonColor: '#8cd4f5',
        cancelButtonColor: '#c1c1c1',
    }).then((result) => {
        if (result.isConfirmed == true) {
            confirmBtnAction();
        }

    });
};
export default {
    notify,
    SaveRatingToLocalStorage,
    GetRatingToLocalStorage,
    SaveHelpfulToLocalStorage,
    GetHelpfulToLocalStorage,
    encryptDecrypt,
    confirmALert
};
