import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "../../styles/preloadstyles.css";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Search from "./Search";
import "./leaflet.css";
import { MapContainer, TileLayer, useMap, Marker, Popup, useLeaflet } from 'react-leaflet';
import L from "leaflet";
import { Slide } from 'react-slideshow-image';
/*import 'react-slideshow-image/dist/styles.css';*/

function Gardens() {

    const icon = L.icon({
        iconUrl: "https://nominatim.openstreetmap.org/ui/build/images/marker-icon.png",
        iconSize: [32, 35],
    });
    const position = [24.774265, 46.738586];
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [ProjectStages, setProjectStages] = useState([]);
    const [DesignStatuses, setDesignStatuses] = useState([]);
    const [Priorities, setPriorities] = useState([]);
    const [WorkStages, setWorkStages] = useState([]);
    const [GardenSizes, setGardenSizes] = useState([]);
    useEffect(() => {
        fetch(apiUrl + "/riyadh-gardens/get-lookups", 
             {
                method: "get",
                headers: {
                    'Content-Type': 'application/json'
                },
            
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setProjectStages(result.Data.ProjectStages);
                    setPriorities(result.Data.Priorities);
                    setDesignStatuses(result.Data.DesignStatuses);
                    setWorkStages(result.Data.WorkStages);
                    setGardenSizes(result.Data.GardenSizes);
                })
             .catch((error) => {
                    setIsLoaded(false);
                 setError(error);
                 toast.error('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                 });
             })
      
    }, [])

    useEffect(() => {
        fetch(apiUrl + "/riyadh-gardens/get-all", 
             {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify({
                    TransferId: null,
                    CurrentPage: 1,
                    PageSize:10000
                })
            
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.Data.Gardens);
                })
             .catch((error) => {
                    setIsLoaded(false);
                 setError(error);
                 toast.error('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                 });
             })
      
    }, [])
    
    return (
        <React.Fragment>
            <section class="page-title page-title-6 center" id="page-title">
                {!isLoaded &&
                    <div class="preloader">
                    <div class="dual-ring"></div>
                </div> }
                <ToastContainer />
                <MapContainer center={position} zoom={12} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                 
                    <Search items={items} center={position}
                    ProjectStages={ProjectStages} 
                    Priorities={Priorities} 
                    DesignStatuses={DesignStatuses} 
                    GardenSizes={GardenSizes} 
                        WorkStages={WorkStages}
                    />
                        {items.map((place, index) => {

                            return (<Marker position={[place.Latitude, place.Longitude]} icon={icon} key={index}>
                                <Popup >
                                    <span class="garden-heading">الحي: <p className="garden-details px-1">{place.Neighborhood}</p></span>
                                    <span class="garden-heading">مساحة المشروع :<p className="garden-details px-1">{place.AreaSize} م²</p></span>
                                    <span class="garden-heading">تصنيف المساحة:<p className="garden-details px-1">{GardenSizes.find(a => a.Id == place.GardenSizeId)?.Name}</p></span>
                                    <span class="garden-heading"> رابط الموقع :{place.LocationUrl == null || place.LocationUrl == '' || place.LocationUrl == "" ? <p className="garden-details px-1">غير مضاف </p> : <a href={place.LocationUrl} class="pointer-item" target="_blank" style={{ color: '#fff' }}> <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "0px 8px" }} height="16" width="12" viewBox="0 0 384 512"><path fill="#9b9b9b" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg></a>}</span>
                                    <span class="garden-heading">رابط التصميم:{place.DesignUrl == null || place.DesignUrl == '' || place.DesignUrl == "" ? <p className="garden-details px-1">غير مضاف </p> : <a href={place.DesignUrl} class="pointer-item" target="_blank" style={{ color: '#fff' }}> <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "0px 8px" }} height="16" width="16" viewBox="0 0 512 512"><path fill="#9b9b9b" d="M469.3 19.3l23.4 23.4c25 25 25 65.5 0 90.5l-56.4 56.4L322.3 75.7l56.4-56.4c25-25 65.5-25 90.5 0zM44.9 353.2L299.7 98.3 413.7 212.3 158.8 467.1c-6.7 6.7-15.1 11.6-24.2 14.2l-104 29.7c-8.4 2.4-17.4 .1-23.6-6.1s-8.5-15.2-6.1-23.6l29.7-104c2.6-9.2 7.5-17.5 14.2-24.2zM249.4 103.4L103.4 249.4 16 161.9c-18.7-18.7-18.7-49.1 0-67.9L94.1 16c18.7-18.7 49.1-18.7 67.9 0l19.8 19.8c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1l45.1 45.1zM408.6 262.6l45.1 45.1c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1L496 350.1c18.7 18.7 18.7 49.1 0 67.9L417.9 496c-18.7 18.7-49.1 18.7-67.9 0l-87.4-87.4L408.6 262.6z" /></svg></a>}</span>
                                </Popup>
                            </Marker>)

                        })}
                </MapContainer>
            </section>

        </React.Fragment>
    );
}

export default Gardens;
