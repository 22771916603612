import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";

import {
  DetailsLoader,
  RepeatableTableRows,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Helpers from "../Helpers/Helpers";
import ReCAPTCHA from "react-google-recaptcha";
import useLocalizer from "../Localization/useLocalizer";

function ContactUS() {
  const localizer = useLocalizer();
  const context = useContext(SessionContext);
  const FormSchema = Yup.object().shape({
    name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    userId: Yup.string().required(localizer.getJsonKey("PleaseInsertID")),
    description: Yup.string().required(
      localizer.getJsonKey("PleaseInsertDetails")
    ),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail")),
    recaptcha: Yup.string().required(
      localizer.getJsonKey("PleaseInsertCapcha")
    ),
  });

  const submitForm = async function (values) {
    const result = await httpService
      .post(
        apiUrl + "/ContactUs/SaveContactUs",
        {
          ReferenceID: PortalID,
          Name: values.name,
          MobileNo: values.mobileNo,
          UserId: values.userId,
          Description: values.description,
          Subject: values.subject,
          Email: values.email,
          Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("SendSuccess"));
      });
  };
  return (
    <div
      className="contact-body tab-pane active"
      id="president"
      role="tabpanel"
    >
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("ContactUs")} >
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("ContactUs")}</h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                    </div>
                  </div>
                  <p>
                  {localizer.getJsonKey("ContactUsWelcome")}
                  </p>
                  <div className="rate-des">
                    <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i>  {localizer.getJsonKey("ServiceAvailabilityLabel")}:
                      {localizer.getJsonKey("openingHours")}
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ResponseTimeLabel")}: مدة الاستجابة: 7 ايام
                    </div>

                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Formik
        initialValues={{
          name: "",
          mobileNo: "",
          email: "",
          userId: "",
          description: "",
          recaptcha: "",
        }}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values);
          resetForm();
        }}
      >
        {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <div className="contactForm">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={localizer.getJsonKey("Name")}
                    title={localizer.getJsonKey("Name")}
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.name}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <Field
                    className="form-control"
                    type="text"
                    name="userId"
                    placeholder={localizer.getJsonKey("UserID")}
                    title={localizer.getJsonKey("UserID")}
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.userId}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <Field
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder={localizer.getJsonKey("Email")}
                    title={localizer.getJsonKey("Email")}
                    required=""
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.email}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <Field
                    className="form-control"
                    type="text"
                    name="mobileNo"
                    placeholder={localizer.getJsonKey("MobileNum")}
                    title={localizer.getJsonKey("MobileNum")}
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.mobileNo}
                  </label>
                </div>

                <div className="col-12">
                  <Field
                    className="form-control"
                    placeholder={localizer.getJsonKey("MsgTextChars250")}
                    title={localizer.getJsonKey("MsgTextChars250")}
                    name="description"
                    cols="30"
                    rows="20"
                    type="textarea"
                    spellcheck="true"
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.description}
                  </label>
                </div>
                <div className="col-12" style={{ marginBottom: "10px" }}>
                  <ReCAPTCHA
                    sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                    onChange={(response) => {
                     // console.log(response);
                      setFieldValue("recaptcha", response);
                    }}
                  ></ReCAPTCHA>
                  <label id="" class="col-12 errorr" for="contact-name">
                    {errors && errors.recaptcha}
                  </label>
                </div>

                <div className="col-12">
                  <button
                    className="btn btn--secondary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {localizer.getJsonKey("Send")}{" "}
                    <i className="fas fa-arrow-left"></i>
                  </button>
                </div>
                <div className="col-12">
                  <div className="contact-result"></div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactUS;
