import React, { useState, useContext, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";

import httpService from "../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../Config/Config.json";
import { ThreeDots } from "../Components/SkeletonLoader";
import useLocalizer from "../Localization/useLocalizer";
import { BsBorder } from "react-icons/bs";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import Helpers from "../Helpers/Helpers";
import * as Yup from "yup";

export default function MasterFooter(props) {
  // const location = useLocation();
  const location = useLocation();
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const [lastUpdates, setLastUpdates] = useState([]);
  async function getLlastUpdates() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Statistics/GetPortalLatestUpdate",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
       // debugger;
        setLastUpdates(res.data.output.EntitiesLatestUpdate.find(obj => obj.entityId == "OVJyQlRlWG1MRkk9" ));
        //setLastUpdates(res.data.output.EntitiesLatestUpdate[0]);
        context.setEntityID(-1);
      });
  }

  const FormSchema = Yup.object().shape({
    
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail"))
  });
  
  /*const submitForm = async function (values) {
    const result = await httpService
      .get(
        apiUrl + "/News/SaveNewsLettersEmail?email=" + values.email,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        if(res == "1")
          Helpers.notify(localizer.getJsonKey("SendSuccess"));
        else
        {
          Helpers.notify(localizer.getJsonKey("Error"));
        }
      });
  };*/


  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
      getLlastUpdates();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Menus/GetMenus",
        {
          ReferenceID: PortalID,
          Code: FooterCode,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        debugger;
        setPageContent(res.data.output.MenusEntity.subMenus);
      });
  }

  return (
    <React.Fragment>
      <footer
        className={
          "footer footer-1 rtl " +
          (location.pathname != "/ar" &&
            location.pathname != "/en" &&
            "no-margin")
        }
      >
        <div className="footer-top widget-links widget-icon" id="footerDiv">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="footer-widget widget-contact">
                  <div className="widget-content">
                    <div className="widget-footer">
                      <img
                        src="/assets/images/icons/footer-logo.png"
                        className="footer-logo"
                        alt="RM Logo"
                        title="RM Logo"
                        longdesc="#footerDiv"

                      />
                      <img
                        src="/assets/images/icons/vision30.png"
                        className="footer-logo"
                        alt="Vision2030 Logo"
                        title="Vision2030 Logo"
                        longdesc="#footerDiv"
                      />
                    </div>

                    <ul>
                      <li className="phone">
                        <a> 940</a>
                      </li>
                      <li className="email">
                        <a>
                          <span className="__cf_email__" data-cfemail="">
                            940@alriyadh.gov.sa
                          </span>
                        </a>
                      </li>
                    </ul>
                  
                    {/*<div className="widget-footer footer-widget-subcribe ">
                      <p>اشترك بالنشرة البريدية </p>
                      <Formik
                        initialValues={{
                          email: ""
                        }}
                        validationSchema={FormSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          submitForm(values);
                          resetForm();
                        }}
                      >
                      {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
                      <Form className="mc4wp-form ">
                        <div className="mc4wp-form-fields">
                          <div className="subscribe-inner-form">
                            <Field
                              className="form-control"
                              type="email"
                              id="contact-usage"
                              name="email"
                              placeholder="بريدك الإلكتروني"
                              title="بريدك الإلكتروني"
                              required=""
                            />
                            <button
                              type="submit"
                              onSubmit={(values, { setSubmitting, resetForm }) => {
                                submitForm(values);
                              }}
                              className="subscribe-btn-icon"
                            >
                              <i className="Riyadh-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </Form>
                      )}
                      </Formik>
                    </div>*/}
                    <br />
                    {lastUpdates == undefined ? (
                      <ThreeDots />
                    ) : (
                          <div className="border col-md-6 p-2 pt-2" style={{textAlign:'center',fontFamily:'var(--global--font-body)'}}>
                                
                                  {localizer.getJsonKey("PortalLastUpdate")} : 
                                  <Moment format="DD/MM/YYYY">
                                  {  lastUpdates.lastUpdate} 
                                </Moment> 
                              </div>
                    )}

                  </div>
                </div>
              </div>
              {pageContent == undefined ? (
                <ThreeDots />
              ) : (
                pageContent.map((e, i) => (
                  <div key={i} className="col-12 col-sm-6 col-md-6 col-lg-2">
                    <div className="footer-widget">
                      <div className="footer-widget-title">
                        <h5>{localizer.getKey(e.nameAr, e.nameEn)}</h5>
                      </div>
                      <div className="widget-content">
                        <ul>
                          {e.subMenus.map(
                            (se, si) =>
                              se.url != null && (
                                <li key={si}>
                                  {se.url.indexOf("http") == -1 ? (
                                    <Link
                                      to={
                                        localizer.getLang() +
                                        se.url +
                                        (se.articleId == null
                                          ? ""
                                          : "/" + se.articleId)
                                      }
                                      target={se.openBlankPage ? "_blank" : ""}
                                    >
                                      {" "}
                                      {localizer.getKey(
                                        se.nameAr,
                                        se.nameEn
                                      )}{" "}
                                    </Link>
                                  ) : (
                                    <a
                                      href={se.url}
                                      target={
                                        se.openBlankPage == true ? "_blank" : ""
                                      }
                                    >
                                      {" "}
                                      {localizer.getKey(
                                        se.nameAr,
                                        se.nameEn
                                      )}{" "}
                                    </a>
                                  )}
                                </li>
                              )
                          )}
                          
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div> 
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-copyright">
                  <div className="copyright">
                    <span> {localizer.getJsonKey("CopyRights")}</span>
                    <span style={{color:'white'}}> <a style={{color:'white'}} href={localizer.getLang() + "/sitemap"}>{localizer.getJsonKey("sitemap")} </a></span>
                    {/* <ul className="list-unstyled social-icons">
                      <li className="d-none">
                        <a className="share-facebook" href="javascript:void(0)">
                          <i className="Riyadh-facebook"></i>{" "}
                          <div className="d-none">{localizer.getJsonKey("Facebook")} </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="share-twitter"
                          href="https://twitter.com/amanatalriyadh"
                        >
                          <i className="Riyadh-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          className="share-youtube"
                          href="https://www.youtube.com/channel/UCwHl8ipDFzOlZTI5YA_8_FQ"
                        >
                          <i className="Riyadh-youtube"></i>
                        </a>
                      </li>
                    </ul> */}
                    <ul class="list-unstyled social-icons">
                      <li class="d-none">
                        <a class="share-facebook" href="javascript:void(0)" aria-label={localizer.getJsonKey("Facebook")}>
                          <i class="Riyadh-facebook"></i>{" "}
                          <div className="d-none">{localizer.getJsonKey("Facebook")} </div>
                        </a>
                      </li>
                      <li>
                        <a
                          class="share-twitter"
                          href="https://twitter.com/amanatalriyadh"
                          target={"_blank"}
                          aria-label={localizer.getJsonKey("Twitter")}
                        >
                          <i class="Riyadh-twitter"></i>
                          <div className="d-none">{localizer.getJsonKey("Twitter")} </div>
                        </a>
                      </li>
                      <li>
                        <a
                          class="share-youtube"
                          href="https://www.youtube.com/channel/UCwHl8ipDFzOlZTI5YA_8_FQ"
                          target={"_blank"}
                          aria-label={localizer.getJsonKey("Youtube")}
                        >
                          <i class="Riyadh-youtube"></i>
                          <div className="d-none">{localizer.getJsonKey("Youtube")} </div>
                        </a>
                      </li>
                      <li>
                        <a
                          class="share-linkedin"
                          href="https://www.linkedin.com/company/amanatalriyadh/"
                          target={"_blank"}
                          aria-label={localizer.getJsonKey("Linkedin")}
                        >
                          <i class="Riyadh-linkedin"></i>
                          <div className="d-none">{localizer.getJsonKey("Linkedin")} </div>
                        </a>
                      </li>
                      <li>
                        <a
                          class="share-youtube"
                          href="https://www.snapchat.com/add/amanatalriyadh?sender_web_id=d0e00b6e-6a4f-459d-b1b4-73e904f85d65&device_type=desktop&is_copy_url=true"
                          target={"_blank"}
                          aria-label="snapchat"
                        >
                          <i class="fab fa-snapchat"></i>
                          <div className="d-none">snapchat</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div style={{left:"10px",right:"auto"}} className="back-top" id="back-to-top" data-hover="">
        <i className="Riyadh-arrow-up"></i>
      </div>
    </React.Fragment>
  );
}
