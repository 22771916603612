import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import { Chart } from "react-google-charts";
import useLocalizer from "../../Localization/useLocalizer";
function Trading() {
  const [yearlyContent, setYearlyContent] = useState();
  const [monthlyContent, setMonthlyContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  const options = {
    series: {
      0: { color: "#4f9ac3" },
      1: { targetAxisIndex: 1, color: "#b3e9e9" },
    },
    hAxis: {
      title: localizer.getJsonKey("Months"),
    },
    vAxis: {
      title: localizer.getJsonKey("Count"),
    },
  };
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getMonthlyContent();
      getYearlyContent();
    }
  }, [context.tokenVal]);

  async function getMonthlyContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/EserviceStats/GetTradingStatsMonthly",
        { ReferenceId: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setMonthlyContent((oldArray) => [
          ...oldArray,
          [
            localizer.getJsonKey("Month"),
            localizer.getJsonKey("NewLicence"),
            localizer.getJsonKey("RenewedLicence"),
          ],
        ]);
        res.data.output.CurrEserviceStatsEntity.map((e, i) =>
          setMonthlyContent((oldArray) => [
            ...oldArray,
            [
              localizer.getJsonKey("Month"),
              localizer.getJsonKey("NewLicence"),
              localizer.getJsonKey("RenewedLicence"),
            ],
          ])
        );
        context.setEntityID(-1);
      });
  }
  async function getYearlyContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/EserviceStats/GetTradingStatsYearly",
        { ReferenceId: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setYearlyContent(res.data.output.EserviceStatsEntity[0]);
        context.setEntityID(-1);
      });
  }
  return (
    <div class="features features-3 mar-top50">
      <div class="contact-card">
        <div class="heading heading-1">
          <h2 class="heading-title">
            {localizer.getJsonKey("TradingLicence")}
          </h2>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <span class="uk-icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      width="68"
                      height="68"
                      x="0px"
                      y="0px"
                      viewBox="0 0 489.4 489.4"
                    >
                      <g>
                        <g>
                          <path
                            d="M347.7,263.75h-66.5c-18.2,0-33,14.8-33,33v51c0,18.2,14.8,33,33,33h66.5c18.2,0,33-14.8,33-33v-51
			C380.7,278.55,365.9,263.75,347.7,263.75z M356.7,347.75c0,5-4.1,9-9,9h-66.5c-5,0-9-4.1-9-9v-51c0-5,4.1-9,9-9h66.5
			c5,0,9,4.1,9,9V347.75z"
                          ></path>
                          <path
                            d="M489.4,171.05c0-2.1-0.5-4.1-1.6-5.9l-72.8-128c-2.1-3.7-6.1-6.1-10.4-6.1H84.7c-4.3,0-8.3,2.3-10.4,6.1l-72.7,128
			c-1,1.8-1.6,3.8-1.6,5.9c0,28.7,17.3,53.3,42,64.2v211.1c0,6.6,5.4,12,12,12h66.3c0.1,0,0.2,0,0.3,0h93c0.1,0,0.2,0,0.3,0h221.4
			c6.6,0,12-5.4,12-12v-209.6c0-0.5,0-0.9-0.1-1.3C472,224.55,489.4,199.85,489.4,171.05z M91.7,55.15h305.9l56.9,100.1H34.9
			L91.7,55.15z M348.3,179.15c-3.8,21.6-22.7,38-45.4,38c-22.7,0-41.6-16.4-45.4-38H348.3z M232,179.15c-3.8,21.6-22.7,38-45.4,38
			s-41.6-16.4-45.5-38H232z M24.8,179.15h90.9c-3.8,21.6-22.8,38-45.5,38C47.5,217.25,28.6,200.75,24.8,179.15z M201.6,434.35h-69
			v-129.5c0-9.4,7.6-17.1,17.1-17.1h34.9c9.4,0,17.1,7.6,17.1,17.1v129.5H201.6z M423.3,434.35H225.6v-129.5
			c0-22.6-18.4-41.1-41.1-41.1h-34.9c-22.6,0-41.1,18.4-41.1,41.1v129.6H66v-193.3c1.4,0.1,2.8,0.1,4.2,0.1
			c24.2,0,45.6-12.3,58.2-31c12.6,18.7,34,31,58.2,31s45.5-12.3,58.2-31c12.6,18.7,34,31,58.1,31c24.2,0,45.5-12.3,58.1-31
			c12.6,18.7,34,31,58.2,31c1.4,0,2.7-0.1,4.1-0.1L423.3,434.35L423.3,434.35z M419.2,217.25c-22.7,0-41.6-16.4-45.4-38h90.9
			C460.8,200.75,441.9,217.25,419.2,217.25z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>
                <div class="feature-content">
                  <h4>{localizer.getJsonKey("RenewedLicenceCount")}</h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      {yearlyContent && (
                        <span
                          class="counting"
                          data-counterup-nums={yearlyContent.reNewCount}
                        >
                          {parseFloat(
                            yearlyContent.reNewCount
                          ).toLocaleString()}
                        </span>
                      )}
                    </div>
                    <div class="counter-name">
                      <h6> {new Date().getFullYear()}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="feature-panel-holder" data-hover="">
              <div class="feature-panel feature-panel-2">
                <div class="feature-icon">
                  <span class="uk-icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      width="68"
                      height="68"
                      x="0px"
                      y="0px"
                      viewBox="0 0 489.4 489.4"
                    >
                      <g>
                        <g>
                          <path
                            d="M347.7,263.75h-66.5c-18.2,0-33,14.8-33,33v51c0,18.2,14.8,33,33,33h66.5c18.2,0,33-14.8,33-33v-51
			C380.7,278.55,365.9,263.75,347.7,263.75z M356.7,347.75c0,5-4.1,9-9,9h-66.5c-5,0-9-4.1-9-9v-51c0-5,4.1-9,9-9h66.5
			c5,0,9,4.1,9,9V347.75z"
                          ></path>
                          <path
                            d="M489.4,171.05c0-2.1-0.5-4.1-1.6-5.9l-72.8-128c-2.1-3.7-6.1-6.1-10.4-6.1H84.7c-4.3,0-8.3,2.3-10.4,6.1l-72.7,128
			c-1,1.8-1.6,3.8-1.6,5.9c0,28.7,17.3,53.3,42,64.2v211.1c0,6.6,5.4,12,12,12h66.3c0.1,0,0.2,0,0.3,0h93c0.1,0,0.2,0,0.3,0h221.4
			c6.6,0,12-5.4,12-12v-209.6c0-0.5,0-0.9-0.1-1.3C472,224.55,489.4,199.85,489.4,171.05z M91.7,55.15h305.9l56.9,100.1H34.9
			L91.7,55.15z M348.3,179.15c-3.8,21.6-22.7,38-45.4,38c-22.7,0-41.6-16.4-45.4-38H348.3z M232,179.15c-3.8,21.6-22.7,38-45.4,38
			s-41.6-16.4-45.5-38H232z M24.8,179.15h90.9c-3.8,21.6-22.8,38-45.5,38C47.5,217.25,28.6,200.75,24.8,179.15z M201.6,434.35h-69
			v-129.5c0-9.4,7.6-17.1,17.1-17.1h34.9c9.4,0,17.1,7.6,17.1,17.1v129.5H201.6z M423.3,434.35H225.6v-129.5
			c0-22.6-18.4-41.1-41.1-41.1h-34.9c-22.6,0-41.1,18.4-41.1,41.1v129.6H66v-193.3c1.4,0.1,2.8,0.1,4.2,0.1
			c24.2,0,45.6-12.3,58.2-31c12.6,18.7,34,31,58.2,31s45.5-12.3,58.2-31c12.6,18.7,34,31,58.1,31c24.2,0,45.5-12.3,58.1-31
			c12.6,18.7,34,31,58.2,31c1.4,0,2.7-0.1,4.1-0.1L423.3,434.35L423.3,434.35z M419.2,217.25c-22.7,0-41.6-16.4-45.4-38h90.9
			C460.8,200.75,441.9,217.25,419.2,217.25z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>
                <div class="feature-content">
                  <h4>{localizer.getJsonKey("NewLicenceCount")}</h4>
                  <div class="counter counter-6">
                    <div class="counter-num">
                      {" "}
                      {yearlyContent && (
                        <span
                          class="counting"
                          data-counterup-nums={yearlyContent.newCount}
                        >
                          {parseFloat(yearlyContent.newCount).toLocaleString()}
                        </span>
                      )}
                    </div>
                    <div class="counter-name">
                      <h6> {new Date().getFullYear()} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="heading heading-1" style={{ direction: "ltr" }}>
          <p class="heading-subtitle heading-subtitle-bg">
            {localizer.getJsonKey("MonthsChart")} {new Date().getFullYear()}{" "}
            {localizer.getJsonKey("TillNow")}
          </p>
          {monthlyContent.length == 0 ? (
            <DetailsLoader></DetailsLoader>
          ) : (
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={monthlyContent}
              options={options}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Trading;
