import React, { useState, useContext, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";

function LocalPartnersList() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/partners/GetLocalPartners",
        { ReferenceID: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.output.PartnersEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }

  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("LocalPartners")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"> {localizer.getJsonKey("LocalPartners")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row"  id="localPartnersListDiv">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("LocalPartners")}
                    </h5>
                    {listContent.length == 0 ? (
                      <DetailsLoader></DetailsLoader>
                    ) : (
                      listContent.map((e, i) => (
                        <div class="contact-panel no-padd">
                          <div class="row">
                            <div class="col-12">
                              <div class="contact-card">
                                <div class="contact-body">
                                  <div class="row">
                                    <div class="col-12 col-md-3">
                                      <img src={e.iconUrl} alt={localizer.getKey(e.titleAr,e.titleEn)} title={localizer.getKey(e.titleAr,e.titleEn)} longdesc="#localPartnersListDiv"/>
                                    </div>

                                    <div class="col-12 col-md-9">
                                      <p>
                                        <strong>
                                          {localizer.getKey(
                                            e.titleAr,
                                            e.titleEn
                                          )}
                                        </strong>
                                      </p>
                                      <p class="entry-desc">
                                        {localizer.getKey(
                                          e.contentAr,
                                          e.contentEn
                                        )}
                                      </p>

                                      <ul class="list-unstyled advantages-list advantages-list-3">
                                        <li>
                                          {localizer.getJsonKey(
                                            "PartnerShipField"
                                          )}
                                          :{" "}
                                          {localizer.getKey(
                                            e.addressAr,
                                            e.addressEn
                                          )}
                                        </li>
                                        <li>
                                          {localizer.getJsonKey("ContractDate")}
                                          :{" "}
                                          <Moment format="DD/MM/YYYY">
                                            {e.contractDate}
                                          </Moment>
                                        </li>
                                        <li>
                                          {localizer.getJsonKey(
                                            "PartnerDepartment"
                                          )}
                                          :{" "}
                                          {localizer.getKey(
                                            e.rmdepartmentNameAr,
                                            e.rmdepartmentNameEn
                                          )}
                                        </li>
                                        <li>
                                          {localizer.getJsonKey(
                                            "ContractStatus"
                                          )}
                                          :{" "}
                                          {e.contractActive
                                            ? localizer.getJsonKey("Active")
                                            : localizer.getJsonKey("InActive")}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default LocalPartnersList;
