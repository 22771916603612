import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, Link, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import _ from "lodash";
import {
    DetailsLoader,
    RepeatableTableRows, 
    ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import { ToastContainer, toast } from 'react-toastify';
import RequestDetails from './RequestDetails'

function VerificationRequest() {
    const context = useContext(SessionContext);

    const localizer = useLocalizer();
    const [number, setNumber] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [listContent, setListContent] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const FormSchema = Yup.object().shape({
        mobileNo: Yup.string().matches(/^([0-9]{10})$/, localizer.getJsonKey("MaxMobileNo")).required(localizer.getJsonKey("PleaseInsertMobNum")),
    });
    const FormSchema2 = Yup.object().shape({

        codeNumber: Yup.string().matches(/^([0-9]{4})$/, localizer.getJsonKey("MaxNumber")).required(localizer.getJsonKey("CodeNumber")),
    });

   
    const submitForm = async function (values) {

        setNumber(values.mobileNo)
        debugger;
        await httpService
            .post(
                apiUrl + "/IntraVolunteer/otp",
                {
                    Mobile: values.mobileNo,

                    ReferenceID: PortalID,
                    ServiceName: "Volunteering"  
                },
                { tokenVal: context.tokenVal }

            )
            .then((res) => {
                debugger
                if (res.data.data == true) {
                    setIsSubmited(true)
                    debugger
                    Helpers.notify(res.data.resultMessage);
                } else {
                    Helpers.notify(res.data.resultMessage, true);
                }

            }).catch((error) => {
                debugger
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            })

    }; const submitNewOTP = async function (number) {

/*        setNumber(values.mobileNo)*/
        debugger;
        await httpService
            .post(
                apiUrl + "/IntraVolunteer/otp",
                {
                    Mobile: number,

                    ReferenceID: PortalID,
                    ServiceName: "Volunteering"  
                },
                { tokenVal: context.tokenVal }

            )
            .then((res) => {
                debugger
                if (res.data.data == true) {
                    setIsSubmited(true)
                    debugger
                    Helpers.notify(res.data.resultMessage);
                } else {
                    Helpers.notify(res.data.resultMessage, true);
                }

            }).catch((error) => {
                debugger
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            })

    };

    const submitCodeNO = async function (values) {
        debugger;
        //setOtpCount(otpCount+1)
        //if (otpCount < 5) {
            await httpService
                .post(
                    apiUrl + "/IntraVolunteer/check-otp",
                    {
                        Mobile: number,
                        OtpCode: values.codeNumber,
                        ServiceName: "Volunteering"  
                        /*  ReferenceID: PortalID,*/

                    }
                    /*           , { OTP: values.codeNumber, }*/
                )
                .then((res) => {
                    debugger

                    /*setIsSubmited(true) isDone*/
                    if (res.data.data != false) {
                        Helpers.notify(localizer.getJsonKey("CheckOTP"));
                        getListContent();
                        setIsVerified(true)

                    } else {
                        Helpers.notify(res.data.resultMessage, true);
                    /*    setIsSubmited(false)*/
                    }
                });
   /* } */
    //else {
    //        setIsSubmited(false)
    //        Helpers.notify("تم تجاوز المحاولات يرجي طلب كود تفعيل جديد", true);
         
    //    }
    };
    async function getListContent() {
        debugger;
        const result = await httpService
            .post(
                apiUrl +  "/IntraVolunteer/get-volunteer-list",
                {
                    Mobile: number,
            /*        ReferenceID: PortalID,*/
                    ServiceName: "Volunteering"  
                },
                /*   { tokenVal: context.tokenVal }*/
             ).then((res) => {
                debugger
                if (res.data.data != null) {
                    setListContent(res.data.data);
                    debugger
                } 

            }).catch((error) => {
                debugger
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);

            })

        debugger
    }

    return (
        <React.Fragment>
            {!isVerified ?
                <div>
            <section className="page-title page-title-6 center" id="page-title">
                <div
                    className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                    style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="title">
                                    <h1 className="title-heading">
                                        {localizer.getJsonKey("ConfirmationRequests")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap rtl">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item">
                                <a href={localizer.getLang() + "/"}> {localizer.getJsonKey("Home")}</a>
                            </li>

                            <li className="breadcrumb-item active" aria-current="page">
                                {localizer.getJsonKey("ConfirmationRequests")}
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section class="contact contact-4 rtl" id="contact-4">
                <div class="container">
                            <div class="contact-panel contact-panel-3 form-card" style={{ flexDirection: "column" }}>
                                <div class="heading heading-6" style={{ textAlign: "center" }}>
                            <h2 class="heading-title">
                                {" "}
                                        { localizer.getJsonKey("ConfirmationRequests")}
                            </h2>
                        </div>
                        <div class="contact-card">
                            <div class="contact-body">
                                <h5 class="card-heading">
                                    {!isSubmited ?localizer.getJsonKey("VolunteerData"): localizer.getJsonKey("CodeNumber")}
                                </h5>
                                {!isSubmited ?

                                    <Formik
                                        initialValues={{
                                            mobileNo: ""
,
                                        }}
                                        validationSchema={FormSchema}
                                        //validateOnChange={false}
                                        //validateOnBlur={false}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {

                                            setSubmitting(true);
                                            await submitForm(values, setSubmitting);
                                            setSubmitting(false);
                                            resetForm();
                                        }}
                                    >
                                        {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                            <Form>
                                                <div className="contactForm">
                                                    {isSubmitting ? (
                                                        <ThreeDots></ThreeDots>
                                                    ) : (
                                                        <div className="row">     
                                                            <div className="col-12 col-md-6">
    
                                                                <Field
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="mobileNo"
                                                                    placeholder={localizer.getJsonKey("MobileNum")}
                                                                    title={localizer.getJsonKey("MobileNum")}
                                                                />
                                                                            <label id="" class="label-input errorr">
                                                                    {errors && errors.mobileNo}
                                                                </label>
                                                            </div>
                                                           
                                                            <div className="col-12">
                                                                    <button
                                                                     style={{backgroundColor: '#253745',color: 'white' } }
                                                                    className="btn btn--secondary"
                                                                    type="submit"
                                                                    disabled={!isValid}
                                                                >
                                                                    {localizer.getJsonKey("Send")}{" "}
                                                                    <i className="fas fa-arrow-left"></i>
                                                                </button>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="contact-result"></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    :
                                    <Formik
                                        initialValues={{
                                            codeNumber: ''
                                        }}
                                        validationSchema={FormSchema2}
                                        validateOnChange={false}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {

                                            setSubmitting(true);
                                            await submitCodeNO(values, setSubmitting);
                                            setSubmitting(false);
                                            resetForm();
                                        }}
                                    >
                                        {({ errors, isSubmitting, isValid, setFieldValue }) => (
                                            <Form>
                                                <div className="contactForm">
                                                    {isSubmitting ? (
                                                        <ThreeDots></ThreeDots>
                                                    ) : (
                                                        <div>
                                                            <div className="col-12 col-md-6">
                                                                <Field
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="codeNumber"
                                                                    placeholder={localizer.getJsonKey("CodeNumber")}
                                                                    title={localizer.getJsonKey("CodeNumber")}
                                                                    style={{ display: "inline" }}
                                                                />
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.codeNumber}
                                                                            </label>
                                   
                                                                        </div>
                                                                        <div className=" col-md-6" style={{ cursor: "pointer" }}>
                                                                            <p style={{ cursor: "pointer" }} class="external-desc my-2" 
                                                                                onClick={() => submitNewOTP(number)}>
                                                                                {localizer.getJsonKey("SendAnotherCode")}
                                                                      </p>
                                                                 </div>
                                                                <div className="col-12 ">

                                                                <button
                                                                    style={{ backgroundColor: '#253745', color: 'white' }}
                                                                    className="btn btn--secondary"
                                                                    type="submit"
                                                                    disabled={!isValid}
                                                                >
                                                                    {localizer.getJsonKey("Send")}{" "}
                                                                    <i className="fas fa-arrow-left"></i>
                                                                    </button>
                                                                      
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="contact-result"></div>
                                                            </div>
                                                        </div>


                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                    </section>
                </div>
                :
               <RequestDetails listContent={listContent} ></RequestDetails>
                }
        </React.Fragment>
    );
}
export default VerificationRequest;
