import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";
import axios from "axios";
import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode ,newOpenDataGateWay} from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function DataSetUserManuals() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const [mainSelectedOption, setMainSelectedOption] = useState();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent(0);
    }
  }, [context.tokenVal]);

  async function getListContent() {
    const result = await httpService
      .post(
        apiUrl +newOpenDataGateWay+ "/ManualAndPolices/GetAll",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.data.items.filter((x) => x.type == 1));
        context.setEntityID(-1);
      });
  }

  async function downloadAttachment(event, item) {
    axios(apiUrl +newOpenDataGateWay+ "/Attachment/download/" + item.id + "/" + item.name, {
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      Authorization: "bearer " + context.tokenVal,
    }).then((res) => {
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("target", "_blank");
      link.setAttribute("href", URL.createObjectURL(file));
      link.setAttribute("download", "download");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
  async function UpdateDownloadTimes(event, item) {
    const result = await httpService
      .post(
        apiUrl + newOpenDataGateWay+"/Attachment/UpdateDownloadTimes",
        {
          id: item.id,
          attachmentTypeId: 3,
          entityID: 1,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
      });
  }
  const SortDescending = () => {
    const strDescending = [...listContent].sort((a, b) =>
      localizer.getKey(a.nameAr, a.nameEn) >
      localizer.getKey(b.nameAr, b.nameEn)
        ? -1
        : 1
    );
    setListContent(strDescending);
  };
  const SortAscending = () => {
    const strAscending = [...listContent].sort((a, b) =>
      localizer.getKey(a.nameAr, a.nameEn) >
      localizer.getKey(b.nameAr, b.nameEn)
        ? 1
        : -1
    );
    setListContent(strAscending);
  };
  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("DataSetUserManuals")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {" "}
                  {localizer.getJsonKey("Home")}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/DataSetLanding"}>
                  {" "}
                  {localizer.getJsonKey("OpenData")}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("DataSetUserManuals")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-8">
                          <h5 class="entry-heading">
                            {localizer.getJsonKey("DataSetUserManuals")}
                          </h5>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-md-8"></div>
                            <div class="col-12 col-md-2">
                              <button
                                class="btn btn--secondary w-100 serch-left-flot"
                                onClick={SortAscending}
                              >
                                {localizer.getJsonKey("SortAscending")}
                                <i
                                  style={{
                                    backgroundColor: "initial",
                                    color: "white",
                                  }}
                                  class="fas fa-arrow-up"
                                ></i>
                              </button>
                            </div>
                            <div class="col-12 col-md-2">
                              <button
                                class="btn btn--secondary w-100 serch-left-flot"
                                onClick={SortDescending}
                              >
                                {localizer.getJsonKey("SortDescending")}
                                <i
                                  style={{
                                    backgroundColor: "initial",
                                    color: "white",
                                  }}
                                  class="fas fa-arrow-down"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {mainSelectedOption == "" || mainSelectedOption == undefined
                      ? listContent.map((x, ind) => (
                          <div key={ind} class="single-product">
                            <div class="external-sub-logo">
                              <div class="features features-3 row-hover">
                                <div class="row">
                                  <div class="col-12 col-md-12 col-lg-12">
                                    <div
                                      class="feature-panel-holder"
                                      data-hover=""
                                    >
                                      <div class="feature-panel feature-panel-2">
                                        <div class="feature-content">
                                          <div class="avatar-icon">
                                            <img
                                              src="/assets/images/dataset/trade.svg"
                                              alt=""
                                            />
                                          </div>
                                          <h4 class="mar-bot0">
                                            {localizer.getKey(
                                              x.nameAr,
                                              x.nameEn
                                            )}
                                          </h4>
                                        </div>
                                        <a>
                                          <span
                                            onClick={(event) => {
                                              downloadAttachment(
                                                event,
                                                x.attachments[0]
                                              );
                                              UpdateDownloadTimes(
                                                event,
                                                x.attachments[0]
                                              );
                                            }}
                                          >
                                            {localizer.getJsonKey("Donwload")}
                                          </span>{" "}
                                          <i class="fas fa-arrow-left"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : listContent.filter(
                          (x) =>
                            x.nameAr == mainSelectedOption ||
                            x.nameEn == mainSelectedOption
                        ).length > 0 &&
                        listContent
                          .filter(
                            (x) =>
                              x.nameAr == mainSelectedOption ||
                              x.nameEn == mainSelectedOption
                          )
                          .map((x, ind) => (
                            <div key={ind} class="single-product">
                              <div class="external-sub-logo">
                                <div class="features features-3 row-hover">
                                  <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12">
                                      <div
                                        class="feature-panel-holder"
                                        data-hover=""
                                      >
                                        <div class="feature-panel feature-panel-2">
                                          <div class="feature-content">
                                            <div class="avatar-icon">
                                              <img
                                                src="/assets/images/dataset/trade.svg"
                                                alt=""
                                              />
                                            </div>
                                            <h4 class="mar-bot0">
                                              {localizer.getKey(
                                                x.nameAr,
                                                x.nameEn
                                              )}
                                            </h4>
                                          </div>
                                          <a>
                                            <span
                                              onClick={(event) => {
                                                downloadAttachment(
                                                  event,
                                                  x.attachments[0]
                                                );
                                                UpdateDownloadTimes(
                                                  event,
                                                  x.attachments[0]
                                                );
                                              }}
                                            >
                                              {localizer.getJsonKey("Donwload")}
                                            </span>{" "}
                                            <i class="fas fa-arrow-left"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                  </div>
                </div>
              </div>
            </div>
            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default DataSetUserManuals;
