import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";

function AlbumsList() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Multimedia/GetImageGalleries",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.output.MultimediaEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("ImageAlbums")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("MediaCenter")}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("ImageAlbums")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section
        class="projects projects-standard projects-standard-1 rtl"
        id="projects-standard-1"
      >
        <div class="container">
          <div class="row">
            <div class="col-12"></div>
          </div>

          <div class="row" id="projects-all">
            {listContent.length == 0 && (
              <RepeatableTableRows></RepeatableTableRows>
            )}
            {listContent.length > 0 &&
              listContent.map((e, i) => (
                <div key={i} class="col-12 col-md-6 col-lg-3 project-item">
                  <div class="project-panel" data-hover="">
                    <div class="project-panel-holder">
                      <div class="project-img">
                        <Link class="link" to={e.id}><div className="d-none">{localizer.getKey(e.nameAr, e.nameEn)} </div></Link>
                        <img src={e.imageUrl} 
                        alt={localizer.getKey(e.nameAr, e.nameEn)} 
                        title={localizer.getKey(e.nameAr, e.nameEn)} 
                        />
                      </div>

                      <div class="project-content">
                        <div class="project-title">
                          <h4>
                            <Link to={e.id}>
                              {localizer.getKey(e.nameAr, e.nameEn)}
                            </Link>
                          </h4>
                        </div>
                        <div class="project-cat d-none">
                          <a href="page-gallery.html">فعاليات</a>
                          <a href="page-gallery.html">الرياض</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            ;
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AlbumsList;
