import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, useSearchParams } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID } from "../../Config/Config.json";
import {
    DetailsLoader,
    RepeatableTableRows, 
} from "../../Components/SkeletonLoader";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";

function RequestDetails(props) {
    const [listContent, setListContent] = useState([]);
    const context = useContext(SessionContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const localizer = useLocalizer();

    useEffect(() => {

        setListContent(props.listContent);

    }, [props.listContent]);


    const showDocument = (id, name) => {
        debugger
        localStorage.setItem('userId', JSON.stringify(id));
        window.open(window.location.origin + `/ar/Volunteers/Certificate/${name}`, "_blank",
            "width=500,height=500");
        //window.open(window.location.origin + `/ar/Volunteers/Certificate/${Helpers.encryptDecrypt(id)}/${name}`, "_blank",
        //    "width=500,height=500");

    }
    return (
        <React.Fragment>
            <div>
                <section class="page-title page-title-6 center" id="page-title">
                    <div
                        class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                        style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                    >
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="title">
                                        <h1 class="title-heading">
                                            {localizer.getJsonKey("RequestsList")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="breadcrumb-wrap rtl">
                        <div class="container">
                            <ol class="breadcrumb d-flex">
                                <li class="breadcrumb-item">
                                    <a href={localizer.getLang() + "/"}>{localizer.getJsonKey("Home")}</a>
                                </li>

                                <li class="breadcrumb-item active" aria-current="page">
                                    <a href="#"> {localizer.getJsonKey("RequestsList")} </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </section>

                <section class="service-single rtl" id="">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-12 order-0">
                                <div class="service-entry">
                                    <div class="entry-content">
                                        <div class="entry-introduction entry-infos">
                                            <div class="row">
                                                <div class="col-6  col-lg-6">
                                                    <h5 class="entry-heading">
                                                        {localizer.getJsonKey("RequestsList")}
                                                    </h5>
                                                </div>
                                            </div>



                                            <div class="single-product">
                                                <div class="external-sub-logo">

                                                    <div class="features features-3 row-hover">
                                                        <div class="row">
                                                            {listContent.length == 0 && (
                                                                <RepeatableTableRows style={{ width: "100%" }} />
                                                            )}
                                                            {listContent.length > 0 &&
                                                                listContent.map((volunteer, i) => (
                                                                    <div
                                                                        key={i}

                                                                    >
                                                                        <div
                                                                            class="feature-panel-holder"
                                                                            style={{ transition: "none" }}

                                                                        >

                                                                            <div class="feature-panel feature-panel-2" style={{ textAlign: "initial", cursor: "auto", transition: "none" }}>
                                                                                <div class="col-lg-6 col-12">
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {/*    <i style={{ color: "#177a73" }} class="fas fa-hand-holding-heart fa-lg mx-1"></i>*/}
                                                                                        {localizer.getJsonKey("Name")} :

                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.name}</p>
                                                                                </div>
                                                                                <div class="col-lg-6 col-12  mt-2">
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("MobileNum")} :
                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.mobileNo}</p>
                                                                                </div>
                                                                                <div class="col-lg-6 col-12 mt-2" >
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("Email")} :

                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.email}</p>

                                                                                </div>
                                                                                <div class="col-lg-6 col-12  mt-2" >
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("Gender")} :

                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.gender?.nameAr}</p>

                                                                                </div>
                                                                                <div class="col-lg-6 col-12 mt-2" >
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("District")} :

                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.district?.nameAr}</p>

                                                                                </div>
                                                                                <div class="col-lg-6 col-12 mt-2" >
                                                                                    <h4 class="mar-bot0" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("Qualification")} :

                                                                                    </h4>
                                                                                    <p class="external-desc mx-2" style={{ display: "inline", margin: "0px" }}>  {volunteer.qualification?.nameAr}</p>

                                                                                </div>
                                                                                <div class=" col-12 mt-2">
                                                                                    <h4 class="mar-bot0 my-2" style={{ display: "inline" }}>
                                                                                        {localizer.getJsonKey("Skills")}
                                                                                    </h4>
                                                                                    {volunteer.volunteerSkills.map(function (object, i) {
                                                                                        return <p class="external-desc mt-1" style={{ margin: "0px", marginBottom: '0px  !important' }}>
                                                                                            {i + 1} - {object.skill.nameAr}
                                                                                        </p>
                                                                                    })}
                                                                                </div>
                                                                                <hr></hr>
                                                                                <div class="col-4 mx-auto my-4">
                                                                                    <button
                                                                                        onClick={() => { showDocument(volunteer.userId, volunteer.name) }}
                                                                                        style={{ height: 50, justifyContent: "center"}}
                                                                                        class="btn btn--secondary mb-2 w-100 mx-auto"
                                                                                    >
                                                                                        {localizer.getJsonKey("DownloadCertificate")}
                                                                                        <i style={{ backgroundColor: "initial", color: "white" }} class="fas fa-arrow-down mx-2"></i>
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        {/*  < Certificate name={volunteer.name} userId={volunteer.userId} printDocument={printDocument} />*/}



                                                                    </div>


                                                                ))}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row d-none">
                                    <div class="col-12 text--center">
                                        <ul class="pagination">
                                            <li>
                                                <a href="#" aria-label={localizer.getJsonKey("Next")}>
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="current" href="">
                                                    1
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">2</a>
                                            </li>
                                            <li>
                                                <a href="">3</a>
                                            </li>
                                            <li>
                                                <a href="">4</a>
                                            </li>

                                            <li>
                                                <a href="#" aria-label={localizer.getJsonKey("Next")}>
                                                    <i class="fas fa-arrow-left"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}

export default RequestDetails;
