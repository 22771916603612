import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID ,newOpenDataGateWay} from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import TotalRequests from "./../EservicesStats/TotalRequests";
import Building from "./../EservicesStats/Building";
import Trading from "./../EservicesStats/Trading";
import Medical from "./../EservicesStats/Medical";
import SWP from "./../EservicesStats/SWP";
import useLocalizer from "../../Localization/useLocalizer";

function DataSetSuccessStories(props) {
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState([]);
  const [listContent, setListContent] = useState([]);
  const localizer = useLocalizer();
  useEffect(() => {
    async function getListContent() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/SuccessStories/GetAll",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setListContent(res.data.data.items);
        });
    }
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);

  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("OpenData")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {" "}
                  {localizer.getJsonKey("Home")}
                </a>
              </li>

              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/DataSetLanding"}>
                  {" "}
                  {localizer.getJsonKey("OpenData")}
                </a>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("DataSetSuccessStories")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <div className="row">
        {listContent.map((x, ind) => (
          <div key={ind} className="col-4 rtl">
            <section className="card-section rtl">
              <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/SuccessStories.css"
              />
              <div className="cardLand">
                <div className="flip-card">
                  <div className="flip-card__container">
                    <div className="card-front">
                      <div className="card-front__tp card-front__tp--city">
                        <svg className="card-front__icon">
                          <g>
                            <path d="M49.7,22c-1.1,0-2,0.9-2,2v32.2c0,1.1,0.9,2,2,2s2-0.9,2-2V24C51.7,22.9,50.8,22,49.7,22z" />
                            <path
                              d="M13,29.5c1.1,0,2-0.9,2-2s-0.9-2-2-2H5.7v-3h20.8c1.1,0,2-0.9,2-2s-0.9-2-2-2H5.7v-3h20.8c1.1,0,2-0.9,2-2s-0.9-2-2-2H5.7
                              V7.1h24.8v15.3c0,1.1,0.9,2,2,2s2-0.9,2-2V5.1c0-1.1-0.9-2-2-2H3.7c-1.1,0-2,0.9-2,2v51c0,1.1,0.9,2,2,2s2-0.9,2-2V36.5H13
                              c1.1,0,2-0.9,2-2s-0.9-2-2-2H5.7v-3H13z"
                              />
                            <path
                              d="M58,11.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0
                                c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.2-0.1
                                c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
                                c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1l-12.6,7.8
                                c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
                                c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0v5.4H22.1
                                c-1.1,0-2,0.9-2,2v28.9c0,1.1,0.9,2,2,2s2-0.9,2-2V29.3h17.3v26.9c0,1.1,0.9,2,2,2s2-0.9,2-2V21l8.6-5.3v40.5c0,1.1,0.9,2,2,2
                                s2-0.9,2-2V12.1C58,12,58,12,58,11.9z"
                                />
                            <path d="M28,31L28,31c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S29.1,31,28,31z" />
                            <path d="M33.5,31L33.5,31c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2S34.6,31,33.5,31z" />
                            <path d="M28,36L28,36c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S29.1,36,28,36z" />
                            <path d="M33.5,36L33.5,36c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2S34.6,36,33.5,36z" />
                          </g>
                        </svg>
                        <h2 className="card-front__heading">{localizer.getKey(x.projectNameAr,x.projectNameEn)}</h2>
                      </div>
                      <div className="card-front__bt">
                        <p className="card-front__text-view card-front__text-view--city">
                        {localizer.getJsonKey("Details")}
                        </p>
                      </div>
                    </div>
                    <div className="card-back">
                      {/* <img src="https://ibb.co/gZq7Dv9" /> */}
                      {/* <video class="video__container" autoplay muted loop>
                        <source
                          class="video__media"
                          src="https://player.vimeo.com/external/332588783.sd.mp4?s=cab1817146dd72daa6346a1583cc1ec4d9e677c7&profile_id=139&oauth2_token_id=57447761"
                          type="video/mp4"
                        />
                      </video> */}
                       <img class="video__container" src={x.imageBase64} />
                    </div>
                  </div>
                </div>

                <div className="inside-page">
                  <div className="inside-page__container">
                    <h3 className="inside-page__heading inside-page__heading--city">
                    {localizer.getKey(x.projectNameAr,x.projectNameEn)}
                    </h3>
                    <p className="inside-page__text">
                    {ReactHtmlParser(localizer.getKey(x.projectSummaryAr,x.projectSummaryEn))}
                    </p>
                    <a
                      href={localizer.getLang()+'/DataSetSuccessStories/'+x.id}
                      className="inside-page__btn inside-page__btn--city"
                    >
                     {localizer.getJsonKey("Details")}
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default DataSetSuccessStories;
