import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { useFormik, Field, FormikProvider } from "formik";
import { Formik, Form, useFormikContext } from "formik";

import _ from "lodash";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
import Static from "./Static";
import * as Yup from "yup";

function Questions() {
  const location = useLocation();
  const context = useContext(SessionContext);
  let [cats, setCats] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [pageContent, setpageContent] = useState();
  const [questionContent, setQuestionContent] = useState([]);

  // const [QpageContent, setQPageContent] = useState([]);
  const localizer = useLocalizer();
  let queryID = useParams();
  let params = useParams();
  const [catItem, setCatItem] = useState("");

  // useEffect(() => {
  //   setpageContent(Static);
  //   fillContent();
  // }, []);
  const FormSchema = Yup.object().shape({
    cats: Yup.string().required(localizer.getJsonKey("RM")),
  });
  const submitForm = async function (values) {
  };
    useEffect(() => {
      // setpageContent(Static);
    if (
      context.tokenVal != undefined 
      // &&
      // context.entityID != undefined &&
      // context.entityID != -1
    ) {
     // console.log("token effect " + context.tokenVal);
      getContent();
      // fillContent();
    }
  }, [context.entityID, location, searchParams]);

  const getContent = async function () {
    // if (context.entityID != -1) {
      await httpService
        .post(
          apiUrl + "/FAQs_Categories/GetFAQCatsList",
          {
            ReferenceID:PortalID,
            EntityID: "",//context.entityID,
            // ItemId: queryID != undefined ? queryID.did : "",
            Name: "",
            CreatedBy:"",
            FAQs:[]
          },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
         // console.log("FAQ",res);
          setpageContent(res.data.output.ContactUsEntity);
          setCatItem(res.data.output.ContactUsEntity[0].id)
          setQuestionContent(res.data.output.ContactUsEntity[0].faQs);
        //  console.log("pageContent",pageContent);
        });
    // }
  };
  
  function fillContent()
  {
    // let temp = pageContent;
    // const list = temp.listOfQuestions.filter((x=> x.lang === localizer.getLang()));
   // console.log(pageContent);
    setpageContent(pageContent);
  }

  const handleClick = (e) => {
    if(e=="0"){
      setQuestionContent([]);
    }
    else{
  //  console.log(catItem);
    let temp = pageContent;
    const list = temp.map((item) => {
      if (item.id == e) {
        item.show = !item.show;
      } else {
        item.show = false;
      }
      return item;
    });
    // const isExist = (value) => value._id==;
  //  console.log("list",list);
    let questions=list.filter(x=>x.id==e);
    setQuestionContent(questions[0].faQs);
  //  console.log("setQuestionContent",questionContent);
    // let item = {};
    // item.listOfQuestions = list;
    // setpageContent(item);
  }};

  const handleQuestionClick = (ID) => {
    //console.log(questionContent);
    let temp = questionContent;

    const list = temp.map((item) => {
      if (item.id === ID) {
        item.show = !item.show;
      } else {
        item.show = false;
      }
      return item;
    });

    let item = {};
    item = list;
    setQuestionContent(item);
  };
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{
            backgroundImage: "url('/assets/images/page-titles/6.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("commonQuestions")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href="">{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("RM")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("commonQuestions")}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading heading-18 text-center">
                <p className="heading-subtitle">
                  {localizer.getJsonKey("frequentlyQuestions")}
                </p>
                <h2 className="heading-title">
                  {localizer.getJsonKey("commonQuestions")}
                </h2>
                <label>{localizer.getJsonKey("commonQuestionsNote")}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <Formik
                  initialValues={{
                    cats:pageContent&& pageContent[0]._id,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await submitForm(values, setSubmitting);
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({ errors, isSubmitting, isValid, setFieldValue }) => (
                    <Form>
                      <div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <Field
                                className="form-control"
                                component="select"
                                name="cats"
                                placeholder=""
                                // onChange={() => handleClick()}
                                onChange={(e) =>{
                                  setCatItem(e.target.value);
                                  handleClick(e.target.value)
                                 }
                                }
                                value={catItem}>
                                {/* <option value="0">
                                  {localizer.getJsonKey("FAQsCatsSelect")}{" "}
                                </option> */}
                                {!_.isEmpty(pageContent) &&
                                  pageContent.map((e, i) => (
                                    <option key={i} value={e.id} 
                                    selected={e._id === pageContent[0]._id?true:false}>
                                      {localizer.getKey(e.nameAr, e.nameEn)}
                                    </option>
                                  ))}
                              </Field>
                            </div>
                          </div>
                      </div>
                    </Form>
                  )}
                </Formik>
            </div>
          </div>
          <div class="accordion accordion-2" id="accordion03">
            <div class="row">
              {pageContent &&
                questionContent.map((e, i) => (
                  <div key={i} class="col-12 col-lg-6">
                    <div class="card">
                      <div class="card-heading">
                        <a
                          className="card-link collapsed "
                          data-hover=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls={"child" + i}
                          href={"#child" + i}
                          onClick={() => handleQuestionClick(e.id)}
                          style={{textAlign:"left"}}
                        >
                          {localizer.getKey(e.questionTextAr, e.questionTextEn)}
                        </a>
                      </div>
                      {e.show ? <div class="card-body">
                        <p class="text-align-lang">
                          {/* {localizer.getLang()} */}
                        {localizer.getKey(e.answerTextAr, e.answerTextEn)}
                        </p>
                        </div> : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Questions;
