import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import ReCAPTCHA from "react-google-recaptcha";
import useLocalizer from "../Localization/useLocalizer";

import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Helpers from "../Helpers/Helpers";

export default function Form940() {
  const context = useContext(SessionContext);
  const [categoryContent, setCategoryContent] = useState([]);
  const [subCategoryContent, setSubCategoryContent] = useState([]);
  const [inquiryContent, setInquiryContent] = useState();
  const localizer = useLocalizer();
  const FormSchema = Yup.object().shape({
    name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    description: Yup.string().required(localizer.getJsonKey("PleaseInsertDetails")),
    address: Yup.string().required(localizer.getJsonKey("PleaseInsertTitle")),
    category: Yup.string().required(localizer.getJsonKey("PleaseInsertCategory")),
    subCategory: Yup.string().required(localizer.getJsonKey("PleaseInsertSubCategory")),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail")),
    recaptcha: Yup.string().required(
      localizer.getJsonKey("PleaseInsertCapcha")
    ),
  });
  const InquiryFormSchema = Yup.object().shape({
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    complaintID: Yup.string().required(localizer.getJsonKey("PleaseInsertComplaintNumber")),
    recaptcha: Yup.string().required(
      localizer.getJsonKey("PleaseInsertCapcha")
    ),
  });
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  const getContent = async function (selected) {
    const result = await httpService.post(
      apiUrl + "/ContactUs/Get940Categories",
      {
        ReferenceID: PortalID,
        ID: selected != undefined ? selected : null,
      },
      { tokenVal: context.tokenVal }
    );
   // console.log(result);
    selected == undefined
      ? setCategoryContent(result.data.output.CategoryEntity)
      : setSubCategoryContent(result.data.output.CategoryEntity);
    // return result.data.output.CategoryEntity;
  };

  const submitForm = async function (values) {
    const result = await httpService
      .post(
        apiUrl + "/ContactUs/SaveContact940",
        {
          ReferenceID: PortalID,
          Name: values.name,
          MobileNo: values.mobileNo,
          CategoryID: values.category,
          SubCategoryID: values.subCategory,
          Description: values.description,
          Address: values.address,
          Email: values.email,
          Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("ComplaintSentSuccefully"));
      });
  };
  const submitInquiryForm = async function (values) {
    const result = await httpService.post(
      apiUrl + "/ContactUs/Get940ComplainDetails",
      {
        ReferenceID: PortalID,
        MobileNo: values.mobileNo,
        ComplainId: values.complaintID,
        Capcha: values.recaptcha,
      },
      { tokenVal: context.tokenVal }
    );

    result != null
      ? setInquiryContent(result.data.output.ContactUsEntity)
      : setInquiryContent();
  };
  return (
    <div
      className="contact-body tab-pane active"
      id="emergency"
      role="tabpanel"
    >
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("940Title")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("940Title")}</h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  <p>{localizer.getJsonKey("940ContactUsDesc")}</p>
                  <div className="rate-des" >
                  <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                      
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className="single-product">
        <div className="product-tabs">
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation">
              <a
                className="active"
                href="#description"
                data-bs-target="#description"
                aria-controls="description"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
              >
                {localizer.getJsonKey("sendReport")}
              </a>
            </li>
            <li role="presentation">
              <a
                href="#details"
                data-bs-target="#details"
                aria-controls="details"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
              >
                {localizer.getJsonKey("ReportInquiry")}
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tab-pane active" id="description" role="tabpanel">
              <Formik
                initialValues={{
                  name: "",
                  mobileNo: "",
                  email: "",
                  category: "",
                  subCategory: "",
                  description: "",
                  address: "",
                  recaptcha: "",
                }}
                validationSchema={FormSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  submitForm(values);
                  resetForm();
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={localizer.getJsonKey("Name")}
                            title={localizer.getJsonKey("Name")}
                            required=""
                            spellcheck="true"
                          />
                          <label id="" class=" errorr">
                            {errors && errors.name}
                          </label>
                        </div>

                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="mobileNo"
                            placeholder={localizer.getJsonKey("MobileNum")}
                            title={localizer.getJsonKey("MobileNum")}
                            required=""
                            spellcheck="true"
                          />
                          <label id="" class=" errorr">
                            {errors && errors.mobileNo}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="email"
                            placeholder={localizer.getJsonKey("Email")}
                            title={localizer.getJsonKey("Email")}
                            required=""
                            spellcheck="true"
                          />
                          <label id="" class=" errorr">
                            {errors && errors.email}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            component="select"
                            name="category"
                            onChange={(e) => {
                              handleChange(e);
                              getContent(e.target.value);
                            }}
                            placeholder={localizer.getJsonKey("ChooseCategory")}
                            title={localizer.getJsonKey("ChooseCategory")}
                            required=""
                          >
                            {subCategoryContent.length == 0 && (
                              <option value="0">{localizer.getJsonKey("ChooseCategory")}</option>
                            )}
                            {categoryContent.length > 0 &&
                              categoryContent.map((e, i) => (
                                <option key={i} value={e.id}>
                                  {e.descAr}
                                </option>
                              ))}
                          </Field>
                          <label id="" class=" errorr">
                            {errors && errors.category}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            component="select"
                            name="subCategory"
                            placeholder={localizer.getJsonKey("SubChooseCategory")}
                            title={localizer.getJsonKey("SubChooseCategory")}
                            required=""
                          >
                            {subCategoryContent.length == 0 && (
                              <option value="0" disabled>
                               {localizer.getJsonKey("SubChooseCategory")}
                              </option>
                            )}

                            {subCategoryContent.length > 0 &&
                              subCategoryContent.map((e, i) => (
                                <option key={i} value={e.id}>
                                  {e.descAr}
                                </option>
                              ))}
                          </Field>
                          <label id="" class=" errorr">
                            {errors && errors.subCategory}
                          </label>
                        </div>

                        <div className="col-12">
                          <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("DescriptionLimit")}
                            name="address"
                            cols="30"
                            rows="10"
                            spellcheck="true"
                          />
                          <label id="" class=" errorr">
                            {errors && errors.address}
                          </label>
                        </div>
                        <div className="col-12">
                          <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("DetailsLimit")}
                            title={localizer.getJsonKey("DetailsLimit")}
                            name="description"
                            cols="30"
                            rows="10"
                            spellcheck="true"
                          ></Field>
                          <label id="" class=" errorr">
                            {errors && errors.description}
                          </label>
                        </div>
                        <div
                          className="col-12"
                          style={{ marginBottom: "10px" }}
                        >
                          <ReCAPTCHA
                            sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                            onChange={(response) => {
                             // console.log(response);
                              setFieldValue("recaptcha", response);
                            }}
                          ></ReCAPTCHA>
                          <label id="" class=" col-12 errorr">
                            {errors && errors.recaptcha}
                          </label>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn--secondary"
                            disabled={!isValid}
                          >
                            {localizer.getJsonKey("Send")} <i className="fas fa-arrow-left"></i>
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="contact-result"></div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="tab-pane" id="details" role="tabpanel">
              <Formik
                initialValues={{
                  complaintID: "",
                  mobileNo: "",
                  recaptcha: "",
                }}
                validationSchema={InquiryFormSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  submitInquiryForm(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="complaintID"
                            placeholder={localizer.getJsonKey("InquiryNum")}
                            title={localizer.getJsonKey("InquiryNum")}
                          />
                          <label id="" class=" errorr">
                            {errors && errors.complaintID}
                          </label>
                        </div>

                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="mobileNo"
                            placeholder={localizer.getJsonKey("MobileNum")}
                            title={localizer.getJsonKey("MobileNum")}
                            spellcheck="true"
                          />
                          <label id="" class=" errorr">
                            {errors && errors.mobileNo}
                          </label>
                        </div>
                        <div
                          className="col-12"
                          style={{ marginBottom: "10px" }}
                        >
                          <ReCAPTCHA
                            sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                            onChange={(response) => {
                             // console.log(response);
                              setFieldValue("recaptcha", response);
                            }}
                          ></ReCAPTCHA>
                          <label id="" class="col-12 errorr">
                            {errors && errors.recaptcha}
                          </label>
                        </div>

                        <div className="col-12">
                          <button className="btn btn--secondary">
                          {localizer.getJsonKey("Inquiry")} <i className="fas fa-arrow-left"></i>
                          </button>
                        </div>
                        <div className="col-12">
                          <div
                            className="contact-result"
                            style={{ marginTop: "15px" }}
                          >
                            {inquiryContent != undefined && (
                              <>
                                <h4>{localizer.getJsonKey("InquriyStatus")}</h4>
                                <h5>
                                  {isSubmitting && <ThreeDots></ThreeDots>}
                                  {inquiryContent.problemStatus_Desc}
                                </h5>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
