import React from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import useLocalizer from "../Localization/useLocalizer";


function ComplaintIndex() {
    const localizer = useLocalizer();
    return (
      <React.Fragment>

        <div className="row">

        <div className="col-12 col-lg-6">
            <div className="contact-card tab-content">
            <div className="contact-body tab-pane active" id="opendata" role="tabpanel">
                <div className="service-single p-0 mar-bot50 contact-header">
                <div className="srt-srt">
                    <Link to={"OpenDataRequest"}>
                    <div className="entry-widget-bio">
                        <div className="entry-widget-content">
                        <div className="entry-bio-desc">
                            <div className="head-box">
                            <div className="titleee">
                                <h4>{localizer.getJsonKey("OpenDataRequest")} </h4>
                            </div>
                            
                            </div>
                            
                            <p>{localizer.getJsonKey("odContactUsDesc")}</p>                  
                            
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
                </div>
            </div>


            </div>
        </div>
        
        <div className="col-12 col-lg-6">
            <div className="contact-card tab-content">

            <div className="contact-body tab-pane active" id="emergency" role="tabpanel">
                <div className="service-single p-0 mar-bot50 contact-header">
                <div className="srt-srt">
                    <Link to={"Form940"}>
                    <div className="entry-widget-bio">
                        <div className="entry-widget-content">
                        <div className="entry-bio-desc">
                            <div className="head-box">
                            <div className="titleee">
                                <h4>{localizer.getJsonKey("940Title")}</h4>
                            </div>
                            
                            </div>
                            <p>{localizer.getJsonKey("940ContactUsDesc")}</p>
                            
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
                </div>
            </div>

            </div>
        </div>
       
        </div>
        <div className="row">
        <div className="col-12 col-lg-6">
            <div className="contact-card tab-content">
            <div className="contact-body tab-pane active" id="president" role="tabpanel">
            <div className="service-single p-0 mar-bot50 contact-header">
                <div className="srt-srt">
                <Link to={"ContactMayor"}>
                    <div className="entry-widget-bio">
                    <div className="entry-widget-content">
                        <div className="entry-bio-desc">
                        <div className="head-box">
                            <div className="titleee">
                            <h4>{localizer.getJsonKey("ContactMayor")}</h4>
                            </div>
                            
                        </div>
                        <p>{localizer.getJsonKey("HappyToServeYou")} </p>
                        
                        </div>
                    </div>
                    </div>
                </Link>
                </div>
            </div>
            </div>
            </div>
        </div>
        <div className="col-12 col-lg-6">
            <div className="contact-card tab-content">
            <div className="contact-body tab-pane active" id="president" role="tabpanel">
                <div className="service-single p-0 mar-bot50 contact-header">
                <div className="srt-srt">
                    <Link to={"Corruption"}>
                    <div className="entry-widget-bio">
                        <div className="entry-widget-content">
                        <div className="entry-bio-desc">
                            <div className="head-box">
                            <div className="titleee">
                                <h4>{localizer.getJsonKey("Corruption")}</h4>
                            </div>
                            </div>
                            <p>
                            {localizer.getJsonKey("HappyToServeYou")}
                            </p>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        </div>
        <div className="row">
        <div className="col-12 col-lg-6">
            <div className="contact-card tab-content">

            <div className="contact-body tab-pane active" id="president" role="tabpanel">
                <div className="service-single p-0 mar-bot50 contact-header">
                <div className="srt-srt">
                    <Link to={"Complaint"} >
                    <div className="entry-widget-bio">
                        <div className="entry-widget-content">
                        <div className="entry-bio-desc">
                            <div className="head-box">
                            <div className="titleee">
                                <h4>{localizer.getJsonKey("Complaint")}</h4>
                            </div>
                            </div>
                            <p>
                            {localizer.getJsonKey("ComplaintDesc")}
                            </p>
                            
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
           
  
      </React.Fragment>
    );
  }
  
  export default ComplaintIndex;
  