import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { RepeatableTableRows, ThreeDots } from "./SkeletonLoader";
import SessionContext from "../Context/SessionContext";

const GridList = ({ itemsLength, totalCount, nextFun, children }) => {
  const [currPage, setcurrPage] = useState(1);
  const context = useContext(SessionContext);

  useEffect(() => {
    if (context.tokenVal != undefined) {
      nextFun(currPage);
    }
  }, [context.tokenVal, currPage]);
  return (
    <React.Fragment>
      {itemsLength == 0 ? (
        <RepeatableTableRows></RepeatableTableRows>
      ) : (
        <InfiniteScroll
          className="row"
          dataLength={itemsLength}
          next={() => {
            setcurrPage(currPage + 1);
            //nextFun(currPage);
          }}
          loader={<ThreeDots></ThreeDots>}
          hasMore={itemsLength >= totalCount ? false : true}
        >
          {children}
        </InfiniteScroll>
      )}
    </React.Fragment>
  );
};

export default GridList;
