import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";

function SiteMapList(props) {
  var params = useParams();
  const localizer = useLocalizer();
  if (props.menuProp == undefined) {
    return <RepeatableTableRows></RepeatableTableRows>;
  }
  return (
    <React.Fragment>
      {props.menuProp != undefined &&
        props.menuProp.map(
          (x) =>
            (
              <>
              {
                localizer.getLang() == "/ar" ?
                (
                  props.source== "child"?
                      
                  (
                   <li key={x.id} className="col-form-label d-block  mt-2 mb-2 my-2 nav-item" style={{backgroundColor: "#08a76c", marginRight: "5%" }} >
                   { x.url.indexOf("http") == -1 ? (
                     <Link className="p-3" style={{color: "white"}}
                       to={
                         `/${params.lid}` +
                         x.url +
                         (x.articleId != null ? "/" + x.articleId : "")
                       }
                       target={x.openBlankPage ? "_blank" : ""} 
                     >
                       <span>{ localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </Link>
                   ) : ( 
                     <a className="p-3" style={{color: "white"}} href={x.url}   target={x.openBlankPage ? "_blank" : ""}>
                       <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </a>
                   )}
                 </li> 
                  )
                  :
                  (
                   <li key={x.id} className="col-form-label d-block  mt-2 mb-2 my-2 nav-item" style={{backgroundColor: "#08a76c"}} >
                   { x.url.indexOf("http") == -1 ? (
                     <Link className="p-3" style={{color: "white"}}
                       to={
                         `/${params.lid}` +
                         x.url +
                         (x.articleId != null ? "/" + x.articleId : "")
                       }
                       target={x.openBlankPage ? "_blank" : ""} 
                     >
                       <span>{ localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </Link>
                   ) : ( 
                     <a className="p-3" style={{color: "white"}} href={x.url}   target={x.openBlankPage ? "_blank" : ""}>
                       <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </a>
                   )}
                 </li> 
                  )
                ):
                (
                  props.source== "child"?
                      
                  (
                   <li key={x.id} className="col-form-label d-block  mt-2 mb-2 my-2 nav-item" style={{backgroundColor: "#08a76c", marginLeft: "5%" }} >
                   { x.url.indexOf("http") == -1 ? (
                     <Link className="p-3" style={{color: "white"}}
                       to={
                         `/${params.lid}` +
                         x.url +
                         (x.articleId != null ? "/" + x.articleId : "")
                       }
                       target={x.openBlankPage ? "_blank" : ""} 
                     >
                       <span>{ localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </Link>
                   ) : ( 
                     <a className="p-3" style={{color: "white"}} href={x.url}   target={x.openBlankPage ? "_blank" : ""}>
                       <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </a>
                   )}
                 </li> 
                  )
                  :
                  (
                   <li key={x.id} className="col-form-label d-block  mt-2 mb-2 my-2 nav-item" style={{backgroundColor: "#08a76c"}} >
                   { x.url.indexOf("http") == -1 ? (
                     <Link className="p-3" style={{color: "white"}}
                       to={
                         `/${params.lid}` +
                         x.url +
                         (x.articleId != null ? "/" + x.articleId : "")
                       }
                       target={x.openBlankPage ? "_blank" : ""} 
                     >
                       <span>{ localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </Link>
                   ) : ( 
                     <a className="p-3" style={{color: "white"}} href={x.url}   target={x.openBlankPage ? "_blank" : ""}>
                       <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                     </a>
                   )}
                 </li> 
                  )
                )
               
              }
                

                {x.subMenus != undefined &&
                <>
                <SiteMapList menuProp={x.subMenus} source="child"></SiteMapList>
                </>
              }
              </>
              
            )
        )}
    </React.Fragment>
  );
}

export default SiteMapList;
