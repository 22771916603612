import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import ReCAPTCHA from "react-google-recaptcha";

import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Helpers from "../Helpers/Helpers";
import useLocalizer from "../Localization/useLocalizer";

function ContactMayor() {
  const localizer = useLocalizer();
  const FormSchema = Yup.object().shape({
    name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    userId: Yup.string().required(localizer.getJsonKey("PleaseInsertID")),
    description: Yup.string().required(
      localizer.getJsonKey("PleaseInsertDetails")
    ),

    subject: Yup.string().required(localizer.getJsonKey("PleaseInsertTitle")),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail")),
    recaptcha: Yup.string()
      .required(localizer.getJsonKey("PleaseInsertCapcha"))
      .nullable(),
  });
  const InquiryFormSchema = Yup.object().shape({
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    complaintID: Yup.string().required(
      localizer.getJsonKey("PleaseInsertInquiryNum")
    ),
    recaptcha: Yup.string()
      .required(localizer.getJsonKey("PleaseInsertCapcha"))
      .nullable(),
  });
  const context = useContext(SessionContext);
  const [inquiryContent, setInquiryContent] = useState();

  const submitForm = async function (values) {
    const result = await httpService
      .post(
        apiUrl + "/ContactUs/SaveContactMayor",
        {
          ReferenceID: PortalID,
          Name: values.name,
          MobileNo: values.mobileNo,
          UserId: values.userId,
          Description: values.description,
          Subject: values.subject,
          Email: values.email,
          FileUrl: values.file,
          Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(
          localizer.getJsonKey("ComplaintSentSuccefully") +
            res.data.output.ComplainId
        );
        // notify(res.data.output.ComplainId);
      });
  };
  const submitInquiryForm = async function (values) {
    const result = await httpService.post(
      apiUrl + "/ContactUs/GetMayorComplainDetails",
      {
        ReferenceID: PortalID,
        MobileNo: values.mobileNo,
        ComplainId: values.complaintID,
        Capcha: values.recaptcha,
      },
      { tokenVal: context.tokenVal }
    );

    result != null
      ? setInquiryContent(result.data.output.ContactUsEntity)
      : setInquiryContent();
  };
  return (
    <div
      className="contact-body tab-pane active"
      id="president"
      role="tabpanel"
    >
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("ContactMayor")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("ContactMayor")}</h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                    </div>
                  </div>
                  <p>{localizer.getJsonKey("HappyToServeYou")} </p>
                  <div className="rate-des">
                    <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> وقت توفر الخدمة:
                      الأحد - الخميس - 7.30ص لغاية 3.30م
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i> مدة الاستجابة: 7 ايام
                    </div>

                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="single-product">
        <div className="product-tabs">
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation">
              <a
                className="active"
                href="#description"
                data-bs-target="#description"
                aria-controls="description"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
              >
                {localizer.getJsonKey("SendComplain")}
              </a>
            </li>
            <li role="presentation">
              <a
                href="#details"
                data-bs-target="#details"
                aria-controls="details"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
              >
                {localizer.getJsonKey("ComplainInquiry")}
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tab-pane active" id="description" role="tabpanel">
              <Formik
                initialValues={{
                  name: "",
                  mobileNo: "",
                  email: "",
                  userId: "",
                  description: "",
                  subject: "",
                  file: null,
                  recaptcha: "",
                }}
                validationSchema={FormSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  submitForm(values);
                  resetForm();
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder={localizer.getJsonKey("ComplainTitle")}
                            title={localizer.getJsonKey("ComplainTitle")}
                            spellcheck="true"
                          />
                          <label id="" class="errorr">
                            {errors && errors.subject}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={localizer.getJsonKey("ComplainOwnerName")}
                            title={localizer.getJsonKey("ComplainOwnerName")}
                            spellcheck="true"
                          />
                          <label id="" class="errorr">
                            {errors && errors.name}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="userId"
                            placeholder={localizer.getJsonKey("ComplainOwnerID")}
                            title={localizer.getJsonKey("ComplainOwnerID")}
                          />
                          <label id="" class="errorr">
                            {errors && errors.userId}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder={localizer.getJsonKey("Email")}
                            title={localizer.getJsonKey("Email")}
                            required=""
                          />
                          <label id="" class="errorr">
                            {errors && errors.email}
                          </label>
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="mobileNo"
                            placeholder={localizer.getJsonKey("MobileNum")}
                            title={localizer.getJsonKey("MobileNum")}
                          />
                          <label id="" class="errorr">
                            {errors && errors.mobileNo}
                          </label>
                        </div>

                        <div className="col-12">
                          <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("MsgTextChars250")}
                            title={localizer.getJsonKey("MsgTextChars250")}
                            name="description"
                            cols="30"
                            rows="20"
                            type="textarea"
                            spellcheck="true"
                          />
                          <label id="" class="errorr">
                            {errors && errors.description}
                          </label>
                        </div>
                        <div className="col-12">
                          <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("UploadFile")}
                            title={localizer.getJsonKey("UploadFile")}
                            name="file"
                            type="file"
                            value={null}
                            onChange={(event) => {
                              let reader = new FileReader();
                              reader.onloadend = function () {
                                setFieldValue("file", reader.result);
                              };
                              reader.readAsDataURL(
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          {/* <label id="" class="errorr">
                            {errors && errors.file}
                          </label> */}
                        </div>
                        <div
                          className="col-12"
                          style={{ marginBottom: "10px" }}
                        >
                          <ReCAPTCHA
                            sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                            onChange={(response) => {
                             // console.log(response);
                              setFieldValue("recaptcha", response);
                            }}
                          ></ReCAPTCHA>
                          <label id="" class="col-12 errorr">
                            {errors && errors.recaptcha}
                          </label>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn--secondary"
                            type="submit"
                            disabled={!isValid}
                          >
                            {localizer.getJsonKey("Send")}{" "}
                            <i className="fas fa-arrow-left"></i>
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="contact-result"></div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="tab-pane" id="details" role="tabpanel">
              <Formik
                initialValues={{
                  complaintID: "",
                  mobileNo: "",
                  recaptcha: "",
                }}
                validationSchema={InquiryFormSchema}
                onSubmit={(
                  values,
                  { setSubmitting, resetForm, setFieldValue }
                ) => {
                  setSubmitting(true);
                  submitInquiryForm(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="complaintID"
                            placeholder={localizer.getJsonKey("InquiryNum")}
                            title={localizer.getJsonKey("InquiryNum")}
                            spellcheck="true"
                          />
                          <label id="" class="errorr" for="contact-name">
                            {errors && errors.complaintID}
                          </label>
                        </div>

                        <div className="col-12 col-md-6">
                          <Field
                            className="form-control"
                            type="text"
                            name="mobileNo"
                            placeholder={localizer.getJsonKey("MobileNum")}
                            title={localizer.getJsonKey("MobileNum")}
                          />
                          <label id="" class="errorr" for="contact-name">
                            {errors && errors.mobileNo}
                          </label>
                        </div>
                        <div
                          className="col-12"
                          style={{ marginBottom: "10px" }}
                        >
                          <ReCAPTCHA
                            sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                            onChange={(response) => {
                             // console.log(response);
                              setFieldValue("recaptcha", response);
                            }}
                          ></ReCAPTCHA>
                          <label id="" class="col-12 errorr" for="contact-name">
                            {errors && errors.recaptcha}
                          </label>
                        </div>

                        <div className="col-12">
                          <button className="btn btn--secondary">
                            {localizer.getJsonKey("Inquiry")}{" "}
                            <i className="fas fa-arrow-left"></i>
                          </button>
                        </div>
                        <div className="col-12">
                          <div
                            className="contact-result"
                            style={{ marginTop: "15px" }}
                          >
                            {inquiryContent != undefined && (
                              <>
                                <h4>
                                  {" "}
                                  {localizer.getJsonKey("ComplaintDetails")}
                                </h4>
                                <h5>
                                  {isSubmitting && <ThreeDots></ThreeDots>}
                                </h5>
                              </>
                            )}
                            {inquiryContent != undefined && (
                              <>
                                <div>
                                  <div class="form-group row">
                                    <label
                                      for="IName"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"الاسم"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="IName"
                                        value={inquiryContent.name}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label
                                      for="IEmail"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"البريد الإلكتروني"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="IEmail"
                                        value={inquiryContent.email}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label
                                      for="IUserID"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"رقم الهوية"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="IUserID"
                                        value={inquiryContent.userId}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label
                                      for="IMobileNo"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"رقم الجوال"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="IMobileNo"
                                        value={inquiryContent.mobileNo}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label
                                      for="ISubject"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"عنوان المراسلة"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="ISubject"
                                        value={inquiryContent.subject}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label
                                      for="IDescription"
                                      class="col-sm-2 col-form-label"
                                    >
                                      {"تفاصيل المراسلة"}
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        readonly
                                        class="form-control-plaintext"
                                        id="IDescription"
                                        value={inquiryContent.description}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {inquiryContent.replies.length > 0 ? (
                                  <div class="table-responsive">
                                    <table
                                      class="table table-hover table-bordered"
                                      cellspacing="0"
                                      rules="all"
                                      border="1"
                                    >
                                      <tbody>
                                        <tr class="GridViewTr">
                                          <th scope="col">
                                            {localizer.getJsonKey("Geha")}
                                          </th>
                                          <th scope="col">
                                            {localizer.getJsonKey("Reply")}
                                          </th>
                                          <th scope="col">
                                            {localizer.getJsonKey("Date")}
                                          </th>
                                        </tr>
                                        {inquiryContent.replies.map(
                                          (x, ind) => (
                                            <tr key={ind}>
                                              <td>
                                                {
                                                  inquiryContent.comments[ind]
                                                    .item1
                                                }
                                              </td>
                                              <td>{x.item2}</td>
                                              <td>{x.item3}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  "لا يوجد ردود"
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMayor;
