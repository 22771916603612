import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import useLocalizer from "../../Localization/useLocalizer";

function MenuList(props) {
  var params = useParams();
  const localizer = useLocalizer();
  if (props.menuProp == undefined) {
    return <RepeatableTableRows></RepeatableTableRows>;
  }
  return (
    <React.Fragment>
      {props.menuProp != undefined &&
        props.menuProp[props.menuIndex].subMenus.map(
          (x, ind) =>
            ind >= props.start &&
            ind <= props.end && (
              <li key={ind} className="nav-item">
                {x.url.indexOf("http") == -1 ? (
                  <Link
                    to={
                      `/${params.lid}` +
                      x.url +
                      (x.articleId != null ? "/" + x.articleId : "")
                    }
                    target={x.openBlankPage ? "_blank" : ""}
                  >
                    <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                  </Link>
                ) : (
                  <a href={x.url} target={x.openBlankPage ? "_blank" : ""}>
                    <span>{localizer.getKey(x.nameAr, x.nameEn)}</span>
                  </a>
                )}
              </li>
            )
        )}
    </React.Fragment>
  );
}

export default MenuList;
