import React, { useEffect, useContext, useLayoutEffect } from "react";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
export default function UseJsFiles() {
  const location = useLocation();

  const navigate = useNavigate();
  const context = useContext(SessionContext);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "/jsreg/vendor/jquery-3.6.0.min.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "/jsreg/vendor.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useLayoutEffect(() => {
    //console.log("functions js");
    const script = document.createElement("script");
    script.src = "/jsreg/functions.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "/jsreg/Custom.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "/analytics/analytics.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.appdynamics.com/adrum/adrum-20.12.0.3360.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      {/* <ScriptTag
        isHydrating={false}
        type="text/javascript"
        src="/jsreg/vendor/jquery-3.6.0.min.js"
      />
      <ScriptTag
        isHydrating={false}
        type="text/javascript"
        src="/jsreg/vendor.js"
      />
      <ScriptTag
        isHydrating={false}
        type="text/javascript"
        src="/jsreg/functions.js"
      />
      <ScriptTag
        isHydrating={false}
        type="text/javascript"
        src="/jsreg/Custom.js"
      /> */}
    </React.Fragment>
  );
}
