import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function EServicesDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  //let queryID = searchParams.get("did");
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Govservices/GetEServiceDetails",
          { ReferenceID: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.EservicesEntity);
          context.setEntityID(res.data.output.EservicesEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent != undefined &&
                      localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("EservicesGuide")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section class="service-single rtl" id="service-single">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 order-0 genericContent">
                <div class="service-entry">
                  <div class="entry-content">
                    <div class="entry-introduction entry-infos">
                      <div class="single-product">
                        <div class="col-12">
                          <div class="checkout-banner">
                            <p>
                              {pageContent.nameAr}
                              <span> {pageContent.siteNameAr}</span>
                            </p>

                            <a
                              class="btn btn--primary justify-content-center"
                              href={pageContent.path}
                              target="_blank"
                            >
                              {localizer.getJsonKey("GoToService")}
                            </a>
                          </div>
                        </div>

                        <div class="external-sub-logo">
                          <div class="features features-3 row-hover">
                            <p class="entry-desc">
                              {ReactHtmlParser(pageContent.contentAr)}
                            </p>
                            <p class="entry-desc">
                              <strong>
                                {localizer.getJsonKey("ServiceDetails")}
                              </strong>
                              <br />
                            </p>
                            <div class="serv-details">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td class="name">
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("ServiceChannels")}
                                    </td>
                                    <td class="value">
                                      {pageContent.deliveryChannelAr}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("ServiceCategory")}
                                    </td>
                                    <td class="value">
                                      {pageContent.categoryAr}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("AccountType")}
                                    </td>
                                    <td class="value">{pageContent.type}</td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("ServiceMaturity")}
                                    </td>
                                    <td class="value">
                                      {pageContent.maturity}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("ServiceFees")}
                                    </td>
                                    <td class="value">{pageContent.feesAr}</td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("PaymentChannels")}
                                    </td>
                                    <td class="value">
                                      {pageContent.paymentChannelAr}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="name">
                                      {" "}
                                      <i class="fas fa-dot-circle"></i>
                                      {localizer.getJsonKey("ExecutionTime")}
                                    </td>
                                    <td class="value">
                                      {pageContent.executionTime}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <p class="entry-desc">
                              <strong>
                                {" "}
                                {localizer.getJsonKey("Requirements")}
                              </strong>
                              <br />
                            </p>
                            {ReactHtmlParser(pageContent.requirementsAr)}

                            <p class="entry-desc">
                              <strong>
                                {localizer.getJsonKey("Attachments")}
                              </strong>
                              <br />
                            </p>
                            {ReactHtmlParser(pageContent.attachmentsAr)}

                            <p class="entry-desc">
                              <strong>{localizer.getJsonKey("Rules")}</strong>
                              <br />
                            </p>
                            {ReactHtmlParser(pageContent.contactAr)}

                            <p class="entry-desc">
                              <strong>
                                {localizer.getJsonKey("Procedures")}
                              </strong>
                              <br />
                            </p>
                            {ReactHtmlParser(pageContent.proceduresAr)}

                            <div class="blog-single d-none">
                              <div class="entry-widget-bio">
                                <div class="entry-widget-content">
                                  <div class="entry-bio-desc">
                                    <h4>خطوات التقديم</h4>

                                    <ol class="numberss d-none">
                                      <li>
                                        قدّم طلب تصريح بيئي على نظام التراخيص
                                        البيئية للمركز الوطني للرقابة على
                                        الالتزام البيئي واملأ النموذج.
                                      </li>
                                      <li>يتم مراجعة ودراسة الطلب.</li>
                                      <li>يتم إصدار التصريح البيئي.</li>
                                    </ol>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SubMenu></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default EServicesDetails;
