import React, { useState, useContext, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";

import TotalRequests from "./TotalRequests";
import Building from "./Building";
import Trading from "./Trading";
import Medical from "./Medical";
import SWP from "./SWP";
import useLocalizer from "../../Localization/useLocalizer";

function EservicesStats(props) {
  const localizer = useLocalizer();
  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("RMStats")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("RMStats")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("RMStats")}
                    </h5>

                    <TotalRequests></TotalRequests>

                    <Building></Building>

                    <Trading></Trading>

                    <Medical></Medical>

                    <SWP></SWP>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default EservicesStats;
