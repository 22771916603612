import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";

export default function NewsDetails() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  //let [searchParams, setSearchParams] = useSearchParams();
  // let newsID = searchParams.get("did");
  let params = useParams();
  const localizer = useLocalizer();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/news/GetNewsByID",
          { ReferencesMajorId: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.NewsEntity);
          //console.log("details response");
          //console.log(res);
          context.setEntityID(res.data.output.NewsEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, params]);

  return (
    <React.Fragment>
      <section
        className="page-title page-title-blank-2 bg-white rtl"
        id="page-title"
      >
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{
            backgroundImage: "url('/assets/images/page-titles/6.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("NewsDetails")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrap">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="">{localizer.getJsonKey("MediaCenter")}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="">{localizer.getJsonKey("News")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {localizer.getJsonKey("NewsDetails")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pageContent == undefined && <DetailsLoader />}
      {pageContent != undefined && (
        <section ref={componentRef} className="blog blog-single rtl" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="blog-entry">
                  <div className="entry-img">
                    <img src={pageContent.originalPic} 
                      alt={localizer.getKey(pageContent.titleAr,pageContent.titleEn)} 
                      title={localizer.getKey(pageContent.titleAr,pageContent.titleEn)}
                      longdesc="#blog"  />
                    <div className="entry-meta">
                      <div className="entry-category">
                        <a href="#">
                          {pageContent.tags != null &&
                            pageContent.tags.replaceAll("$", " , ")}
                        </a>
                      </div>
                      <div className="entry-date">
                        <span className="date">
                          <Moment format="YYYY/MM/DD">
                            {pageContent.newsDate}
                          </Moment>
                        </span>
                        <span className="year"></span>
                      </div>
                      <div className="entry-comments">
                        <span>{localizer.getJsonKey("Source")}:</span>
                      </div>
                      <div className="entry-author">
                        <a href="">
                          {localizer.getKey(
                            pageContent.newsSourceAr,
                            pageContent.newsSourceEn
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="entry-content">
                    <div className="entry-title">
                      <h4>
                        {localizer.getKey(
                          pageContent.titleAr,
                          pageContent.titleEn
                        )}
                      </h4>
                    </div>

                    <div className="entry-bio">
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.newsContentAr,
                          pageContent.newsContentEn
                        )
                      )}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />

                    <div className="entry-holder">
                      <div className="entry-share">
                        <span>
                          <i className="fas fa-cog"></i>{" "}
                          {localizer.getJsonKey("ContentSettings")}{" "}
                        </span>
                        <div>
                          <a
                            className="share-facebook"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={localizer.getJsonKey("PrintContent")}
                            onClick={handlePrint}
                          >
                            <i className="fa fa-print"></i>
                          </a>

                          <a
                            className="share-facebook d-none"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="القارئ الآلي"
                          >
                            <i className="fa fa-volume-up"></i>
                          </a>

                          <EmailShareButton url={window.location.href}>
                            <a
                              className="share-facebook"
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={localizer.getJsonKey("SendEmail")}
                            >
                              <i className="fa fa-envelope"></i>
                            </a>
                          </EmailShareButton>
                          <WhatsappShareButton url={window.location.href}>
                            <a
                              className="share-facebook"
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={localizer.getJsonKey("ShareWhatsapp")}
                            >
                              <i className="fab fa-whatsapp"></i>
                            </a>
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href}>
                            <a
                              className="share-facebook"
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={localizer.getJsonKey("ShareFacebook")}
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </FacebookShareButton>
                          <TwitterShareButton url={window.location.href}>
                            <a
                              className="share-facebook"
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={localizer.getJsonKey("ShareTwitter")}
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nav-posts">
                    {pageContent.prevNews != undefined && (
                      <div className="prev-post ">
                        <div className="post-img">
                        <Link
                            
                            to={
                              localizer.getLang() +
                              "/news/" +
                              pageContent.prevNews.id
                            }
                          >
                          <div className="overlay">
                            {" "}
                            <i className="Riyadh-arrow-right"></i>
                          </div>
                          </Link>
                          <img
                            src={pageContent.prevNews.thumpPic}
                            alt={localizer.getKey(pageContent.prevNews.titleAr,pageContent.prevNews.titleEn)}
                            title={localizer.getKey(pageContent.prevNews.titleAr,pageContent.prevNews.titleEn)}
                            longdesc="#blog"
                          />
                        </div>
                        <div className="post-body">
                          <span>{localizer.getJsonKey("PrevNews")}</span>
                          <Link
                            className="post-link"
                            title={localizer.getKey(
                              pageContent.prevNews.titleAr,
                              pageContent.prevNews.titleEn
                            )}
                            to={
                              localizer.getLang() +
                              "/news/" +
                              pageContent.prevNews.id
                            }
                          >
                            {pageContent.prevNews != null &&
                              _.truncate(
                                localizer.getKey(
                                  pageContent.prevNews.titleAr,
                                  pageContent.prevNews.titleEn
                                ),
                                { length: 250 }
                              )}
                          </Link>
                        </div>
                      </div>
                    )}
                    {pageContent.nextNews != undefined && (
                      <div className="next-post">
                        <div className="post-body">
                          <span>{localizer.getJsonKey("NextNews")}</span>
                          <Link
                            className="post-link"
                            title={localizer.getKey(
                              pageContent.nextNews.titleAr,
                              pageContent.nextNews.titleEn
                            )}
                            to={
                              localizer.getLang() +
                              "/news/" +
                              pageContent.nextNews.id
                            }
                          >
                            {pageContent.nextNews != null &&
                              _.truncate(
                                localizer.getKey(
                                  pageContent.nextNews.titleAr,
                                  pageContent.nextNews.titleEn
                                ),
                                { length: 250 }
                              )}
                          </Link>
                        </div>
                        <div className="post-img">
                        <Link
                            
                            to={
                              localizer.getLang() +
                              "/news/" +
                              pageContent.nextNews.id
                            }
                          >
                          <div className="overlay">
                            {" "}
                            <i className="Riyadh-arrow-right"></i>
                          </div>
                          </Link>
                          <img
                            src={pageContent.nextNews.thumpPic}
                            alt={localizer.getKey(pageContent.nextNews.titleAr,pageContent.nextNews.titleEn)}
                            title={localizer.getKey(pageContent.nextNews.titleAr,pageContent.nextNews.titleEn)}
                            longdesc="#blog"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="sidebar sidebar-blog">
                  <SubMenu style={{ width: "100%" }}></SubMenu>
                  {/* <div className="widget widget-services">
                    <div className="widget-title">
                      <h5>المركز الإعلامي</h5>
                    </div>
                    <div className="widget-content">
                      <ul className="list-unstyled">
                        <li>
                          <a href="">
                            {" "}
                            <span>الملف الصحفي</span>
                            <i className="fas fa-arrow-left"></i>
                          </a>
                        </li>
                        <li className="active">
                          <Link to={"/news"}>
                            <span>الاخبار</span>
                            <i className="fas fa-arrow-left"></i>
                          </Link>
                        </li>
                        <li>
                          <a href="">
                            {" "}
                            <span>مكتبة الصور</span>
                            <i className="fas fa-arrow-left"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            {" "}
                            <span>مكتبة الفيديو</span>
                            <i className="fas fa-arrow-left"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  <div className="widget widget-recent-posts">
                    <div className="widget-title">
                      <h5>{localizer.getJsonKey("RelatedNews")}</h5>
                    </div>
                    <div className="widget-content">
                      {pageContent.relatedNews.map((e, i) => {
                        return (
                          <div key={i} className="post">
                            <div className="post-img">
                              <img src={e.thumpPic} alt={localizer.getKey(e.titleAr, e.titleEn)} title={localizer.getKey(e.titleAr, e.titleEn)} longdesc="#blog" />
                            </div>
                            <div className="post-content">
                              <div className="post-date">
                                <span className="date">
                                  <Moment format="YYYY/MM/DD">
                                    {e.newsDate}
                                  </Moment>
                                </span>
                                <span className="year"></span>
                              </div>
                              <div className="post-title">
                                <Link
                                  title={localizer.getKey(e.titleAr, e.titleEn)}
                                  to={localizer.getLang() + "/news/" + e.id}
                                >
                                  {_.truncate(
                                    localizer.getKey(e.titleAr, e.titleEn),
                                    { length: 50 }
                                  )}
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* <div className="widget widget-download">
                    <div className="widget-title">
                      <h5>تحميل المرفقات</h5>
                    </div>
                    <div className="widget-content">
                      <ul className="list-unstyled">
                        <li>
                          <a href="javascript:void(0)">
                            {" "}
                            <span>دليل المستخدم 2021</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 18"
                              width="18"
                              height="18"
                            >
                              <g>
                                <g>
                                  <g>
                                    <path
                                      className="shp0"
                                      d="M2.12 2L2.93 1L14.93 1L15.87 2L2.12 2ZM9 14.5L3.5 9L7 9L7 7L11 7L11 9L14.5 9L9 14.5ZM17.54 2.23L16.15 0.55C15.88 0.21 15.47 0 15 0L3 0C2.53 0 2.12 0.21 1.84 0.55L0.46 2.23C0.17 2.57 0 3.02 0 3.5L0 16C0 17.1 0.9 18 2 18L16 18C17.1 18 18 17.1 18 16L18 3.5C18 3.02 17.83 2.57 17.54 2.23Z"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                        </li>
                        <li className="inversed">
                          <a href="javascript:void(0)">
                            {" "}
                            <span>النشرة الدورية 2021</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 18"
                              width="18"
                              height="18"
                            >
                              <g>
                                <g>
                                  <g>
                                    <path
                                      className="shp0"
                                      d="M2.12 2L2.93 1L14.93 1L15.87 2L2.12 2ZM9 14.5L3.5 9L7 9L7 7L11 7L11 9L14.5 9L9 14.5ZM17.54 2.23L16.15 0.55C15.88 0.21 15.47 0 15 0L3 0C2.53 0 2.12 0.21 1.84 0.55L0.46 2.23C0.17 2.57 0 3.02 0 3.5L0 16C0 17.1 0.9 18 2 18L16 18C17.1 18 18 17.1 18 16L18 3.5C18 3.02 17.83 2.57 17.54 2.23Z"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  <div className="widget widget-tags">
                    <div className="widget-title">
                      <h5>{localizer.getJsonKey("Tags")}</h5>
                    </div>
                    <div className="widget-content">
                      {pageContent.tags != null &&
                        pageContent.tags
                          .replaceAll("$", " , ")
                          .split(",")
                          .map((e, i) => (
                            <a key={i}>
                              {e}
                            </a>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
