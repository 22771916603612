import React, { useState, useContext, useEffect, useRef } from "react";
import VideoRecorder from "react-video-recorder";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import ReCAPTCHA from "react-google-recaptcha";

import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Helpers from "../Helpers/Helpers";
import useLocalizer from "../Localization/useLocalizer";

function DeafContactUs() {
  const context = useContext(SessionContext);

  const videoRecorderRef = useRef();
  const localizer = useLocalizer();
  const FormSchema = Yup.object().shape({
    file: Yup.string().required(localizer.getJsonKey("PleaseRecordVideo")),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail")),
    recaptcha: Yup.string().required(
      localizer.getJsonKey("PleaseInsertCapcha")
    ),
  });
  const submitForm = async function (values) {
    const result = await httpService
      .post(
        apiUrl + "/ContactUs/SaveContactDeaf",
        {
          ReferenceID: PortalID,
          Email: values.email,
          FileUrl: values.file,
          Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("SendSuccess"));
      });
  };
  return (
    <div className="contact-body  tab-pane active" id="deaf" role="tabpanel">
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("DeafContactUs")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("DeafContactUs")}</h4>
                    </div>
                    <div className="product-rating stars-rate">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  <p>{localizer.getJsonKey("DeafContactUsDesc")}</p>
                  <div className="rate-des">
                  <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                      
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* <ReactMediaRecorder
        video
        render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
          <div>
            <p>{status}</p>
            <button onClick={startRecording}>Start Recording</button>
            <button onClick={stopRecording}>Stop Recording</button>
            <video src={mediaBlobUrl} controls autoPlay loop />
          </div>
        )}
      /> */}
      <Formik
        initialValues={{
          email: "",
          file: "",
          recaptcha: "",
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await submitForm(values);
          resetForm();
        }}
      >
        {({ errors, touched, isSubmitting, isValid, setFieldValue }) =>
          isSubmitting ? (
            <ThreeDots></ThreeDots>
          ) : (
            <Form>
              <div className="contactForm" action="">
                <div className="row">
                  <VideoRecorder
                    renderDisconnectedView={function noRefCheck() {}}
                    ref={videoRecorderRef}
                    timeLimit={15000}
                    onRecordingComplete={(videoBlob) => {
                      // Do something with the video...
                      //console.log("videoBlob", videoBlob);
                      var reader = new FileReader();
                      reader.readAsDataURL(videoBlob);
                      reader.onloadend = function () {
                        var base64String = reader.result;
                      //  console.log("Base64 String - ", base64String);
                        setFieldValue("file", base64String);
                      };
                    }}
                  />
                  <div
                    style={{ marginTop: "30px" }}
                    className="col-12 col-md-12"
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="email"
                      placeholder={localizer.getJsonKey("Email")}
                      title={localizer.getJsonKey("Email")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.email}
                    </label>
                  </div>
                  <div className="col-12" style={{ marginBottom: "10px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                      onChange={(response) => {
                     //   console.log(response);
                        setFieldValue("recaptcha", response);
                      }}
                    ></ReCAPTCHA>
                    <label id="" class="col-12 errorr">
                      {errors && errors.recaptcha}
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn--secondary"
                      type="submit"
                      disabled={!isValid}
                    >
                      {localizer.getJsonKey("Send")}
                      <i className="fas fa-arrow-left"></i>
                    </button>
                  </div>

                  {/* <div className="col-6">
            <button
              className="btn btn--primary btn-line btn-line-before"
              type="button"
              onClick={() => {
                videoRecorderRef.current.turnOnCamera();
                videoRecorderRef.current.handleStartRecording();
                //videoRecorderRef.current.handleStartRecording();
              }}
            >
              <span>ابدأ التسجيل</span>
              <i className="fa fa-video"></i>
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn--primary btn-line btn-line-before"
              type="button"
              onClick={() => {
                videoRecorderRef.current.handleStopRecording();
              }}
            >
              <span>إنهاء التسجيل</span>
              <i className="fa fa-stop"></i>
            </button>
          </div> */}

                  <div className="col-12">
                    <div className="contact-result"></div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
}

export default DeafContactUs;
