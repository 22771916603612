import React from "react";
import { Outlet } from "react-router";
import useLocalizer from "../Localization/useLocalizer";
import ContactUS from "./ContactUs";

function ParentContactUs() {
  const localizer = useLocalizer();
  return (
    <React.Fragment>
      <section className="map map-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="d-none">موقعنا على الخريطة</h3>
            </div>
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10258.595289464889!2d46.71509368357826!3d24.62502395596511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05a0bab9b661%3A0x4227e17f708691b4!2z2KPZhdin2YbYqSDZhdiv2YrZhtipINin2YTYsdmK2KfYtg!5e0!3m2!1sar!2ssa!4v1630839668367!5m2!1sar!2ssa"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>

      <section
        className="testimonial testimonial-5 bg-overlay bg-overlay-white2 rtl bg-section"
        style={{
          backgroundImage: "url('/assets/images/background/wavy-pattern.png')",
        }}
      >
        <div className="container">
          <div className="contact-panel contact-panel-2 product-tabs">
            <ContactUS/>
            
          </div>
        </div>
      </section>

      <section className="content-settings">
        <div className="container">
          <div className="features features-3">
            <div className="row">
              <div  className="col-12 col-md-6 col-lg-3">
                <div className="feature-panel-holder" data-hover="">
                  <div className="feature-panel feature-panel-2">
                    <div className="feature-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="feature-content">
                      <h4>{localizer.getJsonKey("ContactNumber")}</h4>
                      <p style={{height:45}} >940</p>

                      <div className="rate-dess">
                        <ul className="icons-rate">
                          <li>
                            <i className="fas fa-calendar-check"></i>
                            {localizer.getJsonKey("ServiceAvailabilityLabel")}
                            <span>
                              {" "}
                              {localizer.getJsonKey("ServiceAvailabilityDays")}                              
                              <br />
                              {localizer.getJsonKey("ServiceAvailabilityFullDays")} 
                            </span>
                          </li>
                          <li>
                            <i className="fas fa-clock"></i>
                            {localizer.getJsonKey("ResponseTimeLabel")}
                            <span> {localizer.getJsonKey("ResponseTime4")} </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="feature-panel-holder" data-hover="">
                  <div className="feature-panel feature-panel-2">
                    <div className="feature-icon">
                      <i className="fas fa-comments"></i>
                    </div>
                    <div className="feature-content">
                      <h4> {localizer.getJsonKey("Support")}</h4>
                      <p style={{height:45}}>{localizer.getJsonKey("LiveChatService")}</p>
                      <div className="rate-dess">
                        <ul className="icons-rate" style={{alignItems:"center"}}>
                          <li>
                            <i className="fas fa-calendar-check"></i>
                            {localizer.getJsonKey("ServiceAvailabilityLabel")}
                            <span>
                              {" "}
                              {localizer.getJsonKey("ServiceAvailabilityDays")}
                              <br />
                              {localizer.getJsonKey("ServiceAvailabilityHours")}
                            </span>
                          </li>
                          <li>
                            <i className="fas fa-clock"></i>  {localizer.getJsonKey("ResponseTimeLabel")} 
                            <span> {localizer.getJsonKey("ResponseTime6")}   </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="feature-panel-holder" data-hover="">
                  <div className="feature-panel feature-panel-2">
                    <div className="feature-icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="feature-content">
                      <h4> {localizer.getJsonKey("Email")} </h4>
                      <p style={{ direction: "ltr",height:45 }}>940@alriyadh.gov.sa</p>
                      <div className="rate-dess">
                        <ul className="icons-rate">
                          <li>
                            <i className="fas fa-calendar-check"></i> 
                            {localizer.getJsonKey("ServiceAvailabilityLabel")} 
                            <span>
                              {" "}
                              {localizer.getJsonKey("ServiceAvailabilityDays")} 
                              <br />
                              {localizer.getJsonKey("ServiceAvailabilityHours")} 
                            </span>
                          </li>
                          <li>
                            <i className="fas fa-clock"></i> 
                            {localizer.getJsonKey("ResponseTimeLabel")} 
                            <span> {localizer.getJsonKey("ResponseTime48")}  </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-12 col-md-6 col-lg-3">
              <a href={localizer.getLang()+"/"+"Municipalities"}>
                <div className="feature-panel-holder" data-hover="">
                  <div className="feature-panel feature-panel-2">
                    <div className="feature-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="feature-content">
                      <h4>{localizer.getJsonKey("VisitUs")} </h4>
                      <p style={{height:45}}>
                      {localizer.getJsonKey("MainBuilding")}
                        <br />
                        {localizer.getJsonKey("Center")}
                        <br />
                        {localizer.getJsonKey("VisitLine3")}
                      </p>

                      <div className="rate-dess">
                        <ul className="icons-rate">
                          <li>
                            <i className="fas fa-calendar-check"></i> 
                            {localizer.getJsonKey("ServiceAvailabilityLabel")}
                            <span>
                              {" "}
                              {localizer.getJsonKey("ServiceAvailabilityDays")}
                              <br />
                              {localizer.getJsonKey("ServiceAvailabilityHours")}
                            </span>
                          </li>
                          <li>
                            <i className="fas fa-clock"></i> {localizer.getJsonKey("ServiceAvailabilityLabel")}
                            <span> {localizer.getJsonKey("ResponseTime0")} </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ParentContactUs;
