function ErrorHandling() {
  //console.log("error handling");
  try {
    return "SOmething went wrong";
  } catch (error) {
   // console.log("wrongggggggggg" + error);
  }
}

export default ErrorHandling;
