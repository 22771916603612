import React, { Fragment, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import httpService from "../services/httpService";
import useLocalizer from "../Localization/useLocalizer";
export default function Weather(props) {
  const localizer = useLocalizer();
  const [weather, setWeather] = useState({});
  useEffect(() => {
    getWeather();
  }, []);

  async function getWeather() {
    const lat = "24.67";
    const lon = "46.69";
    const appid = "ac1dc1e0f58f857ece592a91f453b8a0";
    const units = "metric";
    const result = await httpService
      .get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${appid}&units=${units}`,
        {},
        { noOverride: true }
      )
      .then((res) => {
        //console.log("weather: " + res.data.main.temp);
        //console.log("weather: " + res.data.weather[0].icon);
        if (res.data.weather[0].id.toString().startsWith("7")) {
          res.data.weather[0].main = "Atmosphere";
        }
        res.data.weather[0].icon =
          "https://openweathermap.org/img/wn/" +
          res.data.weather[0].icon +
          ".png";
        setWeather({ weather: res.data.weather[0], main: res.data.main });
      });
  }

  return (
    <>
      {!_.isEmpty(weather) && (
        <p className="headline weather" id="weatherDiv">
          <img src={weather.weather.icon} style={{ width: "35px" }} alt="weather icon" title="weather icon" longdesc="#weatherDiv" />
          {localizer.getJsonKey(weather.weather.main)}
          <span>{weather.main.temp}‎°C </span>
          <span> <a
                        href="https://ncec.gov.sa/aq-star-rate/aq-dashboard.html"
                        target="_blank"
                        rel="noopener">
            {localizer.getJsonKey("AQI")}
            </a>
            </span>

        </p>
      )}
    </>
  );
}
