import React, { useState, useContext, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
import LocalPartnersList from "./LocalPartnersListControl";
import LocalPartnersListControl from "./LocalPartnersListControl";
import PartnersListControl from "./PartnersListControl";
import Moment from "react-moment";
import Slider from "react-slick";
import { FiTriangle } from "react-icons/fi";
import "./Partners.scss"

function AllPartners() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  
  const [active,setActive]=useState(0);


  useEffect(() => {
    if (context.tokenVal != undefined) {
       getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/partners/getpartners",
        { ReferenceID: PortalID },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.output.PartnersEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FiTriangle className={"arrow-next"} onClick={onClick}></FiTriangle>
  
    );
  }
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FiTriangle className={"arrow-prev"} onClick={onClick}></FiTriangle>
  
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: listContent.length < 3  ? listContent.length : 3,
    slidesToScroll: 1,
    
    
    nextArrow:<PrevArrow />,
    prevArrow:  <NextArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: true
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Partners")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"> {localizer.getJsonKey("Partners")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>
      
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("Partners")}
                    </h5>
                    
                    <div class={"single-product engli-product"}>
                    <div id="AllPartnersDiv" class="product-tabs " style={{ marginTop: "0px" }}>
                        <ul class="nav nav-tabs" role="tablist" style={{backgroundColor:'#f4f4f4',padding:'14px 40px 5px 50px',borderRadius:'50px'}}>
                        <Slider {...settings}>

                          {listContent.length == 0 ? (
                            <DetailsLoader></DetailsLoader>
                          ) : (
                            listContent.map((e, i) => (
                              
                              <li key={i} role="presentation">
                                
                                <a
                                  class={i == active ? "active" : ""}
                                  href={"#tab"+i}
                                  data-bs-target={"#tab"+i}
                                  aria-controls={"tab"+i}
                                  role="tab"
                                  data-bs-toggle="tab"
                                  aria-selected="true"
                                  onClick={()=>setActive(i)}
                                >
                                  {localizer.getKey(e.titleAr,e.titleEn)}
                                </a>
                              </li>
                            ))
                          )}
                          </Slider>
                        </ul>

                        
                        <div class="tab-content external-sub-logo">
                            {listContent.length == 0 ? (
                                <DetailsLoader></DetailsLoader>
                              ) : (
                                listContent.map((e, i) => (
                              <div
                                class={i == active ? "active show tab-pane" : "tab-pane"}
                                id={"tab"+i}
                                role="tabpanel"
                              >
                                <div class="features features-3 row-hover">
                                  <div class="row">
                                  {e.childrenPartner.map((c, z) =>(
                                    
                                    <div key={z+c} class="contact-panel no-padd">
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="contact-card">
                                          <div class="contact-body">
                                            <div class="row">
                                              <div class="col-12 col-md-3">
                                                <img src={c.iconUrl} alt={localizer.getKey(c.titleAr,c.titleEn)} title={localizer.getKey(c.titleAr,c.titleEn)} longdesc="#AllPartnersDiv"/>
                                              </div>
          
                                              <div class="col-12 col-md-9">
                                                <p>
                                                  <strong>
                                                    {localizer.getKey(
                                                      c.titleAr,
                                                      c.titleEn
                                                    )}
                                                  </strong>
                                                </p>
                                                <p class="entry-desc">
                                                  {localizer.getKey(
                                                    c.contentAr,
                                                    c.contentEn
                                                  )}
                                                </p>
                                              </div>
                                              <ul class="list-unstyled advantages-list advantages-list-3">
                                                <li>
                                                  {localizer.getJsonKey("PartnerShipField")}:{" "}
                                                  {localizer.getKey(c.addressAr, c.addressEn)}
                                                </li>
                                                <li>
                                                  {localizer.getJsonKey("ContractDate")}:{" "}
                                                  <Moment format="DD/MM/YYYY">
                                                    {c.contractDate}
                                                  </Moment>
                                                </li>
                                                <li>
                                                  {localizer.getJsonKey("PartnerDepartment")}:{" "}
                                                  {localizer.getKey(
                                                    c.rmdepartmentNameAr,
                                                    c.rmdepartmentNameEn
                                                  )}
                                                </li>
                                                <li>
                                                  {localizer.getJsonKey("ContractStatus")}:{" "}
                                                  {c.contractActive
                                                    ? localizer.getJsonKey("Active")
                                                    : localizer.getJsonKey("InActive")}
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  )
                                  )}
                                  </div>
                                </div>
                              </div>
                              ))
                              )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu></SubMenu>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AllPartners;
