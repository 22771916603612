import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SessionContext from "../Context/SessionContext";
import Helpers from "../Helpers/Helpers";

axios.interceptors.request.use(function (config) {
  // const context = useContext(SessionContext);
  // const token = context.tokenVal;
 // console.log("calling");

  if (config.url.indexOf("gateway") != -1) {
    if (config.tokenVal != undefined) {
    //  console.log(config.url);
      config.headers.Authorization = "bearer " + config.tokenVal;
    }
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    if (response.request.responseURL.indexOf("gateway") != -1) {
     // console.log("recieving ");
    //  console.log(response.request.responseURL);
      if(response.data.header != undefined){
        if (response.data.header.code != 103) {
          Helpers.notify(response.data.header.message, true);
          return null;
        }
      }
      else{
        if (response.data.succeeded!=undefined&& !response.data.succeeded) {
          Helpers.notify(response.data.message, true);
          //  toast.error(response.data.header.message);
          return null;
        }
      }
      
    }
    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (error.response != undefined && error.response.status == 401) {
      //window.location.reload();
    }
    if (!expectedError) {
      toast.error(error.message);
    }
    return Promise.reject(error);
  }
);
function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
