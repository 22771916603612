import React, { useState, useContext, useEffect } from "react";

import { useSearchParams, useParams, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function AboutAmana(props) {
  const localizer = useLocalizer();
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const location = useLocation();
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + "/Articles/GetArticleDetails",
          { ReferencesMajorId: PortalID, id: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.ArticlesEntity);
          context.setEntityID(res.data.output.ArticlesEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, location.pathname]);

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {pageContent == 0 ? "" : pageContent.nameAr}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>الرئيسية</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">أمانة الرياض</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                عن أمانة الرياض
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-8 order-0">
              {pageContent == 0 && <DetailsLoader></DetailsLoader>}
              <div className="service-entry">
                <div className="entry-content">
                  <div className="entry-introduction">
                    {ReactHtmlParser(
                      pageContent != 0 ? pageContent.contentAr : ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SubMenu customMenu={ContentMenuCode} />
            {/* <div className="col-12 col-lg-4 order-1">
              <div className="sidebar sidebar-service">
                <div className="widget widget-services">
                  <div className="widget-title">
                    <h5>أمانة الرياض</h5>
                  </div>
                  <div className="widget-content">
                    <ul className="list-unstyled">
                      <li className="active">
                        <a href="">
                          {" "}
                          <span>عن أمانة الرياض</span>
                          <i className="fas fa-arrow-left"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AboutAmana;
