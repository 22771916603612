import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import axios from "axios";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, GMapsKey ,newOpenDataGateWay} from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Helpers from "../../Helpers/Helpers";

import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import _ from "lodash";
import useLocalizer from "../../Localization/useLocalizer";
function DataSetDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay+"/DataSets/GetById?id=" + params.did,
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          debugger
         // console.log(res, "detail");
          setPageContent(res.data.data);
          context.setEntityID(res.data.data.id);
        });
    }

    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  async function getContent() {
    const result = await httpService
      .post(
        apiUrl + newOpenDataGateWay+"/DataSets/GetById?id=" + params.did,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        debugger
       // console.log(res, "detail");
        setPageContent(res.data.data);
      });
  }

  async function AddDataSetRate(rateValue) {
    debugger;
    const result = await httpService
      .post(
        apiUrl + newOpenDataGateWay+"/DataSets/AddDataSetRate?datasetId=" + params.did+"&rate="+rateValue,
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        if (res.data.succeeded && res.data.data) Helpers.notify(localizer.getJsonKey("RateSendSuccessfully"));
        getContent();
        
      });
  }
  async function downloadAttachment(event, item) {
    axios(apiUrl +newOpenDataGateWay+ "/Attachment/download/" + item.id + "/" + item.name, {
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      Authorization: "bearer " + context.tokenVal,
    }).then((res) => {
      
      const file = new Blob([res.data], {
        type:item.extension=='xlsx'?"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":item.extension=='xml'||item.extension=='csv' ?'text/plain':item.extension=='csv'?'text/csv;charset=utf-8,':undefined,
    });
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', URL.createObjectURL(file));
      link.setAttribute('download',item.name );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
  
  async function UpdateDownloadTimes(event, item) {
    const result = await httpService
      .post(
        apiUrl +newOpenDataGateWay+ "/Attachment/UpdateDownloadTimes",
        {
          id: item.id,
          attachmentTypeId: 1,
          entityID: 1,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
      //  console.log(res, "downloadtimes");
      });
  }
  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {pageContent.length > 0 &&
                      localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {localizer.getJsonKey("Home")}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
              <a href={localizer.getLang()+'/DataSets'}>
                {localizer.getJsonKey("DataSets")}
                </a>
              </li>
              <li class="breadcrumb-item active">
                {localizer.getJsonKey("DataSetDetails")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section
          class="single-product rtl mar-top50 mar-bot50"
          id="single-product"
        >
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="product-content features features-3 row-hover">
                  <div class="product-title">
                    <h3>
                      {localizer.getKey(pageContent.nameAr, pageContent.nameEn)}
                    </h3>
                  </div>
                  <div class="product-review">
                   <div class="product-rating">
                      <i className={"fas fa-star" + ((pageContent.averageRate>=1) ?" active-fa-star":"")}
                         onClick={() => {AddDataSetRate(1.00)}}>
                      </i>
                      <i className={"fas fa-star" + ((pageContent.averageRate>=2) ?" active-fa-star":"")}
                         onClick={() => {AddDataSetRate(2.00)}}>
                      </i>
                      <i className={"fas fa-star" + ((pageContent.averageRate>=3) ?" active-fa-star":"")}
                         onClick={() => {AddDataSetRate(3.00)}}>
                      </i>
                      <i className={"fas fa-star" + ((pageContent.averageRate>=4) ?" active-fa-star":"")}
                         onClick={() => {AddDataSetRate(4.00)}}>
                      </i>
                      <i className={"fas fa-star" + ((pageContent.averageRate>=5) ?" active-fa-star":"")} 
                         onClick={() => {AddDataSetRate(5.00)}}>
                      </i>
                     </div>
                    <span>
                      {localizer.getJsonKey("EvalNumber")}{" "}
                      {pageContent.userRateNumber}
                    </span>
                    <span>
                      <i class="fas fa-eye"></i>
                      <span class="datasetIcon">{pageContent.views}</span>
                    </span>
                    <span>
                      <i class="fas fa-download"></i>
                      <span class="datasetIcon">{pageContent.downloads}</span>
                    </span>
                  </div>

                  <div class="product-desc">
                    <p>
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.summaryAr,
                          pageContent.summaryEn
                        )
                      )}
                    </p>
                  </div>
                  {/* <p class="entry-desc">
                    <strong>{localizer.getJsonKey("DataSetInfo")}</strong>
                  </p> */}
                  <div class="serv-details">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="name">
                            {" "}
                            <i class="fas fa-users"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("Publisher")}:
                            </span>
                          </td>
                          <td class="value">
                            {localizer.getKey(
                              pageContent.publisher?.nameAr,
                              pageContent.publisher?.nameEn
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td class="name">
                            {" "}
                            <i class="fas fa-desktop"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("DataSetType")}:
                            </span>
                          </td>
                          <td class="value">
                            {localizer.getKey(
                              pageContent.dataSetType?.nameAr,
                              pageContent.dataSetType?.nameEn
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td class="name">
                            <i class="fas fa-calendar"></i>
                            <span class="datasetIcon">
                              {localizer.getJsonKey("Date")}:
                            </span>
                          </td>
                          <td class="value">{pageContent.createdDateHijri}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {pageContent.dataSetMetaData.length >0 &&
                  <>
                  <p class="entry-desc">
                    <strong>{localizer.getJsonKey("DataSetExtraData")}</strong>
                  </p>
                  <div class="serv-details">
                    <table class="table">
                      <tbody>
                        {pageContent.dataSetMetaData.map((meta, ind) => (
                          <tr>
                            <td class="name">
                              {/* <i class="fas fa-calendar-alt"></i>  */}
                              {localizer.getKey(meta.nameAr, meta.nameEn)} :
                            </td>
                            <td class="value">{localizer.getKey(meta.valueAr, meta.valueEn)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  </>
                  }
                  {pageContent.attachments.length >0 &&
                    <>
                      <p class="entry-desc">
                        <strong>{localizer.getJsonKey("Attachments")}</strong>
                      </p>
                      <div class="row">
                          <div class="col-7">
                              <ul class="list-group">
                                {pageContent.attachments.map((attach, ind) => (
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-11">
                                        <i class="datasetAttachIcon datasetIcon">{attach.extension.toUpperCase()}</i> {attach.name}
                                      </div>
                                      <div class="col-1 align-items-end rtl">
                                        <link
                                            rel="stylesheet"
                                            type="text/css"
                                            href="/assets/css/attachment_menu.css"
                                          />
                                          <div class="dropdown">
                                            
                                                <div style={{cursor:'pointer'}} onClick={(event) => {downloadAttachment(event, attach); UpdateDownloadTimes(event, attach);}}>
                                                    <i class="fas fa-download downloadIcon "></i>
                                                    {/* <span className="datasetIcon">{localizer.getJsonKey("Donwload")}</span> */}
                                                </div>
                                              
                                              {/* <a href={localizer.getLang()+'/DataSetFileReview?id='+attach.id+'&name='+attach.name}>
                                                  <i class="fas fa-eye downloadIcon"></i>
                                                  <span className="datasetIcon">{localizer.getJsonKey("Preview")}</span>
                                              </a> */}
                                          </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                          </div>
                      </div>
                    </>
                  }
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
              {/* <SubMenu></SubMenu> */}
            </div>
          </div>
          <div>
        </div>  
        </section>
      )}
    </React.Fragment>
  );
}

export default DataSetDetails;
