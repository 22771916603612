import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function LastUpdates() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent(0);
    }
  }, [context.tokenVal]);

  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Statistics/GetPortalLatestUpdate",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.EntitiesLatestUpdate);
        context.setEntityID(-1);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("LastPortalUpdates")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("LastPortalUpdates")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("LastPortalUpdates")}
                    </h5>

                    {listContent.length == 0 && (
                      <RepeatableTableRows style={{ width: "100%" }} />
                    )}
                    <div class="table-responsive">
                      <table
                        class="table table-hover table-bordered"
                        cellspacing="0"
                        rules="all"
                        border="1"
                      >
                        <tbody>
                          <tr class="GridViewTr">
                            <th scope="col">
                              {localizer.getJsonKey("ModuleName")}
                            </th>
                            <th scope="col">
                              {localizer.getJsonKey("LastUpdate")}
                            </th>
                          </tr>

                          {listContent.length > 0 &&
                            listContent.map((x, ind) => (
                              <tr key={ind}>
                                <td>{localizer.getKey(x.nameAr, x.nameEn)}</td>

                                <td>
                                  <Moment format="DD/MM/YYYY">
                                    {x.lastUpdate}
                                  </Moment>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default LastUpdates;
