import React, { useState, useContext, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";

import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import useLocalizer from "../../Localization/useLocalizer";

export default function LowerQuickLinks() {
  const lowerQuickLinksCode = "4330454022";
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Menus/GetMenus",
        {
          ReferenceID: PortalID,
          Code: lowerQuickLinksCode,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.MenusEntity.subMenus);
        window.loadslider(".lowerQuickLinks");
      });
  }

  return pageContent.length == 0 ? (
    <ThreeDots></ThreeDots>
  ) : (
    <div
      class="owl-carousel lowerQuickLinks"
      data-slide="6"
      data-slide-rs="2"
      data-autoplay="true"
      data-nav="false"
      data-dots="false"
      data-space="0"
      data-loop="true"
      data-speed="3000"
      rtl="true"
    >
      {pageContent.map((e, i) => (
        <div key={i} class="client">
          {e.url.indexOf("http") == -1 ? (
            <Link
              to={
                localizer.getLang() +
                e.url +
                (e.articleId == null ? "" : "?did=" + e.articleId)
              }
              target={e.openBlankPage ? "_blank" : ""}
            >
               <div className="d-none">{localizer.getKey(e.nameAr, e.nameEn)}</div>
            </Link>
          ) : (
            <a href={e.url} target={e.openBlankPage ? "_blank" : ""} aria-label={localizer.getKey(e.nameAr, e.nameEn)} >
              {" "}
              <div className="d-none">{localizer.getKey(e.nameAr, e.nameEn)}</div>
            </a>
          )}
          <img src={e.imageIcon} 
              alt={localizer.getKey(e.nameAr, e.nameEn)} 
              title={localizer.getKey(e.nameAr, e.nameEn)} 
              />
          {localizer.getKey(e.nameAr, e.nameEn)}
        </div>
      ))}
    </div>
  );
}
