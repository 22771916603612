import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";

function InitiativeDetails() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/InitiativesProjects/GetInitiativesProjectDetails",

          { ReferenceID: PortalID, ID: params.did },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.InitiativesProjectsEntity);

          //console.log(res);
          context.setEntityID(
            res.data.output.InitiativesProjectsEntity.entityId
          );
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, searchParams]);
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("Initiatives")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item">
                <a href="#">{localizer.getJsonKey("Initiatives")}</a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      {pageContent.length == 0 ? (
        <DetailsLoader></DetailsLoader>
      ) : (
        <section
          class="single-product rtl mar-top50 mar-bot50"
          id="single-product"
        >
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="product-content">
                  <div class="product-title">
                    <h3>
                      {localizer.getKey(
                        pageContent.titleAr,
                        pageContent.titleEn
                      )}
                    </h3>
                  </div>

                  <div class="product-price">
                    <span>
                      {localizer.getJsonKey("Category")}{" "}
                      {localizer.getKey(
                        pageContent.typeTextAr,
                        pageContent.typeTextEn
                      )}
                    </span>
                  </div>
                  <div class="icons-rate">
                    <i class="fas fa-calendar-alt"></i>{" "}
                    <Moment format="DD/MM/YYYY">
                      {pageContent.initiativeDate}
                    </Moment>
                    <i class="fas fa-map-marker-alt"></i>{" "}
                    {localizer.getKey(
                      pageContent.locationAr,
                      pageContent.locationEn
                    )}
                    <i class="fas fa-comments"></i> {pageContent.commentsNumber}{" "}
                    {localizer.getJsonKey("Comment")}
                  </div>
                  <div class="product-desc" style={{ marginTop: "20px;" }}>
                    <p class="entry-desc">
                      {ReactHtmlParser(
                        localizer.getKey(
                          pageContent.contentAr,
                          pageContent.contentEn
                        )
                      )}
                    </p>
                  </div>

                  <div class="product-share d-none">
                    <a class="facebook-bg" href="javascript:void(0)" aria-label={localizer.getJsonKey("Facebook")}>
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a class="twitter-bg" href="javascript:void(0)" aria-label={localizer.getJsonKey("Twitter")}>
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a class="pinterest-bg" href="javascript:void(0)" aria-label={localizer.getJsonKey("Pinterest")}>
                      <i class="fab fa-pinterest"></i>
                    </a>
                    <a class="instagram-bg" href="javascript:void(0)" aria-label={localizer.getJsonKey("Instagram")}>
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div class="blog-single">
                  <div class="entry-widget-bio">
                    <div class="entry-widget-content">
                      <div class="entry-bio-desc">
                        <h4>
                          {" "}
                          {ReactHtmlParser(
                            localizer.getJsonKey("InitiativeGoal")
                          )}
                        </h4>
                        {ReactHtmlParser(
                          pageContent.length != 0 &&
                            localizer.getKey(
                              pageContent.goalsAr,
                              pageContent.goalsEn
                            )
                        )}

                        <ol class="numberss d-none">
                          <li>
                            توعية و تعريف المستفيد عن كيفية واهمية استخدام
                            الخدمات الإلكترونية
                          </li>
                          <li>
                            توعية و تعريف المستفيد عن كيفية واهمية استخدام
                            الخدمات الإلكترونية للاستفادة منها
                          </li>
                          <li>
                            توعية و تعريف المستفيد عن كيفية واهمية استخدام
                            الخدمات
                          </li>
                          <li>
                            توعية و تعريف المستفيد عن كيفية واهمية استخدام
                            الخدمات الإلكترونية{" "}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="entry-widget-bio">
                    <div class="entry-widget-content">
                      <div class="entry-bio-desc">
                        <h4>
                          {localizer.getJsonKey("InitiativeBenifciaries")}
                        </h4>
                        <ol class="numberss">
                          {pageContent.length != 0 &&
                            pageContent.listOfBeneficiaries.map((x, i) => {
                              return (
                                <li key={i}>
                                  {localizer.getKey(x.nameAr, x.nameEn)}
                                </li>
                              );
                            })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default InitiativeDetails;
