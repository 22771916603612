import React, { useEffect, useState, useContext } from "react";
//import $ from "jquery";
import httpService from "../services/httpService";
import SessionContext from "../Context/SessionContext";
import LowerQuickLinks from "./HomeControls/LowerQuickLinks";
import UpperQuickLinks from "./HomeControls/UpperQuickLinks";
import EservicesLinks from "./HomeControls/EservicesLinks";
import News from "./HomeControls/News";
import AdsSlider from "./HomeControls/AdsSlider";
import OpenData from "./HomeControls/OpenData";
import Survey from "./HomeControls/Survey";
import ErrorHandling from "../Controls/ErrorHandling";
import { ErrorBoundary } from "react-error-boundary";
import useLocalizer from "../Localization/useLocalizer";
import "./Home.scss"
export default function Home() {
  // const context = useContext(SessionContext);
  // //console.log(context.tokenVal);
  const localizer = useLocalizer();
  useEffect(() => {
    //$(".preloader").fadeOut(30000);
    //$(".preloader").remove();
  }, []);
  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorHandling}>
        <AdsSlider></AdsSlider>
      </ErrorBoundary>
      <section
        className="features-bar bg-overlay bg-overlay-theme3 bg-section"
        id="featuresBar-1"
      >
        <div className="bg-section" style={{ backgroundImage: "url('/assets/images/background/2.jpg')" }}></div>
        <div className="container upper-quick-links">
          <ErrorBoundary FallbackComponent={ErrorHandling}>
            <UpperQuickLinks></UpperQuickLinks>
          </ErrorBoundary>
        </div>
      </section>
      <section className="clients clients-carousel clients-1" id="clients-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <LowerQuickLinks></LowerQuickLinks>
            </div>
          </div>
        </div>
      </section>
      <ErrorBoundary FallbackComponent={ErrorHandling}>
        <News></News>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorHandling}>
        <EservicesLinks></EservicesLinks>
      </ErrorBoundary>
      <section className="about about-2" id="about-2">
        <div className="about-wrapper">
          <div className="video-wrapper">
            <div
              className="video video-2 bg-section"
              style={{ backgroundImage: "url('/assets/images/video/2.jpg')" }}
              id=""
            >
              <div className="bg-section d-none">
                <img src="/assets/images/video/2.jpg" alt="video img" title="video img" longdesc="#about-2" />
              </div>
              <a
                className="popup-video btn-video btn-video-2"
                target="_blank"
                href="https://www.youtube.com/watch?v=SFWXfQ4lfhg"
              >
                {" "}
                <i className="fas fa-play"></i>
                <div className="d-none">youtube</div>
              </a>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={ErrorHandling}>
            <OpenData></OpenData>
          </ErrorBoundary>
        </div>
      </section>
      <section
        className="features features-2 bg-overlay bg-overlay-theme2 rtl bg-section"
        id="features-2"
        style={{ backgroundImage: "url('/assets/images/background/2.jpg')" }}
      >
        <div className="bg-section d-none">
          <img src="/assets/images/background/2.jpg" alt="Background"  title="Background" longdesc="#features-2" />
        </div>
        <div className="container">
          <div
            className="heading heading-4 heading-light heading-light2"
            style={{ paddingTop: "70px" }}
          >
            <div className="row">
              <div className="col-12 col-lg-5">
                <p className="heading-subtitle">
                  {localizer.getJsonKey("SingleRiyadhGuide")}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5">
                <h2 className="heading-title">
                  {" "}
                  {localizer.getJsonKey("InteractiveRiyadhGuide")}
                </h2>
              </div>
              <div className="col-12 col-lg-6 offset-lg-1">
                <p className="heading-desc">
                  {" "}
                  {localizer.getJsonKey("RiyadhGuidedesc")}
                </p>
                <div className="actions-holder">
                  <a
                    className="btn btn--primary btn--inversed"
                    href="https://maps.alriyadh.gov.sa/geoportal/geomap"
                    target={"_blank"}
                  >
                    {localizer.getJsonKey("InteractiveRiyadhGuide")}
                    <i className="fas fa-arrow-left"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="img-hotspot-2 img-hotspot">
                <div className="img-hotspot-wrap">
                  <div className="img-hotspot-bg">
                    <img
                      src="/assets/images/background/world-map-dark.png"
                      alt="world map dark"
                      title="world map dark"
                      longdesc="#features-2"
                    />
                  </div>
                  <div className="img-hotspot-pointers">
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="29%"
                      data-spot-y="72%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-012-mining-1"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="48%"
                      data-spot-y="48%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-035-battery"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="75%"
                      data-spot-y="17%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-027-energy-tower"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="34%"
                      data-spot-y="9%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-032-recycle"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="59%"
                      data-spot-y="21%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-030-biology"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="68%"
                      data-spot-y="38%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-034-coal"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer"
                      data-spot-x="15%"
                      data-spot-y="29%"
                    >
                      <div className="pointer-icon"></div>
                      <div className="info" data-info-x="" data-info-y="">
                        <div className="border-outer">
                          <div className="border-inner">
                            <i className="flaticon-039-wind-mill"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer r"
                      data-spot-x="35%"
                      data-spot-y="55%"
                    >
                      <img
                        src="/assets/images/testimonial/thumbs/1.jpg"
                        alt={localizer.getJsonKey("KingdomDesc")}
                        title={localizer.getJsonKey("KingdomDesc")}
                        longdesc="#features-2"
                      />
                      <div
                        className="info right"
                        data-info-x="-20px"
                        data-info-y="-152px"
                      >
                        <span>{localizer.getJsonKey("KingdomDesc")}</span>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer r"
                      data-spot-x="40%"
                      data-spot-y="25%"
                    >
                      <img
                        src="/assets/images/testimonial/thumbs/2.jpg"
                        alt={localizer.getJsonKey("FaisalyaDesc")}
                        title={localizer.getJsonKey("FaisalyaDesc")}
                        longdesc="#features-2"
                      />
                      <div
                        className="info right"
                        data-info-x="-20px"
                        data-info-y="-152px"
                      >
                        <span>{localizer.getJsonKey("FaisalyaDesc")}</span>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer r"
                      data-spot-x="79%"
                      data-spot-y="25%"
                    >
                      <img
                        src="/assets/images/testimonial/thumbs/3.jpg"
                        alt={localizer.getJsonKey("KingFahdLib")}
                        title={localizer.getJsonKey("KingFahdLib")}
                        longdesc="#features-2"
                      />
                      <div
                        className="info right"
                        data-info-x="-20px"
                        data-info-y="-152px"
                      >
                        <span>{localizer.getJsonKey("KingFahdLib")}</span>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer r"
                      data-spot-x="25%"
                      data-spot-y="36%"
                    >
                      <img
                        src="/assets/images/testimonial/thumbs/4.jpg"
                        alt={localizer.getJsonKey("KingSalmanGarden")}
                        title={localizer.getJsonKey("KingSalmanGarden")}
                        longdesc="#features-2"
                      />
                      <div
                        className="info right"
                        data-info-x="-20px"
                        data-info-y="-152px"
                      >
                        <span>{localizer.getJsonKey("KingSalmanGarden")}</span>
                      </div>
                    </div>
                    <div
                      className="img-hotspot-pointer r"
                      data-spot-x="70%"
                      data-spot-y="60%"
                    >
                      <img
                        src="/assets/images/testimonial/thumbs/5.jpg"
                        alt={localizer.getJsonKey("RiyadhMetro")}
                        title={localizer.getJsonKey("RiyadhMetro")}
                        longdesc="#features-2"
                      />
                      <div
                        className="info right"
                        data-info-x="-20px"
                        data-info-y="-152px"
                      >
                        <span>{localizer.getJsonKey("RiyadhMetro")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 offset-lg-1">
              <div className="counter counter-2">
                <div className="counter-num">
                  <h6> {localizer.getJsonKey("ParksGardens")}</h6>
                  <span className="counting" data-counterup-nums="359">
                    359
                  </span>
                </div>
              </div>

              <div className="counter counter-2">
                <div className="counter-num">
                  <h6> {localizer.getJsonKey("MunPlaces")}</h6>
                  <span className="counting" data-counterup-nums="58">
                    58
                  </span>
                </div>
              </div>
              <div className="counter counter-2">
                <div className="counter-num">
                  <h6> {localizer.getJsonKey("OpenAreas")}</h6>
                  <span className="counting" data-counterup-nums="23">
                    23
                  </span>
                </div>
              </div>
              <div className="counter counter-2">
                <div className="counter-num">
                  <h6>{localizer.getJsonKey("Walks")}</h6>
                  <span className="counting" data-counterup-nums="70">
                    70
                  </span>
                </div>
              </div>
              <div className="counter counter-2">
                <div className="counter-num">
                  <h6>{localizer.getJsonKey("RiyadhOasis")}</h6>
                  <span className="counting" data-counterup-nums="8">
                    8
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="contact-panel contact-panel-2">
            <div className="row">
              <ErrorBoundary FallbackComponent={ErrorHandling}>
                <Survey></Survey>
              </ErrorBoundary>

              <div className="col-12 col-lg-5 img-card-holder">
                <div
                  className="img-card img-card-2 bg-overlay bg-overlay-theme bg-section"
                  style={{
                    backgroundImage: "url('/assets/images/contact/2.jpg')",
                  }}
                >
                  <div className="bg-section d-none">
                    <img src="/assets/images/contact/2.jpg" 
                        alt="contact us icon" 
                        title="image" 
                        longdesc="#features-2"
                        />
                  </div>
                  <div className="card-content">
                    <div className="content-top">
                      <h2 className="heading-title hed-ttt">
                        {localizer.getJsonKey("InteractWithRM")}
                      </h2>
                      <p>{localizer.getJsonKey("SurveyDesc")}</p>
                    </div>
                    <div className="content-bottom">
                      <ul className="list-unstyled contact-infos">
                        <li className="contact-info">
                          <i className="fas fa-info-circle"></i>
                          <p>
                            <a href="https://eqs.alriyadh.gov.sa">
                              {localizer.getJsonKey("EQS")}
                            </a>
                          </p>
                        </li>
                        {/* <li className="contact-info">
                          <i className="fas fa-info-circle"></i>
                          <p>
                            <a href="surveys.html">استبيان تطبيق 940</a>
                          </p>
                        </li>
                        <li className="contact-info">
                          <i className="fas fa-info-circle"></i>
                          <p>
                            <a href="surveys.html">
                              استبيان جودة خدمة الشات بوت
                            </a>
                          </p>
                        </li>
                        <li className="contact-info">
                          <i className="fas fa-info-circle"></i>
                          <p>
                            <a href="surveys.html">
                              استبيان مدى الرضا عن بوابة أمانة الرياض
                            </a>
                          </p>
                        </li>
                        <li className="contact-info">
                          <i className="fas fa-info-circle"></i>
                          <p>
                            <a href="surveys.html">
                              استبيان فعالية تطبيق الأضاحي
                            </a>
                          </p>
                        </li> */}
                      </ul>
                      <a
                        className="btn btn--white full-w"
                        href="https://eqs.alriyadh.gov.sa/Pages/Ar/ArchiveSurvey.aspx"
                      >
                        {localizer.getJsonKey("SurveyArchive")}{" "}
                        <i className="fas fa-arrow-left"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
