import React, { useState, useContext, useEffect, useRef } from "react";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, attachmentToken } from "../../Config/Config.json";
import _ from "lodash";
import {
    DetailsLoader,
    RepeatableTableRows,
    ThreeDots,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext, useFormik } from "formik";
import * as Yup from "yup";
import Helpers from "../../Helpers/Helpers";
import useLocalizer from "../../Localization/useLocalizer";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Register the plugins
registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

function AddRequest() {
    const context = useContext(SessionContext);

    const localizer = useLocalizer();
    const [invalidFile, setinvalidFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [lookups, setLookups] = useState({});
    const [departmentsName, setDepartmentsName] = useState({});
    const [isDone, setIsDone] = useState(false);
    const [amanadepId, setAmanaDepId] = useState(null);
    const [otherCategoryID, setOtherCategoryID] = useState(null);
    var otherCategory;
    var attachmentId;
    var valuesData;

    const FormSchema = Yup.object().shape({
        fullname: Yup.string().required(
            localizer.getJsonKey("PleaseInsertName")
        ).test('len', 'الاسم يجب ان يحتوي على 50 خانة على الأكثر', val => val?.length <= 50),

        mobileNo: Yup.string().matches(/^([0-9]{10})$/, localizer.getJsonKey("MaxMobileNo")).required(localizer.getJsonKey("PleaseInsertMobNum")),

        email: Yup.string().email(localizer.getJsonKey("EmailNotValid")).required(localizer.getJsonKey("PleaseInsertEmail")).test('len', 'حقل البريد الإلكتروني يجب ان يحتوي على 50 خانة على الأكثر', val => val?.length <= 50),

        departement: Yup.string().required(
            localizer.getJsonKey("PleaseInsertDepartement")
        ).test('len', 'حقل الجهة اللتي تمثلها يجب ان يحتوي على 100 خانة على الأكثر', val => val?.length <= 100),

        challengedescription: Yup.string().required(
            localizer.getJsonKey("PleaseInsertDescription")
        ).test('len', 'حقل وصف التحدي يجب ان يحتوي على 5000 خانة على الأكثر', val => val?.length <= 5000),

        descriptionsolution: Yup.string().nullable().notRequired().max(5000, 'حقل وصف الحل المقترح يجب ان تحتوي على 5000 خانة على الأكثر'),

        relateddepartements: Yup.string().notRequired().max(100, ' حقل الجهات الأخرى المرتبطة يجب ان تحتوي على 100 خانة على الأكثر'),

        suggestionType: Yup.string().required(
            localizer.getJsonKey("PleaseInsertTyperequest")
        ),
        classification: Yup.string().required(
            localizer.getJsonKey("PleaseChooseclassification")
        ),
        anotherClassification: Yup.string().when("classification", {
            is: "9846cf03-2dd7-4546-9dab-0b7889bfcae1",
            then: Yup.string().required(localizer.getJsonKey("PleaseEnterclassification")).test('len', 'تصنيف القطاع يجب ان يحتوي على 50 خانة على الأكثر', val => val?.length <= 50),
        }),
       
        //departementName: Yup.string().when("departementsofamana", {
        //    is: val => val != null ? depID = values.departementsofamana : depID = null,
        //    then: Yup.string().required(localizer.getJsonKey("PleaseEnterclassification")).test('len', 'تصنيف القطاع يجب ان يحتوي على 50 حروف على الأكثر', val => val?.length <= 50),
        //}),

        recaptcha: Yup.string().required(
             localizer.getJsonKey("PleaseInsertCapcha")
         ),
    });

    useEffect(() => {
        if (context.tokenVal != undefined) {
            getContent();
        }

    }, [context.tokenVal]);

    useEffect(() => {
        debugger
        setinvalidFile(false)
        allowedExtensions(files)

    }, [files]);


    const getContent = async function (selected) {
        setIsDone(false)
        const result = await httpService.get(
            apiUrl + "/Suggestions/Suggestions-lookups",
            {
                ReferenceID: PortalID,
            },
            { tokenVal: context.tokenVal }
        ).then((res) => {
            setIsDone(true)
            if (res != null && res.data.data != null) {
                setLookups(res.data.data);
            
            } else {
                setIsDone(true)
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }
        });
    }

        const getDepartmentsName = async function (id) {
        //debugger;
        const result = await httpService.get(
            apiUrl + `/Suggestions/departments-by-type-id?typeid=${id}`,
            {
                ReferenceID: PortalID,
            },
            { tokenVal: context.tokenVal }
        ).then((res) => {
            if (res != null && res.data.data != null) {
                setAmanaDepId(id)
                setDepartmentsName(res.data.data.departmentsList);
            } else {
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
            }
        });
    }

    const handleChange = e => {
        setDepartmentsName({})
        if (e.target.value != "") {
            getDepartmentsName(e.target.value)
        } else if (e.target.value == "") {
            setAmanaDepId(null)
        }
        debugger
 
    }
    const submitRequest = function (values) {
 
        setIsSending(true)
        setFiles([])
        debugger
        httpService
            .post(
                apiUrl + "/Suggestions/add-Suggestions",
                {
                    name: values.fullname,
                    Phone: values.mobileNo,
                    email: values.email,
                    departmentTypeId: amanadepId == null ? null: amanadepId,
                    departmentId: amanadepId == null ? null : values.departementName,
                    representedDepartment: values.departement,
                    suggestionTypeId: values.suggestionType,
                    sectorCategoryId: values.classification,
                    otherSectorCategory: values.anotherClassification,
                    otherSectorName: values.relateddepartements,
                    suggestionDescription: values.challengedescription,
                    solutionDescription: values.descriptionsolution,
                    attachmentHeaderID: attachmentId,

                },
            )
            .then((res) => {
                debugger
                setFiles([])

                if (res.data.data != null && res.data.isDone == true) {
                    Helpers.notify(localizer.getJsonKey("SendVolunteeringSuccess"));
                    setIsSending(false)
                    setTimeout(function () {
                        window.open(window.location.origin + "/ar", '_self')

                    }, 5000);
                } else  {
                    Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
                }
            }).catch((error) => {
                setIsSending(false)
                Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);


            })
    }


    const confirmSubmit = async function (values, submit) {
        valuesData = values
        window.scrollTo({ top: 0, behavior: 'smooth' });
        Helpers.confirmALert(localizer.getJsonKey("ConfirmSubmit"), submitForm, localizer.getLang())
        debugger
    }

    const submitForm = function () {
        setIsSending(true)
        setinvalidFile(false)

     
        debugger
        if (files.length != 0) {
            uploadFile(files, valuesData)
        } else {
            submitRequest(valuesData);
        }
    }


    const uploadFile = async function (files, valuesData) {
        debugger
        for (var i = 0; i < files.length; i++) {
                const headers = {
                    'attachment-token': attachmentToken
                }
                var bodyFormData = new FormData();
                bodyFormData.append('FolderName', 'PrivateSector');
                bodyFormData.append('Token', attachmentToken);
                bodyFormData.append('Attachment.FileName', files[i].file.name);
                bodyFormData.append('Attachment.Extension', files[i].fileExtension);
                bodyFormData.append('Attachment.File', files[i].file);
                if (attachmentId != null && attachmentId != undefined)
                    bodyFormData.append('Attachment.HeaderID', attachmentId);

                const result = await axios.post(apiUrl + "/attachments/add-attachment", bodyFormData, { headers })
                    .then((response) => {
                        debugger
                        if (i == 0) {
                            attachmentId = response.data.data
                        }

                    })
                if (i + 1 == files.length) {
                    debugger
                    submitRequest(valuesData);
                }

        }
    }

    const uploadFiles = function (e, file) {
        debugger

        if (e != null) {
            setinvalidFile(true)
        }
        else {
            setinvalidFile(false)

        }
    }

    function allowedExtensions(files) {

        files.find(file => {
            if (file.fileSize <= 10000000 && file.fileExtension == "png" || file.fileExtension == "docx" || file.fileExtension == "pdf") {
                debugger

            }
            else {
                debugger
                setinvalidFile(true)
            }
        })


    };
    return (
        <React.Fragment >
            <section className="page-title page-title-6 center" id="page-title">
                <div
                    className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
                    style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
                >

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="title">
                                    <h1 className="title-heading">
                                        {localizer.getJsonKey("CommunicationPrivateSector")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap rtl">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item">
                                <a href={localizer.getLang() + "/"}> {localizer.getJsonKey("Home")}</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {localizer.getJsonKey("CommunicationPrivateSector")}
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section class="contact contact-4 rtl" id="contact-4">

                <div class="container">
                    <div class="contact-panel contact-panel-3 form-card" style={{ flexDirection: "column" }} >
                        <div class="heading heading-6" style={{ textAlign: "center" }}>
                            <h2 class="heading-title">
                                {" "}
                                {localizer.getJsonKey("CommunicationPrivateSector")}{" "}
                            </h2>
                            <p class="heading-desc" style={{ marginRight: "30px;", marginLeft: "30px;" }}>
                                {localizer.getJsonKey("CommunicationPrivateSectorDesc")}
                                
                            </p>
                        </div>
                        <div class="contact-card">
                            {!isDone || isSending ?
                                    <ThreeDots ></ThreeDots>
                                :
                                <div class="contact-body">
                                    <Formik
                                        initialValues={{
                                            fullname: "",
                                            email: "",
                                            mobileNo: "",
                                            departement: "",
                                            suggestionType: "",
                                            classification: "",
                                            anotherClassification: "",
                                            departementsofamana: "",
                                            departementName: "",
                                            relateddepartements: "",
                                            challengedescription: "",
                                            descriptionsolution: "",
                                            otherCategory: otherCategory,
                                            recaptcha: "",
                                        }}
                                        validationSchema={FormSchema}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                            setSubmitting(true);
                                            await confirmSubmit(values);
                                            setSubmitting(false);
                                        }}

                                    >
                                        {({ errors, isSubmitting, isValid, setFieldValue, values }) => (
                                            <Form>

                                                <div className="contactForm">

                                          
                                       
                                                        <div className="row label-input">
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("Name")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="fullname"
                                                                    title={localizer.getJsonKey("Name")}
                                                                />
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.fullname}
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("Email")} : <span className="required"><strong className="required"> *</strong></span>
                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    name="email"
                                                                    title={localizer.getJsonKey("Email")}

                                                                />
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.email}
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("MobileNum")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    name="mobileNo"
                                                                    title={localizer.getJsonKey("MobileNum")}

                                                                />
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.mobileNo}
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("Departement")} : <span className="required"><strong className="required"> *</strong></span>

                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    name="departement"
                                                                    title={localizer.getJsonKey("Departement")}

                                                                />
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.departement}
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("TypeRequest")} : <span className="required"><strong className="required"> *</strong></span>
                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    component="select"
                                                                    name="suggestionType"
                                                                    title={localizer.getJsonKey("TypeRequest")}
                                                                >
                                                                    <option value="">
                                                                        {localizer.getJsonKey("TypeRequest")}{" "}
                                                                    </option>
                                                                        {!_.isEmpty(lookups) &&
                                                                            lookups.suggestionTypeList.map((e, i) => (
                                                                                <option key={i} value={e.id}>
                                                                                    {localizer.getLang() != '/ar' ? e.nameEn : e.name}
                                                                                </option>
                                                                            ))}
                                                                </Field>
                                                                <label id="" class=" errorr">
                                                                        {errors && errors.suggestionType}
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("ClassificationSector")} : <span className="required"><strong className="required"> *</strong></span>
                                                                {values.otherCategory}
                                                                </label>
                                                                <Field
                                                                    className="form-control"
                                                                    component="select"
                                                                    name="classification"

                                                                    title={localizer.getJsonKey("ClassificationSector")}
                                                                    >

                                                                    <option value="">
                                                                        {localizer.getJsonKey("ClassificationSector")}{" "}
                                                                        </option>
                                                                        {!_.isEmpty(lookups) &&
                                                                            lookups.sectorCategoryList.map((e, i) => (
                                                                                <option key={i} value={e.id}>
                                                                                    {localizer.getLang() != '/ar' ? e.nameEn : e.name}
                                                                                </option>
                                                                            ))}
                                                                </Field>
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.classification}
                                                                </label>

                                                                </div>
                                                        {values.classification == "9846cf03-2dd7-4546-9dab-0b7889bfcae1"  &&
                                                             
                                                                    <div className="col-12 " >
                                                                        <label className="">
                                                                            {localizer.getJsonKey("AnotherSectorClassification")} : <span className="required"><strong className="required"> *</strong></span>
                                                                  
                                                                        </label>
                                                                        <Field
                                                                            className="form-control"
                                                                            name="anotherClassification"
                                                                            placeholder={localizer.getJsonKey("AnotherSectorClassification")}
                                                                            title={localizer.getJsonKey("AnotherSectorClassification")}

                                                                            >
                                                                        </Field>
                                                                        <label id="" class=" errorr">
                                                                            {errors && errors.anotherClassification}
                                                                        </label>
                                                                    </div>
                                                            }
                                                                <div className="col-12 col-md-6">
                                                                    <label className="">
                                                                        {localizer.getJsonKey("DepartementTypeOfAmana")} :
                                                                    </label>
                                                                    <Field
                                                                        component="select"
                                                                        className="form-control"
                                                                        name="departementsofamana"
                                                                        onChange={handleChange}
                                                                        value={values.departementsofamana[1]}
                                                                        title={localizer.getJsonKey("DepartementTypeOfAmana")}
                                                                    >
                                                                        {_.isEmpty(values.departementsofamana) &&
                                                                            <option value="">
                                                                                {localizer.getJsonKey("DepartementTypeOfAmana")}{" "}
                                                                            </option>
                                                                        }
                                                                        {!_.isEmpty(lookups) &&
                                                                            lookups.departmentTypeList.map((e, i) => (
                                                                                <option key={i} value={[e.departmentTypeID]}>
                                                                                    {e.departmentTypeName}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <label id="" class=" errorr">
                                                                        {errors && errors.departementsofamana}
                                                                    </label>
                                                                </div>
                                                                {values.departementsofamana != null &&
                                                                    <div className="col-12 col-md-6">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("DepartementOfAmana")} :
                                                                        </label>
                                                                        <Field
                                                                            component="select"
                                                                            className="form-control"
                                                                            name="departementName"
                                                                            title={localizer.getJsonKey("DepartementOfAmana")}
                                                                        >

                                                                          {/*  {_.isEmpty(departmentsName) &&*/}
                                                                                <option value="">
                                                                                    {localizer.getJsonKey("PleaseChooseDepartmentType")}{" "}
                                                                                </option>
                                                                    {/*        }*/}

                                                                            {!_.isEmpty(departmentsName) &&
                                                                                departmentsName.map((e, i) => (
                                                                                    <option key={i} value={e.departmentID}>
                                                                                        {e.departmentName}
                                                                                    </option>
                                                                                ))}
                                                                        </Field>
                                                                        <label id="" class=" errorr">
                                                                            {errors && errors.departementName}
                                                                        </label>
                                                                    </div>
                                                                }
                                                              
                                                            <div className="col-12 col-md-6">
                                                                <label className="">
                                                                    {localizer.getJsonKey("RelatedDepartements")} :
                                                                </label>
                                                                <Field className="form-control"
                                                                    title={localizer.getJsonKey("RelatedDepartements")}
                                                                    name="relateddepartements"></Field>
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.relateddepartements}
                                                                </label>
                                                            </div>


                                                            <div className="col-12">
                                                                <label className="">
                                                                    {localizer.getJsonKey("ChallengeDescription")} : <span className="required"><strong className="required"> *</strong></span>
                                                            </label>
                                                            <Field className="form-control" style={{ marginBottom: "0px"} }
                                                                    component="textarea"
                                                                    rows="4"
                                                                    title={localizer.getJsonKey("ChallengeDescription")}
                                                                    name="challengedescription"></Field>
                                                                <label id="" class=" errorr">
                                                                    {errors && errors.challengedescription}
                                                            </label>
                                                            <label class="text--end">
                                                                    {values.challengedescription.length  + '/ 5000'}
                                                            </label>
                                                            </div>

                                                                {values.suggestionType == "3a8c47ac-73b4-4e65-9969-cee897fa4b33" &&
                                                                    <div className="col-12">
                                                                        <label className="">
                                                                            {localizer.getJsonKey("DescriptionSolution")} :
                                                                        </label>
                                                                <Field className="form-control" style={{ marginBottom: "0px" }}
                                                                            component="textarea"
                                                                            rows="4"
                                                                            title={localizer.getJsonKey("DescriptionSolution")}
                                                                            name="descriptionsolution"></Field>
                                                                        <label id="" class=" errorr">
                                                                            {errors && errors.descriptionsolution}
                                                                </label>
                                                                <label class="text--end">
                                                                    {values.descriptionsolution.length + '/ 5000'}
                                                                </label>
                                                                    </div>
                                                                } 
                                                            <div className="col-12">
                                                                <label className="mx-2" style={{ fontFamily: 'hanimation', color: "#9b9b9b" }}>
                                                                    {localizer.getJsonKey("Attachments")}
                                                                </label>
                                                                <br></br>

                                                                <FilePond
                                                                    files={files}
                                                                    allowReorder={true}
                                                                    allowMultiple={true}
                                                                    onupdatefiles={setFiles}
                                                                    maxFiles={3}
                                                                    maxFileSize="10MB"
                                                                    name="files"
                                                                    allowFileSizeValidation={true}
                                                                    allowFileTypeValidation={true}
                                                                    onaddfile={(error, file) => uploadFiles(error, file)}
                                                                    acceptedFileTypes={[
                                                                        'image/png',
                                                                        'application/pdf',
                                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                    ]}

                                                                    fileValidateTypeLabelExpectedTypesMap={{
                                                                        "image/png": ".png",
                                                                        "application/pdf": ".pdf",
                                                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx"
                                                                    }}
                                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                                        labelInvalidField='Field contains invalid files'
                                                                    labelIdle=
                                                                    {localizer.getLang() == '/ar' ? `<p class="filepondtext heading-desc filepond--label-action">اضغط لاختيار المرفقات</p><p class="filepondtext heading-desc">الحد الأقصى لعدد الملفات هو 3</p>` : `<span class="filepondtext heading-desc filepond--label-action">Drag & Drop your files or Browse</span> <p class=" heading-desc">The maximum number of files is 3</p>`}

                                                                />

                                                            </div>

                                                        <div
                                                            className="col-12"
                                                            style={{ marginTop: "10px" }}
                                                        >
                                                            <ReCAPTCHA
                                                                sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                                                                onChange={(response) => {
                                                                    console.log(response);
                                                                    setFieldValue("recaptcha", response);
                                                                }}
                                                            ></ReCAPTCHA>
                                                            <div>

                                                                <label id="" class="col-12 errorr" style={{ position: "relative;" }}>
                                                                    {errors && errors.recaptcha}
                                                                </label>
                                                            </div>

                                                        </div>
                                                            <div className="col-12">
                                                                <button
                                                                    style={{ backgroundColor: '#253745', color: 'white' }}
                                                                    className="btn btn--secondary"
                                                                    type="submit"
                                                                    disabled={invalidFile}
                                                                >
                                                                    {localizer.getJsonKey("Send")}{" "}
                                                                    <i className="fas fa-arrow-left"></i>
                                                                </button>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="contact-result"></div>
                                                            </div>
                                                        </div>
                                          
                                                </div>
                                            </Form>
                                        )}

                                    </Formik>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
}

export default AddRequest;
