import React, { useState, useContext, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";

import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import useLocalizer from "../../Localization/useLocalizer";
import _ from "lodash";
export default function OpenData() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    if (context.tokenVal != undefined) {
      //TODO
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/OpenData/OpenDataStats",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.OpenDataEntity);
      });
  }

  return pageContent.length == 0 ? (
    <ThreeDots></ThreeDots>
  ) : (
    <div className="about-block-wrapper rtl">
      <div className="about-block">
        <div className="heading heading-1">
          <p className="heading-subtitle heading-subtitle-bg">
            {localizer.getJsonKey("OpenData")}
          </p>
          <h2 className="heading-title">
            {localizer.getJsonKey("OpenDataDesc")}
          </h2>

          <div className="signature-block">
            <div className="signature-body d-none">
              <h6>{localizer.getJsonKey("RiyadhMayorName")}</h6>
              <p> {localizer.getJsonKey("RiyadhMayor")}</p>
            </div>
            <Link
              className="btn btn--secondary d-none"
              to={localizer.getLang() + "/contactus/contactmayor"}
            >
              {localizer.getJsonKey("ContactMayor")}
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="counter counter-5 counters-bar">
                <div className="counter-name">
                  <h6> {localizer.getJsonKey("TLSCount")}</h6>
                </div>
                <div className="counter-num">
                  <span className="counting">
                    {pageContent[0] != null && pageContent[0].number}
                  </span>
                </div>
              </div>

              <div className="counter counter-5 counters-bar">
                <div className="counter-name">
                  <h6> {localizer.getJsonKey("BLSCount")}</h6>
                </div>
                <div className="counter-num">
                  {" "}
                  <span className="counting">
                    {pageContent[1] != null && pageContent[1].number}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="counter counter-5 counters-bar">
                <div className="counter-name">
                  <h6> {localizer.getJsonKey("SWPCount")}</h6>
                </div>
                <div className="counter-num">
                  <span className="counting">
                    {pageContent[2] != null && pageContent[2].number}
                  </span>
                </div>
              </div>

              <div className="counter counter-5  counters-bar">
                <div className="counter-name">
                  <h6> {localizer.getJsonKey("TowerCount")}</h6>
                </div>
                <div className="counter-num">
                  <span className="counting">
                    {pageContent[3] != null && pageContent[3].number}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
