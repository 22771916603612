import React, { useState, useContext, useEffect, useRef } from "react";
import SessionContext from "../../Context/SessionContext";
import GridList from "../../Components/GridList";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";

import {
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function CondRegulation() {
  const [mainSelectedOption, setMainSelectedOption] = useState(0);
  // const [subSelectedOption, setSubSelectedOption] = useState(0);
  const [pageContent, setPageContent] = useState();
  const [listContent, setListContent] = useState([]);
  const [contentArr, setContentArr] = useState([]);
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  useEffect(() => {
    async function getContent() {
      const result = await httpService
        .post(
          apiUrl + "/Regulations/GetRegulationsLookups",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.RegulationsEntity);
          //console.log(res);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
      //  getListContent(1);
    }
  }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...contentArr].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setContentArr(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...contentArr].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setContentArr(strAscending);
  }
  async function getListContent(page) {
    const result = await httpService
      .post(
        apiUrl + "/Regulations/GetRegulationsList",
        {
          ReferenceID: PortalID,
          ParentId: mainSelectedOption == 0 ? null : mainSelectedOption,
          Pagination: {
            currentPageIndex: page,
            totalPagesCount: 15,
          },
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //console.log(res);
        //setListContent(res.data.output.RegulationsEntity);
        setListContent(res.data.output);
        page == 1
          ? setContentArr(res.data.output.RegulationsEntity)
          : setContentArr(contentArr.concat(res.data.output.RegulationsEntity));

        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("CondReg")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("Home")}</a>
              </li>
              <li class="breadcrumb-item">
              <a href={localizer.getLang()+"/"}> {localizer.getJsonKey("RM")}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("CondReg")}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("CondReg")}
                    </h5>

                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div>
                                <div class="row">
                                  <div class="col-12 col-md-5">
                                    <label class="form-label">
                                      {localizer.getJsonKey("Category")}
                                    </label>
                                    <select
                                      class="form-control"
                                      value={mainSelectedOption}
                                      onChange={(e) => {
                                        setMainSelectedOption(e.target.value);
                                      }}
                                    >
                                      <option value="0">
                                        {localizer.getJsonKey("All")}
                                      </option>
                                      {pageContent != undefined &&
                                        pageContent.map((x, ind) => (
                                          <option
                                            value={x.id}
                                            key={ind}
                                            class="option"
                                          >
                                            {x.nameAr}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  {/* 
                                  <div class="col-12 col-md-4">
                                    <label class="form-label">
                                      التصنيف الفرعي
                                    </label>
                                    <select
                                      class="form-control"
                                      value={subSelectedOption}
                                      onChange={(e) => {
                                        setSubSelectedOption(e.target.value);
                                      }}
                                    >
                                      <option value="0">الكل</option>
                                      {pageContent != undefined &&
                                        mainSelectedOption != 0 &&
                                        pageContent
                                          .filter(
                                            (m) => m.id == mainSelectedOption
                                          )[0]
                                          .listOfRegulations.map((x, ind) => (
                                            <option
                                              value={x.id}
                                              key={ind}
                                              class="option"
                                            >
                                              {x.nameAr}
                                            </option>
                                          ))}
                                    </select>
                                  </div> */}

                                  <div class="col-12 col-md-3">
                                    <button
                                      onClick={(e) => {
                                        getListContent(1);
                                      }}
                                      class="btn btn--secondary w-100 serch-left-flot"
                                    >
                                      {localizer.getJsonKey("Search")}
                                      <i class="fas fa-arrow-left"></i>
                                    </button>
                                  </div>
                              
                                  <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                         
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                          
                        </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            {listContent.length == 0 && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            <GridList
                              itemsLength={contentArr.length}
                              totalCount={
                                listContent.length != 0
                                  ? listContent.Pagination.totalItemsCount
                                  : 0
                              }
                              nextFun={getListContent}
                            >
                              {contentArr.length != 0 &&
                                contentArr.map((x, ind) => (
                                  <div
                                    key={ind}
                                    class="col-12 col-md-12 col-lg-12"
                                  >
                                    <div
                                      class="feature-panel-holder"
                                      data-hover=""
                                    >
                                      <div class="feature-panel feature-panel-2">
                                        <div class="feature-content">
                                          <div class="avatar-icon">
                                            <img
                                              src="/assets/images/icons/min-logo.png"
                                              alt={localizer.getKey(x.nameAr,x.nameEn)}
                                              title={localizer.getKey(x.nameAr,x.nameEn)}
                                            />
                                          </div>
                                          <h4 class="mar-bot0">{localizer.getKey(
                                                    x.nameAr,
                                                    x.nameEn
                                                  )}</h4>
                                          
                                        </div>
                                        <a href={x.url}>
                                          {" "}
                                          <span>
                                            {localizer.getJsonKey("Donwload")}
                                          </span>{" "}
                                          <i class="fas fa-arrow-left"></i>
                                        </a>

                                        <p class="external-desc d-none">
                                          {localizer.getKey(
                                                    x.briefeContentAr,
                                                    x.briefeContentEn
                                                  )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </GridList>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12 text--center">
                  <ul class="pagination">
                    <li>
                      <a href="#"  aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-right"></i>
                        <div className="d-none"> {localizer.getJsonKey("Next")}</div>
                      </a>
                    </li>
                    <li>
                      <a class="current" href="">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="">2</a>
                    </li>
                    <li>
                      <a href="">3</a>
                    </li>
                    <li>
                      <a href="">4</a>
                    </li>

                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-left"></i>
                        <div className="d-none">{localizer.getJsonKey("Next")}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default CondRegulation;
