import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID,newOpenDataGateWay } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import TotalRequests from "./../EservicesStats/TotalRequests";
import Building from "./../EservicesStats/Building";
import Trading from "./../EservicesStats/Trading";
import Medical from "./../EservicesStats/Medical";
import SWP from "./../EservicesStats/SWP";
import useLocalizer from "../../Localization/useLocalizer";

function DataSetTypesLanding(props) {
  const context = useContext(SessionContext);
  const [pageContent, setPageContent] = useState([]);
  const [listContent, setListContent] = useState([]);
  const localizer = useLocalizer();
  useEffect(() => {
    async function getListContent() {
      const result = await httpService
        .post(
          apiUrl +newOpenDataGateWay+ "/DataSetTypes/GetAll",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setListContent(res.data.data.items);
        });
    }
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);

  return (
    <React.Fragment>
      {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {localizer.getJsonKey("OpenData")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/"}>
                  {" "}
                  {localizer.getJsonKey("Home")}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={localizer.getLang() + "/DataSetLanding"}>
                  {" "}
                  {localizer.getJsonKey("OpenData")}
                </a>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("DataSetTypesLanding")}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section
        class="projects projects-standard projects-standard-1 rtl"
        id="projects-standard-1">
        <div class="container">
          <div class="row" id="projects-all">
            {listContent.map((x, ind) => (
                <div  key={ind} class="col-12 col-md-6 col-lg-3 project-item filter-events filter-riyadh">
                  <div class="project-panel" data-hover="">
                    <div class="project-panel-holder">
                      <div class="project-content">
                        <div class="project-title">
                          <h4>
                            <a href={localizer.getLang()+'/DataSets?type='+x.id}>
                              {localizer.getKey(x.nameAr,x.nameEn)}
                            </a>
                          </h4>
                        </div>
                        <div class="project-cat">
                          <a href={localizer.getLang()+'/DataSets?type='+x.id}>{localizer.getJsonKey("DataSets")}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default DataSetTypesLanding;
