import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID } from "../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import Moments from "moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import useLocalizer from "../Localization/useLocalizer";
import _ from "lodash";
export default function AdvancedSearch() {
  const [resultContent, setresultContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyWord, setKeyword] = useState('');

  const [searchParams] = useSearchParams();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  context.setEntityID(-1);

  useEffect(() => {
    debugger
    if (
      context.tokenVal != undefined &&
      searchParams.get("query") != undefined
    ) {
      let values = {};
      //debugger;
      values.searchTerm = searchParams
        .get("query")
        .replace(/<\/?[^>]+(>|$)/g, "");
      
      setKeyword(values.searchTerm);

      submitSearch(values);
    }
  },[context.tokenVal])

  useEffect(() => {
   
    if (
      context.tokenVal != undefined &&
      searchParams.get("query") != undefined
    ) {
      let values = {};
      //debugger;
      values.searchTerm = searchParams
        .get("query")
        .replace(/<\/?[^>]+(>|$)/g, "");
      
      setKeyword(values.searchTerm);

      submitSearch(values);
    }
  },[searchParams])

  const submitSearch = async function (values) {
    //debugger;
    window.scrollTo(0, 0);
    setresultContent([]);

    setLoading(true);
    const result = await httpService
      .post(
        apiUrl + "/Reference/SearchAsText",
        {
          ReferenceID: PortalID,
          SearchWord: values.searchTerm,
          ListTargetedEntities: values.chkEntityType
            ? values.chkEntityType.map((elem) => ({
                id: elem,
              }))
            : null,
          FromDate: values.fromDate ? values.fromDate : null,
          ToDate: values.toDate ? values.toDate : null,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setresultContent(res.data.output);
        setLoading(false);
      });
  };
 
  const SortDescending=()=>
  {
    let shallow = Object.assign({}, resultContent);
    shallow.SearchResultEntity =
    [...shallow.SearchResultEntity].sort((a, b) =>
    localizer.getKey(
      a.titleAr,
      a.titleEn
    )  >  localizer.getKey(
      b.titleAr,
      b.titleEn
    ) ? -1 : 1,
  );
  
   setresultContent(shallow);

  }
  const SortAscending=()=>
  {
    let shallow = Object.assign({}, resultContent);
    shallow.SearchResultEntity =
    [...shallow.SearchResultEntity].sort((a, b) =>
    localizer.getKey(
      a.titleAr,
      a.titleEn
    )  >  localizer.getKey(
      b.titleAr,
      b.titleEn
    ) ? 1 : -1,
  );
  
   setresultContent(shallow);
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("AdvancedSearch")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 order-0 srt-srt-right">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <div class="contact-panel no-marr">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <h5 class="entry-heading">
                                {" "}
                                {localizer.getJsonKey("AdvancedSearch")}{" "}
                              </h5>
                              <p class="card-desc">
                                {localizer.getJsonKey("PleaseUseForm")}
                              </p>
                              <div class="">
                                <div class="row">
                                  <Formik
                                    initialValues={{
                                      searchTerm:
                                        searchParams.get("query") != undefined
                                          ? searchParams
                                              .get("query")
                                              .replace(/<\/?[^>]+(>|$)/g, "")
                                          : "",
                                      fromDate: "",
                                      toDate: "",
                                      chkEntityType: [],
                                    }}
                                    onSubmit={(
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                      var  searchQuery =  searchParams.get("query") != undefined ? 
                                      searchParams.get("query").replace(/<\/?[^>]+(>|$)/g, "") : "";

                                      if(values.searchTerm != null && values.searchTerm != searchQuery){
                                        window.location.href=localizer.getLang()+`/AdvancedSearch?query=`+ values.searchTerm
                                      }
                                      else{
                                       // console.log(values);
                                        submitSearch(values);
                                        
                                      }
                                      //resetForm();
                                    }}
                                  >
                                    {({
                                      errors,
                                      touched,
                                      isSubmitting,
                                      isValid,
                                    }) => (
                                      <Form>
                                        <div class="col-12 col-md-12">
                                          <label
                                            class="form-label"
                                            for="searchTerm"
                                          >
                                            {localizer.getJsonKey("SearchText")}
                                          </label>
                                          <Field
                                            class="form-control"
                                            type="text"
                                            id="searchTerm"
                                            name="searchTerm"
                                            spellcheck="true"
                                          
                                            placeholder={localizer.getJsonKey("SearchText")}
                                            title={localizer.getJsonKey("SearchText")}
                                          />
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <label className="form-label">
                                            {localizer.getJsonKey(
                                              "ResultCategory"
                                            )}
                                          </label>
                                          <div
                                            className="custom-radio-group"
                                            id="custom-radio-group"
                                          >
                                              <>
                                              <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={"chk0"}
                                                value="1"
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={"chk0"}>
                                              {localizer.getJsonKey(
                                                "ServiceSearch"
                                              )}
                                              </label>
                                            </div>

                                            <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={"chk1"}
                                                value="2"
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={"chk1"}>
                                              {localizer.getJsonKey(
                                                "ContentSearch"
                                              )}
                                              </label>
                                            </div>
                                            

                                            
                                            <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={"chk2"}
                                                value="3"
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={"chk2"}>
                                              {localizer.getJsonKey(
                                                "CondRegulationSearch"
                                              )}
                                              </label>
                                            </div>
                                            <div class="custom-control">
                                              <Field
                                                className="custom-control-input"
                                                id={"chk3"}
                                                value="4"
                                                type="checkbox"
                                                name="chkEntityType"
                                              />
                                              <label htmlFor={"chk3"}>
                                              {localizer.getJsonKey(
                                                "OpenDataSearch"
                                              )}
                                              </label>
                                            </div>
                                            
                                              </>


                                          </div>
                                        </div>
                                        <div class="col-12 col-md-12">
                                          <label class="form-label">
                                            {localizer.getJsonKey("FromDate")}
                                          </label>

                                          <Field
                                            class="form-control"
                                            type="date"
                                            name="fromDate"
                                            title="start date"
                                             max= { Moments(new Date()).format("yyyy-MM-DD")}
                                          />
                                        </div>
                                        <div class="col-12 col-md-12">
                                          <label class="form-label">
                                            {localizer.getJsonKey("ToDate")}
                                          </label>

                                          <Field
                                            class="form-control"
                                            type="date"
                                            name="toDate"
                                            title="end date"
                                            max= { Moments(new Date()).format("yyyy-MM-DD")}
                                          />
                                        </div>
                                        <div class="col-12">
                                          <button
                                            type="submit"
                                            class="btn btn--secondary w-100"
                                          >
                                            {localizer.getJsonKey("Search")}
                                            <i class="fas fa-arrow-left"></i>
                                          </button>
                                        </div>
     
                                        <div class="col-12">
                                          <div class="contact-result"></div>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-8 order-1">
              {loading && <ThreeDots></ThreeDots>}

              <div style={{display:"flex",flexDirection:"row"}}>
                <h5 class="entry-heading">
                  {localizer.getJsonKey("SearchResult")}
                </h5>
                <p style={{marginLeft:"60px",marginRight:"50px"}} class="res-note">
                  {" "}
                  {localizer.getJsonKey("Showing")}{" "}
                  {resultContent.SearchResultEntity &&
                    resultContent.SearchResultEntity.length}{" "}
                  {localizer.getJsonKey("Result")}
                </p>
                <div style={{}}>
                                        <button style={{height:30}}
                            class="btn btn--secondary w-100 "
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                                        </div>
                <div >
                                        <button
                            class="btn btn--secondary w-100 " style={{height:30}}
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                                        </div>
              </div>
              {!_.isEmpty(resultContent) &&
                resultContent.SearchResultEntity.length == 0 &&
                localizer.getJsonKey("NoData")}

              {!_.isEmpty(resultContent) &&
                resultContent.SearchResultEntity.map(
                  (e, i) =>
                    (
                      <div key={i} class="srt-srt srt-srt-overflow" >
                        {e.url?.indexOf("http") == -1 ? (
                          <Link
                            to={
                              localizer.getLang() +
                              "/" +
                              e.url
                                .replace("govservices", "govservicesdetails")
                                .replace("eservices", "eservicesdetails") +
                              "/" +
                              e.id
                            }
                          >
                            <div class="entry-widget-bio">
                              <div class="entry-widget-content">
                                <div class="entry-bio-desc">
                                  <h4>
                                    {localizer.getKey(e.titleAr, e.titleEn)}
                                  </h4>
                                 
                                    
                                  <i
                                    class="fas fa-calendar-alt"
                                    style={{
                                      display: "inline-block",
                                      padding: "5px",
                                    }}
                                  ></i>
                                  <Moment format="MM-DD-YYYY">
                                    {e.createdDate}
                                  </Moment>
                                  <i
                                    class="fas fa-clock"
                                    style={{
                                      display: "inline-block",
                                      padding: "5px",
                                    }}
                                  ></i>
                                  <Moment format="hh:mm">
                                    {e.createdDate}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <a href={e.url} target={"_blank"} aria-label={localizer.getKey(e.titleAr, e.titleEn)}>
                            <div class="entry-widget-bio">
                              <div class="entry-widget-content">
                                <div class="entry-bio-desc">
                                  <h4>
                                    {localizer.getKey(e.titleAr, e.titleEn)}
                                  </h4>
                                  
                                  <i
                                    class="fas fa-calendar-alt"
                                    style={{
                                      display: "inline-block",
                                      padding: "5px",
                                    }}
                                  ></i>
                                  <Moment format="MM-DD-YYYY">
                                    {e.createdDate}
                                  </Moment>
                                  <i
                                    class="fas fa-clock"
                                    style={{
                                      display: "inline-block",
                                      padding: "5px",
                                    }}
                                  ></i>
                                  <Moment format="hh:mm">
                                    {e.createdDate}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </a>
                        )}
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
