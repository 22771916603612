import React, { useState, useContext, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function Publications() {
  const [mainSelectedOption, setMainSelectedOption] = useState(0);
  const [subSelectedOption, setSubSelectedOption] = useState(0);
  const [pageContent, setPageContent] = useState();
  const [listContent, setListContent] = useState();
  const context = useContext(SessionContext);
  const myRef = useRef();
  const localizer = useLocalizer();

  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/Documents/GetVersionsDocumentsLookups",
          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.DocumentsEntity);
        });
    }
    if (context.tokenVal != undefined) {
      //console.log("token effect " + context.tokenVal);
      getContent();
      getListContent();
    }
  }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setListContent(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setListContent(strAscending);
  }
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Documents/GetDocumentVersion",
        {
          ReferenceID: PortalID,
          ParentId:
            subSelectedOption == 0 && mainSelectedOption == 0
              ? null
              : subSelectedOption == 0
              ? mainSelectedOption
              : subSelectedOption,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setListContent(res.data.output.DocumentsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {" "}
                    {localizer.getJsonKey("Publications")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                {localizer.getJsonKey("Publications")}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {" "}
                      {localizer.getJsonKey("Publications")}
                    </h5>

                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div>
                                <div class="row">
                                  <div class="col-12 col-md-3">
                                    <label class="form-label">
                                      {localizer.getJsonKey("MainCategory")}
                                    </label>
                                    <select
                                      class="form-control"
                                      value={mainSelectedOption}
                                      onChange={(e) => {
                                        setMainSelectedOption(e.target.value);
                                        setSubSelectedOption(0);
                                      }}
                                    >
                                      <option value="0">
                                        {" "}
                                        {localizer.getJsonKey("All")}
                                      </option>
                                      {pageContent != undefined &&
                                        pageContent.map((x, ind) => (
                                          <option
                                            value={x.id}
                                            key={ind}
                                            class="option"
                                          >
                                            {localizer.getKey(
                                              x.nameAr,
                                              x.nameEn
                                            )}
                                          </option>
                                        ))}
                                    </select>
                                  </div>

                                  <div class="col-12 col-md-3">
                                    <label class="form-label">
                                      {localizer.getJsonKey("SubCategory")}
                                    </label>
                                    <select
                                      class="form-control"
                                      value={subSelectedOption}
                                      onChange={(e) => {
                                        setSubSelectedOption(e.target.value);
                                      }}
                                    >
                                      <option value="0">
                                        {" "}
                                        {localizer.getJsonKey("All")}
                                      </option>
                                      {pageContent != undefined &&
                                        mainSelectedOption != 0 &&
                                        pageContent
                                          .filter(
                                            (m) => m.id == mainSelectedOption
                                          )[0]
                                          .listOfDocuments.map((x, ind) => (
                                            <option
                                              value={x.id}
                                              key={ind}
                                              class="option"
                                            >
                                              {localizer.getKey(
                                                x.nameAr,
                                                x.nameEn
                                              )}
                                            </option>
                                          ))}
                                    </select>
                                  </div>

                                  <div class="col-12 col-md-2">
                                    <button
                                      onClick={getListContent}
                                      class="btn btn--secondary w-100 serch-left-flot"
                                    >
                                      {localizer.getJsonKey("Search")}{" "}
                                      <i class="fas fa-arrow-left"></i>
                                    </button>
                                  </div>
                                  <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                         
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                          
                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="single-product">
                      <div class="external-sub-logo">
                        <div class="features features-3 row-hover">
                          <div class="row">
                            {listContent == undefined && (
                              <RepeatableTableRows style={{ width: "100%" }} />
                            )}
                            {listContent != undefined &&
                              listContent.map((x, ind) => (
                                <div
                                  key={ind}
                                  class="col-12 col-md-12 col-lg-12"
                                >
                                  <div
                                    class="feature-panel-holder"
                                    data-hover=""
                                  >
                                    <div class="feature-panel feature-panel-2">
                                      <div class="feature-content">
                                        <div class="avatar-icon">
                                          <img
                                            src="/assets/images/icons/min-logo.png"
                                            alt={localizer.getKey(x.nameAr, x.nameEn)}
                                            title={localizer.getKey(x.nameAr, x.nameEn)}
                                          />
                                        </div>
                                        <h4 class="mar-bot0">
                                          {localizer.getKey(x.nameAr, x.nameEn)}
                                        </h4>
                                      </div>
                                      <a href={ 'https://resources.alriyadh.gov.sa:9443/archive/docs/' +  x.url}>
                                        {" "}
                                        <span>
                                          {" "}
                                          {localizer.getJsonKey("Donwload")}
                                        </span>{" "}
                                        <i class="fas fa-arrow-left"></i>
                                      </a>

                                      <p class="external-desc">
                                        {localizer.getKey(
                                          x.briefeContentAr,
                                          x.briefeContentEn
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-12 text--center">
                  <ul class="pagination">
                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a class="current" href="">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="">2</a>
                    </li>
                    <li>
                      <a href="">3</a>
                    </li>
                    <li>
                      <a href="">4</a>
                    </li>

                    <li>
                      <a href="#" aria-label={localizer.getJsonKey("Next")}>
                        <i class="fas fa-arrow-left"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Publications;
