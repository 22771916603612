import React, { useState, useContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import FileViewer from 'react-file-viewer';
import { Link, useParams, useSearchParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import axios from "axios";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, GMapsKey ,newOpenDataGateWay} from "../../Config/Config.json";
import ReactHtmlParser, {processNodes, convertNodeToElement,htmlparser2} from "react-html-parser";
import {DetailsLoader,RepeatableTableRows} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import _ from "lodash";
import useLocalizer from "../../Localization/useLocalizer";
import XMLViewer from "react-xml-viewer";
import ReactJson from "react-json-view";
function DataSetFileReview() {
  const [pageContent, setPageContent] = useState([]);
  const [XML, setXML] = useState('');
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const localizer = useLocalizer();
  const customTheme = {
    "attributeKeyColor": "#FF0000",
    "attributeValueColor": "#000FF"
  }

  useEffect(() => {
    const PreviewAttachment=async function() {
      axios(apiUrl +newOpenDataGateWay+ "/Attachment/download/" + searchParams.get('id') + "/" + searchParams.get('name'), {
        method: "POST",
        responseType: "blob", //Force to receive data in a Blob Format
        Authorization: "bearer " + context.tokenVal,
      }).then((res) => {
          var extension= searchParams.get('name').split('.')[1];
          const file = new Blob([res.data], {
            type:extension=='xlsx'?"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":extension=='xml'||extension=='csv' ?'text/plain':extension=='csv'?'text/csv;charset=utf-8,':undefined,
          });
          if(extension=="xlsx" || extension=="csv"){ 
            var filePath=URL.createObjectURL(file);
            ReactDOM.render(<FileViewer  fileType={extension} filePath={filePath}/>, document.getElementById('excel-file-viewer'));
          }
          else{
              var reader = new FileReader();
              reader.readAsText(file);
              reader.onloadend = function(){
                var FileAsText =reader.result;
                if(extension=="xml"){
                  var xml=FileAsText.substring(0,1000);
                  ReactDOM.render(<></>, document.getElementById('excel-file-viewer'));
                  ReactDOM.render(<XMLViewer xml={xml} theme={customTheme}/>,document.getElementById('file-viewer'));
                }
                else if(extension=="json"){
                  var json=JSON.parse(FileAsText);
                  ReactDOM.render(<></>, document.getElementById('excel-file-viewer'));
                  ReactDOM.render(<ReactJson src={json}/>, document.getElementById('file-viewer'));
                }
              }
        };
        
      });
    }
    if (context.tokenVal != undefined) {
      PreviewAttachment();
    }
  }, [context.tokenVal, searchParams]);

  async function downloadAttachment() {
    axios(apiUrl + newOpenDataGateWay+"/Attachment/download/" + searchParams.get('id')+ "/" + searchParams.get('name'), {
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      Authorization: "bearer " + context.tokenVal,
    }).then((res) => {
      
      var extension= searchParams.get('name').split('.')[1];
      const file = new Blob([res.data], {
        type:extension=='xlsx'?"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":extension=='xml'||extension=='csv' ?'text/plain':extension=='csv'?'text/csv;charset=utf-8,':undefined,
    });
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', URL.createObjectURL(file));
      link.setAttribute('download',searchParams.get('name'));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
  
  async function UpdateDownloadTimes() {
    const result = await httpService
      .post(
        apiUrl +newOpenDataGateWay+ "/Attachment/UpdateDownloadTimes",
        {
          id: searchParams.get('id'),
          attachmentTypeId: 1,
          entityID: 1,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
      });
  }


  return (
  <React.Fragment>
    {localizer.getLang()=="/ar"?
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_data.css"
        />
      :<link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/new_open_dataEn.css"
        />
      }
    <section class="page-title page-title-6 center" id="page-title">
      <div
        class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
        style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="title">
                <h1 class="title-heading">
                  {localizer.getJsonKey("DataSetFileReview")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-wrap rtl">
        <div class="container">
          <ol class="breadcrumb d-flex">
            <li class="breadcrumb-item">
              <a href={localizer.getLang() + "/"}>
                {" "}
                {localizer.getJsonKey("Home")}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <a href={localizer.getLang()+'/DataSets'}>
                {localizer.getJsonKey("DataSets")}
                </a>
              </li>
            <li class="breadcrumb-item active" aria-current="page">
              <a href="#"> {localizer.getJsonKey("DataSetFileReview")}</a>
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section class="single-product rtl mar-top50 mar-bot50" id="single-product">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12">
              <Link to='' 
              onClick={(event) => {downloadAttachment();UpdateDownloadTimes();}}>
                <span>
                  {localizer.getJsonKey("DownloadFILE")}
                </span>{" "}
                <i class="fas fa-download"></i>
              </Link>
            </div>
            <div class="col-12 col-lg-12">
              <div id="file-viewer" class="mt-5"></div>
              <div id="excel-file-viewer" class="mt-5" style={{ height: '300px' }}></div>
            </div>
          </div>
        </div>
    </section>
  </React.Fragment>
  );
}

export default DataSetFileReview;
