export default async function speakHover(targetText) {
    let native_voices = speechSynthesis.getVoices();
    let msg = new SpeechSynthesisUtterance();
    let voices = window.speechSynthesis.getVoices();
    let lang = "ar-SA";
    if (window.location.href.indexOf("/en") > -1) {
        lang = "en-US";
    }
    msg.voice = voices.find((element) => element.lang == lang);
    msg.lang = lang;
    msg.text = msg.text = targetText;
    window.speechSynthesis.speak(msg);
}
window.addEventListener("beforeunload", function () {
    window.speechSynthesis.cancel();
});