import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
    DetailsLoader,
    RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import Skeleton from "react-loading-skeleton";
import useLocalizer from "../../Localization/useLocalizer";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from "react-html-parser";
import "./leaflet.css";
import { useMap } from 'react-leaflet';
import L from "leaflet";
//import data from "../../data.json"


export default function Search(props) {

    const context = useContext(SessionContext);
    const localizer = useLocalizer();
    const map = useMap();
    const [searchInput, setSearchInput] = useState('');
    const [selectPosition, setSelectPosition] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    // const gardens = data[0].Gardens


    const searchItems = (searchValue) => {
        
        setSearchInput(searchValue);
        if (searchInput != '' && searchInput.length > 1) {

            const filteredData = props.items.filter((item) => {
                    return Object.values(item.Neighborhood).join('').toLowerCase().includes(searchInput.toLowerCase())
                
             })
            setFilteredResults(searchInput != '' ? filteredData : []);

        }
    }
    const ResetCenterView = (item) => {
        setSelectPosition(item);
        setSearchInput(item.Neighborhood)
        setIsSelected(true)
        setFilteredResults([])
    }

    useEffect(() => {
        if (selectPosition) {
            //map.setView(
            //    L.latLng(selectPosition?.Latitude, selectPosition?.Longitude),
            //    16,
            //    {
            //        animate: true
            //    }
            //)
           map.flyTo([selectPosition?.Latitude, selectPosition?.Longitude], 16);
  
        }

    }, [selectPosition]);

    useEffect(() => {
        if (searchInput.length == 0) {
       
            setFilteredResults([])
            setIsSelected(false)
          
            map.setView(
                L.latLng(props.center),
                12,
                {
                    animate: true
                }
            )
        }
    }, [searchInput]);

    return (

        <div class="form-inline d-flex justify-content-center md-form form-sm  mt-2 edit-location-button">

            <div class="card">
                <div class="form-group has-search">
                    <input type="text" class="form-control garden-details" style={{marginBottom: "0px" }} placeholder={localizer.getJsonKey("Search")} value={searchInput} onChange={(e) => searchItems(e.target.value)} />
                {/*    <span class="fa fa-search form-control-feedback mt-2" style={{ direction: "ltr" }}></span>*/}
                
                </div>
                {searchInput.length > 1 &&
                    filteredResults.length == 0 && isSelected == false ?
                    (<li key="index" class="list-group-item d-flex justify-content-center">..لايوجد نتائج</li>)
                    : filteredResults.map((item, index) => {
                        if (index < 3)
                        return (           
                            <ul class="list-group">
                                <li key="index" class="list-group-item d-flex  pointer-item" onClick={() => ResetCenterView(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "0px 8px" }} height="16" width="12" viewBox="0 0 384 512"><path fill="#9b9b9b" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>        {item.Neighborhood}</li>
                    
                                    </ul>
                        )
                    })
            } 

            </div>
        </div>
    );
}

