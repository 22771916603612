import React, { useState, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import Helpers from "../../Helpers/Helpers";
import _ from "lodash";
import useLocalizer from "../../Localization/useLocalizer";
import html2canvas from 'html2canvas';
import "./CertificateStyle.css"
 
function DownloadCertificate(props) {
    const localizer = useLocalizer();
   // const { userid, name } = useParams()
    const { name } = useParams()
    const [volunteerId, setVolunteerId] = useState(JSON.parse(localStorage.getItem("userId")));
   // const userId = Helpers.encryptDecrypt(userid);

        debugger
    useEffect(() => {

        debugger
            html2canvas(document.querySelector("#capture")).then(canvas => {
                document.body.appendChild(canvas)
                return canvas
            }).then(canvas => {
                const image = canvas.toDataURL('image/png');
                const a = document.createElement('a')
                a.setAttribute('download', 'شهادة تطوع.png')
                a.setAttribute('href', image)
                a.click()
                canvas.remove()
                localStorage.removeItem('userId') 
                Helpers.notify("تم التحميل بنجاح");
      
            })
              localStorage.removeItem('userId') 

    }, []);
    useEffect(() => {

        if (volunteerId == null) {
            Helpers.notify('حدث خطأ غير متوقع. نرجو التواصل مع خدمة الدعم الفني', true);
        }

    }, [volunteerId]);



    return (

        <div className="scroll-image">
            {volunteerId != null ?
                <div class="head-text mx-auto" id="capture" style={{ width: "1098px !important;", height: "776px !important;" }}>
                    <div class="head-image" style={{ width: "1098px !important;", height: "776px !important;", overflow: "auto;" }}>
                        <img src="/assets/images/volunteering/certificate.png" alt="Certificate" style={{ width: "100% !important;", height: "776px !important;" }} /></div>
                    <div class="text-on-image"><h4 class="mar-bot0" style={{ fontSize: "10px !important;" }}>
                        {name} </h4>
                    </div>
                    <div class="text2-on-image mt-2"><h4 class="mar-bot0" style={{ fontSize: "10px !important;" }}>{volunteerId ? volunteerId : ""}</h4></div>
                </div> : "" }

        </div>
                    );
}

export default DownloadCertificate;
