import React, { useState, useContext, useEffect,useRef } from "react";
import { Link, useSearchParams, useParams, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import MenuList from "../../MasterControls/Controls/MenuList";
import useLocalizer from "../../Localization/useLocalizer";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import SiteMapList from "../../MasterControls/Controls/SiteMapList";

function SiteMap(props) {
  const context = useContext(SessionContext);
  const [showMenu, setshowMenu] = useState(false);
  const menutoggler = useRef();
  const [menu, setMenu] = useState();
  const [showLi1, setshowLi1] = useState(false);
  const [showLi2, setshowLi2] = useState(false);
  const [showLi3, setshowLi3] = useState(false);
  const [showLi4, setshowLi4] = useState(false);
  const [showLi5, setshowLi5] = useState(false);
  const localizer = useLocalizer();
  const menuClick = (e) => {
    //window.collapsMenu(".navbar-toggler");
    //c/onsole.log(e);
    //debugger;
    //console.log(e);
    // e.currentTarget.click();
    //   e.stopPropagation();
    //e.nativeEvent.stopImmediatePropagation();
    setshowMenu(!showMenu);
  };

  useEffect(() => {
    async function getMenu() {
      //debugger;

      const result = await httpService
        .post(
          apiUrl + "/Menus/GetSiteMap",

          { ReferenceID: PortalID },
          { tokenVal: context.tokenVal }
        )
        .then((res) => {

          setMenu(res.data.output.MenusEntity.subMenus);
        });
    }
    if (context.tokenVal != undefined) {
      getMenu();
    }
  }, [context.tokenVal, false]);
  const location = useLocation();

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                   خريطة الموقع
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              {localizer.getJsonKey("sitemap")}    
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="align-items-center col-12">
            <center><h4>  {localizer.getJsonKey("sitemap")}    </h4> </center>
          </div>
          <div className="row">
            <div class="col-12 col-lg-12 order-0">
             
              <div className="service-entry">
                <div className="entry-content">
                  <div className="entry-introduction">
                    {
                      localizer.getLang() =="/ar" ? 
                      (
                          <>
                             {menu != undefined && (
                      <div>
                        <ul class="navbar-nav me-auto">


                        {
                              menu.map(menuItem =>
                                (
                                  <>
                                    <li key={menuItem.id}>
                                      <span className="bg-theme col-form-label-lg d-block p-2 rounded-2" style={{color:'white', margin: '16px'}}>
                                        {menuItem != undefined &&
                                        localizer.getKey(menuItem.nameAr, menuItem.nameEn)}{" "}
                                      </span>
                                    </li>

                                    <ul key={"ul_"+menuItem.id} style={{margin:'10px 5% 10px 10px'}}>
                                      <li key={"li_"+menuItem.id}>
                              
                                        <div class="row">
                                          <div class="col-12">

                                              <SiteMapList 
                                                menuProp={menuItem.subMenus}
                                                source="parent"
                                              ></SiteMapList>
                                            
                                          </div>
                                        </div>
                              
                                      </li>
                                    </ul>          
                                  </>
                                ))
                            }



                                       </ul>
            </div>
          )}
                          </>
                      )
                      :
                      (
                        <>
                        {menu != undefined && (
                            <div>
                              <ul class="navbar-nav me-auto">


                              {
                                    menu.map(menuItem =>
                                      (
                                        <>
                                          <li key={menuItem.id}>
                                            <span className="bg-theme col-form-label-lg d-block p-2 rounded-2" style={{color:'white', margin: '16px'}}>
                                              {menuItem != undefined &&
                                              localizer.getKey(menuItem.nameAr, menuItem.nameEn)}{" "}
                                            </span>
                                          </li>

                                          <ul key={"ul_"+menuItem.id} style={{margin:'10px 10px 10px 5%'}}>
                                            <li key={"li_"+menuItem.id}>
                                    
                                              <div class="row">
                                                <div class="col-12">

                                                    <SiteMapList 
                                                      menuProp={menuItem.subMenus}
                                                      source="parent"
                                                    ></SiteMapList>
                                                  
                                                </div>
                                              </div>
                                    
                                            </li>
                                          </ul>          
                                        </>
                                      ))
                                  }



                                            </ul>
                            </div>
                        )}
</>
                      )
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SiteMap;
