// import "./assets/css/vendor.min.css";
// import "./assets/css/style.css";
// import "./assets/css/costum.css";
//  import "./assets/css/LineIcons.css";
import "./styles/preloadstyles.css";
import React, { useEffect, useState,createContext } from "react";
import { CookiesProvider } from "react-cookie";
import UseJsFiles from "./Hooks/UseJsFiles";
import MasterHeader from "./MasterControls/MasterHeader";
import MasterFooter from "./MasterControls/MasterFooter";
import { Routes, Route, Link, Navigate, useLocation, useParams } from "react-router-dom";
import Home from "./InnerPages/Home";
import NewsList from "./InnerPages/News/NewsList";
import NewsDetails from "./InnerPages/News/NewsDetails";
import ParticipationDetails from "./InnerPages/CParticipation/ParticipationDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutAmana from "./InnerPages/AboutAmana/AboutAmana";
import SiteMap from "./InnerPages/AboutAmana/SiteMap";
import ExternalSites from "./InnerPages/ContentPages/ExternalSites";
import BottomComments from "./MasterControls/BottomComments";
import BottomRating from "./MasterControls/BottomRating";
import MasterLayout from "./MasterControls/MasterLayout";
import ParentContactUs from "./ContactUs/ParentContactUs";
import ContactUs from "./ContactUs/ContactUs";
import Form940 from "./ContactUs/Form940";
import Complaint from "./ContactUs/Complaint";
import Corruption from "./ContactUs/Corruption";
import DeafContactUs from "./ContactUs/DeafContactUs";
import OpenDataRequest from "./ContactUs/OpenDataRequest";
import Volunteer from "./ContactUs/Volunteer";
import ContactMayor from "./ContactUs/ContactMayor";
import AboutRiyadh from "./InnerPages/AboutRiyadh/AboutRiyadh";
//import Orgstructure from "./InnerPages/OrgStructure/OrgStructure";
import RMOfficials from "./InnerPages/RMOfficials/RMOfficials";
import CondRegulation from "./InnerPages/AboutAmana/CondRegulation";
import SessionContext from "./Context/SessionContext";
import httpService from "./services/httpService";
import { apiUrl } from "./Config/Config.json";
import Publications from "./InnerPages/AboutAmana/Publications";
import InitiativeList from "./InnerPages/Initiatives/InitiativeList";
import InitiativeDetails from "./InnerPages/Initiatives/InitiativeDetails";
import PrizesList from "./InnerPages/Prizes/PrizesList";
import DepartmentsList from "./InnerPages/Departments/DepartmentsList";
import DepartmentsDetails from "./InnerPages/Departments/DepartmentsDetails";
import { ThemeContext } from "./Context/themeContext";
import AgenciesList from "./InnerPages/Agencies/AgenciesList";
import AgencyDetails from "./InnerPages/Agencies/AgencyDetails";
import MunicipalitiesList from "./InnerPages/Municipalities/MunicipalitiesList";
import MunicipalityDetails from "./InnerPages/Municipalities/MunicipalityDetails";
import AlbumsList from "./InnerPages/ImageGallery/AlbumsList";
import AlbumImages from "./InnerPages/ImageGallery/AlbumImages";
import VideosList from "./InnerPages/VideoGallery/VideosList";
import MayorsList from "./InnerPages/Mayors/MayorsList";
import PrincesList from "./InnerPages/Princes/PrincesList";
import MobAppsList from "./InnerPages/MobileApps/MobileAppsList";
import MobileAppsDetails from "./InnerPages/MobileApps/MobileAppsDetails";

import GovServicesDetails from "./InnerPages/GovServices/GovServicesDetails";
import OpportunitiesList from "./InnerPages/Investment/OpportunitiesList";
import CompetionsList from "./InnerPages/Investment/CompetionsList";
import GenericContent from "./InnerPages/GenericContent/GenericContent";
import AdvancedSearch from "./Search/AdvancedSearch";
import AdsList from "./InnerPages/Advertisements/AdsList";
import AdsDetails from "./InnerPages/Advertisements/AdsDetails";
import EservicesStats from "./InnerPages/EservicesStats/EservicesStats";
import PartnersList from "./InnerPages/Partners/PartnersList";
import LocalPartnersList from "./InnerPages/Partners/LocalPartnersList";
import GovServicesList from "./InnerPages/GovServices/GovServicesList";
import GovServicesCategories from "./InnerPages/GovServices/GovServicesCategories";
import EServicesCategories from "./InnerPages/Eservices/EServicesCategories";
import EServicesList from "./InnerPages/Eservices/EServicesList";
import EServicesDetails from "./InnerPages/Eservices/EServicesDetails";
import PopUp from "./InnerPages/HomeControls/PopUp";
import LastUpdates from "./InnerPages/LastUpdates/LastUpdates";
import MayorsDetails from "./InnerPages/Mayors/MayorsDetails";
import ReactGA from "react-ga";
import Volunteers from "./InnerPages/Volunteers/Volunteers";
import Questions from "./InnerPages/Questions/Questions";
import UnifiedProceduresguide from "./InnerPages/UnifiedProceduresguide/UnifiedProceduresguide";
import AllPartners from "./InnerPages/Partners/AllPartners";
//import useChatbot from "./Hooks/useChatbot";
import EventsList from "./InnerPages/Events/EventsList";
import PortalStat from "./InnerPages/PortalStat/PortalStat";
import ContactUsIndex from "./ContactUs/ComplaintIndex";
import ParentComplaint from "./ContactUs/ParentComplaint";
import ComplaintIndex from "./ContactUs/ComplaintIndex";
import LeadershipList from "./InnerPages/Leadership/LeadershipList";
import LeadershipDetails from "./InnerPages/Leadership/LeadershipDetails";
import MasterNewsLetters from "./InnerPages/NewsLetters/NewsLetters";
import CParticipations from "./InnerPages/CParticipation/CParticipations";
import CoCreations from "./InnerPages/CoCreations/CoCreations";
import CoCreationsDetails from "./InnerPages/CoCreations/CoCreationsDetails";
import DataSetList from "./InnerPages/DataSets/DataSetList";
import DataSetPolices from "./InnerPages/DataSets/DataSetPolices";
import DataSetLanding from "./InnerPages/DataSets/DataSetLanding";
import DataSetTypesLanding from "./InnerPages/DataSets/DataSetTypesLanding";
import DataSetSuccessStories from "./InnerPages/DataSets/DataSetSuccessStories";
import CommissionerActivation from "./InnerPages/DataSets/CommissionerActivation";
import SuccessStoryDetails from "./InnerPages/DataSets/SuccessStoryDetails";
import DataSetRequestSurvey from "./InnerPages/DataSets/DataSetRequestSurvey";
import DataSetFileReview from "./InnerPages/DataSets/DataSetFileReview";
import DataSharingForm from "./InnerPages/DataSets/DataSharingForm";
import DataSetDetails from "./InnerPages/DataSets/DataSetDetails";
import DataSetStat from "./InnerPages/DataSets/DataSetStat";
import AddCommissionerRequest from "./InnerPages/DataSets/AddCommissionerRequest";
import DataSetUserManuals from "./InnerPages/DataSets/DataSetUserManuals";
import Gardens from "./InnerPages/Gardens/Gardens";
import CustomerRegistration from "./InnerPages/CustomerRegistration/CustomerRegistrationForm";
import AddIdea from "./InnerPages/Innovations/AddIdea";
import RequestDetails from "./InnerPages/Volunteers/RequestDetails";
import VerificationRequest from "./InnerPages/Volunteers/VerificationRequest";
import DownloadCertificate from "./InnerPages/Volunteers/DownLoadCertificate";
import PrivateSector from "./InnerPages/PrivateSector/AddRequest"; 
import KingSalmanDistrict from "./InnerPages/KingSalmanDistrict/KingSalmanDistrict"; 
import NewsPaper from "./InnerPages/NewsPaper/PDFViewer"; 
//import { PrimeReactProvider } from 'primereact/context';
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from'firebase/analytics';
import './Hotjar'


//import $ from "jquery";
function App() {
  const EventContext = createContext();
  const [token, setToken] = useState();
  const [entityID, setEntityID] = useState();
  ReactGA.initialize("UA-219965055-1");


  // firebase analytics
  const firebaseConfig = {

    apiKey: "AIzaSyAmtfVu1ciCzDNehSGNfyuuZG1EF1wq7sY",

    authDomain: "alriyadhsite-d9364.firebaseapp.com",

    projectId: "alriyadhsite-d9364",

    storageBucket: "alriyadhsite-d9364.appspot.com",

    messagingSenderId: "633882319634",

    appId: "1:633882319634:web:93ab62f501d5ebc33071cb",

    measurementId: "G-50EXVLNLYK"

  };

  // Initialize Firebase

  const app = initializeApp(firebaseConfig);

  const analytics = getAnalytics(app);



  const [theme, setTheme] = useState("light")
  //useChatbot()
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
  }
  const location = useLocation();

  useEffect(() => {
    async function getToken() {
      //$(".preloader").show();
      const result = await httpService
        .post(apiUrl + "/users/GenerateToken",{})
        .then((result) => {
          setToken(result.data.output.UserJWT);
        });
    }
    getToken();
  }, []);

  

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <CookiesProvider>
      {/* <link href="" class="Colors" rel="stylesheet" /> */}
      <ThemeContext.Provider value={switchTheme}>
      <SessionContext.Provider
        value={{
          tokenVal: token,
          setEntityID: setEntityID,
          entityID: entityID,
        }}
      >
        {token == undefined && (
          <div class="preloader">
            <div class="dual-ring"></div>
          </div>
        )}
        <React.Fragment>
          <ToastContainer></ToastContainer>
          <PopUp></PopUp>

          <div
            className={"wrapper clearfix " + token == undefined ? "d-none" : ""}
            id="wrapperParallax"
            data-theme={theme}
          >
            {/* <MasterHeader></MasterHeader> */}
            <Routes>
              <Route path="/:lid">
                <Route
                  path="RMPartners"
                  element={
                    <MasterLayout
                      title="شركاء الأمانة"
                      renderControl={
                        <AllPartners title="شركاء الأمانة"></AllPartners>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Agencies/:did"
                  element={
                    <MasterLayout
                      title="وكالات الأمانة"
                      renderControl={
                        <AgencyDetails title="وكالات الأمانة"></AgencyDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Agencies"
                  element={
                    <MasterLayout
                      title="وكالات الأمانة"
                      renderControl={
                        <AgenciesList title="وكالات الأمانة"></AgenciesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="UnifiedProceduresguide"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={
                        <UnifiedProceduresguide title=""></UnifiedProceduresguide>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="FAQ"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<Questions title=""></Questions>}
                    />
                  }
                ></Route>
                <Route
                  path="Volunteers"
                  element={
                    <MasterLayout
                      title=" "
                          renderControl={ 
                              <Volunteers title=""></Volunteers>
                                  }
                    />
                  }
                ></Route>
                <Route
                  path="AddIdea"
                  element={
                    <MasterLayout
                      title=" "
                          renderControl={<AddIdea title=""></AddIdea>}
                    />
                  }
                ></Route>
                 <Route
                  path="LastUpdates"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<LastUpdates title=""></LastUpdates>}
                    />
                  }
                ></Route>
                <Route
                  path="RMLocalPartners"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={
                        <LocalPartnersList title=""></LocalPartnersList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="RMPartners"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<PartnersList title=""></PartnersList>}
                    />
                  }
                ></Route>
                <Route
                  path="PortalStat"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<PortalStat title=""></PortalStat>}
                    />
                  }
                ></Route>
                <Route
                  path="EservicesStats"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<EservicesStats title=""></EservicesStats>}
                    />
                  }
                ></Route>
                <Route
                  path="AmanaAds/:did"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<AdsDetails title=""></AdsDetails>}
                    />
                  }
                ></Route>
                <Route
                  path="AmanaAds"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<AdsList title=""></AdsList>}
                    />
                  }
                ></Route>
                <Route
                  path="AdvancedSearch"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<AdvancedSearch title=""></AdvancedSearch>}
                    />
                  }
                ></Route>
                <Route
                  path="Content/:did"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<GenericContent title=""></GenericContent>}
                    />
                  }
                ></Route>
                <Route
                  path="Content/:path/:did"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<GenericContent title=""></GenericContent>}
                    />
                  }
                ></Route>
                {/* <Route
                  path="Content/Structure/:did"
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<Orgstructure title=""></Orgstructure>}
                    />
                  }
                ></Route> */}
                <Route
                  path="InvestmentCompetitions/:did"
                  element={
                    <MasterLayout
                      title="المنافسات الإستثمارية "
                      renderControl={
                        <CompetionsList title="المنافسات الإستثمارية  "></CompetionsList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="InvestmentCompetitions"
                  element={
                    <MasterLayout
                      title="المنافسات الإستثمارية "
                      renderControl={
                        <CompetionsList title="المنافسات الإستثمارية  "></CompetionsList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="InvestmentOpportunities"
                  element={
                    <MasterLayout
                      title="الفرص الإستثمارية "
                      renderControl={
                        <OpportunitiesList title="الفرص الإستثمارية  "></OpportunitiesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="EServices/:cid/:did"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <EServicesDetails title="الخدمات الحكومية"></EServicesDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="EServices/:cid"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <EServicesList title="الخدمات الحكومية"></EServicesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="EServices"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <EServicesCategories title="الخدمات الحكومية"></EServicesCategories>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="GovServicesDetails/:did"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <GovServicesDetails title="الخدمات الحكومية"></GovServicesDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="EServicesDetails/:did"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <EServicesDetails title="الخدمات الحكومية"></EServicesDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="GovServices/:cid/:did"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <GovServicesDetails title="الخدمات الحكومية"></GovServicesDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="GovServices/:cid"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <GovServicesList title="الخدمات الحكومية"></GovServicesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="GovServices"
                  element={
                    <MasterLayout
                      title="الخدمات الحكومية"
                      renderControl={
                        <GovServicesCategories title="الخدمات الحكومية"></GovServicesCategories>
                      }
                    />
                  }
                ></Route>

                <Route
                  path="MobileApplications/:did"
                  element={
                    <MasterLayout
                      title="تطبيقات الأجهزة الذكية"
                      renderControl={
                        <MobileAppsDetails title="تطبيقات الأجهزة الذكية"></MobileAppsDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="MobileApplications"
                  element={
                    <MasterLayout
                      title="تطبيقات الأجهزة الذكية"
                      renderControl={
                        <MobAppsList title="تطبيقات الأجهزة الذكية"></MobAppsList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Princes"
                  element={
                    <MasterLayout
                      title="أمراء الرياض"
                      renderControl={
                        <PrincesList title="أمراء الرياض"></PrincesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Mayors/:did"
                  element={
                    <MasterLayout
                      title="أمناء الرياض"
                      renderControl={
                        <MayorsDetails title="أمناء الرياض"></MayorsDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Mayors"
                  element={
                    <MasterLayout
                      title="أمناء الرياض"
                      renderControl={
                        <MayorsList title="أمناء الرياض"></MayorsList>
                      }
                    />
                  }
                ></Route>
                  <Route
                  path="Leadership/:did"
                  element={
                    <MasterLayout
                      title="قيادات الأمانة"
                      renderControl={
                        <LeadershipDetails title="قيادات الأمانة"></LeadershipDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Leadership"
                  element={
                    <MasterLayout
                      title="قيادات الأمانة"
                      renderControl={
                        <LeadershipList title="قيادات الأمانة"></LeadershipList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="VideosGallery"
                  element={
                    <MasterLayout
                      title="مكتبة الفيديو"
                      renderControl={
                        <VideosList title="مكتبة الفيديو"></VideosList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="ImageGallery/:did"
                  element={
                    <MasterLayout
                      title=" الصور "
                      renderControl={
                        <AlbumImages title="  الصور"></AlbumImages>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="ImageGallery"
                  element={
                    <MasterLayout
                      title="مجموعات الصور "
                      renderControl={
                        <AlbumsList title=" مجموعات الصور"></AlbumsList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Municipalities/:did"
                  element={
                    <MasterLayout
                      title="بلديات الأمانة"
                      renderControl={
                        <MunicipalityDetails title="بلديات الأمانة"></MunicipalityDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Municipalities"
                  element={
                    <MasterLayout
                      title="بلديات الأمانة"
                      renderControl={
                        <MunicipalitiesList title="بلديات الأمانة"></MunicipalitiesList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Departments/:did"
                  element={
                    <MasterLayout
                      title="إدارات الأمانة"
                      renderControl={
                        <DepartmentsDetails title="إدارات الأمانة"></DepartmentsDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Departments"
                  element={
                    <MasterLayout
                      title="إدارات الأمانة"
                      renderControl={
                        <DepartmentsList title="إدارات الأمانة"></DepartmentsList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="awards"
                  element={
                    <MasterLayout
                      title="الجوائز"
                      renderControl={<PrizesList title="الجوائز"></PrizesList>}
                    />
                  }
                ></Route>
                <Route
                  path="AboutAmana/Publications"
                  element={
                    <MasterLayout
                      title="الإصدارات"
                      renderControl={
                        <Publications title="الإصدارات"></Publications>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="sitemap"
                  element={
                    <MasterLayout
                    title="خريطة الموقع"
                      renderControl={
                        <SiteMap title="خريطة الموقع"></SiteMap>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Initiatives/:did"
                  element={
                    <MasterLayout
                      title="مبادرات والمشاريع"
                      renderControl={
                        <InitiativeDetails title="مبادرات والمشاريع"></InitiativeDetails>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="Initiatives"
                  element={
                    <MasterLayout
                      title="مبادرات والمشاريع"
                      renderControl={
                        <InitiativeList title="مبادرات والمشاريع"></InitiativeList>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="AboutAmana/CondRegulation"
                  element={
                    <MasterLayout
                      title=" الإشتراطات واللوائح"
                      renderControl={
                        <CondRegulation title="الإشتراطات واللوائح"></CondRegulation>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="RMOfficials"
                  element={
                    <MasterLayout
                      title="مسؤولو الأمانة"
                      renderControl={
                        <RMOfficials title="مسؤولو الأمانة"></RMOfficials>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="AboutRiyadh"
                  element={
                    <MasterLayout
                      title="عن الرياض"
                      renderControl={
                        <AboutRiyadh title="عن الرياض"></AboutRiyadh>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="ExternalSites"
                  element={
                    <MasterLayout
                      renderControl={
                        <ExternalSites title="المواقع الخارجية"></ExternalSites>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="AboutAmana"
                  element={
                    <MasterLayout
                      renderControl={
                        <AboutAmana title="عن أماتة الرياض"></AboutAmana>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="NewsLetters"
                  element={
                    <MasterLayout
                      renderControl={
                        <MasterNewsLetters title="النشرة البريديه"></MasterNewsLetters>
                      }
                    />
                  }
                ></Route>
                <Route
                  path="ContactUs"
                  element={
                    <MasterLayout
                      renderControl={
                        <ParentContactUs title=" "></ParentContactUs>
                      }
                    />
                  }
                />

                <Route
                  path="Support"
                  element={
                    <MasterLayout
                      renderControl={
                        <ParentComplaint title=" "></ParentComplaint>
                      }
                    />
                  }
                >

                  <Route
                    path=""
                    element={<ComplaintIndex title=""/>}
                  />

                  
                  <Route
                    path="Corruption"
                    element={<Corruption title="   "></Corruption>}
                  ></Route>
                  

                  <Route
                    path="Complaint"
                    element={<Complaint title="   "></Complaint>}
                  ></Route>

                  
                  <Route
                    path="Form940"
                    element={<Form940 title="   "></Form940>}
                  ></Route>
                  <Route
                    path="DeafContactUs"
                    element={<DeafContactUs title="   "></DeafContactUs>}
                  ></Route>
                  <Route
                    path="OpenDataRequest"
                    element={<OpenDataRequest title="   "></OpenDataRequest>}
                  ></Route>
                  <Route
                    path="DataSharingForm"
                    element={<DataSharingForm title="   "></DataSharingForm>}
                  ></Route>
                 <Route 
                      path="CommissionerAdditionRequest"
                      element={<AddCommissionerRequest title="طلب اضافة مفوض"></AddCommissionerRequest>}
                  ></Route>
                  <Route 
                      path="CommissionerActivation"
                      element={<CommissionerActivation title="تفعيل طلب مفوض"></CommissionerActivation>}
                  ></Route>
                   <Route 
                      path="DataSetRequestSurvey"
                      element={<DataSetRequestSurvey title="استبيان طلب مجموعة بيانات"></DataSetRequestSurvey>}
                  ></Route>
                  <Route
                    path="ContactMayor"
                    element={<ContactMayor title="   "></ContactMayor>}
                  ></Route>
                </Route>
                {/* <Route path="news/:did" element={<NewsDetails />}></Route> */}
                <Route
                  path="news/:did"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<NewsDetails title="   "></NewsDetails>}
                    />
                  }
                ></Route>
                <Route
                  path="news"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<NewsList title="   "></NewsList>}
                    />
                  }
                ></Route>
                <Route
                  path="CoCreations"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<CoCreations title="   "></CoCreations>}
                    />
                  }
                ></Route>
                <Route
                  path="CoCreations/:id"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<ParticipationDetails title="   "></ParticipationDetails>}
                    />
                  }
                ></Route>
                 <Route
                  path="c-participations"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<CParticipations title="   "></CParticipations>}
                    />
                  }
                ></Route>
                <Route
                  path="c-participations/:id"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<CoCreationsDetails title="   "></CoCreationsDetails>}
                    />
                  }
                ></Route>
                 <Route path="DataSets/:did"
                  element={
                    <MasterLayout
                      title="مجموعات البيانات"
                      renderControl={
                        <DataSetDetails  title="مجموعات البيانات"></DataSetDetails>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSets"
                  element={
                    <MasterLayout title="مجموعات البيانات"
                      renderControl={
                        <DataSetList title="مجموعات البيانات"></DataSetList>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetStat"
                  element={
                    <MasterLayout title="احصائيات البيانات المفتوحة"
                      renderControl={
                        <DataSetStat title="احصائيات البيانات المفتوحة"></DataSetStat>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetUserManuals"
                  element={
                    <MasterLayout title="أدلةالاستخدام"
                      renderControl={
                        <DataSetUserManuals title="أدلةالاستخدام"></DataSetUserManuals>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetFileReview"
                  element={
                    <MasterLayout  title="استعراض الملف"
                      renderControl={
                        <DataSetFileReview title="استعراض الملف"></DataSetFileReview>
                      }
                    />
                  }
                ></Route>


                <Route path="DataSetPolices"
                  element={
                    <MasterLayout title="سياسات واجراءات"
                      renderControl={
                        <DataSetPolices title="سياسات واجراءات"></DataSetPolices>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetLanding"
                  element={
                    <MasterLayout title="البيانات المفتوحة"
                      renderControl={
                        <DataSetLanding title="البيانات المفتوحة"></DataSetLanding>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetTypesLanding"
                  element={
                    <MasterLayout title="أنواع مجموعات البيانات"
                      renderControl={
                        <DataSetTypesLanding title="أنواع مجموعات البيانات"></DataSetTypesLanding>
                      }/>
                  }
                ></Route>
                <Route path="DataSetSuccessStories/:did"
                  element={
                    <MasterLayout
                      title="تفاصيل قصص النجاح"
                      renderControl={
                        <SuccessStoryDetails  title="تفاصيل قصص النجاح"></SuccessStoryDetails>
                      }
                    />
                  }
                ></Route>
                <Route path="DataSetSuccessStories"
                  element={
                    <MasterLayout title="الاستفادةوقصص النجاح"
                      renderControl={
                        <DataSetSuccessStories title="الاستفادةوقصص النجاح"></DataSetSuccessStories>
                      }/>
                  }
                ></Route>
                <Route
                  path="events"
                  element={
                    <MasterLayout
                      title="   "
                      renderControl={<EventsList title="   "></EventsList>}
                    />
                  }
                ></Route>
                <Route
                  path=""
                  element={
                    <MasterLayout
                      title=" "
                      renderControl={<Home title=""></Home>}
                    />
                  }
                 ></Route>
                   <Route path="Volunteers/VerificationRequest" element={<MasterLayout title="التحقق من الطلب" renderControl={
                                      <VerificationRequest title="التحقق من الطلب"></VerificationRequest>} />}>
                    </Route>
                   <Route path="Volunteers/Certificate/:name"
                                      element={<MasterLayout title="طباعة الشهادة" renderControl={
                                      <DownloadCertificate title="التحقق من الطلب"></DownloadCertificate>} />}>
                  </Route>
                    <Route path="Volunteers/RequestDetails" element={<MasterLayout title="تفاصيل الطلب" renderControl={
                                      <RequestDetails title="تفاصيل الطلب"></RequestDetails>} />}>
                   </Route>
                    <Route path="CustomerDataRegistration" element={<MasterLayout title="تسجيل العملاء" renderControl={
                        <CustomerRegistration title="تسجيل العملاء"></CustomerRegistration>} />}>
                  </Route>
                  <Route path="Gardens" element={<MasterLayout title="حدائق الرياض" renderControl={
                                      <Gardens title="حدائق الرياض"></Gardens>} />}>
                  </Route>
                   <Route path="PrivateSectorCommunication" element={<MasterLayout title="التواصل مع القطاع الخاص" renderControl={
                                      <PrivateSector title="التواصل مع القطاع الخاص"></PrivateSector>} />}>
                  </Route>
                  <Route path="NewsPaper" element={<MasterLayout title="مجلة العاصمة" renderControl={
                                      <NewsPaper title="مجلة العاصمة"></NewsPaper>} />}>
                  </Route>
                  <Route
                  path="KingSalmanDistrict"
                  element={
                    <MasterLayout
                      renderControl={
                        <KingSalmanDistrict title="تطوير حي الملك سلمان"></KingSalmanDistrict>
                      }
                    />
                  }
                ></Route>
              </Route>
            
             
              <Route index path="" element={<Navigate to="ar" />} />
              <Route path="*" element={<Navigate to="ar" />}></Route>
            </Routes>
            {/* <BottomComments></BottomComments>
            <BottomRating></BottomRating>
            <MasterFooter nomargin></MasterFooter> */}
          </div>
          <UseJsFiles></UseJsFiles>
        </React.Fragment>
      </SessionContext.Provider>
      </ThemeContext.Provider>
    </CookiesProvider>
  );
}
 
export default App;