import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import Moment from "react-moment";

function PopUp() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  const location = useLocation();

  useEffect(() => {
    if (
      context.tokenVal != undefined &&
      location.pathname == "/ar" &&
      location.pathname == "/en"
    ) {
      getListContent();
    }
  }, [context.tokenVal, location.pathname]);
  async function getListContent() {
    //debugger;
    await httpService
      .post(
        apiUrl + "/Advertisements/GetLastAdsViewInMainPagePublished",
        {
          ReferenceID: PortalID,
          IsPopUp: true,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.AdvertisementsEntity);
        if (res.data.output.AdvertisementsEntity != null)
          window.InitializePopUp();
      });
  }
  return (
    pageContent != undefined &&
    pageContent != null && (
      <div class="popup-onload">
        <div class="cnt223">
          <img
            src={pageContent.imageUrl}
            class="img-fluid"
            alt="popup"
            title="popup"
          />
          <a href="#" class="close">
            X
          </a>
        </div>
      </div>
    )
  );
}

export default PopUp;
