import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";

import httpService from "../../services/httpService";
import SessionContext from "../../Context/SessionContext";
import Moment from "react-moment";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";

function OpportunitiesList() {
  const [listContent, setListContent] = useState([]);
  const context = useContext(SessionContext);
  const [searchTerm, setSearchTerm] = useState("");
  const localizer = useLocalizer();

  useEffect(() => {
    if (context.tokenVal != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  const SortDescending=()=>
  {
    const strDescending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    ) ? -1 : 1,
  );
  setListContent(strDescending);

  }
  const SortAscending=()=>
  {
    const strAscending = [...listContent].sort((a, b) =>
    localizer.getKey(
      a.nameAr,
      a.nameEn
    )  >  localizer.getKey(
      b.nameAr,
      b.nameEn
    )     ? 1 : -1,
  );
  setListContent(strAscending);
  }
  async function getListContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Investments/GetInvestmentsOpportunitiesList",
        {
          ReferenceID: PortalID,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        //debugger;
        setListContent(res.data.output.InvestmentsEntity);
        context.setEntityID(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section class="page-title page-title-6 center" id="page-title">
        <div
          class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="title">
                  <h1 class="title-heading">
                    {localizer.getJsonKey("InvOpportunity")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-wrap rtl">
          <div class="container">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>

              <li class="breadcrumb-item active" aria-current="page">
                <a href="#"> {localizer.getJsonKey("InvOpportunity")} </a>
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section class="service-single rtl" id="service-single">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-8 order-0">
              <div class="service-entry">
                <div class="entry-content">
                  <div class="entry-introduction entry-infos">
                    <h5 class="entry-heading">
                      {localizer.getJsonKey("InvOpportunity")}
                    </h5>

                    <div class="contact-panel no-padd">
                      <div class="row">
                        <div class="col-12">
                          <div class="contact-card">
                            <div class="contact-body">
                              <div
                                class="contactForm"
                                method="post"
                                action=""
                                novalidate="novalidate"
                              >
                                <div class="row">
                                  <div class="col-12 col-md-5">
                                    <label class="form-label">
                                      {localizer.getJsonKey("SearchAll")}
                                    </label>

                                    <input
                                      class="form-control"
                                      id="name"
                                      type="text"
                                      placeholder={localizer.getJsonKey(
                                        "WriteSearchWord"
                                      )}
                                      title={localizer.getJsonKey(
                                        "WriteSearchWord"
                                      )}
                                      onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                      }
                                      value={searchTerm}
                                    />
                                  </div>
                                  <div class="col-12 col-md-3">
                                    <button class="btn btn--secondary w-100 serch-left-flot d-none">
                                      {localizer.getJsonKey("Search")}{" "}
                                      <i class="fas fa-arrow-left"></i>
                                    </button>
                                  </div>
                                  <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortAscending}
                          >
                            {localizer.getJsonKey("SortAscending")}
                            <i  style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-up"></i>
                          </button>
                         
                        </div>
                        <div class="col-12 col-md-2">
                          <button
                            class="btn btn--secondary w-100 serch-left-flot"
                            onClick={SortDescending}
                          >
                            {localizer.getJsonKey("SortDescending")}
                            <i style={{ backgroundColor: "initial",color:"white" }}  class="fas fa-arrow-down"></i>
                          </button>
                          
                        </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {listContent.length == 0 && (
                      <RepeatableTableRows style={{ width: "100%" }} />
                    )}
                    <div class="table-responsive">
                      <table
                        class="table table-hover table-bordered"
                        cellspacing="0"
                        rules="all"
                        border="1"
                      >
                        <tbody>
                          {listContent.length > 0 &&
                            listContent.filter((x, i) =>
                              searchTerm == ""
                                ? x
                                : x.nameAr.includes(searchTerm)
                            ).length > 0 && (
                              <tr class="GridViewTr">
                                <th scope="col">
                                  {localizer.getJsonKey("InvOpportunityNum")}
                                </th>
                                <th scope="col">
                                  {localizer.getJsonKey("Category")}
                                </th>
                                <th scope="col">
                                  {localizer.getJsonKey("Title")}
                                </th>
                                <th scope="col">
                                  {localizer.getJsonKey("Price")}
                                </th>
                                <th scope="col">
                                  {" "}
                                  {localizer.getJsonKey("Date")}
                                </th>
                              </tr>
                            )}

                          {listContent.length > 0 &&
                            listContent
                              .filter((x, i) =>
                                searchTerm == ""
                                  ? x
                                  : x.nameAr.includes(searchTerm)
                              )
                              .map((x, ind) => (
                                <tr key={ind}>
                                  <td>{x.opportunityNo}</td>
                                  <td>{x.opportunityTypeName}</td>
                                  <td>
                                    <a
                                      class="GridViewLink"
                                      href={x.opportunityUrl}
                                      target="_blank"
                                    >
                                      {x.nameAr}
                                    </a>
                                  </td>
                                  <td> {x.price}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY">
                                      {x.opportunityDate}
                                    </Moment>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="row mar-top50">
                      <div class="col-12 text--center">
                        <ul class="pagination">
                          <li>
                            <a href="#" aria-label={localizer.getJsonKey("Next")}>
                              <i class="fas fa-arrow-right"></i>
                            </a>
                          </li>
                          <li>
                            <a class="current" href="">
                              1
                            </a>
                          </li>
                          <li>
                            <a href="">2</a>
                          </li>
                          <li>
                            <a href="">3</a>
                          </li>
                          <li>
                            <a href="">4</a>
                          </li>

                          <li>
                            <a href="#" aria-label={localizer.getJsonKey("Next")}>
                              <i class="fas fa-arrow-left"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubMenu customMenu={ContentMenuCode} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default OpportunitiesList;
