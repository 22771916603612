import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SessionContext from "../Context/SessionContext";
import httpService from "../services/httpService";
import { apiUrl, PortalID ,newOpenDataGateWay} from "../Config/Config.json";
import _ from "lodash";
import {
  DetailsLoader,
  RepeatableTableRows,
  ThreeDots,
} from "../Components/SkeletonLoader";
import Moment from "react-moment";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import Helpers from "../Helpers/Helpers";
import useLocalizer from "../Localization/useLocalizer";
import ReCAPTCHA from "react-google-recaptcha";

function OpenDataRequest() {
  const context = useContext(SessionContext);
  const localizer = useLocalizer();
  const [dataTypes, setDataTypes] = useState([]);

  useEffect(() => {

    async function getContent() {
      
      const result = await httpService
        .post(
          apiUrl + newOpenDataGateWay+"/DataSetRequestTypes/GetAll",
          { tokenVal: context.tokenVal }
        )
        .then((res) => {

         // console.log(res,"dataTypes");
          setDataTypes(res.data.data.items);
        //  console.log("DataTypes",dataTypes);
        });
    }
    
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  const FormSchema = Yup.object().shape({
    Name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")),
    Email: Yup.string().email(localizer.getJsonKey("EmailNotValid")).required(localizer.getJsonKey("PleaseInsertEmail")),
    PhoneNumber: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")),
    RequestTopic: Yup.string().required(localizer.getJsonKey("PleaseInsertRequestTopic")),
    RequestDescription: Yup.string().required(localizer.getJsonKey("PleaseInsertRequestDescription")),
    RequestTypeID: Yup.number().min(1,localizer.getJsonKey("PleaseInsertRequestType"))
    .required(localizer.getJsonKey("PleaseInsertRequestType")),
    // recaptcha: Yup.string().required(localizer.getJsonKey("PleaseInsertCapcha")),
  });

  const submitForm = async function (values) {
    await httpService
      .post(
        apiUrl + newOpenDataGateWay+"/DataSetRequests",
        {
          // ReferenceID: PortalID,
          Name: values.Name,
          Email: values.Email,
          PhoneNumber: values.PhoneNumber,
          RequestTopic: values.RequestTopic,
          RequestDescription: values.RequestDescription,
          RequestTypeID:values.RequestTypeID,
          // Capcha: values.recaptcha,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
       // console.log(res,"ressssss request");
        if (res.data.succeeded) Helpers.notify(localizer.getJsonKey("RequestSendSuccessfully"));
      });
  };

  return (
    <div className="contact-body tab-pane active" id="opendata" role="tabpanel">
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("OpenDataRequest")}>
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("OpenDataRequest")} </h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  
                  <p>{localizer.getJsonKey("odContactUsDesc")}</p>                  
                  
                  <div className="rate-des">
                  <div className="icons-rate">
                      <i className="fas fa-calendar-check" style={{color: "#08a76c"}}></i> {localizer.getJsonKey("ServiceAveriable")}
                      
                    </div>
                    <div className="icons-rate">
                      <i className="fas fa-clock" style={{color: "#08a76c"}}></i>{localizer.getJsonKey("ODResponseTime")}
                    </div>
                    
                    <div className="icons-rate d-none">
                      <i className="fas fa-star"></i> عدد المقيمين: 23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <Formik
        initialValues={{
          Name: "",
          Email: "",
          PhoneNumber: "",
          RequestTopic: "",
          RequestDescription: "",
          RequestTypeID:"",
          recaptcha: "",
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await submitForm(values, setSubmitting);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ errors, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <div className="contactForm">
              {isSubmitting ? (
                <ThreeDots></ThreeDots>
              ) : (
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="Name"
                      placeholder={localizer.getJsonKey("Name")}
                      title={localizer.getJsonKey("Name")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.Name}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="email"
                      name="Email"
                      placeholder={localizer.getJsonKey("Email")}
                      title={localizer.getJsonKey("Email")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.Email}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      type="text"
                      name="PhoneNumber"
                      placeholder={localizer.getJsonKey("MobileNum")}
                      title={localizer.getJsonKey("MobileNum")}
                    />
                    <label id="" class=" errorr">
                      {errors && errors.PhoneNumber}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      className="form-control"
                      as="select"
                      name="RequestTypeID"
                      placeholder={localizer.getJsonKey("RequestType")}
                      title={localizer.getJsonKey("RequestType")}>
                        <option value='0' class="option">
                              {localizer.getJsonKey("RequestType")}
                        </option>
                        {dataTypes?.length > 0 && dataTypes.map((x, ind) => (
                            <option value={x.id} key={ind} class="option">
                                {localizer.getKey(x.nameAr,x.nameEn)}
                            </option>
                        ))}
                    </Field>
                    <label id="" class=" errorr">
                      {errors && errors.RequestTypeID}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                  <Field
                     className="form-control"
                     cols="30"
                     rows="10"
                     as="textarea"
                     name="RequestTopic"
                     placeholder={localizer.getJsonKey("RequestTopic")}
                     title={localizer.getJsonKey("RequestTopic")}
                    ></Field>
                    <label id="" class=" errorr">
                      {errors && errors.RequestTopic}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                     className="form-control"
                     cols="30"
                     rows="10"
                     as="textarea"
                     type="text"
                     name="RequestDescription"
                     placeholder={localizer.getJsonKey("RequestDescription")}
                     title={localizer.getJsonKey("RequestDescription")}
                    ></Field>
                    <label id="" class=" errorr">
                      {errors && errors.RequestDescription}
                    </label>
                  </div>
                 
                  {/* <div className="col-12" style={{ marginBottom: "10px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld-6wsTAAAAADOSM7S2irkOjcr0HFRdrHIHsyfE"
                      onChange={(response) => {
                       // console.log(response);
                        setFieldValue("recaptcha", response);
                      }}
                    ></ReCAPTCHA>
                    <label id="" class="col-12 errorr">
                      {errors && errors.recaptcha}
                    </label>
                  </div> */}

                  <div className="col-12">
                    <button
                      className="btn btn--secondary"
                      type="submit"
                      disabled={!isValid}
                    >
                      {localizer.getJsonKey("Send")}{" "}
                      <i className="fas fa-arrow-left"></i>
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="contact-result"></div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default OpenDataRequest;
