import React, { useState, useContext, useEffect, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";
import httpService from "../../services/httpService";
import { apiUrl, PortalID, FooterCode } from "../../Config/Config.json";
import { RotatingGallery, ThreeDots } from "../../Components/SkeletonLoader";
//import $ from "jquery";
import Moment from "react-moment";
import useLocalizer from "../../Localization/useLocalizer";
import ErrorHandling from "../../Controls/ErrorHandling";
import { ErrorBoundary } from "react-error-boundary";
import UseJsFiles from "../../Hooks/UseJsFiles";

function AdsSlider() {
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  const params = useParams();
  const localizer = useLocalizer();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (context.tokenVal != undefined && params.lid != undefined) {
      getListContent();
    }
  }, [context.tokenVal]);
  async function getListContent() {
    //debugger;

    await httpService
      .post(
        apiUrl + "/Advertisements/GetPublishedAddsList",
        {
          ReferenceID: PortalID,
          IsHomeSliderAd: true,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.AdvertisementsEntity);
        window.loadslider(".HomeAdsSlider");
      });
  }

  return (
    <section className="slider slider-2" id="slider-2">
      <div className="container-fluid pe-0 ps-0">
        {pageContent.length == 0 ? (
          <ThreeDots></ThreeDots>
        ) : (
          <div
            className="HomeAdsSlider slider-carousel owl-carousel carousel-navs carousel-dots"
            data-slide="1"
            data-slide-rs="1"
            data-autoplay="true"
            data-nav="true"
            data-dots="true"
            data-space="0"
            data-loop="true"
            data-speed="800"
            rtl="false"
          >
            {pageContent.map((e, i) => (
              <div
                key={i}
                className="slide bg-overlay item bg-section"
                style={{ backgroundImage: "url(" + e.imageUrl + ")" }}
              >
                {/* <div
                  className="bg-section"
                  style={{ backgroundImage: "url(" + e.imageUrl + ")" }}
                >
                   <img src={e.imageUrl} alt="Background" />  
                </div> */}
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-12 rtl">
                      <div className="slide-content">
                        <h1 className="slide-headline">
                          {localizer.getKey(e.titleAr, e.titleEn)}
                        </h1>
                        <p className="slide-desc">
                          {localizer.getKey(e.contentAr, e.contentEn)}
                        </p>
                        <div className="slide-action">
                          {e.url.indexOf("http") == -1 ? (
                            <Link
                              className="btn btn--primary"
                              to={localizer.getLang() + e.url}
                            >
                              <span>
                                {" "}
                                {localizer.getKey(e.titleAr, e.titleEn)}
                              </span>
                              <i className="fas fa-arrow-left"></i>
                            </Link>
                          ) : (
                            <a
                              target="_blank"
                              className="btn btn--primary"
                              href={e.url}
                            >
                              <span>
                                {localizer.getKey(e.titleAr, e.titleEn)}
                              </span>
                              <i className="fas fa-arrow-left"></i>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default AdsSlider;
