import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import Moment from "react-moment";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
function AdsDetails() {
  const [pageContent, setPageContent] = useState();
  const context = useContext(SessionContext);
  const localizer = useLocalizer();

  let params = useParams();
  useEffect(() => {
    //debugger;
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal]);

  async function getContent() {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/Advertisements/GetPublishedAd",
        { ReferenceID: PortalID, ID: params.did },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        setPageContent(res.data.output.AdvertisementsEntity);
        context.setEntityID(res.data.output.EntityID);
        //console.log(res.data.output.EntityID);
      });
  }
  return (
    <React.Fragment>
      <section
        className="page-title page-title-blank-2 bg-white rtl"
        id="page-title"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrap">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
                  </li>

                  <li className="breadcrumb-item">
                    <a href=""> {localizer.getJsonKey("RMAds")} </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {pageContent != undefined &&
                      localizer.getKey(
                        pageContent.titleAr,
                        pageContent.titleEn
                      )}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pageContent == undefined && <DetailsLoader />}
      {pageContent != undefined && (
        <section className="blog blog-single rtl" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="blog-entry">
                  <div className="entry-img">
                    <img
                      src="/assets/images/projects/projects/2.jpg"
                      alt="Ads Cover"
                      title="entry image"
                    />
                    <div className="entry-meta">
                      <div className="entry-date">
                        {" "}
                        <span className="date">
                          <Moment format="YYYY/MM/DD">
                            {pageContent.createdDate}
                          </Moment>
                        </span>
                        <span className="year"></span>
                      </div>
                    </div>
                  </div>
                  <div className="entry-content">
                    <div className="entry-title">
                      <h4>
                        {pageContent != undefined &&
                          localizer.getKey(
                            pageContent.titleAr,
                            pageContent.titleEn
                          )}
                      </h4>
                    </div>

                    <div className="entry-bio">
                      {pageContent != undefined &&
                        ReactHtmlParser(
                          localizer.getKey(
                            pageContent.contentAr,
                            pageContent.contentEn
                          )
                        )}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />

                    {/* <div className="entry-holder d-none">
                      <div className="entry-share">
                        <span>
                          <i className="fas fa-cog"></i> اعدادات المحتوى{" "}
                        </span>
                        <div>
                          <a
                            className="share-facebook"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="طباعة المحتوى"
                          >
                            <i className="fa fa-print"></i>
                          </a>

                          <a
                            className="share-facebook"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="القارئ الآلي"
                          >
                            <i className="fa fa-volume-up"></i>
                          </a>

                          <a
                            className="share-facebook"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="ارسال عبر البريد"
                          >
                            <i className="fa fa-envelope"></i>
                          </a>
                          <a
                            className="share-facebook"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="نشر عبر الفيسبوك"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a
                            className="share-instagram"
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="نشر عبر تويتر"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <SubMenu></SubMenu>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default AdsDetails;
