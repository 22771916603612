import React, { useState, useContext, useEffect } from "react";

import { useSearchParams, useParams, useLocation } from "react-router-dom";
import SessionContext from "../../Context/SessionContext";

import httpService from "../../services/httpService";
import { apiUrl, PortalID, ContentMenuCode } from "../../Config/Config.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DetailsLoader,
  RepeatableTableRows,
} from "../../Components/SkeletonLoader";
import SubMenu from "../../Controls/SubMenu";
import useLocalizer from "../../Localization/useLocalizer";
import Helpers from "../../Helpers/Helpers";
import UploadHelpers from "../../Helpers/UploadHelpers"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

function KingSalmanDistrict(props) {
  const localizer = useLocalizer();
  const [pageContent, setPageContent] = useState([]);
  const context = useContext(SessionContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  const location = useLocation();
  useEffect(() => {
    async function getContent() {
      //debugger;
      const result = await httpService
        .post(
          apiUrl + "/KingSalmanDistrict/GetKingSalmanDistrictDetails",
          {},
          { tokenVal: context.tokenVal }
        )
        .then((res) => {
          setPageContent(res.data.output.KingSalmanDistrictEntity);
          //context.setEntityID(res.data.output.KingSalmanDistrictEntity.entityId);
        });
    }
    if (context.tokenVal != undefined) {
      getContent();
    }
  }, [context.tokenVal, location.pathname]);



  const FormSchema = Yup.object().shape({
    name: Yup.string().required(localizer.getJsonKey("PleaseInsertName")).max(150, localizer.getJsonKey("MaxNumberOfChars") + "150"),
    mobileNo: Yup.string().required(localizer.getJsonKey("PleaseInsertMobNum")).max(20, localizer.getJsonKey("MaxNumberOfNumbers") + "20").matches(/^[0-9]+$/, localizer.getJsonKey("NumberValidation")),
    description: Yup.string().required(
      localizer.getJsonKey("PleaseInsertDetails")
    ).max(150, localizer.getJsonKey("MaxNumberOfChars") + "150"),
    email: Yup.string()
      .email(localizer.getJsonKey("EmailNotValid"))
      .required(localizer.getJsonKey("PleaseInsertEmail")),
       //file : Yup.mixed().test(1000, "File Size is too large", value => (value && value.size <= 50000)).test('fileType', "Unsupported File Format", value => ['application/pdf', 'image/jpeg', 'image/png'].includes(value && value.type) ) 
      // file : Yup.mixed()
      // //.nullable()
      // .test('Fichier taille',
      //   'upload file', (value) => !value || (value && value.size <= 1024 * 1024))
      // .test('format',
      //   'upload file', (value) => !value || (value && ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type))),

      file : Yup.mixed().test(
        "fileSize",
        localizer.getJsonKey("FileIsTooLarge") + " > 4MB",
        (value) => UploadHelpers.MbyteCount(value) < 4
      )
      .test(
        "fileFormat",
        localizer.getJsonKey("AllowedExtentions") + " pdf, png, jpg ",
        (value) =>
          value != null ?["data:application/pdf", "data:image/jpeg", "data:image/png"].includes(UploadHelpers.FileExtension(value)):true
      )
  });

  const submitForm = async function (values) {
    //debugger;
    const result = await httpService
      .post(
        apiUrl + "/KingSalmanDistrict/SaveContactUs",
        {
          ReferenceID: PortalID,
          Name: values.name,
          MobileNo: values.mobileNo,
          Description: values.description,
          Email: values.email,
          FileUrl: values.file,
        },
        { tokenVal: context.tokenVal }
      )
      .then((res) => {
        Helpers.notify(localizer.getJsonKey("SendSuccessData"));
      });
  };

  return (
    <React.Fragment>
      <section className="page-title page-title-6 center" id="page-title">
        <div
          className="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section"
          style={{ backgroundImage: "url('/assets/images/page-titles/6.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">
                  <h1 className="title-heading">
                    {pageContent == 0 ? "" : localizer.getLang() == '/ar'? pageContent.nameAr: pageContent.nameEn}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-wrap rtl">
          <div className="container">
            <ol className="breadcrumb d-flex">
              <li className="breadcrumb-item">
                <a href={localizer.getLang()+"/"}>{localizer.getJsonKey("Home")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {localizer.getLang() == '/ar'? pageContent.nameAr: pageContent.nameEn}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="service-single rtl" id="service-single">
        <div className="container">
          <div className="row">
            <div class="col-12 col-lg-8 order-0">
              {pageContent == 0 && <DetailsLoader></DetailsLoader>}
              <div className="service-entry">
                <div className="entry-content">
                  <div className="entry-introduction">
                    {ReactHtmlParser(
                      pageContent != 0 ? localizer.getLang() == '/ar'? pageContent.contentAr : pageContent.contentEn : ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <SubMenu customMenu={ContentMenuCode} /> */}
            {/* <div className="col-12 col-lg-4 order-1">
              <div className="sidebar sidebar-service">
                <div className="widget widget-services">
                  <div className="widget-title">
                    <h5>أمانة الرياض</h5>
                  </div>
                  <div className="widget-content">
                    <ul className="list-unstyled">
                      <li className="active">
                        <a href="">
                          {" "}
                          <span>عن أمانة الرياض</span>
                          <i className="fas fa-arrow-left"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}


{/* Contact */}

<div
      className="contact-body tab-pane active"
      id="president"
      role="tabpanel"
    >
      <div className="service-single p-0 mar-bot50 contact-header">
        <div className="srt-srt">
          <a href="javascript:void(0)" aria-label={localizer.getJsonKey("ContactUs")} >
            <div className="entry-widget-bio">
              <div className="entry-widget-content">
                <div className="entry-bio-desc">
                  <div className="head-box">
                    <div className="titleee">
                      <h4>{localizer.getJsonKey("ContactUs")}</h4>
                    </div>
                    <div className="product-rating stars-rate d-none">
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                      <i className="fas fa-star active"></i>
                    </div>
                  </div>
                  
                 
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Formik
        initialValues={{
          name: "",
          mobileNo: "",
          email: "",
          description: "",
          file: null,
        }}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values);
          resetForm();
        }}
      >
        {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <div className="contactForm">
              <div className="row">
                <div className="col-12 col-md-6">
                <label className="">
                    {localizer.getJsonKey("Name")} : <span className="required"><strong className="required"> *</strong></span>
                </label>
                  <Field
                    className="form-control"
                    type="text"
                    name="name"
                    // placeholder={localizer.getJsonKey("Name")}
                    title={localizer.getJsonKey("Name")}
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.name}
                  </label>
                </div>
                
                <div className="col-12 col-md-6">
                <label className="">
                    {localizer.getJsonKey("Email")} : <span className="required"><strong className="required"> *</strong></span>
                </label>
                  <Field
                    className="form-control"
                    type="email"
                    name="email"
                    //placeholder={localizer.getJsonKey("Email")}
                    title={localizer.getJsonKey("Email")}
                    required=""
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.email}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                <label className="">
                    {localizer.getJsonKey("MobileNum")} : <span className="required"><strong className="required"> *</strong></span>
                </label>
                  <Field
                    className="form-control"
                    type="text"
                    name="mobileNo"
                    //placeholder={localizer.getJsonKey("MobileNum")}
                    title={localizer.getJsonKey("MobileNum")}
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.mobileNo}
                  </label>
                </div>

                <div className="col-12">
                <label className="">
                    {localizer.getJsonKey("Details")} : <span className="required"><strong className="required"> *</strong></span>
                </label>
                  <Field
                    className="form-control"
                    //placeholder={localizer.getJsonKey("Details")}
                    title={localizer.getJsonKey("Details")}
                    name="description"
                    cols="12"
                    rows="4"
                    component="textarea"
                    spellcheck="true"
                  />
                  <label id="" class="errorr" for="contact-name">
                    {errors && errors.description}
                  </label>
                </div>
                <div className="col-12">
                <label className="">
                    {localizer.getJsonKey("Attachments")} 
                </label>
                          <Field
                            className="form-control"
                            placeholder={localizer.getJsonKey("UploadFile")}
                            title={localizer.getJsonKey("UploadFile")}
                            name="file"
                            type="file"
                            value={null}
                            onChange={(event) => {
                              let reader = new FileReader();
                              reader.onloadend = function () {
                                setFieldValue("file", reader.result);
                              };
                              //console.log("files",event.currentTarget.files)
                              //console.log("reader",reader);
                              if(event.currentTarget.files.length > 0) {
                              reader.readAsDataURL(
                                 event.currentTarget.files[0]
                              )
                              
                              }
                              else{
                                setFieldValue("file", reader.result);
                              }
                            }}
                          />
                          { <label id="" class="errorr">
                            {errors && errors.file}
                          </label> }
                        </div>
                <div className="col-12">
                  <button
                    className="btn btn--secondary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {localizer.getJsonKey("Send")}{" "}
                    <i className="fas fa-arrow-left"></i>
                  </button>
                </div>
                <div className="col-12">
                  <div className="contact-result"></div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>








          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default KingSalmanDistrict;
